import React, { Component } from 'react'
import { connect } from 'react-redux'
import { uiRedirect, uiToast } from '../../actions/ui'
import { searchToObject } from '../../utils/utils'
import { page } from '../../hocs/page'
import { Section } from '../../components'
import { ROUTE_STORE } from '../../utils/routingConstants'
import { SENDOWL_JS_MERCHANT_ID } from '../../constants'

class PagePurchaseCallback extends Component {
  componentDidMount () {
    const { dispatch } = this.props
    window.sendOwl.emptyCart(
      SENDOWL_JS_MERCHANT_ID,
      (result) => {
        // ignore result
      })
    const searchObj = searchToObject()
    if (searchObj.subscription === 'mon') {
      dispatch(uiToast({text: `You are premium now! Your monthly subscription is now active.`, bsStyle: 'info'}))
    }
    if (searchObj.subscription === 'yr') {
      dispatch(uiToast({text: `You are premium now! Your annual subscription is now active.`, bsStyle: 'info'}))
    }
    dispatch(uiRedirect(ROUTE_STORE))
  }

  render () {
    return <Section>Purchase completed! Redirecting you to the store...</Section>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session
  }
}

export default page(connect(mapStateToProps)(PagePurchaseCallback))

import React, { Component } from 'react'
import PropTypes from 'prop-types'

class PdfViewerWithEmbed extends Component {
  static propTypes = {
    pdfUrl: PropTypes.string.isRequired,
    allowDownload: PropTypes.bool
  }

  render () {
    const {pdfUrl, allowDownload} = this.props

    const options = allowDownload ? '' : '#toolbar=0' // `#view=Fit&scrollbar=1&toolbar=0&statusbar=1navpanes=1`
    // style={{'pointer-events': 'none'}}
    return <object id='iframepdf' data={pdfUrl + options} type='application/pdf' width='100%'>
      <div>Unfortunately your browser does not support pdf format.</div>
    </object>
  }
}

export default PdfViewerWithEmbed

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { uiFilterVehicles, uiVehiclesRelationship } from '../actions/ui'
import { fetchProfileVehiclesAction, fetchAllVehiclesAction } from '../actions/garageActions'
import { Section, VehicleList, InlineSearchBox } from '../components'
import { Navbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'

class VehiclesView extends Component {
  static propTypes = {
    title: PropTypes.string,
    validRelationships: PropTypes.arrayOf(PropTypes.string),
    userHandle: PropTypes.string,
    filter: PropTypes.string,
    publicOnly: PropTypes.bool
  }

  static defaultProps = {
    validRelationships: null,
    publicOnly: false
  }

  constructor (props) {
    super(props)
    this.loadFilteredVehicles = this.loadFilteredVehicles.bind(this)
  }

  componentWillMount () {
    const { dispatch, validRelationships } = this.props
    if (validRelationships && validRelationships.length > 0) { dispatch(uiVehiclesRelationship(validRelationships[0])) }
  }

  componentDidMount () {
    const { userSession, filter, relationships } = this.props
    if (relationships) { this.getVehiclesFetcher(userSession.token, relationships, filter)() }
  }

  componentWillReceiveProps (newProps) {
    const { relationships, userSession, filter } = this.props
    if (relationships !== newProps.relationships || filter !== newProps.filter) {
      this.getVehiclesFetcher(userSession.token, newProps.relationships, newProps.filter)()
    }
  }

  isRelationshipAllowed (type) {
    const { validRelationships } = this.props
    return validRelationships ? validRelationships.includes(type) : true
  }

  getVehiclesLoader (list, fetcher) {
    return ({ startIndex = 0, stopIndex } = {}) => {
      const total = list.headers ? list.headers.pagination.totalCount : -1
      if ((total < 0 || startIndex < total) && !list.isFetching) { return fetcher(startIndex) }
    }
  }

  getVehiclesFetcher (token, relationships, filter) {
    return (start = 0) => {
      const { dispatch, userHandle, publicOnly } = this.props
      if (publicOnly || (relationships && relationships === 'public')) {
        const relationshipFilter = (!relationships || (relationships === 'shared' || relationships === 'public')) ? 'all' : relationships
        dispatch(fetchAllVehiclesAction(token, relationshipFilter, userHandle, start, { filter }))
      } else {
        const relationshipFilter = (!relationships || (relationships === 'shared')) ? 'all' : relationships
        dispatch(fetchProfileVehiclesAction(token, relationshipFilter, start, { filter }))
      }
    }
  }

  loadFilteredVehicles (filter) {
    this.props.dispatch(uiFilterVehicles(filter))
  }

  renderFilterBar () {
    const { dispatch, relationships, validRelationships } = this.props
    const onChange = value => {
      dispatch(uiVehiclesRelationship(value))
    }
    const showButtons = relationships && validRelationships && validRelationships.length > 1
    return (
      <Navbar className='search-bar' fluid>
        <Navbar.Form>
          {showButtons && <ToggleButtonGroup
            name='vehiclesRelationship'
            type='radio'
            value={relationships}
            onChange={onChange}
            bsSize='small'
          >
            {this.isRelationshipAllowed('mine') && <ToggleButton value={'mine'}>Yours</ToggleButton>}
            {this.isRelationshipAllowed('shared') && <ToggleButton value={'shared'}>Yours+Member</ToggleButton>}
            {this.isRelationshipAllowed('public') && <ToggleButton value={'public'}>All</ToggleButton>}
          </ToggleButtonGroup>}
          <InlineSearchBox filter={this.props.filter} searchFunction={this.loadFilteredVehicles} />
        </Navbar.Form>
      </Navbar>)
  }

  render () {
    const { vehicles, userSession, relationships, title, publicOnly, userHandle } = this.props
    if (!relationships) { return null }

    const { profileVehicles, allVehicles } = vehicles
    const token = userSession.token
    const list = publicOnly || relationships === 'public' ? allVehicles : profileVehicles

    const enableAdd = relationships === 'mine' && !userSession.isAnonymous && (!userHandle || userHandle === userSession.profile.handle)

    const loader = this.getVehiclesFetcher(token, relationships)
    return (
      <Section title={title}>
        {this.renderFilterBar()}
        <VehicleList list={list} loadMoreRows={this.getVehiclesLoader(list, loader)} enableAdd={enableAdd} />
      </Section>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    vehicles: state.garage,
    userSession: state.session,
    relationships: state.ui.vehicles.relationships,
    filter: state.ui.vehicles.filter }
}

export default withRouter(connect(mapStateToProps)(VehiclesView))

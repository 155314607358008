export function asyncActions (actionType) {
  return {
    start: (context) => ({
      type: actionType,
      context
    }),
    success: (result, context) => ({
      type: actionType,
      result,
      context
    }),
    error: (error, context) => ({
      type: actionType,
      error,
      context
    })
  }
}

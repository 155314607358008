import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { RequestForm } from './'
import { Overlay } from '../components'
import { uiRequest } from '../actions/ui'
import { requestTeamMembershipOrSessionAccessAction } from '../actions/inviteActions'
import { deleteSessionShareAction } from '../actions/resultsActions'
import { deleteTeamMemberAction } from '../actions/teamDbActions'
import { Button, Alert } from 'react-bootstrap'
import * as _ from 'lodash'
import { confirmable, createConfirmation } from 'react-confirm'
import SignInButton from '../components/SignInButton'

class RequestOverlay extends React.Component {
  static propTypes = {
    block: PropTypes.any,
    entity: PropTypes.object.isRequired,
    requestButtonText: PropTypes.string,

    explanation: PropTypes.string,
    title: PropTypes.string,
    openButton: PropTypes.element
  }

  static defaultProps = {
  }

  constructor (props) {
    super(props)
    this.state = { show: false }

    this.openOverlay = this.openOverlay.bind(this)
    this.okOperation = this.okOperation.bind(this)
    this.cancelOperation = this.cancelOperation.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.doLeaveTheTeam = this.doLeaveTheTeam.bind(this)
    this.doForgetTheSession = this.doForgetTheSession.bind(this)
  }

  generateOverlayTitle () {
    const { entity, title } = this.props
    if (title) {
      return title
    }

    if (entity.type === 'Team') {
      return `Request to join ${entity.name}`
    }

    if (entity.type === 'Session') {
      return `Request to access to the session`
    }

    console.error('Unexpected entity type: ' + entity.type)
    return 'Request access'
  }

  generateExplanationText () {
    const { entity, explanation } = this.props

    if (explanation) {
      return explanation
    }

    if (entity.type === 'Team') {
      return 'Let the team know who you are and why you want to join.'
    }

    if (entity.type === 'Session') {
      return 'Let the driver know who you are and why you want to see his data.'
    }
    console.error('Unexpected entity type: ' + entity.type)
    return null
  }

  canForget () {
    const { entity, session, teams } = this.props

    if (entity.type === 'Team') {
      const isMyTeam = !session.isAnonymous && session.profile.id === entity.ownerId
      const isMember = !isMyTeam &&
        teams.profileTeams.list &&
        _.find(teams.profileTeams.list, (t) => { return t.id === entity.id })

      return !isMyTeam && isMember
    }

    if (entity.type === 'Session') {
      return entity.shareId && entity.shareKind !== 0
    }

    return false
  }

  generateOpenButton () {
    const { entity, openButton, profileInvites, session, block, requestButtonText } = this.props

    const forget = this.canForget()

    if (openButton) {
      return <span onClick={this.openOverlay}>{openButton}</span>
    }

    if (entity.type === 'Team') {
      if (session.isAnonymous) {
        return <SignInButton text='Sign in to join' />
      }

      if (forget) {
        return <Button block={block} bsStyle='danger' onClick={this.doLeaveTheTeam}>Leave</Button>
      }

      const requested = profileInvites.list && _.find(profileInvites.list, (invite) => {
        return invite.targetObject &&
          (invite.targetObject.handle === entity.handle) &&
          (invite.invitationType === 1) &&
          (invite.invitationStatus === 2) &&
          (invite.ownerObject.handle === session.profile.handle)
      })
      return <Button block={block} bsStyle={requested ? 'info' : null} bsClass={requested ? 'btn' : 'btn-join'} onClick={this.openOverlay} disabled={!!requested}>{requested ? 'Requested' : requestButtonText || 'Join'}</Button>
    }

    if (entity.type === 'Session') {
      if (session.isAnonymous) {
        return <SignInButton text='Sign in to view' />
      }

      if (forget) {
        return <Button block={block} bsStyle='danger' onClick={this.doForgetTheSession}>Forget</Button>
      }

      if (entity.shareKind === 0) {
        // purchased via dataPack
        return <span className='button-like-text'>Data pack</span>
      }

      const requested = profileInvites.list && _.find(profileInvites.list, (invite) => {
        return invite.targetObject &&
          (invite.targetObject.id === entity.id) &&
          (invite.invitationType === 1) &&
          (invite.invitationStatus === 2) &&
          (invite.ownerObject.handle === session.profile.handle)
      })
      return <Button block={block} bsStyle={requested ? 'info' : null} bsClass={requested ? 'btn' : 'btn-join'} onClick={this.openOverlay} disabled={!!requested}>{requested ? 'Requested' : requestButtonText || 'Request'}</Button>
    }
    console.error('Unexpected entity type: ' + entity.type)
    return <span onClick={this.openOverlay}>Hidden entity</span>
  }

  changeHandler (uiState) {
    const { dispatch } = this.props
    dispatch(uiRequest(uiState))
  }

  openOverlay (event) {
    event.stopPropagation()
    this.setState({ show: true })
  }

  render () {
    const { request } = this.props

    return <div>
      {this.generateOpenButton()}
      {this.state.show && <Overlay
        show
        title={this.generateOverlayTitle()}
        buttons={Overlay.createButtons([{ OK: this.okOperation }, { Cancel: this.cancelOperation }])}
        closeAction={this.cancelOperation}>
        <RequestForm
          changeHandler={this.changeHandler}
          explanation={this.generateExplanationText()}
          request={request} />
      </Overlay>}
    </div>
  }

  cancelOperation () {
    const { dispatch } = this.props
    this.setState({ show: false })
    dispatch(uiRequest({ message: '' }))
  }

  okOperation () {
    const { entity, request, dispatch, session } = this.props
    const spec = {
      targetSpec: entity.id,
      targetType: entity.type,
      message: request.message
    }

    if (entity.type === 'Team' || entity.type === 'Session') {
      // same action
      dispatch(requestTeamMembershipOrSessionAccessAction(session.token, spec))
    } else {
      console.error('Unexpected entity type: ' + entity.type)
    }

    // close overlay and clear state
    this.setState({ show: false })
    dispatch(uiRequest({ message: '' }))
  }

  doLeaveTheTeam (event) {
    event.stopPropagation()
    const {dispatch, session, entity} = this.props
    dispatch(deleteTeamMemberAction(session.token, entity, session.profile, true))
  }

  okForgetSessionOperation (entity) {
    const { dispatch, session } = this.props
    dispatch(deleteSessionShareAction(session.token, entity.id, entity.shareId))
  }

  doForgetTheSession (event) {
    const { entity } = this.props

    event.stopPropagation()
    const dialog = ({ show, proceed, dismiss, cancel, confirmation, options }) => {
      const deleteString = `Forget this shared session`
      const buttons = Overlay.createButtons([
        { [deleteString]: () => proceed(entity), bsStyle: 'warning' },
        { Cancel: cancel, bsStyle: 'default' }
      ])
      return (<Overlay
        show={show}
        title={deleteString + '?'}
        closeAction={dismiss}
        buttons={buttons}>
        <Alert bsStyle='warning' >
          <p>You are about to forget this session. You will no longer be able to see its
            telemetry on the web or in Track Attack Analytics. You can always
            ask the owner to share again.</p>
        </Alert>
      </Overlay>)
    }

    const confirmer = createConfirmation(confirmable(dialog))
    confirmer('', {}).then(entity => this.okForgetSessionOperation(entity), () => { })
  }
}

function mapStateToProps (state) {
  return {
    request: state.ui.requests.requests,
    profileInvites: state.invites.profileInvites,
    session: state.session,
    teams: state.teams
  }
}

export default connect(mapStateToProps)(RequestOverlay)

import React from 'react'
import PropTypes from 'prop-types'
import noImage from '../images/no_image.jpg'

const HandleView = ({handle, onRemove, url}) => {
  return onRemove
    ? (
      <div className='handleview'>
        <img src={handle.picture || noImage} alt='avatar' className='handleview__avatar' />
        <span>@{handle.handle}</span>
        <span className='handleview__remove-btn' onClick={() => { onRemove(handle) }}>
          x
        </span>
      </div>
    ) : (
      <div className='handleview'>
        <img src={handle.picture || noImage} alt='avatar' className='handleview__avatar' />
        {url && <a href={url}>
          <span>@{handle.handle}</span>
        </a>}
        {!url && <span>@{handle.handle}</span>}
      </div>
    )
}

HandleView.propTypes = {
  handle: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  url: PropTypes.string
}

export default HandleView

import { asyncActions } from './'
import { fetchVehicles, fetchVehicle, putVehicle, postVehicle, deleteVehicle, fetchMyVehicles, putVehicleAvatar, fetchVehicleSessions, fetchVehicleStats } from '../api'
import { checkErrorAndDispatchlogoutIfNeeded } from './sessionActions'
import { uiToast, uiRedirect } from './ui'
import { generateVehicleUpdatedToast, generateVehicleDeletedToast, generateProgressToast } from '../utils/toastCreator'
import { ROUTE_VEHICLES } from '../utils/routingConstants'
import { eventCreateVehicle } from '../api/gaHelper'

export const ALL_VEHICLES_FETCH = 'ALL_VEHICLES_FETCH'
export const GARAGE_FETCH = 'GARAGE_FETCH'
export const VEHICLE_FETCH = 'VEHICLE_FETCH'
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE'
export const DELETE_VEHICLE = 'DELETE_VEHICLE'
export const VEHICLE_SESSIONS_FETCH = 'VEHICLE_SESSIONS_FETCH'
export const VEHICLE_STATS = 'VEHICLE_STATS'

function refreshMyGarage (dispatch, token, notifyFetching = true, skip = 0, filter = {}) {
  const actions = asyncActions(GARAGE_FETCH)
  notifyFetching && dispatch(actions.start())
  return fetchMyVehicles(token, skip, filter).then(
    (result) => {
      dispatch(actions.success(result, { append: skip !== 0, filter: filter }))
    },
    (error) => {
      if (error.status === 204) {
        dispatch(actions.success({ list: [] }))
      }
      dispatch(actions.error(error))
      checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
    }
  )
}

export function fetchProfileVehiclesAction (token, relationship = 'all', skip = 0, filter = {}) {
  return (dispatch) => {
    const params = { ...filter, relationship }
    refreshMyGarage(dispatch, token, true, skip, params)
  }
}

export function fetchAllVehiclesAction (token, relationship = null, userId = null, skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(ALL_VEHICLES_FETCH)
    const params = { ...filter, relationship, userId }
    dispatch(actions.start())
    return fetchVehicles(token, skip, params).then(
      (result) => {
        dispatch(actions.success(result, { append: skip !== 0, filter: filter }))
      },
      (error) => {
        if (error.status === 204) {
          dispatch(actions.success({ list: [] }))
        }
        dispatch(actions.error(error, { filter: filter }))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

export function deleteVehicleAction (token, handle) {
  return (dispatch) => {
    const actions = asyncActions(DELETE_VEHICLE)
    const fetchActions = asyncActions(VEHICLE_FETCH)
    dispatch(actions.start())
    dispatch(fetchActions.start())
    return deleteVehicle(token, handle)
      .then(result => {
        dispatch(actions.success(result))
        dispatch(fetchActions.success({ deleted: true }))
        dispatch(uiToast(generateVehicleDeletedToast({ handle })))
        dispatch(uiRedirect(ROUTE_VEHICLES))
      })
      .catch(err => {
        dispatch(actions.error(err))
        checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
      })
  }
}

export function fetchVehicleAction (token, handle, fetchedOne) {
  return (dispatch) => {
    const actions = asyncActions(VEHICLE_FETCH)
    dispatch(actions.start({ clear: true }))
    if (handle || fetchedOne) {
      return (fetchedOne
        ? new Promise((resolve, reject) => { resolve(fetchedOne) })
        : fetchVehicle(token, handle))
        .then((result) => {
          dispatch(actions.success(result))
        })
        .catch((err) => {
          dispatch(actions.error(err))
        })
    }
  }
}

export function updateVehicle (vehicle, token) {
  return (dispatch) => {
    const actions = asyncActions(UPDATE_VEHICLE)
    const fetchActions = asyncActions(VEHICLE_FETCH)
    const newAvatar = vehicle.newAvatar
    dispatch(actions.start())
    dispatch(fetchActions.start())
    return putVehicle(vehicle, token)
      .then(result => newAvatar ? putVehicleAvatar(newAvatar, result, token) : new Promise((resolve) => { resolve(result) }))
      .then(result => {
        const newVehicle = newAvatar && result.avatarUrl
          ? { ...result, avatarUrl: result.avatarUrl + '?cacheDisable=' + new Date().getTime() }
          : result
        dispatch(actions.success(newVehicle))
        dispatch(fetchActions.success(newVehicle))
        dispatch(uiToast(generateVehicleUpdatedToast(result)))
      })
      .catch(err => {
        dispatch(actions.error(err))
        checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
      })
  }
}

export function createVehicle (vehicle, token) {
  const newAvatar = vehicle.newAvatar
  return (dispatch) => {
    dispatch(uiToast(generateProgressToast('Creating a vehicle...')))
    const actions = asyncActions(UPDATE_VEHICLE)
    dispatch(actions.start())
    return postVehicle(vehicle, token)
      .then(result => newAvatar ? putVehicleAvatar(newAvatar, result, token) : new Promise((resolve) => { resolve(result) }))
      .then(result => {
        dispatch(actions.success(result))
        eventCreateVehicle(result)
        refreshMyGarage(dispatch, token, false)
        dispatch(uiRedirect(ROUTE_VEHICLES + '/' + result.handle))
      })
      .catch(err => {
        dispatch(actions.error(err))
        checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
      })
  }
}

export function vehicleSessionsFetch (token, handle, skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(VEHICLE_SESSIONS_FETCH)

    if (!handle) {
      dispatch(actions.start({ clear: true }))
      return
    }

    dispatch(actions.start({ filter: filter }))
    return fetchVehicleSessions(token, handle, skip, filter).then(
      (result) => {
        dispatch(actions.success(result, { append: skip !== 0, filter: filter }))
      },
      (error) => {
        dispatch(actions.error(error, { filter: filter }))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

export function fetchVehicleStatsAction (token, handle) {
  const actionsStats = asyncActions(VEHICLE_STATS)

  return (dispatch) => {
    dispatch(actionsStats.start({ clear: true }))

    if (!handle) {
      return
    }

    return fetchVehicleStats(token, handle).then(
      (result) => {
        dispatch(actionsStats.success(result))
      },
      (error) => {
        dispatch(actionsStats.error(error))
      }
    )
  }
}

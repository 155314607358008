import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ROUTE_SESSIONS } from '../../utils/routingConstants'
import { ButtonGroup, ButtonToolbar, Badge } from 'react-bootstrap'
import { RowEntityList, TwoLineEntry, InvitationOverlay, EntityLink, SimBadge } from '../'
import { RequestOverlay } from '../../containers'
import noImage from '../../images/no_image.jpg'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'
import classnames from 'classnames'

class SessionList extends React.Component {
  static propTypes = {
    list: PropTypes.object.isRequired,
    renderButtons: PropTypes.func,
    noRowsRenderer: PropTypes.func,
    loadMoreRows: PropTypes.func,
    fetchingRenderer: PropTypes.func,
    showLegend: PropTypes.bool
  }

  static defaultProps = {
    loadMoreRows: () => { },
    noRowsRenderer: () => (<div className={'list-noRows'}>No sessions found</div>),
    fetchingRenderer: () => 'Loading sessions...',
    showLegend: true
  }

  constructor (props) {
    super(props)
    this.renderRow = this.renderRow.bind(this)
    this.renderButtons = props.renderButtons || this.renderButtons.bind(this)
  }

  render () {
    const { loadMoreRows, noRowsRenderer, list, fetchingRenderer, showLegend } = this.props
    return (<div>
      <RowEntityList
        list={list}
        loadMoreRows={loadMoreRows}
        rowRenderer={this.renderRow}
        noRowsRenderer={noRowsRenderer}
        fetchingRenderer={fetchingRenderer}
      />
      {showLegend && <div className='list-footer'>
        <span className='list-legend'><FontAwesome name='user' /> Your sessions</span>
        <span className='list-legend'><FontAwesome name='share' /> Shared sessions</span>
        <span className='list-legend'><FontAwesome name='group' /> Shared via team</span>
        <span className='list-legend'><FontAwesome name='user-plus' /> Shared by owner</span>
        <span className='list-legend'><FontAwesome name='dollar' /> Purchased via DataPack</span>
      </div>}
    </div>)
  }

  renderButtons ({ session }) {
    const { userSession } = this.props
    switch (this.getAccessLevel(session)) {
      case 'owned':
        return (
          <ButtonToolbar className='list-buttons'>
            <ButtonGroup>
              <InvitationOverlay
                handleTypes={['user', 'team']}
                entity={session}
                excludedUsers={[userSession.profile]}
              />
            </ButtonGroup>
          </ButtonToolbar>
        )
      // case "shared":
      // case "public":
      default:
        return (
          <ButtonToolbar className='list-buttons'>
            <ButtonGroup>
              <RequestOverlay entity={session} />
            </ButtonGroup>
          </ButtonToolbar>
        )
    }
  }

  getAccessLevel (session) {
    const { userSession } = this.props
    if (userSession.isAnonymous) { return 'public' }
    if (session.ownerId === this.props.userSession.profile.id) { return 'owned' }
    if (session.shareId) { return 'shared' }
    return 'public'
  }

  getStartDate (time) {
    return moment(time).format('M/D/YYYY')
  }

  renderHeadline (session) {
    const { userSession } = this.props
    const { track, driver, vehicle, owner } = session
    const date = this.getStartDate(session.start)

    const vehicleText = vehicle && vehicle.handle ? <span> in <EntityLink entity={vehicle} supressEvent type='vehicle' /></span> : ''
    const effectiveDriver = driver || owner
    const isYours = effectiveDriver && !userSession.isAnonymous && effectiveDriver.id === userSession.profile.id

    const driverText = effectiveDriver
      ? isYours ? <span>You drove</span> : <span><EntityLink entity={effectiveDriver} type='user' supressEvent /> drove</span>
      : ''

    const trackText = track ? <span>at <EntityLink entity={track} type='track' supressEvent /></span> : ''
    const { laps, lapCount } = session
    const lapsText = lapCount ? `${lapCount} laps` : ((laps && laps.length > 0) ? <span>{laps.length} laps</span> : '')
    const share = this.getShareText(session, isYours)
    return (<span>{driverText} {lapsText} {vehicleText} {trackText} on {date} &nbsp; {share}</span>)
  }

  getShareText (session, isYours) {
    if (isYours && session.shareId) { return <FontAwesome name='share' /> }
    if (isYours) { return <FontAwesome name='user' /> }
    if (session.shareType === 'team') { return <FontAwesome name='group' /> }
    if (session.shareType === 'user' && session.shareKind !== 0) { return <FontAwesome name='user-plus' /> }
    if (session.shareKind === 0) { return <FontAwesome name='dollar' /> }
    return ''
  }

  getDuration (duration) {
    return duration ? moment.utc(moment.duration(duration).asMilliseconds()).format('m:ss.SSS') : ''
  }

  renderMessage (session) {
    const { fastestLap, layout } = session
    const fastestText = fastestLap ? 'Fastest lap: ' + this.getDuration(fastestLap) : ''
    const layoutText = layout ? `Layout: ${layout.name}` : ''
    return <div>
      <span>{fastestText} &nbsp; {layoutText}&nbsp;</span>
      <Badge className={classnames('visibility', {'private': (session.visibility === 0)})}>{session.visibility === 0 ? 'private' : 'public'}</Badge>
      <SimBadge target={session} />
    </div>
  }

  renderRow ({ index, key, style }) {
    const { list, history } = this.props
    const session = list.list[index]

    const { track } = session

    const clickHandler = () => {
      history.push(ROUTE_SESSIONS + '/' + session.id)
    }

    return (
      <div key={key} style={style}>
        <TwoLineEntry
          image={(track && track.avatarUrl) || noImage}
          headline={this.renderHeadline(session)}
          message={this.renderMessage(session)}
          buttons={this.renderButtons({ session })}
          onClick={clickHandler}
        />
      </div>)
  }
}

function mapStateToProps (state, ownProps) {
  return {
    userSession: state.session
  }
}

export default withRouter(connect(mapStateToProps)(SessionList))

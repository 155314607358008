import React from 'react'
import PropTypes from 'prop-types'
import { RowEntityList, TwoLineEntry, EntityLink } from '../'
import { getDuration, formatDate, getSpeed, getSpeedUnitsName } from '../../utils/modelConvertionUtils'
import noImage from '../../images/no_image.jpg'
import classnames from 'classnames'
import FA from 'react-fontawesome'
import moment from 'moment'

const TYPE_FASTLAP = 'fastlap'

export default class LeaderboardList extends React.Component {
  static propTypes = {
    units: PropTypes.string,
    list: PropTypes.object.isRequired,
    noRowsRenderer: PropTypes.func,
    loadMoreRows: PropTypes.func,
    fetchingRenderer: PropTypes.func,
    type: PropTypes.oneOf([TYPE_FASTLAP, 'speed', 'tracks', 'hours', 'laps', 'sessions']).isRequired
  }

  static defaultProps = {
    units: 'i',
    loadMoreRows: () => { },
    noRowsRenderer: () => (<div className={'list-noRows'}>No sessions recorded for this layout</div>),
    fetchingRenderer: () => 'Loading leaderboard...'
  }

  constructor (props) {
    super(props)
    this.renderRow = this.renderRow.bind(this)
  }

  render () {
    const { loadMoreRows, noRowsRenderer, list, fetchingRenderer } = this.props

    return (<RowEntityList
      className='leaderboard'
      list={list}
      loadMoreRows={loadMoreRows}
      rowRenderer={this.renderRow}
      noRowsRenderer={noRowsRenderer}
      fetchingRenderer={fetchingRenderer}
    />)
  }

  renderHeadline (leaderboardEntry) {
    return <EntityLink entity={leaderboardEntry.driver || {handle: leaderboardEntry.userHandle, id: leaderboardEntry.userId}} type={'user'} />
  }

  renderMessage (leaderboardEntry) {
    const {type} = this.props

    if (type === TYPE_FASTLAP) {
      const onSpan = leaderboardEntry.vehicle && <span> On <EntityLink entity={leaderboardEntry.vehicle} type='vehicle' /></span>
      const sessionSpan = <span>at <EntityLink entity={{id: leaderboardEntry.sessionId}} type='session'>{formatDate(leaderboardEntry.sessionDate)}</EntityLink></span>
      const lapSpan = leaderboardEntry.fastestLapIndex && leaderboardEntry.totalLaps && <span>Lap {leaderboardEntry.fastestLapIndex} of {leaderboardEntry.totalLaps}</span>
      return <div>
        {onSpan} &nbsp; {sessionSpan} &nbsp; {lapSpan}
      </div>
    }

    if (type === 'speed') {
      const onSpan = <span>
        on {leaderboardEntry.trackHandle ? <EntityLink entity={{handle: leaderboardEntry.trackHandle}} type='track' /> : leaderboardEntry.trackName} - {leaderboardEntry.layoutName}
      </span>

      const sessionSpan = <span>at <EntityLink entity={{id: leaderboardEntry.sessionId}} type='session'>{formatDate(leaderboardEntry.start)}</EntityLink></span>

      return <div>
        {onSpan}&nbsp;{sessionSpan}
      </div>
    }

    return null
  }

  renderPseudoButtons (leaderboardEntry, bestLeadrboardEntry) {
    const {type, units} = this.props

    if (type === TYPE_FASTLAP) {
      const lost = bestLeadrboardEntry &&
        bestLeadrboardEntry.fastestLapDuration &&
        leaderboardEntry.fastestLapDuration &&
        leaderboardEntry.fastestLapDuration - bestLeadrboardEntry.fastestLapDuration

      const lostElement = (<span
        className={classnames(!bestLeadrboardEntry && 'leaderboard-interval__header', bestLeadrboardEntry && 'lost', 'leaderboard-interval')}>
        {bestLeadrboardEntry ? (lost ? '+' + getDuration(lost) : '') : 'Leader'}
      </span>)

      return leaderboardEntry.fastestLapDuration && <div>{getDuration(leaderboardEntry.fastestLapDuration)} {lostElement}</div>
    }

    switch (type) {
      case 'hours':
        const hrs = moment.duration(leaderboardEntry.count, 'hours')
        return <span>{`${Math.floor(hrs.asHours())} hrs ${hrs.minutes()} mins`}</span>
      case 'tracks':
        return <span>{leaderboardEntry.count} tracks driven</span>
      case 'laps':
        return <span>{leaderboardEntry.count} laps completed</span>
      case 'sessions':
        return <span>{leaderboardEntry.count} sessions completed</span>
      case 'speed':
        return <span>Top speed: {getSpeed(leaderboardEntry.topSpeed, units, 1, false)} {getSpeedUnitsName(units)}</span>
      default: return <span>{type}</span>
    }
  }

  renderLeaderboardPosition (index) {
    switch (index) {
      case 0: return <FA name='trophy' style={{color: 'gold'}} />
      case 1: return <FA name='trophy' style={{color: 'silver'}} />
      case 2: return <FA name='trophy' style={{color: 'darkgoldenrod'}} />
      default: return <span>{index + 1}</span>
    }
  }

  renderRow ({ index, key, style }) {
    const { list, type } = this.props
    const leaderboardEntry = list.list[index]
    const bestLeadrboardEntry = index > 0 && list.list[0]

    const theEntry = <TwoLineEntry
      decorator={() => <span className={'list-row-number'}>{this.renderLeaderboardPosition(index)} &nbsp;</span>}
      image={(leaderboardEntry.driver ? leaderboardEntry.driver.avatarUrl : leaderboardEntry.avatarUrl) || noImage}
      headline={this.renderHeadline(leaderboardEntry)}
      message={this.renderMessage(leaderboardEntry)}
      buttons={this.renderPseudoButtons(leaderboardEntry, bestLeadrboardEntry)}
      onClick={null}
    />

    return (
      <div key={key} style={style}>
        {type === TYPE_FASTLAP && <EntityLink entity={{id: leaderboardEntry.sessionId}} type={'session'} isDiv>
          {theEntry}
        </EntityLink>}
        {type !== TYPE_FASTLAP && theEntry}
      </div>)
  }
}

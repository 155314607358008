import { asyncActions } from './'
import * as _ from 'lodash'
import { deleteTeam, fetchTeamInvites, deleteTeamMember, postTeam, putTeam, fetchTeams, fetchTeamVehicles, fetchUserTeams, fetchTeam, fetchTeamsCount, fetchTeamMembers, fetchTeamSessions, fetchTeamSummary, putTeamAvatar } from '../api'
import { checkErrorAndDispatchlogoutIfNeeded } from './sessionActions'
import { uiToast, uiRedirect } from './ui'
import { generateTeamUpdatedToast, generateTeamDeletedToast, generateYouHaveLeftTheTeamToast } from '../utils/toastCreator'
import { ROUTE_TEAMS } from '../utils/routingConstants'
import { eventCreateTeam } from '../api/gaHelper'

export const TEAMS_FETCH = 'TEAMS_FETCH'
export const TEAMS_PROFILE_USER_FETCH = 'TEAMS_PROFILE_USER_FETCH'
export const SELECTED_TEAM_FETCH = 'SELECTED_TEAM_FETCH'
export const TEAMS_COUNT = 'TEAMS_COUNT'
export const TEAMS_MEMBER_FETCH = 'TEAMS_MEMBER_FETCH'
export const TEAM_SUMMARY_FETCH = 'TEAM_SUMMARY_FETCH'
export const TEAM_MEMBERS_FETCH = 'TEAM_MEMBERS_FETCH'
export const TEAM_SESSIONS_FETCH = 'TEAM_SESSIONS_FETCH'
export const TEAM_UPDATE = 'TEAM_UPDATE'
export const TEAM_DELETE = 'TEAM_DELETE'
export const TEAM_DELETE_MEMBER = 'TEAM_DELETE_MEMBER'
export const TEAMS_CREATE = 'TEAMS_CREATE'
export const TEAMS_UPDATE = 'TEAMS_UPDATE'
export const TEAMS_INVITES_FETCH = 'TEAMS_INVITES_FETCH'
export const TEAMS_VEHICLES_FETCH = 'TEAMS_VEHICLES_FETCH'

export function createAndRefreshTeamAction (token, team) {
  return dispatch => {
    dispatch(createTeamAction(token, team)).then(
      () => { return dispatch(fetchTeamsAction(token, 0, { role: 'owner' })) }
    )
  }
}

export function createTeamAction (token, team) {
  return (dispatch) => {
    const actions = asyncActions(TEAMS_CREATE)
    const newAvatar = team.newAvatar
    dispatch(actions.start())
    const firstStep = postTeam(token, team)
    return firstStep
      .then(result => newAvatar ? putTeamAvatar(newAvatar, result, token) : new Promise((resolve) => { resolve(result) }))
      .then((result) => {
        const newTeam = newAvatar && result.avatarUrl
          ? { ...result, avatarUrl: result.avatarUrl + '?cacheDisable=' + new Date().getTime() }
          : result
        dispatch(actions.success(newTeam))
        eventCreateTeam(result)
        // dispatch(uiToast(generateTeamCreatedToast(result)))
        dispatch(uiRedirect(ROUTE_TEAMS + '/' + result.handle))
      })
      .catch(err => {
        dispatch(actions.error(err))
        checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
      })
  }
}

export function updateTeamAction (token, team) {
  const newAvatar = team.newAvatar
  let teamData = _.pickBy(team, (value, key) => { return key !== 'newAvatar' })

  return (dispatch) => {
    const actions = asyncActions(TEAM_UPDATE)
    const fetchActions = asyncActions(SELECTED_TEAM_FETCH)

    dispatch(actions.start())
    dispatch(fetchActions.start())

    const firstStep = (newAvatar ? putTeamAvatar(newAvatar, team, token) : new Promise((resolve) => { resolve(true) }))
    return firstStep
      .then((result) => {
        if (result && result.rowVersion) {
          // previous call returns updated profile object
          // now we need to update rowVersion for our changed data
          // and change avatarUrl of our new data before writing
          teamData.rowVersion = result.rowVersion
          teamData.avatarUrl = result.avatarUrl + '?ignorecache=' + new Date().getTime()
        }

        return putTeam(token, teamData)
      })
      .then(result => {
        dispatch(actions.success(result))
        dispatch(uiToast(generateTeamUpdatedToast(team)))
        dispatch(fetchActions.start())

        // use result - handle may have been changed
        return fetchTeam(token, result.handle)
      })
      .then(result => {
        dispatch(fetchActions.success(result))
      })
      .catch(err => {
        dispatch(actions.error(err))
        checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
      })
  }
}

export function deleteTeamAction (token, handle) {
  return (dispatch) => {
    const actions = asyncActions(TEAM_DELETE)
    dispatch(actions.start())
    return deleteTeam(token, handle)
      .then((result) => {
        dispatch(actions.success(result))
        dispatch(uiToast(generateTeamDeletedToast({ handle })))
        dispatch(uiRedirect(ROUTE_TEAMS))
      }).catch(
        (err) => {
          dispatch(actions.error(err))
          checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
        }
      )
  }
}

export function deleteTeamMemberAction (token, team, user, selfDelete = false) {
  return (dispatch) => {
    const actions = asyncActions(TEAM_DELETE_MEMBER)
    const actionsFetchMembers = asyncActions(TEAM_MEMBERS_FETCH)

    dispatch(actions.start())
    dispatch(actionsFetchMembers.success({remove: user}))

    return deleteTeamMember(token, team.handle, user.handle)
      .then((result) => {
        dispatch(actions.success(result))
        dispatch(actionsFetchMembers.start())
        selfDelete && doFetchProfileTeams(dispatch, token)
        selfDelete && dispatch(uiToast(generateYouHaveLeftTheTeamToast(team)))
        return fetchTeamMembers(token, team.handle, 0, {})
      })
      .then(
        (result) => {
          dispatch(actionsFetchMembers.success(result, { append: false, filter: {} }))
        }
      )
      .catch(
        (err) => {
          dispatch(actions.error(err))
          dispatch(actionsFetchMembers.error(err))
          checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
        }
      )
  }
}

// add in a handle here for filtering the teams.  also a role?? or relationship?
export function fetchTeamsAction (token, relationship = null, userId = null, skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(TEAMS_FETCH)
    const params = { ...filter, relationship, userId }
    dispatch(actions.start({ filter: filter }))
    return fetchTeams(token, skip, params).then(
      (result) => {
        dispatch(actions.success(result, { append: skip !== 0, filter: filter }))
      },
      (error) => {
        dispatch(actions.error(error, { filter: filter }))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

function doFetchProfileTeams (dispatch, token, relationship = 'all', skip = 0, filter = {}) {
  const actions = asyncActions(TEAMS_PROFILE_USER_FETCH)
  const params = { ...filter, relationship }
  dispatch(actions.start())
  return fetchUserTeams(token, skip, params)
    .then(result => dispatch(actions.success(result, { append: skip !== 0 })))
    .catch(error => {
      if (error.status === 204) {
        dispatch(actions.success({ list: [] }, { append: skip !== 0 }))
      } else {
        dispatch(actions.error(error))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    })
}

export function fetchProfileTeamsAction (token, relationship = 'all', skip = 0, filter = {}) {
  return (dispatch) => {
    return doFetchProfileTeams(dispatch, token, relationship, skip, filter)
  }
}

export function countTeamsAction (token) {
  return (dispatch) => {
    const actions = asyncActions(TEAMS_COUNT)
    dispatch(actions.start())
    return fetchTeamsCount(token).then(
      (result) => {
        dispatch(actions.success({ count: result }))
      },
      (error) => {
        dispatch(actions.error(error))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

export function selectedTeamFetch (token, handle, fetchedOne) {
  return (dispatch) => {
    const actions = asyncActions(SELECTED_TEAM_FETCH)
    if (!handle) {
      dispatch(actions.start({ clear: true }))
      // we just cleared current selected team
      return
    }

    dispatch(actions.start())
    let promise = fetchedOne
      ? new Promise((resolve, reject) => { resolve(fetchedOne) })
      : fetchTeam(token, handle)
    return promise.then(
      (result) => {
        dispatch(actions.success(result))
      },
      (error) => {
        dispatch(actions.error(error))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

export function teamSummaryFetch (token, handle) {
  return (dispatch) => {
    const actions = asyncActions(TEAM_SUMMARY_FETCH)

    if (!handle) {
      dispatch(actions.start({ clear: true }))
      return
    }

    dispatch(actions.start())
    return fetchTeamSummary(token, handle).then(
      (result) => {
        dispatch(actions.success(result))
      },
      (error) => {
        dispatch(actions.error(error))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

export function teamInvitesFetch (token, handle) {
  return (dispatch) => {
    const actions = asyncActions(TEAMS_INVITES_FETCH)

    if (!handle) {
      dispatch(actions.start({ clear: true }))
      return
    }

    dispatch(actions.start())
    return fetchTeamInvites(token, handle).then(
      (result) => {
        dispatch(actions.success({ list: result }))
      },
      (error) => {
        dispatch(actions.error(error))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

export function teamMembersFetch (token, handle, skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(TEAM_MEMBERS_FETCH)

    if (!handle) {
      dispatch(actions.start({ clear: true }))
      return
    }

    dispatch(actions.start({ filter: filter }))
    return fetchTeamMembers(token, handle, skip, filter)
      .then(
        (result) => {
          dispatch(actions.success(result, { append: skip !== 0, filter: filter }))
        },
        (error) => {
          dispatch(actions.error(error, { filter: filter }))
          checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
        }
      )
  }
}

export function teamVehiclesFetch (token, handle) {
  const actionsVehicles = asyncActions(TEAMS_VEHICLES_FETCH)

  return (dispatch) => {
    dispatch(actionsVehicles.start())
    return fetchTeamVehicles(token, handle, 0, {}).then(
      (result) => {
        dispatch(actionsVehicles.success(result))
      },
      (error) => {
        if (error.status === 204) {
          dispatch(actionsVehicles.success({list: []}))
        }
        dispatch(actionsVehicles.error(error))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

export function teamSessionsFetch (token, handle, skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(TEAM_SESSIONS_FETCH)

    if (!handle) {
      dispatch(actions.start({ clear: true }))
      return
    }

    dispatch(actions.start({ filter: filter }))
    return fetchTeamSessions(token, handle, skip, filter).then(
      (result) => {
        dispatch(actions.success(result, { append: skip !== 0, filter: filter }))
      },
      (error) => {
        dispatch(actions.error(error, { filter: filter }))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

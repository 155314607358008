import { asyncActions } from './'
import { fetchUsers, fetchUser, fetchUserFollowers, fetchPublicSessions, fetchTeams, fetchUserFollowing, fetchUserStats, fetchVehicles } from '../api'
import { checkErrorAndDispatchlogoutIfNeeded } from './sessionActions'

export const USERS_FETCH = 'USERS_FETCH'
export const SELECTED_USER_FETCH = 'SELECTED_USER_FETCH'
export const SELECTED_USER_FOLLOWERS_FETCH = 'SELECTED_USER_FOLLOWERS_FETCH'
export const SELECTED_USER_FOLLOWING_FETCH = 'SELECTED_USER_FOLLOWING_FETCH'
export const SELECTED_USER_STATS_FETCH = 'SELECTED_USER_STATS_FETCH'
export const SELECTED_USER_SESSIONS_FETCH = 'SELECTED_USER_SESSIONS_FETCH'
export const SELECTED_USER_VEHICLES_FETCH = 'SELECTED_USER_CARS_FETCH'
export const SELECTED_USER_TEAMS_FETCH = 'SELECTED_USER_TEAMS_FETCH'

export function fetchUsersAction (token, skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(USERS_FETCH)
    dispatch(actions.start({ filter: filter }))
    return fetchUsers(token, skip, filter).then(
      (result) => {
        dispatch(actions.success(result, { append: skip !== 0, filter: filter }))
      },
      (error) => dispatch(actions.error(error, { filter: filter }))
    )
  }
}

export function fetchSelectedUserAction (token, handle, fetchedOne) {
  return (dispatch) => {
    const actions = asyncActions(SELECTED_USER_FETCH)
    const actionsStats = asyncActions(SELECTED_USER_STATS_FETCH)
    const actionsVehicles = asyncActions(SELECTED_USER_VEHICLES_FETCH)
    const actionsSessions = asyncActions(SELECTED_USER_SESSIONS_FETCH)
    const actionsTeams = asyncActions(SELECTED_USER_TEAMS_FETCH)

    if (!handle) {
      dispatch(actions.start({ clear: true }))
      dispatch(actionsStats.start({ clear: true }))
      dispatch(actionsVehicles.start({ clear: true }))
      dispatch(actionsSessions.start({ clear: true }))
      dispatch(actionsTeams.start({ clear: true }))
      return
    }

    dispatch(actions.start())

    fetchedOne && dispatch(actions.success(fetchedOne))

    return fetchUser(token, handle)
      .then((result) => {
        dispatch(actions.success(result))
        return fetchUserStats(token, handle)
      })
      .then((result) => {
        dispatch(actionsStats.success(result))
        dispatch(actionsSessions.start())
        return fetchPublicSessions(token, 0, { driverId: handle })
      })
      .then((result) => {
        dispatch(actionsSessions.success(result))
      })
      .catch((error) => dispatch(actions.error(error)))
  }
}

export function fetchSelectedUserVehiclesAction (token, handle) {
  const actionsVehicles = asyncActions(SELECTED_USER_VEHICLES_FETCH)

  return (dispatch) => {
    dispatch(actionsVehicles.start())
    return fetchVehicles(token, 0, {userId: handle}).then(
      (result) => {
        dispatch(actionsVehicles.success(result))
      },
      (error) => {
        if (error.status === 204) {
          dispatch(actionsVehicles.success({list: []}))
        }
        dispatch(actionsVehicles.error(error))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

export function fetchSelectedUserTeamsAction (token, handle, relationship = 'all', skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(SELECTED_USER_TEAMS_FETCH)
    const params = { ...filter, userId: handle, relationship }
    dispatch(actions.start({ filter: params }))
    return fetchTeams(token, skip, params).then(
      (result) => {
        dispatch(actions.success(result, { append: skip !== 0, filter: params }))
      },
      (error) => {
        dispatch(actions.error(error, { filter: params }))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

export function fetchUserFollowersAction (token, id, skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(SELECTED_USER_FOLLOWERS_FETCH)
    dispatch(actions.start({ filter: filter }))
    return fetchUserFollowers(token, id, skip, filter).then(
      (result) => {
        dispatch(actions.success(result, { append: skip !== 0, filter: filter }))
      },
      (error) => dispatch(actions.error(error, { filter: filter }))
    )
  }
}

export function fetchUserFollowingAction (token, id, skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(SELECTED_USER_FOLLOWING_FETCH)
    dispatch(actions.start({ filter: filter }))
    return fetchUserFollowing(token, id, skip, filter).then(
      (result) => {
        dispatch(actions.success(result, { append: skip !== 0, filter: filter }))
      },
      (error) => dispatch(actions.error(error, { filter: filter }))
    )
  }
}

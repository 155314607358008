import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Row, Tab, Tabs, Form } from 'react-bootstrap'
import { GA_PAGES } from '../../api/gaHelper'
import { intToRGB, hashCode } from '../../utils/utils'
import { StatisticDataList, Section, FieldGroup } from '../../components'
import {
  fetchCurrentKPIAction,
  fetchKPIDaysFromCurrent,
  fetchKPIMonths,
  fetchCurrentParsersKPIAction,
  fetchParsersKPIDaysFromCurrent,
  fetchParsersKPIMonths,
  fetchSubscriptionsKPIAction,
  fetchKPISubscritionsDaysFromCurrent,
  fetchKPISubscritionsMonths } from '../../actions/adminActions'
import { Line, Pie } from 'react-chartjs-2'
import { page } from '../../hocs/page'
import moment from 'moment'
import * as _ from 'lodash'

const TITLES = [
  '21 days chart (daily)',
  '12 months chart (monthly)'
]

const TAB_GENERAL_DAILY = 0
const TAB_GENERAL_MONTHLY = 1
const TAB_PARSERS = 2
const TAB_PARSERS_DAILY = 3
const TAB_PARSERS_MONTHLY = 4
const TAB_SUBSCRIPTIONS_DAILY = 5
const TAB_SUBSCRIPTIONS_MONTHLY = 6

class PageKPI extends Component {
  constructor (props) {
    super(props)
    this.handleTabSelected = this.selectTab.bind(this)
    this.onCheckboxModeClicked = this.onCheckboxModeClicked.bind(this)
    this.state = {
      tab: 0,
      deltasGeneral: false,
      deltasMonthly: false,
      deltasParserDaily: false,
      deltasParserMonthly: false,
      deltasSubscriptionDaily: false,
      deltasSubscriptionMonthly: false
    }
  }

  componentDidMount () {
    const { dispatch, session } = this.props
    dispatch(fetchCurrentKPIAction(session.token))
    this.selectTab(0)
  }

  selectTab (newTab) {
    const { dispatch, session, lookups } = this.props
    const { tab } = this.state

    switch (newTab) {
      case TAB_GENERAL_DAILY:
        dispatch(fetchKPIDaysFromCurrent(session.token, 21))
        break
      case TAB_GENERAL_MONTHLY:
        dispatch(fetchKPIMonths(session.token))
        break
      case TAB_PARSERS:
        dispatch(fetchCurrentParsersKPIAction(session.token, lookups))
        break
      case TAB_PARSERS_DAILY:
        dispatch(fetchParsersKPIDaysFromCurrent(session.token, lookups, 45))
        break
      case TAB_PARSERS_MONTHLY:
        dispatch(fetchParsersKPIMonths(session.token, lookups))
        break
      case TAB_SUBSCRIPTIONS_DAILY:
        dispatch(fetchSubscriptionsKPIAction(session.token))
        dispatch(fetchKPISubscritionsDaysFromCurrent(session.token))
        break
      case TAB_SUBSCRIPTIONS_MONTHLY:
        dispatch(fetchSubscriptionsKPIAction(session.token))
        dispatch(fetchKPISubscritionsMonths(session.token))
        break
      default: // 0
        break
    }

    (tab !== newTab) && this.setState({ tab: newTab })
  }

  renderCurrent () {
    const { currentKPI } = this.props

    if (!currentKPI.item) {
      return ''
    }

    const stats = {
      'Users': currentKPI.item.userCount,
      'Vehicles': currentKPI.item.vehicleCount,
      'Teams': currentKPI.item.teamsCount,
      'Sessions': currentKPI.item.sessionCount,
      'Custom Tracks': currentKPI.item.customTrackCount,
      'Custom Layouts': currentKPI.item.customLayoutCount,
      'Session shares': currentKPI.item.sessionSharedCount
    }

    return <StatisticDataList stats={stats} />
  }

  postProcessLineChartData (arr, deltas = false) {
    // simple derivable calculation
    return deltas ? arr.map((item, index, array) => {
      if (index === 0) return 0

      return item - array[index - 1]
    }).splice(1, arr.length) : arr
  }

  onCheckboxModeClicked (event) {
    const value = event.target.checked

    const {tab} = this.state
    this.setState((state) => {
      return {
        deltasGeneral: tab === TAB_GENERAL_DAILY ? value : state.deltasGeneral,
        deltasMonthly: tab === TAB_GENERAL_MONTHLY ? value : state.deltasMonthly,
        deltasParserDaily: tab === TAB_PARSERS_DAILY ? value : state.deltasParserDaily,
        deltasParserMonthly: tab === TAB_PARSERS_MONTHLY ? value : state.deltasParserMonthly,
        deltasSubscriptionDaily: tab === TAB_SUBSCRIPTIONS_DAILY ? value : state.deltasSubscriptionDaily,
        deltasSubscriptionMonthly: tab === TAB_SUBSCRIPTIONS_MONTHLY ? value : state.deltasSubscriptionMonthly
      }
    })
  }

  renderTabGeneral (deltas) {
    const {tab, deltasGeneral, deltasMonthly} = this.state
    const { historicalKPI } = this.props

    if (historicalKPI.isFetching) {
      return <div>LOADING...</div>
    }

    if (!historicalKPI || !historicalKPI.item) {
      return null
    }

    const arrUsr = this.postProcessLineChartData(historicalKPI.item.map((kpi) => {
      return kpi.userCount
    }), deltas)

    const arrVeh = this.postProcessLineChartData(historicalKPI.item.map((kpi) => {
      return kpi.vehicleCount
    }), deltas)

    const arrTms = this.postProcessLineChartData(historicalKPI.item.map((kpi) => {
      return kpi.teamsCount
    }), deltas)

    const arrSess = this.postProcessLineChartData(historicalKPI.item.map((kpi) => {
      return kpi.sessionCount
    }), deltas)

    const arrCTr = this.postProcessLineChartData(historicalKPI.item.map((kpi) => {
      return kpi.customTrackCount
    }), deltas)

    const arrCLay = this.postProcessLineChartData(historicalKPI.item.map((kpi) => {
      return kpi.customLayoutCount
    }), deltas)

    const arrSessShar = this.postProcessLineChartData(historicalKPI.item.map((kpi) => {
      return kpi.sessionSharedCount
    }), deltas)

    let labels = historicalKPI.item.map((kpi) => {
      return moment(kpi.date).format('MMM DD')
    })

    if (deltas) {
      labels = labels.splice(1, labels.length)
    }

    const data = {
      labels,
      datasets: [
        {
          label: deltas ? 'New Users' : 'Users',
          fill: false,
          borderColor: 'rgba(255, 128, 128, 1)',
          backgroundColor: 'rgba(255, 128, 128, 0.3)',
          data: arrUsr
        },
        {
          label: deltas ? 'New Vehicles' : 'Vehicles',
          fill: false,
          borderColor: 'rgba(127, 0, 0, 1)',
          backgroundColor: 'rgba(127, 0, 0, 0.3)',
          data: arrVeh
        },
        {
          label: deltas ? 'New Teams' : 'Teams',
          fill: false,
          borderColor: 'rgba(255, 106, 0, 1)',
          backgroundColor: 'rgba(255, 106, 0, 0.3)',
          data: arrTms
        },
        {
          label: deltas ? 'Uploaded Sessions' : 'Sessions',
          fill: false,
          borderColor: 'rgba(0, 190, 0, 1)',
          backgroundColor: 'rgba(0, 190, 0, 0.3)',
          data: arrSess
        },
        {
          label: deltas ? 'New Custom tracks' : 'Custom tracks',
          fill: false,
          borderColor: 'rgba(255, 216, 0, 1)',
          backgroundColor: 'rgba(255, 216, 0, 0.3)',
          data: arrCTr
        },
        {
          label: deltas ? 'New Custom Layouts' : 'Custom Layouts',
          fill: false,
          borderColor: 'rgba(0, 38, 255, 1)',
          backgroundColor: 'rgba(0, 30, 255, 0.3)',
          data: arrCLay
        },
        {
          label: deltas ? 'New Session shares' : 'Session shares',
          fill: false,
          borderColor: 'rgba(255, 0, 220, 1)',
          backgroundColor: 'rgba(255, 0, 220, 0.3)',
          data: arrSessShar
        }
      ]
    }

    const buttons = <Form>
      <FieldGroup
        name='diffmode'
        label='Diff mode'
        onChange={this.onCheckboxModeClicked}
        type='checkbox'
        checked={tab === TAB_GENERAL_DAILY ? deltasGeneral : deltasMonthly} />
    </Form>

    return <Section title={TITLES[tab]} subtitle='The legends are clickable!' headerMessage={buttons}>
      <Line labels={labels} data={data} />
    </Section>
  }

  prepareStats (src) {
    let stats = {}

    for (let item of src) {
      stats[item.name] = stats[item.name] ? stats[item.name] + item.sessionCount : item.sessionCount
    }

    return stats
  }

  renderSessionsByParserCurrentContent () {
    const {currentParsersKPI} = this.props

    if (currentParsersKPI.isFetching) {
      return <Section>LOADING...</Section>
    }

    if (!currentParsersKPI.item) {
      return <Section>ERROR</Section>
    }

    const statsSessionCount = this.prepareStats(_.sortBy(currentParsersKPI.item, item => -item.sessionCount))
    const statsAB = this.prepareStats(_.sortBy(currentParsersKPI.item, ['name']))

    // $color_TA_orange: #F26522;
    let hoverBackgroundColor = new Array(Object.keys(statsAB).length)
    hoverBackgroundColor.fill('#F26522')

    const pieChartData = {
      labels: Object.keys(statsAB),
      datasets: [{
        data: Object.keys(statsAB).map((key) => { return statsAB[key] }),
        backgroundColor: Object.keys(statsAB).map((key) => { return `#${intToRGB(hashCode(key))}` }),
        hoverBackgroundColor
      }]
    }

    return <Section>
      <StatisticDataList className='tiled' stats={statsSessionCount} />
      <Pie data={pieChartData} />
    </Section>
  }

  renderTabParsersHistorical (deltas) {
    const {historicaParserslKPI} = this.props
    const {tab, deltasParserDaily, deltasParserMonthly} = this.state

    if (historicaParserslKPI.isFetching) {
      return <div>LOADING...</div>
    }

    if (!historicaParserslKPI || !historicaParserslKPI.item) {
      return null
    }

    // historicaParserslKPI.item.date / stats

    const preparedStats = historicaParserslKPI.item.map(item => { return this.prepareStats(_.sortBy(item.stats, ['name'])) })
    let labels = historicaParserslKPI.item.map(item => { return moment(item.date).format('MMM DD') })
    if (deltas) {
      labels = labels.splice(1, labels.length)
    }

    const allKeys = Object.keys(preparedStats[preparedStats.length - 1])

    const createLineData = (key, preparedStats) => {
      return {
        label: key,
        fill: false,
        borderColor: `#${intToRGB(hashCode(key))}`,
        backgroundColor: `#${intToRGB(hashCode(key))}`,
        data: this.postProcessLineChartData(preparedStats.map(stats => { return stats[key] || 0 }), deltas)
      }
    }

    const lineChartData = {
      labels,
      datasets: allKeys.map(key => { return createLineData(key, preparedStats) })
    }

    const buttons = <Form>
      <FieldGroup
        name='diffmode'
        label='Diff mode'
        onChange={this.onCheckboxModeClicked}
        type='checkbox'
        checked={tab === TAB_PARSERS_DAILY ? deltasParserDaily : deltasParserMonthly} />
    </Form>

    return <Section title={TITLES[tab]} headerMessage={buttons}>
      <Line data={lineChartData} />
    </Section>
  }

  renderSubscriptionsContent (deltas) {
    const {subscriptionsKPI, historicalSubscriptionsKPI} = this.props
    const {tab, deltasSubscriptionDaily, deltasSubscriptionMonthly} = this.state
    const subscriptions = subscriptionsKPI.item

    const stats = subscriptions ? {
      'Total subscribers': subscriptions.totalSubscribers,
      'Monthly subscribers': subscriptions.monthlySubscribers,
      'Paying / Trial monthly subscribers': `${subscriptions.monthlySubscribers - subscriptions.trialMonthlySubscribers} / ${subscriptions.trialMonthlySubscribers}`,
      'Annual subscribers': subscriptions.yearSubscribers,
      'Paying / Trial annual subscribers': `${subscriptions.yearSubscribers - subscriptions.trialYearSubscribers} / ${subscriptions.trialYearSubscribers}`,
      'Total users': subscriptions.totalUserCount
    } : null

    const chartData = (() => {
      if (!historicalSubscriptionsKPI.item || !historicalSubscriptionsKPI.item.length) {
        return null
      }

      const arrUsr = this.postProcessLineChartData(historicalSubscriptionsKPI.item.map((kpi) => {
        return kpi.totalUserCount
      }), deltas)
      const arrSubscr = this.postProcessLineChartData(historicalSubscriptionsKPI.item.map((kpi) => {
        return kpi.totalSubscribers
      }), deltas)

      const arrMon = this.postProcessLineChartData(historicalSubscriptionsKPI.item.map((kpi) => {
        return kpi.monthlySubscribers
      }), deltas)
      const arrMonPaying = this.postProcessLineChartData(historicalSubscriptionsKPI.item.map((kpi) => {
        return kpi.monthlySubscribers - kpi.trialMonthlySubscribers
      }), deltas)
      const arrMonTrial = this.postProcessLineChartData(historicalSubscriptionsKPI.item.map((kpi) => {
        return kpi.trialMonthlySubscribers
      }), deltas)

      const arrAnn = this.postProcessLineChartData(historicalSubscriptionsKPI.item.map((kpi) => {
        return kpi.yearSubscribers
      }), deltas)
      const arrAnnPaying = this.postProcessLineChartData(historicalSubscriptionsKPI.item.map((kpi) => {
        return kpi.yearSubscribers - kpi.trialYearSubscribers
      }), deltas)
      const arrAnnTrial = this.postProcessLineChartData(historicalSubscriptionsKPI.item.map((kpi) => {
        return kpi.trialYearSubscribers
      }), deltas)

      let labels = historicalSubscriptionsKPI.item.map((kpi) => {
        return moment(kpi.date).format('MMM DD')
      })

      if (deltas) {
        labels = labels.splice(1, labels.length)
      }

      return {
        labels,
        datasets: [
          {
            label: deltas ? 'New Users' : 'Total Users',
            fill: false,
            borderColor: 'rgba(255, 128, 128, 1)',
            backgroundColor: 'rgba(255, 128, 128, 0.3)',
            data: arrUsr
          },
          {
            label: deltas ? 'New Subscribers' : 'Subscribers',
            fill: false,
            borderColor: 'rgba(127, 0, 0, 1)',
            backgroundColor: 'rgba(127, 0, 0, 0.3)',
            data: arrSubscr
          },
          {
            label: deltas ? 'New Monthly' : 'Monthly',
            fill: false,
            borderColor: 'rgba(255, 106, 0, 1)',
            backgroundColor: 'rgba(255, 106, 0, 0.3)',
            data: arrMon
          },
          {
            label: deltas ? 'New Monthly Paying' : 'Monthly Paying',
            fill: false,
            borderColor: 'rgba(0, 190, 0, 1)',
            backgroundColor: 'rgba(0, 190, 0, 0.3)',
            data: arrMonPaying
          },
          {
            label: deltas ? 'New Monthly Trials' : 'Monthly Trials',
            fill: false,
            borderColor: 'rgba(0, 190, 0, 1)',
            backgroundColor: 'rgba(0, 190, 0, 0.3)',
            borderDash: [2, 2],
            data: arrMonTrial
          },
          {
            label: deltas ? 'New Annual' : 'Annual',
            fill: false,
            borderColor: 'rgba(255, 216, 0, 1)',
            backgroundColor: 'rgba(255, 216, 0, 0.3)',
            data: arrAnn
          },
          {
            label: deltas ? 'New Annual Paying' : 'Annual Paying',
            fill: false,
            borderColor: 'rgba(0, 38, 255, 1)',
            backgroundColor: 'rgba(0, 30, 255, 0.3)',
            data: arrAnnPaying
          },
          {
            label: deltas ? 'New Annual Trials' : 'Annual Trials',
            fill: false,
            borderColor: 'rgba(0, 38, 255, 1)',
            backgroundColor: 'rgba(0, 30, 255, 0.3)',
            borderDash: [2, 2],
            data: arrAnnTrial
          }
        ]
      }
    })()

    const buttons = <Form>
      <FieldGroup
        name='diffmode'
        label='Diff mode'
        onChange={this.onCheckboxModeClicked}
        type='checkbox'
        checked={tab === TAB_SUBSCRIPTIONS_DAILY ? deltasSubscriptionDaily : deltasSubscriptionMonthly} />
    </Form>

    return <Section title='Subscriptions data' headerMessage={buttons}>
      {(subscriptionsKPI.isFetching || !subscriptions) && <div>LOADING...</div>}
      {subscriptionsKPI.error && <div>ERROR...</div>}
      <StatisticDataList className='tiled' stats={stats} />
      {historicalSubscriptionsKPI.isFetching && <div>LOADING...</div>}
      {chartData && <Line data={chartData} />}
    </Section>
  }

  render () {
    const { tab } = this.state
    const {deltasGeneral, deltasMonthly, deltasParserDaily, deltasParserMonthly, deltasSubscriptionDaily, deltasSubscriptionMonthly} = this.state

    return (
      <Fragment>
        <Row>
          {this.renderCurrent()}
        </Row>
        <Row>
          <Tabs
            animation={false}
            activeKey={tab}
            onSelect={this.handleTabSelected}
            id='kpis'>
            <Tab eventKey={TAB_GENERAL_DAILY} title='GENERAL (daily)'>
              {this.renderTabGeneral(deltasGeneral)}
            </Tab>
            <Tab eventKey={TAB_GENERAL_MONTHLY} title='GENERAL (monthly)'>
              {this.renderTabGeneral(deltasMonthly)}
            </Tab>
            <Tab eventKey={TAB_PARSERS} title='Parser types (current)'>
              {this.renderSessionsByParserCurrentContent()}
            </Tab>
            <Tab eventKey={TAB_PARSERS_DAILY} title='Parser types (daily)'>
              {this.renderTabParsersHistorical(deltasParserDaily)}
            </Tab>
            <Tab eventKey={TAB_PARSERS_MONTHLY} title='Parser types (monthly)'>
              {this.renderTabParsersHistorical(deltasParserMonthly)}
            </Tab>
            <Tab eventKey={TAB_SUBSCRIPTIONS_DAILY} title='Subscriptions (Daily)'>
              {this.renderSubscriptionsContent(deltasSubscriptionDaily)}
            </Tab>
            <Tab eventKey={TAB_SUBSCRIPTIONS_MONTHLY} title='Subscriptions (Monthly)'>
              {this.renderSubscriptionsContent(deltasSubscriptionMonthly)}
            </Tab>
          </Tabs>
        </Row>
      </Fragment>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session,

    currentKPI: state.admin.currentKPI,
    historicalKPI: state.admin.historicalKPI,

    subscriptionsKPI: state.admin.subscriptionsKPI,
    historicalSubscriptionsKPI: state.admin.historicalSubscriptionsKPI,

    currentParsersKPI: state.admin.currentParsersKPI,
    historicaParserslKPI: state.admin.historicaParserslKPI,
    lookups: state.lookups.item
  }
}

export default page(connect(mapStateToProps)(PageKPI), {pageView: GA_PAGES.KPI, uiNavigationRoute: null})

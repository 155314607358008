import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import { PageViewer } from '../'
import { withRouter } from 'react-router-dom'
import { PRODUCT_FILE_TYPES, PRODUCT_OWNERSHIP_TYPES, PRODUCT_FILE_TYPES_NAMES, PRODUCT_OWNERSHIP_TYPES_NAMES } from '../../api/apiConstants'
import { fetchAdminProducts, fetchProduct, saveProduct, fetchProductContentAction } from '../../actions/storeActions'
import { uiRedirect, uiAdminSelectProduct } from '../../actions/ui'
import { ROUTE_ADMIN_STORE, ROUTE_STORE } from '../../utils/routingConstants'
import { beautifulFileSizeString } from '../../utils/utils'
// import noImage from '../../images/no_image.jpg'
import { Section, FieldGroupReadonly, FieldGroup } from '../../components'
import { page } from '../../hocs/page'
import { find } from 'lodash'
import { GA_PAGES, pageView } from '../../api/gaHelper'
import { Form, Panel, Alert, Col, Button, ButtonToolbar } from 'react-bootstrap'
import Select from 'react-select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Dropzone from 'react-dropzone'
import { Document, Page } from 'react-pdf/dist/entry.webpack'

const TOOLBAR_OPTS = [
  [{ 'header': 1 }, { 'header': 2 }], // custom button values
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],

  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
  // [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
  // [{ 'direction': 'rtl' }], // text direction

  [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],

  ['link', 'image', 'video'],

  ['clean'] // remove formatting button
]

const INITIAL_STATE = {
  file: null,
  additionalFile1: null,
  additionalFile2: null,
  additionalFile3: null,
  additionalFile4: null,
  additionalFile5: null
}

class PageStoreAdminProduct extends Component {
  constructor (props, context) {
    super(props, context)
    this.saveProduct = this.saveProduct.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.descriptionChangeHandler = this.descriptionChangeHandler.bind(this)
    this.cancel = this.cancel.bind(this)
    this.onDrop = this.onDrop.bind(this)

    this.onDropAdditional1 = this.onDropAdditional.bind(this, 1)
    this.onDropAdditional2 = this.onDropAdditional.bind(this, 2)
    this.onDropAdditional3 = this.onDropAdditional.bind(this, 3)
    this.onDropAdditional4 = this.onDropAdditional.bind(this, 4)
    this.onDropAdditional5 = this.onDropAdditional.bind(this, 5)

    this.onDeleteFile1 = this.onDeleteFile.bind(this, 1)
    this.onDeleteFile2 = this.onDeleteFile.bind(this, 2)
    this.onDeleteFile3 = this.onDeleteFile.bind(this, 3)
    this.onDeleteFile4 = this.onDeleteFile.bind(this, 4)
    this.onDeleteFile5 = this.onDeleteFile.bind(this, 5)

    this.state = {...INITIAL_STATE}
  }

  static AdditionalFileGroup ({descriptionName, label, description, descriptionChanged, fileName, url, file, onDrop, onDeleteFile}) {
    const hasFile = file || url

    return <Fragment>
      <FieldGroup
        name={descriptionName}
        label={label + ' description'}
        value={description || (hasFile ? '' : '-no file specified-')}
        disabled={!hasFile}
        validationState={hasFile ? description
          ? 'success' : 'error'
          : null}
        onChange={descriptionChanged}
        type='text' />
      <FieldGroup className='field-group' name={fileName} label={label + ' file'}>
        <Dropzone className='dropzone-fieldgroup' multiple={false} onDrop={onDrop}>
          {!file && <div className='dropzone-fieldgroup__invitation'>Drag the file here</div>}
          {file && <div className='dropzone-fieldgroup__description'>{file.name} | {beautifulFileSizeString(file.size)}</div>}
        </Dropzone>
      </FieldGroup>
      <FieldGroupReadonly name={fileName + 'preview'} label={label + ' preview'} onDelete={hasFile && onDeleteFile} >
        {file ? <a target='_blank' href={file.preview}>Preview</a>
          : url ? 'For now you cannot preview saved files. Just re-upload it if you are not sure. Sorry.' : 'No file specified'}
      </FieldGroupReadonly>
      <hr />
    </Fragment>
  }

  componentWillMount () {
    const { storeProductsList, dispatch, session, productId } = this.props

    if ((!storeProductsList.list || storeProductsList.list.length === 0) && !storeProductsList.fetching && !storeProductsList.error) {
      dispatch(fetchAdminProducts(session.token))
    }
    pageView(GA_PAGES.STORE_PRODUCT + productId, session)
  }

  handleNewProduct (props) {
    const { storeProductsList, selectedProduct, productId, session, dispatch } = props

    if (storeProductsList.error || selectedProduct.error) {
      dispatch(uiRedirect(ROUTE_ADMIN_STORE))
    }

    if (storeProductsList.list && storeProductsList.list.length && !selectedProduct.isFetching && !selectedProduct.error) {
      const selected = find(storeProductsList.list, (o) => { return o && o.idSendOwl === productId })

      if (!selectedProduct.item || selectedProduct.item.idSendOwl !== productId) {
        dispatch(fetchProduct(session.token, productId, selected))
        selected && dispatch(fetchProductContentAction(session.token, selected.id))
        dispatch(uiAdminSelectProduct(selected || {}))
      }
    }
  }

  componentWillReceiveProps (newProps) {
    this.handleNewProduct(newProps)
  }

  saveProduct (e) {
    const {dispatch, session, editingProduct, selectedProduct} = this.props
    const {file, additionalFile1, additionalFile2, additionalFile3, additionalFile4, additionalFile5} = this.state

    e && e.preventDefault()
    dispatch(saveProduct(session.token, {...selectedProduct.item, ...editingProduct}, {file, additionalFile1, additionalFile2, additionalFile3, additionalFile4, additionalFile5}))
      .then((result) => {
        // update current editing state - to make sure we use correct row version
        dispatch(uiAdminSelectProduct(this.props.selectedProduct))
        this.setState(INITIAL_STATE)
      })
  }

  cancel (e) {
    e && e.preventDefault()
    const {history} = this.props
    history.push(ROUTE_ADMIN_STORE)
  }

  changeHandler (e) {
    const { dispatch, editingProduct } = this.props

    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    dispatch(uiAdminSelectProduct({...editingProduct, [name]: value}))
  }

  descriptionChangeHandler (value) {
    const { dispatch, editingProduct } = this.props
    dispatch(uiAdminSelectProduct({...editingProduct, description: value}))
  }

  generateTestLink (product) {
    let innerElement = <span />
    const {file} = this.state

    let url = product.url || (file && file.preview)
    if (!url && (product.productType === PRODUCT_FILE_TYPES.WISTIA || product.productType === PRODUCT_FILE_TYPES.YOUTUBE)) {
      url = this.props.editingProduct.url
    }

    switch (product.productType) {
      case PRODUCT_FILE_TYPES.PDF :
        innerElement = url ? <a target='_blank' href={PageViewer.createLink({...product, url})}>PDF URL</a> : <span className='error'>No PDF uploaed</span>
        break
      case PRODUCT_FILE_TYPES.WISTIA :
        innerElement = url ? <a target='_blank' href={PageViewer.createLink({...product, url})}>WISTIA URL</a> : <span className='error'>No WISTIA url set up</span>
        break
      case PRODUCT_FILE_TYPES.DATA_PACK :
        innerElement = <span>No test link available for the DATA PACK product type</span>
        break
      case PRODUCT_FILE_TYPES.ANALYSE :
        innerElement = <span>No test link available for the DATA ANALYSIS product type</span>
        break
      case PRODUCT_FILE_TYPES.YOUTUBE :
        innerElement = url ? <a target='_blank' href={PageViewer.createLink({...product, url})}>YOUTUBE URL</a> : <span className='error'>No YOUTUBE url set up</span>
        break
      default :
        innerElement = <span className='error'>Unexpected product type: {product.productType}</span>
    }

    return innerElement
  }

  onDrop (files) {
    this.setState({file: files[0]})
  }

  onDropAdditional (fileNumber, files) {
    const { dispatch, editingProduct } = this.props
    files[0] && dispatch(uiAdminSelectProduct({...editingProduct, [`additionalFile${fileNumber}Descr`]: files[0].name}))
    files[0] && this.setState({[`additionalFile${fileNumber}`]: files[0]})
  }

  onDeleteFile (fileNumber) {
    const { dispatch, editingProduct } = this.props
    dispatch(uiAdminSelectProduct({...editingProduct, [`additionalFile${fileNumber}Url`]: '', [`additionalFile${fileNumber}Descr`]: ''}))
    this.setState({[`additionalFile${fileNumber}`]: null})
  }

  render () {
    const { selectedProduct, storeProductsList, editingProduct } = this.props
    const {
      file,
      additionalFile1,
      additionalFile2,
      additionalFile3,
      additionalFile4,
      additionalFile5
    } = this.state

    if (selectedProduct.isFetching || storeProductsList.isFetching || !selectedProduct.item) {
      return <Section><div className='loading-message'>Loading...</div></Section>
    }

    const product = {
      ...selectedProduct.item,
      ...editingProduct
    }

    return (
      <Section title={product.name + ' | EDITING'}>
        <Form onSubmit={this.saveProduct}>
          <Panel>
            <Panel.Heading>Edited on Send Owl</Panel.Heading>
            <Panel.Body>
              <Col sm={7}>
                <FieldGroupReadonly name='idSendOwl' label='SendOwl ID'>{product.idSendOwl}</FieldGroupReadonly>
                <FieldGroupReadonly name='taId' label='TrackAttack ID'>{product.id}</FieldGroupReadonly>
                <FieldGroupReadonly name='name' label='Name'>{product.name}</FieldGroupReadonly>
                <FieldGroupReadonly name='price' label='Price'>{'$' + product.price}</FieldGroupReadonly>
                <FieldGroupReadonly name='updated' label='Updated On'>{product.updatedOn}</FieldGroupReadonly>
              </Col>
              <Col sm={5}>
                <img className='cover-img' alt='product cover' src={product.imageUrl} />
              </Col>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Heading>Description</Panel.Heading>
            <Panel.Body>
              <ReactQuill modules={{toolbar: TOOLBAR_OPTS}} value={product.description || ''} onChange={this.descriptionChangeHandler} />
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Heading>Product type and content setup</Panel.Heading>
            <Panel.Body>
              <Col sm={7}>
                <FieldGroup
                  name='productType'
                  label='Product Type'>
                  <Select
                    className='react-select-container'
                    classNamePrefix='react-select'
                    value={{value: product.productType, label: PRODUCT_FILE_TYPES_NAMES[product.productType]}}
                    options={[
                      {value: PRODUCT_FILE_TYPES.PDF, label: PRODUCT_FILE_TYPES_NAMES[PRODUCT_FILE_TYPES.PDF]},
                      {value: PRODUCT_FILE_TYPES.WISTIA, label: PRODUCT_FILE_TYPES_NAMES[PRODUCT_FILE_TYPES.WISTIA]},
                      {value: PRODUCT_FILE_TYPES.DATA_PACK, label: PRODUCT_FILE_TYPES_NAMES[PRODUCT_FILE_TYPES.DATA_PACK]},
                      {value: PRODUCT_FILE_TYPES.ANALYSE, label: PRODUCT_FILE_TYPES_NAMES[PRODUCT_FILE_TYPES.ANALYSE]},
                      {value: PRODUCT_FILE_TYPES.YOUTUBE, label: PRODUCT_FILE_TYPES_NAMES[PRODUCT_FILE_TYPES.YOUTUBE]}
                    ]}
                    onChange={selected => this.changeHandler({ target: { name: 'productType', value: selected.value } })}
                  />
                </FieldGroup>
                <FieldGroup
                  name='test'
                  label='Test'>
                  <div className='field-stub_text'>
                    {this.generateTestLink(product)}
                  </div>
                </FieldGroup>
                {product.productType === PRODUCT_FILE_TYPES.WISTIA && <FieldGroup
                  name='url'
                  label='WISTIA ID'
                  onChange={this.changeHandler}
                  type='text'
                  value={product.url || ''} />}
                {product.productType === PRODUCT_FILE_TYPES.YOUTUBE && <FieldGroup
                  name='url'
                  label='YouTube Url'
                  onChange={this.changeHandler}
                  type='text'
                  value={product.url || ''} />}
                {product.productType === PRODUCT_FILE_TYPES.DATA_PACK && <FieldGroup
                  name='contentDefinition'
                  label='Session IDs'
                  onChange={this.changeHandler}
                  type='text'
                  value={product.contentDefinition || ''} />}
                {product.productType === PRODUCT_FILE_TYPES.PDF && <FieldGroup
                  name='file'
                  label='PDF file'
                >
                  <Dropzone
                    className='dropzone-fieldgroup'
                    onDrop={this.onDrop}
                    multiple={false}
                    accept='application/pdf'>
                    {!file && <div className='dropzone-fieldgroup__invitation'>Drag the file here</div>}
                    {file && <div className='dropzone-fieldgroup__description'>{file.name} | {beautifulFileSizeString(file.size)}</div>}
                  </Dropzone>
                </FieldGroup>}
                <FieldGroup
                  name='premiumCouponCode'
                  label='Coupon Code'
                  onChange={this.changeHandler}
                  type='text'
                  value={product.premiumCouponCode || ''} />
                <FieldGroup
                  name='premiumCouponDescription'
                  label='Coupon Code Description'
                  onChange={this.changeHandler}
                  type='text'
                  value={product.premiumCouponDescription || ''} />
              </Col>
              <Col sm={5}>
                {file && <div className='form-preview'>
                  <Document file={file.preview}><Page width={320 * 0.7} pageNumber={1} /></Document>
                </div>}
              </Col>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Heading>Additional files</Panel.Heading>
            <Panel.Body>
              <Alert bsStyle='warning'>Currently additional files will be shown only for WISTIA and YouTube products</Alert>
              <PageStoreAdminProduct.AdditionalFileGroup
                descriptionName='additionalFile1Descr'
                description={product.additionalFile1Descr}
                descriptionChanged={this.changeHandler}
                label='File 1'
                fileName='additionalFile1'
                file={additionalFile1}
                onDrop={this.onDropAdditional1}
                url={product.additionalFile1Url}
                onDeleteFile={this.onDeleteFile1} />
              <PageStoreAdminProduct.AdditionalFileGroup
                descriptionName='additionalFile2Descr'
                description={product.additionalFile2Descr}
                descriptionChanged={this.changeHandler}
                label='File 2'
                fileName='additionalFile2'
                file={additionalFile2}
                onDrop={this.onDropAdditional2}
                url={product.additionalFile2Url}
                onDeleteFile={this.onDeleteFile2} />
              <PageStoreAdminProduct.AdditionalFileGroup
                descriptionName='additionalFile3Descr'
                description={product.additionalFile3Descr}
                descriptionChanged={this.changeHandler}
                label='File 3'
                fileName='additionalFile3'
                file={additionalFile3}
                onDrop={this.onDropAdditional3}
                url={product.additionalFile3Url}
                onDeleteFile={this.onDeleteFile3} />
              <PageStoreAdminProduct.AdditionalFileGroup
                descriptionName='additionalFile4Descr'
                description={product.additionalFile4Descr}
                descriptionChanged={this.changeHandler}
                label='File 4'
                fileName='additionalFile4'
                file={additionalFile4}
                onDrop={this.onDropAdditional4}
                url={product.additionalFile4Url}
                onDeleteFile={this.onDeleteFile4} />
              <PageStoreAdminProduct.AdditionalFileGroup
                descriptionName='additionalFile5Descr'
                description={product.additionalFile5Descr}
                descriptionChanged={this.changeHandler}
                label='File 5'
                fileName='additionalFile5'
                file={additionalFile5}
                onDrop={this.onDropAdditional5}
                url={product.additionalFile5Url}
                onDeleteFile={this.onDeleteFile5} />
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Heading>Product access setup</Panel.Heading>
            <Panel.Body>
              <FieldGroup
                name='isPublished'
                label='Published'
                onChange={this.changeHandler}
                type='checkbox'
                checked={product.isPublished} />
              <FieldGroup
                name='isTest'
                label='Test'
                onChange={this.changeHandler}
                type='checkbox'
                checked={product.isTest} />
              <FieldGroup
                name='isNew'
                label='New'
                onChange={this.changeHandler}
                type='checkbox'
                checked={product.isNew} />

              <FieldGroup
                name='basicOwnershipType'
                label='Basic Ownership'>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  value={{value: product.basicOwnershipType, label: PRODUCT_OWNERSHIP_TYPES_NAMES[product.basicOwnershipType]}}
                  options={[
                    {value: PRODUCT_OWNERSHIP_TYPES.NO_ACCESS, label: PRODUCT_OWNERSHIP_TYPES_NAMES[PRODUCT_OWNERSHIP_TYPES.NO_ACCESS]},
                    {value: PRODUCT_OWNERSHIP_TYPES.CAN_VIEW, label: PRODUCT_OWNERSHIP_TYPES_NAMES[PRODUCT_OWNERSHIP_TYPES.CAN_VIEW]},
                    {value: PRODUCT_OWNERSHIP_TYPES.CAN_DOWNLOAD, label: PRODUCT_OWNERSHIP_TYPES_NAMES[PRODUCT_OWNERSHIP_TYPES.CAN_DOWNLOAD]}
                  ]}
                  onChange={selected => this.changeHandler({ target: { name: 'basicOwnershipType', value: selected.value } })}
                />
              </FieldGroup>
              <FieldGroup
                name='premiumOwnershipType'
                label='Premium Ownership'>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  value={{value: product.premiumOwnershipType, label: PRODUCT_OWNERSHIP_TYPES_NAMES[product.premiumOwnershipType]}}
                  options={[
                    {value: PRODUCT_OWNERSHIP_TYPES.NO_ACCESS, label: PRODUCT_OWNERSHIP_TYPES_NAMES[PRODUCT_OWNERSHIP_TYPES.NO_ACCESS]},
                    {value: PRODUCT_OWNERSHIP_TYPES.CAN_VIEW, label: PRODUCT_OWNERSHIP_TYPES_NAMES[PRODUCT_OWNERSHIP_TYPES.CAN_VIEW]},
                    {value: PRODUCT_OWNERSHIP_TYPES.CAN_DOWNLOAD, label: PRODUCT_OWNERSHIP_TYPES_NAMES[PRODUCT_OWNERSHIP_TYPES.CAN_DOWNLOAD]}
                  ]}
                  onChange={selected => this.changeHandler({ target: { name: 'premiumOwnershipType', value: selected.value } })}
                />
              </FieldGroup>
            </Panel.Body>
          </Panel>
          <ButtonToolbar bsClass='bottom-buttons'>
            <Button bsStyle='warning' onClick={this.cancel}>Cancel</Button>
            <Button bsStyle='success' onClick={this.saveProduct}>Save</Button>
          </ButtonToolbar>
        </Form>
      </Section>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    productId: ownProps.match.params.productId,

    selectedProduct: state.store.selectedProduct,
    editingProduct: state.ui.admin.selectedProduct,
    storeProductsList: state.store.storeProductsList,
    productContent: state.store.productContent,
    session: state.session
  }
}

export default withRouter(page(connect(mapStateToProps)(PageStoreAdminProduct), {uiNavigationRoute: ROUTE_STORE}))

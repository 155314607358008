
import React from 'react'
import { OverlayTrigger, Button, Tooltip } from 'react-bootstrap'
import { Overlay } from '../components'

export default class OverlayWithButton extends React.Component {
  open () {
    this.props.dispatch(this.props.openAction)
  }

  renderOpenButton (openButton, openButtonClass, openAction) {
    return typeof (openButton) === 'string' ? <Button bsClass={openButtonClass === undefined ? 'overlay-btn' : openButtonClass} onClick={openAction}>{openButton}</Button>
      : openButton
  }

  render () {
    const { elementType, renderedButton, openButton, openButtonClass, openAction, closeAction, show, title, content, buttonSpecs, tipText } = this.props
    const tooltip = (
      <Tooltip id='tooltip'>{tipText}</Tooltip>
    )

    const resolvedButtons = Overlay.createButtons(buttonSpecs)

    const elements = show
      ? [<Overlay key='overlay' show title={title} closeAction={closeAction} buttons={resolvedButtons}>{content || this.props.children}</Overlay>]
      : []

    if (renderedButton) {
      elements.push(React.cloneElement(renderedButton, { key: 'button' }))
    } else {
      elements.push(<OverlayTrigger key='button' placement='top' overlay={tooltip}>
        {this.renderOpenButton(openButton, openButtonClass, openAction)}
      </OverlayTrigger>)
    }
    return React.createElement(elementType || 'div', {}, elements)
  }
}

import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import { Section } from '../../components'
import { page } from '../../hocs/page'
import { Table, Alert } from 'react-bootstrap'
import {filter} from 'lodash'
// import { GA_PAGES } from '../../api/gaHelper'

class PageSupportedChannelsAndDevices extends Component {
  render () {
    const {lookups} = this.props

    if (lookups.isFetching) {
      return <Section title='Supported channels'>
        <div className='loading-message'>Loading...</div>
      </Section>
    }

    const channels = filter(lookups.item.typeSubCategories, {typeCategoryId: 2000})
    // no unknown & no unigo old
    const parsers = filter(lookups.item.typeSubCategories, (item) => item.typeCategoryId === 3000 && item.typeId !== 3000 && item.typeId !== 3003)

    return <Fragment>
      <Section title='Supported channels' className='page-legal'>
        <Alert>
          Important: some of these channels and parsers may be in the test mode and are not available via the desktop app.
        </Alert>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Channel id</th>
              <th>Channel name</th>
            </tr>
          </thead>
          <tbody>
            {channels.map((typecategory) => {
              return <tr key={typecategory.typeId}>
                <td>{typecategory.typeId}</td>
                <td>{typecategory.description}</td>
              </tr>
            })}
          </tbody>
        </Table>
      </Section>
      <Section title='Parsers' className='page-legal'>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Parser id</th>
              <th>Parser name</th>
            </tr>
          </thead>
          <tbody>
            {parsers.map((typecategory) => {
              return <tr key={typecategory.typeId}>
                <td>{typecategory.typeId}</td>
                <td>{typecategory.description}</td>
              </tr>
            })}
          </tbody>
        </Table>
      </Section>
    </Fragment>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session,
    lookups: state.lookups
  }
}

export default page(connect(mapStateToProps)(PageSupportedChannelsAndDevices), { uiNavigationRoute: null })

import {
  LEADERBOARD_USERHOURS_FETCH,
  LEADERBOARD_USERLAPS_FETCH,
  LEADERBOARD_USERSESSIONS_FETCH,
  LEADERBOARD_USERSPEED_FETCH,
  LEADERBOARD_USERTRACKS_FETCH,
  LEADERBOARD_FILTER_CHANGED
} from '../actions/leaderboardActions'
import { default as listReducer } from './listReducer'
import { combineReducers } from 'redux'

const INITIAL_LEADERBOARD_TYPES = {
  'speed': {startDate: null, endDate: null, filter: 'all'},
  'tracks': {startDate: null, endDate: null, filter: 'all'},
  'hours': {startDate: null, endDate: null, filter: 'all'},
  'laps': {startDate: null, endDate: null, filter: 'all'},
  'sessions': {startDate: null, endDate: null, filter: 'all'}
}

const leaderboarFilters = (state = INITIAL_LEADERBOARD_TYPES, action) => {
  switch (action.type) {
    case LEADERBOARD_FILTER_CHANGED:
      return {
        ...state,
        [action.leaderboardType]: action.filter
      }
    default: return state
  }
}

export default combineReducers({
  leaderboardHours: listReducer(LEADERBOARD_USERHOURS_FETCH),
  leaderboardLaps: listReducer(LEADERBOARD_USERLAPS_FETCH),
  leaderboardSessions: listReducer(LEADERBOARD_USERSESSIONS_FETCH),
  leaderboardSpeed: listReducer(LEADERBOARD_USERSPEED_FETCH),
  leaderboardTracks: listReducer(LEADERBOARD_USERTRACKS_FETCH),
  leaderboarFilters
})

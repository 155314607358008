import React from 'react'
import { connect } from 'react-redux'
import { Badge } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { TwoLineEntry, RowEntityList, EntityLink } from '../'
import {VehicleEditOverlay} from '../../containers'
import noImage from '../../images/no_image.jpg'
import classnames from 'classnames'

class VehicleList extends React.Component {
  static propTypes = {
    list: PropTypes.object.isRequired,
    renderButtons: PropTypes.func,
    noRowsRenderer: PropTypes.func,
    fetchingRenderer: PropTypes.func,
    loadMoreRows: PropTypes.func,
    itemSelector: PropTypes.func,
    enableAdd: PropTypes.bool
  }

  static defaultProps = {
    renderButtons: () => null,
    loadMoreRows: () => { },
    itemSelector: (item) => item,
    noRowsRenderer: () => (<div className={'list-noRows'}>No vehicles found</div>),
    fetchingRenderer: () => 'Loading vehicles...',
    enableAdd: false
  }

  constructor (props) {
    super(props)
    this.renderRow = this.renderRow.bind(this)
  }

  render () {
    const { loadMoreRows, noRowsRenderer, list, fetchingRenderer, enableAdd } = this.props

    return <RowEntityList
      list={list}
      loadMoreRows={loadMoreRows}
      rowRenderer={this.renderRow}
      noRowsRenderer={noRowsRenderer}
      fetchingRenderer={fetchingRenderer}

      header={enableAdd ? <VehicleEditOverlay /> : null}
    />
  }

  renderRow ({ index, key, style }) {
    const { renderButtons, list, itemSelector } = this.props
    const item = itemSelector(list.list[index])

    return (
      <div key={key} style={style}>
        <EntityLink entity={item} isDiv>
          <TwoLineEntry
            buttons={renderButtons(item)}
            image={item.avatarUrl || noImage}
            headline={this.renderHeadline(item)}
            message={this.renderMessage(item)} />
        </EntityLink>
      </div>)
  }

  renderHeadline (item) {
    return <div>
      <EntityLink entity={item} type={'vehicle'}>{item.name}</EntityLink>
      {item.handle && <span className='handle'>{` ${item.handle}`}</span>}
    </div>
  }

  renderMessage (item) {
    const {session} = this.props
    const isDefault = session.profile && session.profile.defaultVehicleId === item.id

    return <div>
      {isDefault && <Badge className='isDefault'>default</Badge>}
      <Badge className={classnames('visibility', {'private': (item.visibility === 0)})}>{item.visibility === 0 ? 'private' : 'public'}</Badge>
    </div>
  }
}

function mapStateToProps (state) {
  return {
    session: state.session
  }
}

export default connect(mapStateToProps)(VehicleList)

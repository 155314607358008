import { fetchAllLookups as apiFetchAllLookups } from '../api'
import { asyncActions } from './index'
export const LOOKUPS_FETCH_ALL = 'LOOKUPS_FETCH_ALL'

export function fetchAllLookups (token) {
  return dispatch => {
    const actions = asyncActions(LOOKUPS_FETCH_ALL)
    dispatch(actions.start())
    return apiFetchAllLookups(token).then(
      result => dispatch(actions.success(result)),
      error => dispatch(actions.error(error))
    )
  }
}

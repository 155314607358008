import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import FA from 'react-fontawesome'
import { TwoLineEntry, RowEntityList, Overlay } from '..'
import classnames from 'classnames'
import moment from 'moment'
import {Button} from 'react-bootstrap'
import * as _ from 'lodash'

const DATE_FORMAT = 'DD MMM YY HH:mm'

class NotificationsList extends React.Component {
  static propTypes = {
    list: PropTypes.object.isRequired,
    noRowsRenderer: PropTypes.func,
    fetchingRenderer: PropTypes.func,
    itemSelector: PropTypes.func,
    loadMoreRows: PropTypes.func,
    adminMode: PropTypes.bool,
    onDelete: PropTypes.func,
    onRead: PropTypes.func
  }

  static defaultProps = {
    adminMode: false,
    loadMoreRows: () => { },
    itemSelector: (item) => item.notification,
    noRowsRenderer: () => (<div className={'list-noRows'}>No notifications found</div>),
    fetchingRenderer: () => 'Loading notifications...'
  }

  constructor (props) {
    super(props)
    this.renderRow = this.renderRow.bind(this)
    this.renderRowForAdminMode = this.renderRowForAdminMode.bind(this)
    this.deleteNotification = this.deleteNotification.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.openNotification = this.openNotification.bind(this)
    this.state = {
      selectedNotification: null
    }
  }

  getNotificationSymbolFromPriority (notificationPriority) {
    if (notificationPriority < 2) {
      return 'exclamation-circle'
    } else {
      return 'info-circle'
    }
  }

  openNotification (notification) {
    this.setState({selectedNotification: notification})
  }

  closeNotification () {
    this.setState({selectedNotification: null})
  }

  markNotificationAsRead (notification) {
    const {onRead} = this.props
    onRead && onRead(notification)
    this.closeNotification()
  }

  deleteNotification (notification) {
    const {onDelete} = this.props
    onDelete && onDelete(notification)
  }

  render () {
    const { loadMoreRows, noRowsRenderer, list, fetchingRenderer, adminMode, itemSelector } = this.props
    const {selectedNotification} = this.state

    const selectedDeliveryTask = selectedNotification && _.find(list.list, (noti) => { return itemSelector(noti).id === selectedNotification.id })
    const buttons = adminMode ? [] : [
      <Button key='ok' bsStyle='success' onClick={() => { this.markNotificationAsRead(selectedDeliveryTask) }}>OK</Button>
    ]

    return <Fragment>
      {selectedNotification && <Overlay
        show={!!selectedNotification}
        title={selectedNotification.title}
        buttons={buttons}
        closeAction={this.closeNotification}>
        <h5>{selectedNotification.exerpt}</h5>
        <div>{selectedNotification.text}</div>
      </Overlay>}
      <RowEntityList
        list={list}
        loadMoreRows={loadMoreRows}
        rowRenderer={adminMode ? this.renderRowForAdminMode : this.renderRow}
        noRowsRenderer={noRowsRenderer}
        fetchingRenderer={fetchingRenderer}
      />
    </Fragment>
  }

  renderRowForAdminMode ({ index, key, style }) {
    const { list, itemSelector } = this.props
    const notification = itemSelector(list.list[index])

    const clickHandler = () => { this.openNotification(notification) }

    return <div key={key} style={style}>
      <TwoLineEntry
        onClick={clickHandler}
        decorator={() => <FA className={'list-row-number'} name={this.getNotificationSymbolFromPriority(notification.priority)} />}
        headline={`${moment(notification.updatedOn).format(DATE_FORMAT)} - [${notification.priority}] - ${notification.title}`}
        message={notification.exerpt}
        buttons={<Button bsStyle='danger' onClick={() => this.deleteNotification(notification)}>Delete forever</Button>}
      />
    </div>
  }

  renderRow ({ index, key, style }) {
    const { list, itemSelector } = this.props
    const notification = itemSelector(list.list[index])
    const notificationTask = list.list[index]
    const clickHandler = () => { this.openNotification(notification) }

    return <div key={key} style={style}>
      <TwoLineEntry className={classnames({'read': notificationTask.isDelivered})}
        onClick={clickHandler}
        decorator={() => <FA className={'list-row-number'} name={this.getNotificationSymbolFromPriority(notification.priority)} />}
        headline={`${moment(notification.updatedOn).format(DATE_FORMAT)} - ${notification.title}`}
        message={notification.exerpt}
        buttons={<FA name={notificationTask.isDelivered ? 'envelope-open-o' : 'envelope-o'} />}
      />
    </div>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session
  }
}

export default connect(mapStateToProps)(NotificationsList)

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ROUTE_TEAMS } from '../../utils/routingConstants'
import { TwoLineEntry, RowEntityList, InvitationOverlay, EntityLink } from '../'
import { TeamEditOverlay, RequestOverlay } from '../../containers'
import { ButtonGroup, ButtonToolbar, Badge } from 'react-bootstrap'
import noImage from '../../images/no_image.jpg'
import classnames from 'classnames'

class TeamList extends React.Component {
  static propTypes = {
    list: PropTypes.object.isRequired,
    renderButtons: PropTypes.bool,
    noRowsRenderer: PropTypes.func,
    loadMoreRows: PropTypes.func,
    fetchingRenderer: PropTypes.func,
    enableAdd: PropTypes.bool
  }

  static defaultProps = {
    renderButtons: () => null,
    loadMoreRows: () => { },
    enableAdd: false
  }

  constructor (props) {
    super(props)
    this.renderRow = this.renderRow.bind(this)
  }

  renderTeamButtons (team) {
    const { renderButtons, session } = this.props
    if (!renderButtons || !team) {
      return null
    }

    const isMyTeam = !session.isAnonymous && session.profile.id === team.ownerId

    return <ButtonToolbar className='list-buttons'>
      {isMyTeam && <ButtonGroup>
        {/* TODO: think how to provide excludedUsers={members} entitySentInvites={entitySentInvites} */}
        <InvitationOverlay entity={team} handleTypes={['user']} />
      </ButtonGroup>}
      {!isMyTeam && <ButtonGroup>
        <RequestOverlay entity={team} />
      </ButtonGroup>}
    </ButtonToolbar>
  }

  render () {
    const { loadMoreRows, noRowsRenderer, list, fetchingRenderer, enableAdd } = this.props

    // dont need to sort here
    return (<RowEntityList
      header={enableAdd ? <TeamEditOverlay /> : null}
      list={list}
      loadMoreRows={loadMoreRows}
      rowRenderer={this.renderRow}
      noRowsRenderer={noRowsRenderer}
      fetchingRenderer={fetchingRenderer}
    />)
  }

  renderRow ({ index, key, style }) {
    const { list, history } = this.props
    const item = list.list[index]
    const clickHandler = () => {
      history.push(ROUTE_TEAMS + '/' + item.handle)
    }
    return (
      <div key={key} style={style}>
        <TwoLineEntry
          image={item.avatarUrl || noImage}
          headline={this.renderHeadline(item)}
          message={this.renderMessage(item)}
          buttons={this.renderTeamButtons(item)}
          onClick={clickHandler} />
      </div>)
  }

  renderHeadline (item) {
    return <div>
      <EntityLink entity={item} type={'team'}>{item.name}</EntityLink>
      {item.handle && <span className='handle'>{` ${item.handle}`}</span>}
    </div>
  }

  renderMessage (item) {
    return <div>
      <Badge className={classnames('visibility', {'private': (item.visibility === 0)})}>{item.visibility === 0 ? 'private' : 'public'}</Badge>
    </div>
  }
}

function mapStateToProps (state) {
  return {
    session: state.session,
    teams: state.teams
  }
}

export default withRouter(connect(mapStateToProps)(TeamList))

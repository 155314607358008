export const ROUTE_SESSIONS = '/sessions'
export const ROUTE_TEAMS = '/teams'
export const ROUTE_USERS = '/users'
export const ROUTE_TEAMS_DB = '/teams/db'
export const ROUTE_TRACKS = '/tracks'
export const ROUTE_TRACKS_DB = '/tracks/db'
export const ROUTE_VEHICLES = '/vehicles'
export const ROUTE_SETTINGS = '/settings'
export const ROUTE_DOWNLOADS = '/downloads'
export const ROUTE_TOS = '/terms'
export const ROUTE_FAQ = '/faq'
export const ROUTE_PRICING = '/pricing'
export const ROUTE_PRICING_STUDENTS = '/pricing/students'
export const ROUTE_PRIVACY = '/privacy'
export const ROUTE_LEADERBOARDS = '/leaderboards'
export const ROUTE_STORE = '/store'
export const ROUTE_PURCHASECOMPLETED = '/purchasecompleted'
export const ROUTE_PURCHASECOMPLETED_STRIPE = '/purchase/success'
export const ROUTE_STORE_CART = '/store/cart'
export const ROUTE_VIEWER = '/viewer'
export const ROUTE_SSCHALKTALK = '/sschalktalk'
export const ROUTE_NEWS = '/news'

export const ROUTE_ADMIN_NEW_TRACK = '/admin/newtrack'
export const ROUTE_ADMIN_EDIT_TRACK = '/admin/edittrack'
export const ROUTE_ADMIN_KPI = '/admin/kpi'
export const ROUTE_ADMIN_STORE = '/admin/store'
export const ROUTE_ADMIN_NOTIFICATIONS = '/admin/notifications'

export const ROUTE_AUTH_CALLBACK = '/callback'
export const ROUTE_AUTH_PROFILECHECK = '/profilecheck'
export const ROUTE_AUTH_LICENSECHECK = '/licensecheck'
export const ROUTE_ROOT_SOLUTIONS = '/solutions'
export const ROUTE_CONTACTS = '/contacts'
export const ROUTE_IRACING = '/iracing'
export const ROUTE_ASSETTOCORSA = '/assettocorsa'
export const ROUTE_SUPPORTED_DEVICES_AND_CHANNELS = '/list-of-channels'

export const ROUTE_ROOT = '/'

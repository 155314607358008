export const BING_MAPS_KEY = 'AjaQyrHhIZ0B9HN_CbIjRXrbFr7V3JtTmttkg0o4Pr_qk31GGiIUvMcDg6gM7Luv'
export const DARK_SKY_API_KEY = '85fdf0debf8b062c88778e86b697a8cd'

const FACEBOOK_PIXEL_ID_DEV = null
const FACEBOOK_PIXEL_ID_PROD = '749806178527896'

const GOOGLE_ANALYTICS_PROPERTY_ID_DEVELOP = 'UA-114382761-4'
const GOOGLE_ANALYTICS_PROPERTY_ID_PROD = 'UA-114382761-1'

export const SENDOWL_JS_MERCHANT_ID = 131292
export const STRIPE = {
  MONTHLY_SUBSCRIPTION_STRIPE_PRODUCT_ID: process.env.MONTHLY_SUBSCRIPTION_STRIPE_PRODUCT_ID || 'plan_GiAb9a1uUCX40i',
  YEARLY_SUBSCRIPTION_STRIPE_PRODUCT_ID: process.env.YEARLY_SUBSCRIPTION_STRIPE_PRODUCT_ID || 'plan_GiAXxQAcAuxhAs',
  PUBLIC_STRIPE_KEY: process.env.PUBLIC_STRIPE_KEY || 'pk_live_27LU035jVaz3iMdVc8CC0L9200vtue16Kl',
  PUBLIC_STRIPE_KEY_TEST: 'pk_test_iZaTBetDi2uYp7c73WBr1iGH00EvIoYie9'
}

// API endpoints
// const API_LOCAL = 'http://localhost:5000'
const API_DEVELOP = 'https://trackattackapi-apidevelopment2.azurewebsites.net'
// old -  export const API_DEVELOP = 'https://trackattackapi-apidevelopment.azurewebsites.net/'
// const API_STAGING = 'https://trackattackapi-apistaging.azurewebsites.net'
const API_PROD = 'https://trackattackapi.azurewebsites.net'

// downloads
export const DOWNLOAD_WIN_LATEST = 'https://downloads.offcamberdata.com/TrackAttack-latest.msi'
export const DOWNLOAD_MAC_LATEST = 'https://downloads.offcamberdata.com/TrackAttack-latest.pkg'
export const DOWNLOAD_WIN_BETA = 'https://downloads.offcamberdata.com/TrackAttack-beta.msi'
export const DOWNLOAD_MAC_BETA = 'https://downloads.offcamberdata.com/TrackAttack-beta.pkg'

export const API_ENDPOINT = process.env.REACT_APP_TA_SERVER || API_DEVELOP
// export const IS_STAGING = API_ENDPOINT === API_STAGING
export const IS_PROD = API_ENDPOINT === API_PROD
export const IS_DEVELOP = API_ENDPOINT === API_DEVELOP

// company requisites
export const COMPANY_REQUISITES = {
  email: 'feedback@offcamberdata.com',
  website: '',
  facebook: 'https://facebook.com/trackattackapp',
  twitter: 'https://twitter.com/trackattackapp',
  instagram: 'https://www.instagram.com/trackattackapp/',
  youtube: 'https://www.youtube.com/user/TrackAttackApp'
}

// company requisites
export const COMPANY_REQUISITES_DISPLAY = {
  email: 'feedback@offcamberdata.com',
  facebook: 'facebook.com/trackattackapp',
  twitter: '@trackattackapp',
  instagram: '@trackattackapp',
  youtube: 'youtube.com/user/TrackAttackApp'
}

// $break-small: 480px;
// $break-tablet: 768px;
// $break-large: 992px;
// $break-xlarge: 1200px;
// screen sizes & render

export const BREAKOUTS = { // pixels
  MOBILE_BREAKOUT: 768,
  SMALL_BREAKOUT: 480,
  TABLET_BREAKOUT: 768,
  LARGE_BREAKOUT: 992,
  XLARGE_BREAKOUT: 1200
}

// == UI ==
export const UI_INPUT_LENGTH = {
  HANDLE: 15,
  NAME: 30,
  TEAM_NAME: 30,
  CITY: 60,
  PASS: 30,
  DESCRIPTION: 200,
  MESSAGE: 200,
  URL: 200
}

// purchase
export const LEGACY_PURCHASE_PAGE = IS_PROD ? 'https://cloud.offcamberdata.com/v2/purchase/trackattack' : 'https://dev.cloud.offcamberdata.com/v2/purchase/trackattack'

export const FACEBOOK_PIXEL_ID = IS_PROD ? FACEBOOK_PIXEL_ID_PROD
  : IS_DEVELOP ? FACEBOOK_PIXEL_ID_DEV : null

export const GOOGLE_ANALYTICS_PROPERTY_ID = IS_PROD ? GOOGLE_ANALYTICS_PROPERTY_ID_PROD
  : IS_DEVELOP ? GOOGLE_ANALYTICS_PROPERTY_ID_DEVELOP : null

export function logAll () {
  console.log('API_ENDPOINT=' + API_ENDPOINT)
  console.log('API_DEVELOP=' + API_DEVELOP)
  console.log('API_PROD=' + API_PROD)
  console.log('IS_PROD=' + IS_PROD)
  console.log('IS_DEVELOP=' + IS_DEVELOP)
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ROUTE_TEAMS } from '../../utils/routingConstants'
import { TeamsView } from '../../components'
import { Row, Grid, Col } from 'react-bootstrap'
import { GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'

class PageTeams extends Component {
  render () {
    const { userSession } = this.props
    return (
      <Row>
        <Col md={1} />
        <Col md={10}>
          <Grid fluid>
            <TeamsView title='Teams' validRelationships={userSession.isAnonymous ? ['public'] : ['mine', 'shared', 'public']} publicOnly={userSession.isAnonymous} />
          </Grid>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return { userSession: state.session }
}

export default page(connect(mapStateToProps)(PageTeams), { pageView: GA_PAGES.TEAMS, uiNavigationRoute: ROUTE_TEAMS })

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ROUTE_USERS } from '../../utils/routingConstants'
import { userFullName } from '../../utils/modelConvertionUtils'
import { Link } from 'react-router-dom'
import { TwoLineEntry, RowEntityList, InvitationOverlay } from '../'
import noImage from '../../images/no_image.jpg'

class UserList extends React.Component {
  static propTypes = {
    list: PropTypes.object.isRequired,
    renderButtons: PropTypes.func,
    renderDecorator: PropTypes.func,
    noRowsRenderer: PropTypes.func,
    fetchingRenderer: PropTypes.func,
    loadMoreRows: PropTypes.func,
    itemSelector: PropTypes.func,

    invitationEntity: PropTypes.object,
    entitySentInvites: PropTypes.array
  }

  static defaultProps = {
    itemSelector: (item) => item
  }

  constructor (props) {
    super(props)
    this.renderRow = this.renderRow.bind(this)
  }

  render () {
    const { loadMoreRows, noRowsRenderer, list, fetchingRenderer, invitationEntity, itemSelector, entitySentInvites } = this.props

    const members = list.list.map((item) => { return itemSelector(item) })

    return <RowEntityList
      list={list}
      loadMoreRows={loadMoreRows}
      rowRenderer={this.renderRow}
      noRowsRenderer={noRowsRenderer}
      fetchingRenderer={fetchingRenderer}

      header={invitationEntity ? <InvitationOverlay entity={invitationEntity} excludedUsers={members} entitySentInvites={entitySentInvites} handleTypes={['user']} /> : null}
    />
  }

  renderRow ({ index, key, style }) {
    const { renderButtons, list, itemSelector, history, renderDecorator } = this.props
    const item = itemSelector(list.list[index])
    const clickHandler = () => {
      history.push(ROUTE_USERS + '/' + item.handle)
    }
    return (
      <div key={key} style={style}>
        <TwoLineEntry
          decorator={renderDecorator ? () => { return renderDecorator.call(this, item) } : null}
          image={item.avatarUrl || noImage}
          headline={this.renderHeadline(item)}
          message={this.renderMessage(item)}
          buttons={renderButtons(item)}
          onClick={clickHandler} />
      </div>)
  }

  renderHeadline (item) {
    return (<Link to={ROUTE_USERS + '/' + item.handle}>{userFullName(item)}</Link>)
  }

  renderMessage (item) {
    return (<Link to={ROUTE_USERS + '/' + item.handle}>{item.handle}</Link>)
  }
}

function mapStateToProps (state) {
  return {
    session: state.session,
    recipients: state.ui.invites.recipients
  }
}

export default withRouter(connect(mapStateToProps)(UserList))


import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import PropTypes from 'prop-types'

class Overlay extends React.Component {
  static defaultProps = {
    closeButton: true,
    show: false
  }

  static propTypes = {
    closeAction: PropTypes.func,
    show: PropTypes.bool,
    title: PropTypes.string,
    buttons: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    closeButton: PropTypes.bool,
    bsSize: PropTypes.string,
    manualFooter: PropTypes.element
  }

  constructor (props, context) {
    super(props, context)

    this.close = this.close.bind(this)
  }

  static createButton = (buttonSpec) => {
    // spec is either simply a label or an object with one property where the key is the label and
    // the value is the function to run when the button is clicked
    const label = typeof buttonSpec === 'string' ? buttonSpec : Object.keys(buttonSpec)[0]
    const func = typeof buttonSpec === 'string' ? null : buttonSpec[label]
    return (<Button
      key={label}
      bsStyle={buttonSpec.bsStyle ? buttonSpec.bsStyle : 'default'}
      disabled={!!buttonSpec.disabled}
      onClick={func}>
      {label}
    </Button>)
  }

  static createButtons = (buttonSpecs) => {
    return !buttonSpecs ? null : buttonSpecs.map(spec => Overlay.createButton(spec))
  }

  close () {
    const {closeAction} = this.props
    closeAction && closeAction()
  }

  render () {
    const { show, title, children, buttons, closeButton, bsSize, manualFooter } = this.props
    return (
      <Modal show={show} onHide={this.close} bsSize={bsSize}>
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        {manualFooter}
        {buttons && <Modal.Footer> {buttons} </Modal.Footer>}
      </Modal>
    )
  }
}

export default Overlay

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GA_PAGES, pageView } from '../../api/gaHelper'
import { ROUTE_ROOT, ROUTE_ROOT_SOLUTIONS } from '../../utils/routingConstants'
import { uiNavigation } from '../../actions/ui'
import { Redirect } from 'react-router'
import { Grid, Row, Col } from 'react-bootstrap'
import { SignInButton, ExternalLink } from '../../components'
import { DOWNLOAD_MAC_LATEST, DOWNLOAD_WIN_LATEST } from '../../constants'
import { isMobile } from '../../utils/utils'
import classnames from 'classnames'

import imgLogoWin from '../../images/win_logo.png'
import imgLogoMac from '../../images/mac_logo.png'

import imgTDHeader from '../../images/value-specific-landing/trackdays-header.jpg'
import imgTD1 from '../../images/value-specific-landing/trackdays-1.jpg'
import imgTD2 from '../../images/value-specific-landing/trackdays-2.jpg'
import imgTD3 from '../../images/value-specific-landing/trackdays-3.jpg'

import imgTeamsHeader from '../../images/value-specific-landing/teams-header.jpg'
import imgTeams1 from '../../images/value-specific-landing/teams-1.jpg'
import imgTeams2 from '../../images/value-specific-landing/teams-2.jpg'
import imgTeams3 from '../../images/value-specific-landing/teams-3.jpg'

import imgCoachesHeader from '../../images/value-specific-landing/coaches-header.jpg'
import imgCoaches1 from '../../images/value-specific-landing/coaches-1.jpg'

import imgClubRacersHeader from '../../images/value-specific-landing/clubracers-header.jpg'
import imgClubRacers1 from '../../images/value-specific-landing/clubracers-1.jpg'

// reuse some images

const imgCoaches2 = imgTeams2 // reuse image
const imgCoaches3 = imgTD3 // reuse image

const imgClubRacers2 = imgTeams2 // reuse image
const imgClubRacers3 = imgTD3 // reuse

const ROUTES_AND_GA_NAMES_BY_TYPE = {
  teams: { uiNavigationRoute: ROUTE_ROOT_SOLUTIONS, ga: GA_PAGES.VALUE_TEAMS },
  coaches: { uiNavigationRoute: ROUTE_ROOT_SOLUTIONS, ga: GA_PAGES.VALUE_COACHES },
  trackdays: { uiNavigationRoute: ROUTE_ROOT_SOLUTIONS, ga: GA_PAGES.VALUE_TRACKDAYS },
  clubracers: { uiNavigationRoute: ROUTE_ROOT_SOLUTIONS, ga: GA_PAGES.VALUE_CLUBRACERS }
}

const HEADER_BY_TYPE = {
  teams: {
    image: imgTeamsHeader,
    title: 'Teamwork wins races and championships',
    headerText: 'Give your drivers, mechanics and coaches the data they need, with Track Attack.',
    subtitle: 'Track Attack enables team managers and owners to securely and easily share data with drivers, coaches, mechanics, engineers and other personnel. Have peace of mind that your customers and team have what they need, without letting a valuable asset walk out the door, with Track Attack.'
  },
  coaches: {
    image: imgCoachesHeader,
    title: 'Engage more often and deeply with clients',
    headerText: 'Learn about your client’s driving history before an event and stay connected for follow up engagements.',
    subtitle: 'Track Attack gives coaches a single solution to manage and analyze all client driving data, regardless of the data logging system. Clients can seamlessly share data prior to an engagement and coaches stay up to date on the client’s development afterwards. Deeper engagements, more often, with Track Attack.'
  },
  trackdays: {
    image: imgTDHeader,
    title: 'Track days are more fun when you improve',
    headerText: 'Break personal best lap times, drive more consistently and connect with other drivers, without breaking the bank.',
    subtitle: 'Track Attack brings professional level tools to help drivers get faster, without the professional level costs. Track day and HPDE drivers can use Track Attack with most of the major smartphone GPS lap timer apps and lower cost data logging systems, like the Apex Pro Track Coach. Drive faster, faster, with Track Attack.'
  },
  clubracers: {
    image: imgClubRacersHeader,
    title: 'Consistency and driving on the edge wins races',
    headerText: 'Break personal best lap times, drive more consistently, move up the timesheets and connect with other drivers, without breaking the bank.',
    subtitle: 'Track Attack brings professional level tools to help drivers get faster, without the professional level costs. Club racers and time attack drivers all over the world use Track Attack to easily compare themselves with other drivers and find those last tenths for podiums and wins. Drive faster, faster, with Track Attack.' }
}

const CONTENT_BY_TYPE = {
  teams: [
    {
      image: imgTeams1,
      title: 'Command central for teams',
      text: 'Create a team and share sessions with that team, so every team member has seamless and secure access to session data, without emailing a thing. Create top-secret, private teams, that only and your team know exists.'
    },
    {
      image: imgTeams2,
      title: 'Work with any data system',
      text: 'Regardless of what data logging system a team member uses or wants to use, Track Attack will work with their data and all members will be able to share and compare data across devices. From high-end professional loggers to smartphone apps, Track Attack works with all major data logging systems in the world.'
    },
    {
      image: imgTeams3,
      title: 'Anytime, anywhere access',
      text: 'With Track Attack, your team data is securely stored in the cloud. This means you can pick up any computer, download the app, sign-in and access all of your data. Invite drivers, share and manage sessions on the trackattack.io. Your data will be with you anywhere in the world, on any device and anytime.'
    }
  ],
  coaches: [
    {
      image: imgCoaches1,
      title: 'Command central for you',
      text: 'Track Attack is the only solution that allows you to keep track of all your clients, learn about their driving history and get access to all of the data that you need from them without doing all of the administration yourself.'
    },
    {
      image: imgCoaches2,
      title: 'Work with any data logger',
      text: 'Regardless of what data logging system a client uses or wants to use, Track Attack will work with their data and you will be able to view and compare data across devices. From high-end professional loggers to smartphone apps, Track Attack works with all major data logging systems in the world.'
    },
    {
      image: imgCoaches3,
      title: 'One system to remember',
      text: 'One of the biggest pain points we saw from coaches is having to context switch, learn and remember different systems. Track Attack is not only the easiest to use software tool, with pairing of video from any camera source but you only need to remember how to use one system. And it works on PC and Mac!'
    }
  ],
  trackdays: [
    {
      image: imgTD1,
      title: 'A digital driver log for you',
      text: 'Have you ever tried to show up at a new track day/HPDE organizer’s event and wanted to not have to start with them driving in the novice group? Now you can easily show your driving history based on real data. This is your resume as a driver, automatically created and updated when you use Track Attack.'
    },
    {
      image: imgTD2,
      title: 'Work with any data logger',
      text: 'Track Attack supports data files from most major smartphone based GPS lap timer and logging applications, including Harry’s Lap Timer, Track Addict, AiM Solo/Solo 2 (and DL versions) and Apex Pro Track Coach. Best of all, if and when you decide to move up to a more dedicated system - your data stays with you.'
    },
    {
      image: imgTD3,
      title: 'Easy insights with video',
      text: 'When looking at squiggly lines, knowing where on the track the data corresponds and what actually happened, is critical. Track Attack makes data easy by always having a track map that aligns with the data and easy synchronization of the data with video from any camera. And it works on PC and Mac!'
    }
  ],
  clubracers: [
    {
      image: imgClubRacers1,
      title: 'Data made simple',
      text: 'The two most common questions we hear are: 1. Where is that on the track? 2. How do I know if I am leaving time on the table? Track Attack always shows where every data point is on the track and makes comparing data with other people a nobrainer. Share and compare data sessions, as easy as working on Google Doc.'
    },
    {
      image: imgClubRacers2,
      title: 'Work with any data logger',
      text: 'Regardless of what data logging system you or other drivers use, Track Attack will work enable you to view and compare data with other drivers. From high-end professional loggers to entry-level loggers such as the AiM Solo 2 and Apex Pro Track Coach, to smartphone apps like Harry’s Lap Timer, Track Attack works with all major data logging systems in the world. Learning from others has never been this easy.'
    },
    {
      image: imgClubRacers3,
      title: 'Easy insights with video',
      text: 'When looking at squiggly lines, knowing where the data corresponds to on the track and what actually happened, is critical. Track Attack makes data easy by always having a track map that aligns with the data and easy synchronization of the data with video from any camera. And it works on PC and Mac!'
    }
  ]
}

class LandingValuePage extends Component {
  constructor (props) {
    super(props)
    const { pageType } = props

    if (ROUTES_AND_GA_NAMES_BY_TYPE[pageType]) {
      const { dispatch, session } = this.props
      dispatch(uiNavigation({ to: ROUTES_AND_GA_NAMES_BY_TYPE[pageType].uiNavigationRoute }))
      pageView(ROUTES_AND_GA_NAMES_BY_TYPE[pageType].ga, session)
    }
  }

  createSection ({title, text, image, key, even = true}) {
    const textCol = <Col sm={6} xs={12}>
      <h2>{title}</h2>
      <div className='value-page-section__text'>{text}</div>
    </Col>

    const imgCol = isMobile() ? null : <Col sm={6}>
      <div className={classnames('value-page-section__img-container', {'odd': !even})}>
        <img src={image} alt={title} />
      </div>
    </Col>

    return <Row className='value-page-section' key={key}>
      <Grid>
        {even ? textCol : ''}
        {imgCol}
        {even ? '' : textCol}
      </Grid>
    </Row>
  }

  render () {
    const { pageType } = this.props

    if (!ROUTES_AND_GA_NAMES_BY_TYPE[pageType]) {
      return <Redirect to={ROUTE_ROOT} />
    }

    const headerData = HEADER_BY_TYPE[pageType]

    return (
      <div className='value-page-root'>
        <Grid>
          <Row className='value-page-header'>
            <Col sm={5} xs={12}>
              <h1>{headerData.title}</h1>
              <div className='value-page-header__subtitle'>{headerData.headerText}</div>
              <SignInButton linkClassName='value-page-header__sign-up-link' text='Create a free account to get started' isLink />
            </Col>
            <Col sm={7} xs={12}>
              <div className='value-page-header__download'>
                <div className='value-page-header__download-title-image-container'>
                  <img src={headerData.image} alt={headerData.title} />
                </div>
                <div className='value-page-header__download-availablefor'>Available for:</div>
                <Row>
                  <Col xs={6}>
                    <ExternalLink to={DOWNLOAD_WIN_LATEST} >
                      <img src={imgLogoWin} alt='Windows' />
                    </ExternalLink >
                  </Col>
                  <Col xs={6}>
                    <ExternalLink to={DOWNLOAD_MAC_LATEST}>
                      <img src={imgLogoMac} alt='Mac' />
                    </ExternalLink>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Grid>
        <Row className='value-page-header_subtitle'>
          <Grid>{headerData.subtitle}</Grid>
        </Row>
        { CONTENT_BY_TYPE[pageType].map((value, idx) => {
          return this.createSection({...value, key: idx, even: (idx + 1) % 2})
        }) }
        <Row className='value-page-footer'>
          <h2>Try Track Attack for free today</h2>
          <SignInButton text='Sign up or Sign in' />
        </Row>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session,
    pageType: ownProps.match.params.pageType
  }
}

export default connect(mapStateToProps)(LandingValuePage)

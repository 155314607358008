import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ROUTE_USERS } from '../../utils/routingConstants'
import { uiFilterUsers } from '../../actions/ui'
import { fetchUsersAction } from '../../actions/userDbActions'
import { Section, UserList, InlineSearchBox } from '../../components'
import { Row, Grid, Col, Navbar } from 'react-bootstrap'
import { GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'

class PageUsers extends Component {
  constructor (props) {
    super(props)
    this.state = { filter: '' }
    this.loadUsers = this.loadUsers.bind(this)
    this.loadFilteredUsers = this.loadFilteredUsers.bind(this)
  }

  componentDidMount () {
    const { dispatch, session, filter } = this.props
    dispatch(fetchUsersAction(session.token, 0, { filter }))
  }

  componentWillReceiveProps (newProps) {
    const { filter, dispatch, session } = this.props
    if (filter !== newProps.filter) { dispatch(fetchUsersAction(session.token, 0, { filter: newProps.filter })) }
  }

  loadUsers ({ startIndex, stopIndex }) {
    const { dispatch, session, users, filter } = this.props
    const total = users.allUsers.headers ? users.allUsers.headers.pagination.totalCount : -1
    if (startIndex < total && !users.allUsers.isFetching) {
      return dispatch(fetchUsersAction(session.token, startIndex, { filter }))
    }
  }

  renderButtons (user) {
    // return (<ButtonToolbar className="list-buttons">
    //   <ButtonGroup>
    //     <Button>Follow</Button>
    //   </ButtonGroup>
    // </ButtonToolbar>)
    return null
  }

  renderAllUsers (users) {
    const noContent = () => (<div className='list-noRows'>No users available to you</div>)
    return (<Section title='All users'>
      {this.renderFilterBar()}
      <UserList list={users} loadMoreRows={this.loadUsers} noRowsRenderer={noContent} renderButtons={this.renderButtons} />
    </Section>)
  }

  loadFilteredUsers (filter) {
    this.props.dispatch(uiFilterUsers(filter))
  }

  renderFilterBar () {
    return (
      <Navbar className='search-bar' fluid>
        <Navbar.Form pullRight>
          <InlineSearchBox filter={this.props.filter} searchFunction={this.loadFilteredUsers} />
        </Navbar.Form>
      </Navbar >)
  }

  render () {
    const allUsers = this.props.users.allUsers
    return (
      <Row>
        <Col md={1} />
        <Col md={10}>
          <Grid fluid>
            {this.renderAllUsers(allUsers)}
          </Grid>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return { users: state.users, session: state.session, filter: state.ui.users.filter }
}

export default page(withRouter(connect(mapStateToProps)(PageUsers)), { pageView: GA_PAGES.USERS, uiNavigationRoute: ROUTE_USERS })

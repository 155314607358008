import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {FormControl, Col, HelpBlock, FormGroup, InputGroup, ControlLabel} from 'react-bootstrap'
import FA from 'react-fontawesome'

class UrlInput extends Component {
  static propTypes = {
    value: PropTypes.string,
    type: PropTypes.oneOf(['url', 'twitter', 'instagram', 'facebook', 'email']).isRequired,
    onChanged: PropTypes.func,
    layoutType: PropTypes.oneOf(['row', 'column'])
  }

  static defaultProps = {
    layoutType: 'column'
  }

  constructor (props, context) {
    super(props, context)

    this.handleInput = this.handleInput.bind(this)
  }

  getPrefix (type) {
    switch (type) {
      case 'twitter': return 'https://twitter.com/'
      case 'facebook': return 'https://facebook.com/'
      case 'instagram': return 'https://instagram.com/'
      default: return ''
    }
  }

  componentWillMount () {
    // set current value to editing state
    const {value, type, onChanged} = this.props

    const displayValue = value ? value.replace(this.getPrefix(type), '') : ''

    onChanged && onChanged(displayValue.length > 0 ? this.getPrefix(type) + displayValue : displayValue, this.isValidValue(displayValue, type))
  }

  isValidValue (str, type, allowNull = false) {
    if (str.length === 0) {
      return allowNull ? null : true
    }

    if (type === 'twitter') {
      // up to 15 chars, lowercase, uppercase, numbers, _
      const pattern = /^\w+$/
      return str.length <= 15 && pattern.test(str)
    }

    if (type === 'email') {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(str)
    }

    if (type === 'instagram') {
      // up to 30 chars, lowercase, uppercase, numbers, _ , .
      const pattern = /^[\w.]+$/
      return str.length <= 30 && pattern.test(str)
    }

    if (type === 'facebook') {
      // up to 50 (?) chars, lowercase, uppercase, numbers, .
      const pattern = /^[a-zA-Z0-9./=?&-]+$/
      return str.length <= 50 && pattern.test(str)
    }

    const test = this.getPrefix(type) + str
    const pattern = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    return pattern.test(test)
  }

  getLabel (type) {
    switch (type) {
      case 'twitter': return 'Twitter'
      case 'email': return 'Email'
      case 'facebook': return 'Facebook'
      case 'instagram': return 'Instagram'
      default: return 'Website'
    }
  }

  handleInput (event) {
    const {onChanged, type} = this.props

    const value = event.target.value

    onChanged && onChanged(value && value.length > 0 ? this.getPrefix(type) + value : value, this.isValidValue(value, type))
  }

  getErrorText (type, isValid = false) {
    if (isValid) {
      return null
    }

    if (isValid === null) {
      switch (type) {
        case 'twitter': return 'Type your twitter profile name'
        case 'facebook': return 'Type your facebook profile name'
        case 'email': return 'Type your email'
        case 'instagram': return 'Type your instagram profile name'
        default: return 'Type your website here'
      }
    }

    switch (type) {
      case 'twitter': return 'Use valid twitter profile name'
      case 'email': return 'Use valid email'
      case 'facebook': return 'Use valid facebook profile name'
      case 'instagram': return 'Use valid instagram profile name'
      default: return 'Use valid url, like http://example.com'
    }
  }

  getHintText (type) {
    switch (type) {
      case 'twitter': return 'handle'
      case 'facebook': return 'handle'
      case 'instagram': return 'handle'
      case 'email': return 'email address'
      default: return 'http://example.com'
    }
  }

  render () {
    const {value, type, layoutType} = this.props

    const displayValue = value.replace(this.getPrefix(type), '')

    const isValid = this.isValidValue(displayValue, type, true)

    if (layoutType === 'column') {
      return (
        <div>
          <FormGroup controlId={'input_url_' + type} validationState={isValid ? 'success' : isValid === null ? null : 'error'}>
            <Col componentClass={ControlLabel} sm={3}>{this.getLabel(type)}</Col>
            <Col sm={9}>
              <InputGroup>
                <InputGroup.Addon>{type === 'url' ? <FA name='globe' />
                  : type === 'email' ? <FA name='envelope' /> : this.getPrefix(type)}</InputGroup.Addon>
                <FormControl
                  value={displayValue}
                  onChange={this.handleInput}
                  placeholder={this.getHintText(type)}
                />
              </InputGroup>
            </Col>
            {(isValid === false) && <Col sm={9} xsOffset={3}> <HelpBlock>{this.getErrorText(type, isValid)}</HelpBlock> </Col>}
          </FormGroup>
        </div>
      )
    }

    return (
      <FormGroup controlId={'input_url_' + type} validationState={isValid ? 'success' : isValid === null ? null : 'error'}>
        <ControlLabel>{this.getLabel(type)}</ControlLabel>
        <InputGroup>
          <InputGroup.Addon>{type === 'url' ? <FA name='globe' />
            : type === 'email' ? <FA name='envelope' /> : this.getPrefix(type)}</InputGroup.Addon>
          <FormControl
            value={displayValue}
            onChange={this.handleInput}
            placeholder={this.getHintText(type)}
          />
        </InputGroup>
        <HelpBlock>{this.getErrorText(type, isValid)}</HelpBlock>
      </FormGroup>
    )
  }
}

export default UrlInput

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchSelectedUserAction, fetchSelectedUserTeamsAction, fetchSelectedUserVehiclesAction } from '../../actions/userDbActions'
import { fetchAllProfileInvitesAction } from '../../actions/inviteActions'
// import { fetchAllUserNotifications, markNotificationAsReadAction } from '../../actions/notificationsActions'
import { fetchProfileTeamsAction } from '../../actions/teamDbActions'
import { fetchProfileVehiclesAction } from '../../actions/garageActions'
import { ROUTE_USERS } from '../../utils/routingConstants'
import { searchToObject } from '../../utils/utils'
import { Section, InviteList, NotificationsList, StatisticDataList, TeamsView, VehiclesView, SocialIcons, SessionsView } from '../../components'
import { find, includes } from 'lodash'
import { Row, Grid, Col, Badge, Tab, Tabs } from 'react-bootstrap'
import { getMeasuringUnitsFromProfile, userFullName, getSpeed, getSpeedUnitsName } from '../../utils/modelConvertionUtils'
import noImage from '../../images/no_image.jpg'
import moment from 'moment'
import { pageView, GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'

class PageUserProfile extends Component {
  constructor (props) {
    super(props)

    this.state = {
      innerTab: this.getTabFromProps(props)
    }

    this.handleSelectInnerNav = this.handleSelectInnerNav.bind(this)
    this.markNotificationAsRead = this.markNotificationAsRead.bind(this)
  }

  getTabFromProps (propsObject) {
    const { search } = propsObject

    const searchObj = searchToObject(search)

    let innerNav = searchObj.tab
    const allowedHash = ['sessions', 'garage', 'teams', ...(this.isProfileUser() ? ['invites', 'notifications'] : [])]
    return includes(allowedHash, innerNav) ? innerNav : allowedHash[0]
  }

  isProfileUser (handle = this.props.handle) {
    const { userSession } = this.props
    return !userSession.isAnonymous && (userSession.profile.handle === handle)
  }

  componentDidMount () {
    const { handle, users, userSession } = this.props
    // if we have a handle then see if it matches the seleted user. If not then the user is a new selection

    // TODO: we can uncomment this to perform better but this is the easiest way to handle the objects edit
    // if (handle && (!selectedUser || !selectedUser.user || selectedUser.user.handle !== handle)) {
    this.handleNewUser(users.allUsers, handle)
    // }
    pageView(GA_PAGES.USER + handle, userSession)
  }

  componentWillReceiveProps (newProps) {
    // Look at the new props and see if the user is changing. If so, kick off some loading to get all the state in order
    const newHandle = newProps.handle
    if (newHandle && this.props.handle !== newHandle) {
      this.handleNewUser(newProps.users.allUsers, newHandle)
    }

    // handle user profile menu
    const oldTab = this.state.innerTab
    const newTab = this.getTabFromProps(newProps)
    if (oldTab !== newTab) {
      this.setState({ innerTab: newTab })
    }
  }

  // A new user has been seleted, fetch all the details
  handleNewUser (users, handle) {
    const { dispatch, userSession } = this.props
    // step 0 - clear previos user data from state
    dispatch(fetchSelectedUserAction())

    const target = (users && users.list) ? find(users.list, user => user.handle === handle) : null
    dispatch(fetchSelectedUserAction(userSession.token, handle, target))

    if (this.isProfileUser(handle)) {
      // profile user handled separately with bunch of individual requests
      dispatch(fetchAllProfileInvitesAction(userSession.token))
      // dispatch(fetchAllUserNotifications(userSession.token))
      dispatch(fetchProfileTeamsAction(userSession.token, 'all'))
      dispatch(fetchProfileVehiclesAction(userSession.token, handle))
    } else {
      dispatch(fetchSelectedUserVehiclesAction(userSession.token, handle))
      dispatch(fetchSelectedUserTeamsAction(userSession.token, handle, 'all'))
      if (this.state.innerTab === 'invites' || this.state.innerTab === 'notifications') {
        this.setState({ innerTab: 'sessions' })
      }
    }
  }

  handleSelectInnerNav (eventKey) {
    const { history, selectedUser } = this.props
    selectedUser.user && history.push(`${ROUTE_USERS}/${selectedUser.user.handle}?tab=${eventKey}`)
    // this.setState({ innerTab: eventKey })
  }

  renderTabs (stats) {
    const { profileInvites } = this.props
    const { innerTab } = this.state
    const renderBadge = counter => counter || counter === 0 ? <Badge>{counter}</Badge> : null

    const totalInvites = this.isProfileUser() && profileInvites && profileInvites.list && profileInvites.list.length

    const sessionsTitle = <span>Sessions {renderBadge(stats && stats.totalSessions)}</span>
    const invitesTitle = <span>Invites {renderBadge(totalInvites)}</span>
    // const notificationsTitle = <span>Notifications {renderBadge(profileNotifications.headers && profileNotifications.headers.pagination.totalCount)}</span>
    const garageTitle = <span>Garage {renderBadge(stats && stats.vehicles)}</span>
    const teamsTitle = <span>Teams {renderBadge(stats && stats.teams)}</span>
    // const followingTitle = <span>Following {renderBadge(following)}</span>
    // const followersTitle = <span>Followers {renderBadge(followers)}</span>

    return (
      <Tabs activeKey={innerTab} onSelect={this.handleSelectInnerNav} id='inner_tabs'>
        <Tab eventKey='sessions' title={sessionsTitle}>{this.renderSessions()}</Tab>
        {this.isProfileUser() && <Tab eventKey='invites' title={invitesTitle}>{this.renderInvites()}</Tab>}
        {/* {this.isProfileUser() && <Tab eventKey='notifications' title={notificationsTitle}>{this.renderNotifications()}</Tab>} */}
        <Tab eventKey='garage' title={garageTitle}>{this.renderGarage()}</Tab>
        <Tab eventKey='teams' title={teamsTitle}>{this.renderTeams()}</Tab>
        {/* <Tab eventKey='following' title={followingTitle}>{this.renderFollowing()}</Tab> */}
        {/* <Tab eventKey='followers' title={followersTitle}>{this.renderFollowers()}</Tab> */}
      </Tabs>
    )
  }

  renderUserStats (userStats) {
    if (!userStats) { return null }

    const { userSession } = this.props
    const units = getMeasuringUnitsFromProfile(userSession.profile)

    const topSpeedTitle = 'Top speed, ' + getSpeedUnitsName(units)

    const stats = {
      'Total sessions': userStats.totalSessions,
      'Total laps': userStats.totalLaps,
      // "Minutes over 100 mph": userStats.minutesOver100Mph,
      [topSpeedTitle]: getSpeed(userStats.topSpeed, units, 1, false),
      'Total hours': Math.round(userStats.totalSeatMinutes / 6) / 10
    }
    return <StatisticDataList stats={stats} />
  }

  renderSessions () {
    const { userSession, handle } = this.props
    const validRelationships = userSession.isAnonymous
      ? ['public']
      : this.isProfileUser() ? ['mine', 'shared'] : ['mine']
    return (<SessionsView validRelationships={validRelationships} userHandle={handle} publicOnly={!this.isProfileUser()} />)
  }

  renderTeams () {
    const { userSession, handle } = this.props
    const validRelationships = userSession.isAnonymous
      ? ['shared']
      : this.isProfileUser() ? ['mine', 'shared'] : ['all']
    return (<TeamsView validRelationships={validRelationships} userHandle={handle} publicOnly={!this.isProfileUser()} />)
  }

  renderGarage () {
    const { userSession, handle } = this.props
    const validRelationships = userSession.isAnonymous
      ? ['shared']
      : this.isProfileUser() ? ['mine'] : ['all']
    return (<VehiclesView validRelationships={validRelationships} userHandle={handle} publicOnly={!this.isProfileUser()} />)
  }

  renderInvites () {
    return <Section> <InviteList list={this.props.profileInvites} supressEntityInvitesRefresh /></Section>
  }

  markNotificationAsRead (notification) {
    // const {session, dispatch} = this.props
    // dispatch(markNotificationAsReadAction(session.token, notification))
  }

  renderNotifications () {
    const {profileNotifications} = this.props
    return <Section>
      <NotificationsList list={profileNotifications} onRead={this.markNotificationAsRead} />
    </Section>
  }

  renderUserSummary (user) {
    if (!user) { return '' }

    const { userStats } = this.props
    let driverSince
    if (userStats.item) {
      const m = moment(new Date(userStats.item.driverSince))
      // invalid data is possible here
      driverSince = m.year() > 1900 && m.format('M/D/YYYY')
    }

    const tileText = (<div>
      <h4>{userFullName(user)}</h4>
      <h5>{'@' + user.handle}</h5>
      {driverSince && <div>{`Driver since ${driverSince}`}</div>}
    </div>)

    return (
      <div>
        <img className='page__logo' src={user.avatarUrl || noImage} alt='user' />
        {tileText}
        {/* {!userSession.isAnonymous && !isProfileUser && <div><Button block onClick={this.followUser.bind(this, user)}>Follow</Button></div>} */}
        <SocialIcons entity={{...user, email: ''}} />
        {`${user.city || ''}${user.city && user.country ? ', ' : ''}${user.country ? user.country : ''}`}
        {user.description && <h5>{user.description}</h5>}
      </div>
    )
  }

  render () {
    const { selectedUser, userStats } = this.props
    return (
      <Row>
        <Col md={3}>
          {this.renderUserSummary(selectedUser.user)}
        </Col>
        <Col md={9}>
          <Grid fluid>
            <Row>
              {this.renderUserStats(userStats.item)}
            </Row>
            {this.renderTabs(userStats.item)}
          </Grid>
        </Col>
      </Row>
    )
  }
}

// selected user props are mapped to selectedXyz props from state.users.selectedUser.xyz something
// own user (profile) properties are mapped to profileXyz props from different parts of the state (teams, sessions, garage.
// Stats is the only exception here as they are always fully public

function mapStateToProps (state, ownProps) {
  return {
    search: ownProps.location.search,
    handle: ownProps.match.params.handle,
    userSession: state.session,

    users: state.users,
    selectedUser: state.users.selectedUser.user,
    userStats: state.users.selectedUser.stats,

    profileInvites: state.invites.profileInvites,
    profileNotifications: state.notifications.allUserNotifications,
    profileVehicles: state.garage.profileVehicles,
    profileTeams: state.teams.profileTeams,
    profileSessions: state.results.profileSessions,
    publicSessions: state.results.publicSessions
  }
}

export default page(withRouter(connect(mapStateToProps)(PageUserProfile)), { uiNavigationRoute: ROUTE_USERS })

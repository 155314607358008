import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, FormGroup, FormControl, HelpBlock, ControlLabel, Col, Alert, Row, Panel, Button } from 'react-bootstrap'
import { deleteSessionAction, updateSessionAction, fetchSessionInvitesAction } from '../actions/resultsActions'
import { fetchTrackAction } from '../actions/trackDbActions'
import FA from 'react-fontawesome'
import Select, {components} from 'react-select'
import { EntityLink, HandleSelect, Overlay, EmailSelect } from '../components'
import { UI_INPUT_LENGTH } from '../constants'
import { uiSessionSettings, UI_SESSION_SELECTED_TRACK, uiToast } from '../actions/ui'
import { confirmable, createConfirmation } from 'react-confirm'
import { sendInvitesAction, changeInviteStatusAction } from '../actions/inviteActions'
import * as _ from 'lodash'
import { INVITE_STATUS } from '../api/apiConstants'

class SessionSettings extends Component {
  static FieldGroup ({ id, label, help, validationState, ...props }) {
    return (
      <FormGroup controlId={id || props.name} validationState={validationState} >
        <Col componentClass={ControlLabel} sm={3}>{label}</Col>
        <Col sm={9}>
          <FormControl {...props} />
        </Col>
        {help && <Col sm={9} xsOffset={3}> <HelpBlock>{help}</HelpBlock> </Col>}
      </FormGroup>
    )
  }

  static FieldStub ({ id, label, value, onEdit, ...props }) {
    return (
      <FormGroup controlId={id || props.name} className='settings-field-stub'>
        <Col componentClass={ControlLabel} sm={3}>{label}</Col>
        <Col sm={8}>
          <div className='settings-field-stub_text'>{value}</div>
        </Col>
        <Col sm={1}>
          {onEdit && <a className='settings-field-stub_edit' onClick={onEdit}><FA name='edit' /></a>}
        </Col>
      </FormGroup>
    )
  }

  constructor (props) {
    super(props)
    this.changeHandler = this.handleChange.bind(this)
    this.clearDriverHandler = this.handleHandleChange.bind(this, 'driver', null)
    this.handleTrackChange = this.handleHandleChange.bind(this, 'track')
    this.handleVehicleChange = this.handleHandleChange.bind(this, 'vehicle')

    this.handleDelete = this.handleDelete.bind(this)

    this.inviteByEmailHandler = this.startInviteDriver.bind(this, true)
    this.inviteByHandleHandler = this.startInviteDriver.bind(this, false)

    this.endInviteDriver = this.endInviteDriver.bind(this)
    this.cancelPendingDriverInvite = this.cancelPendingDriverInvite.bind(this)

    this.state = ({
      invitationEmail: false,
      invitationHandle: false
    })
  }

  isValid (newState = null) {
    // All is optional but if there is a layout there needs to be a track and vice versa
    const settings = newState || this.props.settings
    return !!(settings.layout ? settings.track : !settings.track)
  }

  componentDidMount () {
    const { dispatch, selectedSession, userSession } = this.props
    const session = selectedSession.item
    const initialState = {
      driver: session.driver ? { handle: session.driver.handle, entityId: session.driver.id, picture: session.driver.avatarUrl } : null,
      track: session.track ? { handle: session.track.handle, entityId: session.track.id } : null,
      layout: session.layout ? { label: session.layout.name, value: session.layout.id } : null,
      vehicle: session.vehicle ? { handle: session.vehicle.handle, entityId: session.vehicle.id } : null,
      name: session.name,
      description: session.description,
      visibility: session.visibility
    }
    dispatch(uiSessionSettings(initialState, { valid: this.isValid(initialState) }))
    // if there is a track, fetch so we have the layout
    if (session.track && session.layoutId) { dispatch(fetchTrackAction(userSession.token, session.track.handle, UI_SESSION_SELECTED_TRACK)) }
    dispatch(fetchSessionInvitesAction(userSession.token, session.id))
  }

  okDeleteSessionOperation (session) {
    const { dispatch, userSession } = this.props
    dispatch(deleteSessionAction(userSession.token, session.id))
  }

  handleDelete (event) {
    const {selectedSession} = this.props
    const session = selectedSession.item

    event.stopPropagation()

    const dialog = ({ show, proceed, dismiss, cancel, confirmation, options }) => {
      const deleteString = `Delete the session forever`
      const buttons = Overlay.createButtons([
        { [deleteString]: () => proceed(session), bsStyle: 'danger' },
        { Cancel: cancel, bsStyle: 'default' }
      ])
      return (<Overlay
        show={show}
        title={deleteString + '?'}
        closeAction={dismiss}
        buttons={buttons}>
        <Alert bsStyle='danger' >
          <p>You are about to delete this session forever.</p>
          <p><strong>This action cannot be undone!</strong></p>
        </Alert>
      </Overlay>)
    }

    const confirmer = createConfirmation(confirmable(dialog))
    confirmer('', {}).then(session => this.okDeleteSessionOperation(session), () => { })
  }

  handleChange (event) {
    const { dispatch, settings } = this.props
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const newState = { ...settings, [target.name]: value }
    dispatch(uiSessionSettings(newState, { valid: this.isValid(newState) }))
  }

  handleHandleChange (name, value) {
    const { dispatch, settings, userSession } = this.props
    const newState = { ...settings, [name]: value }
    dispatch(uiSessionSettings(newState, { valid: this.isValid(newState) }))
    if (name === 'track' && value) {
      // if the track changes, load the new track to get the layouts
      // The reducer will setup the layout
      dispatch(fetchTrackAction(userSession.token, value.handle, UI_SESSION_SELECTED_TRACK))
    }
  }

  startInviteDriver (isEmail) {
    this.setState({
      invitationEmail: isEmail,
      invitationHandle: !isEmail,
      inviteEmailDriver: null,
      inviteHandleDriver: null
    })
  }

  endInviteDriver () {
    this.setState({
      invitationEmail: false,
      invitationHandle: false,
      inviteEmailDriver: null,
      inviteHandleDriver: null
    })
  }

  renderOverlay () {
    const { invitationEmail, invitationHandle, inviteEmailDriver, inviteHandleDriver } = this.state
    const { userSession, selectedSession, dispatch, settings } = this.props

    const proceedInvite = () => {
      let recipientSpec

      if (invitationEmail) {
        recipientSpec = inviteEmailDriver.value
      }

      if (invitationHandle) {
        // check spessial case
        if (inviteHandleDriver.handle === userSession.profile.handle) {
          // save the driver change at once!
          const newState = { ...settings, driver: inviteHandleDriver }
          dispatch(uiSessionSettings(newState, { valid: this.isValid(newState) }))
          const sessionSpec = {
            ...selectedSession.item,
            driver: null,
            driverId: inviteHandleDriver.entityId,
            track: null,
            layout: null,
            vehicle: null
          }
          dispatch(updateSessionAction(userSession.token, sessionSpec))
        } else {
          recipientSpec = inviteHandleDriver.handle
        }
      }

      if (recipientSpec) {
        const spec = {
          targetType: 'Session',
          targetSpec: selectedSession.item.id,
          recipientType: 'user',
          recipientSpec,
          role: 'driver'
        }

        // set old driver to null
        const newState = { ...settings, driver: null }
        dispatch(uiSessionSettings(newState, { valid: this.isValid(newState) }))

        dispatch(sendInvitesAction(userSession.token, [spec]))
          .then((result) => {
            dispatch(uiToast({ text: 'Invitation sent' }))
            // if the session has the driver we need to clear it and save the settings
            // to maintain same experience as when sending the invite - driver change will be recordered without clicking the "SAVE btn"
            if (selectedSession.item.driver) {
              const sessionSpec = {
                ...selectedSession.item,
                driver: null,
                driverId: null,
                track: null,
                layout: null,
                vehicle: null
              }
              dispatch(updateSessionAction(userSession.token, sessionSpec))
            }
          })
      }

      this.endInviteDriver()
    }

    const emailOrHAndleForInviteIsSet = (args) => {
      if (args) {
        // handle
        if (args.handle) {
          this.setState({ inviteHandleDriver: args })
        }
        // email
        if (args.value) {
          this.setState({ inviteEmailDriver: args })
        }
      }
    }

    const canInvite = inviteEmailDriver || inviteHandleDriver

    return <Overlay
      show={invitationEmail || invitationHandle}
      title={invitationEmail ? 'Invite driver via email' : 'Find driver by handle'}
      closeAction={this.endInviteDriver}
      buttons={Overlay.createButtons([
        { Invite: proceedInvite, bsStyle: 'success', disabled: !canInvite },
        { Cancel: this.endInviteDriver }
      ])}>
      {invitationHandle && <Row>
        <HandleSelect
          value={inviteHandleDriver}
          exclusions={[]}
          types={['user']}
          changeHandler={emailOrHAndleForInviteIsSet}
          token={userSession.token}
          placeholder='What amazing machine was doing the rounds?'
          multi={false}
          title='Driver' />
      </Row>}
      {invitationEmail && <Row>
        <EmailSelect
          multi={false}
          exclusions={[]}
          emails={inviteEmailDriver}
          changeHandler={emailOrHAndleForInviteIsSet}
        />
      </Row>}
    </Overlay>
  }

  getDriverInvite () {
    const { sessionInvites, selectedSession } = this.props

    let driverInvite

    if (sessionInvites.list) {
      driverInvite = _.find(sessionInvites.list, { role: 'driver', targetId: selectedSession.item.id, invitationStatus: 2, invitationType: 2 })
    }

    return driverInvite
  }

  cancelPendingDriverInvite () {
    const { dispatch, userSession } = this.props

    const driverInvite = this.getDriverInvite()

    driverInvite && dispatch(changeInviteStatusAction(userSession.token, driverInvite, INVITE_STATUS.CANCELLED))
  }

  renderInviteButtonsOrInviteObject () {
    const { selectedSession } = this.props

    const driverInvite = this.getDriverInvite()

    return driverInvite
      ? <span className='help-block'>
        You have invited a driver for this session. <a onClick={this.cancelPendingDriverInvite}>Cancel invite</a> if you want to invite someone else
      </span>
      : <span className='help-block'>
        {selectedSession.item.driver ? 'Whant to change driver?' : ''} Invite a driver <a onClick={this.inviteByHandleHandler}>by their handle</a> or <a onClick={this.inviteByEmailHandler}>via email</a>
      </span>
  }

  renderDriverName () {
    const { sessionInvites, selectedSession, settings } = this.props

    if (settings.driver) {
      return <EntityLink type={'user'} entity={settings.driver} />
    }

    let driverInvite

    if (sessionInvites.list) {
      driverInvite = _.find(sessionInvites.list, { role: 'driver', targetId: selectedSession.item.id, invitationStatus: 2, invitationType: 2 })
    }

    if (driverInvite && driverInvite.recipientObject) {
      return <span>
        {driverInvite.recipientObject.handle ? <EntityLink type={'user'} entity={driverInvite.recipientObject} /> : <b>{driverInvite.recipientObject.email}</b>} - Pending
      </span>
    }

    return <span>Not set</span>
  }

  renderRemoveDriverButton () {
    const { sessionInvites, selectedSession, settings } = this.props

    if (settings.driver) {
      return <a className='settings-field-stub_clear' onClick={this.clearDriverHandler}><FA name='remove' /></a>
    }

    let driverInvite

    if (sessionInvites.list) {
      driverInvite = _.find(sessionInvites.list, { role: 'driver', targetId: selectedSession.item.id, invitationStatus: 2, invitationType: 2 })
    }

    if (driverInvite) {
      return <a className='settings-field-stub_clear' onClick={this.cancelPendingDriverInvite}><FA name='remove' /></a>
    }

    return ''
  }

  customOption (props) {
    return <components.Option {...props}>
      <div className='dropdown-item' key={props.data.value}>
        <div><strong>{props.data.label}</strong></div>
        <div className='description'>{props.data.desc}</div>
      </div>
    </components.Option>
  }

  render () {
    const { userSession, settings, selectedSession, selectedTrack } = this.props
    if (selectedSession.isFetching || !settings) { return <Alert bsStyle='info'>Updating data...</Alert> }

    if (selectedSession.error) { return <Alert bsStyle='danger'>Failed to save your changes. Please retry</Alert> }

    const layoutOptions = selectedTrack && selectedTrack.item
      ? selectedTrack.item.layouts.map(layout => { return { value: layout.id, label: layout.name } })
      : []

    const visibilityOptions = [
      { value: 0, label: 'Private', desc: 'Only you and people you share the session know it exists' },
      { value: 1, label: 'Summary', desc: 'Anyone can see the basic session stats. Only people you\'ve shared the session with can access the data.' }
    ]

    const selectedV = _.find(visibilityOptions, {value: settings && settings.visibility === 0 ? 0 : 1})

    const visibilityRenderer = (item) => {
      return (
        <div className='dropdown-item' key={item.value}>
          <div><strong>{item.label}</strong></div>
          <div className='description'>{item.desc}</div>
        </div>
      )
    }

    return (
      <Form horizontal>
        {this.renderOverlay()}
        <Row>
          <Col sm={11}>
            <SessionSettings.FieldGroup
              key='name'
              name='name'
              type='text'
              label='Name'
              maxLength={UI_INPUT_LENGTH.SESSION_NAME}
              value={settings.name || ''}
              onChange={this.changeHandler}
              placeholder='Make your session easier to remember with an interesting name'
            />

            <FormGroup controlId='visibility' validationState={null}>
              <Col componentClass={ControlLabel} sm={3}>Session visibility</Col>
              <Col sm={9}>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  value={selectedV}
                  options={visibilityOptions}
                  components={{ Option: this.customOption }}
                  optionRenderer={visibilityRenderer}
                  onChange={visibility => this.handleChange({ target: { name: 'visibility', value: visibility.value } })} />
              </Col>
            </FormGroup>

            <FormGroup controlId='driver' className='settings-field-stub'>
              <Col componentClass={ControlLabel} sm={3}>Driver</Col>
              <Col sm={8}>
                <div className='settings-field-stub_text'>{this.renderDriverName()}</div>
              </Col>
              <Col sm={1}>
                {this.renderRemoveDriverButton()}
              </Col>
              <Col smOffset={3} sm={9}>
                {this.renderInviteButtonsOrInviteObject()}
              </Col>
            </FormGroup>

            <HandleSelect
              exclusions={[]}
              value={settings.track ? settings.track : null}
              types={['track']}
              changeHandler={this.handleTrackChange}
              token={userSession.token}
              placeholder='What track was it at?'
              multi={false}
              title='Track' />

            <FormGroup controlId='layout' validationState={null}>
              <Col componentClass={ControlLabel} sm={3}>Layout</Col>
              <Col sm={9}>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  value={settings.layout}
                  options={layoutOptions}
                  onChange={layout => this.handleChange({ target: { name: 'layout', value: layout } })} />
              </Col>
            </FormGroup>

            <HandleSelect
              exclusions={[]}
              value={settings.vehicle ? settings.vehicle : null}
              types={['vehicle']}
              changeHandler={this.handleVehicleChange}
              token={userSession.token}
              placeholder='What amazing machine was doing the rounds?'
              multi={false}
              title='Vehicle' />

            <SessionSettings.FieldGroup
              key='description'
              name='description'
              type='textarea'
              componentClass='textarea'
              label='Description'
              maxLength={UI_INPUT_LENGTH.DESCRIPTION}
              rows={8}
              value={settings.description || ''}
              onChange={this.changeHandler}
              placeholder='Capture details about the session. Car configuration, temp, track conditions, other cars, ...'
            />

            <hr />
            <Panel bsStyle='danger'>
              <Panel.Heading><Panel.Toggle>Dangerous operations</Panel.Toggle></Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>
                  <Alert bsStyle='danger' >
                    <p>Deleting this session permanently removes all of its data
                  from Track Attack and makes it inaccessible to your teams and friends.
                    </p>
                    <p>
                      <strong>This action cannot be undone!</strong>
                    </p>
                  </Alert>
                  <Button bsStyle='danger' onClick={this.handleDelete}>Delete this session and all its data forever</Button>
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          </Col>
        </Row>
      </Form>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    userSession: state.session,
    selectedSession: state.results.selectedSession,
    selectedTrack: state.ui.sessions.selectedTrack,
    settings: state.ui.sessions.settings,
    sessionInvites: state.invites.sessionInvites
  }
}

export default connect(mapStateToProps)(SessionSettings)

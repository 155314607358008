import { asyncActions } from '.'
import { unreadNotifications, allNotifications, createNotification, deleteNotification, markNotificationAsRead } from '../api'

export const NOTIFICATIONS_UNREAD_FETCH = 'NOTIFICATIONS_UNREAD_FETCH'
export const NOTIFICATIONS_USER_ALL_FETCH = 'NOTIFICATIONS_USER_ALL_FETCH'
export const NOTIFICATIONS_ADMIN_ALL_FETCH = 'NOTIFICATIONS_ADMIN_ALL_FETCH'
export const NOTIFICATIONS_OPEN = 'NOTIFICATIONS_OPEN'
export const NOTIFICATIONS_MARK_READ = 'NOTIFICATIONS_MARK_READ'

export function fetchUnreadNotifications (token) {
  return (dispatch) => {
    const actions = asyncActions(NOTIFICATIONS_UNREAD_FETCH)
    dispatch(actions.start({clear: true}))
    return unreadNotifications(token).then(
      (result) => {
        dispatch(actions.success(result))
      },
      (error) => {
        dispatch(actions.error(error))
      }
    )
  }
}

export function fetchAllUserNotifications (token, skip) {
  return (dispatch) => {
    const actions = asyncActions(NOTIFICATIONS_USER_ALL_FETCH)
    dispatch(actions.start({clear: true}))
    return allNotifications(token, skip).then(
      (result) => {
        dispatch(actions.success(result))
      },
      (error) => {
        dispatch(actions.error(error))
      }
    )
  }
}

export function fetchAllAdminNotifications (token, skip) {
  return (dispatch) => {
    const actions = asyncActions(NOTIFICATIONS_ADMIN_ALL_FETCH)
    dispatch(actions.start())
    return allNotifications(token, skip).then(
      (result) => {
        // HACK: remove map() when admin API is ready
        dispatch(actions.success({...result, list: result.list.map(item => item.notification)}))
      },
      (error) => {
        dispatch(actions.error(error))
      }
    )
  }
}

export function markNotificationAsReadAction (token, notification) {
  return (dispatch) => {
    const actions = asyncActions(NOTIFICATIONS_UNREAD_FETCH)
    const actionsAll = asyncActions(NOTIFICATIONS_USER_ALL_FETCH)

    dispatch(actions.start())
    dispatch(actionsAll.start())
    return markNotificationAsRead(token, notification).then(
      (result) => {
        dispatch(actionsAll.success({remove: notification, add: result}))
        return unreadNotifications(token)
      },
      (error) => {
        dispatch(actionsAll.error(error))
      }
    )
      .then(
        (result) => {
          dispatch(actions.success(result))
        },
        (error) => {
          dispatch(actions.error(error))
        }
      )
  }
}

export function createNotificationAction (token, notification) {
  return (dispatch) => {
    const actions = asyncActions(NOTIFICATIONS_ADMIN_ALL_FETCH)
    dispatch(actions.start())
    return createNotification(token, notification).then(
      (result) => {
        dispatch(actions.success({add: result, prepend: true}))
      },
      (error) => {
        dispatch(actions.error(error))
      }
    )
  }
}

export function deleteNotificationAction (token, notification) {
  return (dispatch) => {
    const actions = asyncActions(NOTIFICATIONS_ADMIN_ALL_FETCH)
    dispatch(actions.start())
    return deleteNotification(token, notification).then(
      (result) => {
        dispatch(actions.success({remove: result}))
      },
      (error) => {
        dispatch(actions.error(error))
      }
    )
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { FormGroup, FormControl, Row, Col, ControlLabel, Button, Thumbnail, ButtonToolbar } from 'react-bootstrap'
import { SIMULATOR_PLATFORM } from '../api/apiConstants'
import FontAwesome from 'react-fontawesome'
import * as _ from 'lodash'

const propTypes = {
  layoutInfo: PropTypes.object.isRequired,

  onSaveLayout: PropTypes.func.isRequired,
  onDeleteLayout: PropTypes.func.isRequired,
  onAddSegment: PropTypes.func.isRequired,
  onDeleteSegment: PropTypes.func.isRequired,

  onEditName: PropTypes.func.isRequired,
  onEditOrientation: PropTypes.func.isRequired,
  onEditType: PropTypes.func.isRequired,
  onEditSurfaceType: PropTypes.func.isRequired,
  onEditSimulatorPlatform: PropTypes.func.isRequired,
  onEditTurns: PropTypes.func.isRequired,
  onEditDistance: PropTypes.func.isRequired,
  onClickedMapImage: PropTypes.func.isRequired,
  onEditStartLineLat: PropTypes.func.isRequired,
  onEditStartLineLong: PropTypes.func.isRequired,
  onEditFinishLineLat: PropTypes.func.isRequired,
  onEditFinishLineLong: PropTypes.func.isRequired,
  onEditSegmentLat: PropTypes.func.isRequired,
  onEditSegmentLong: PropTypes.func.isRequired,
  onEdit3rdpartyID: PropTypes.func.isRequired,
  onEditStartPointHeading: PropTypes.func.isRequired,
  onEditEndPointHeading: PropTypes.func.isRequired,

  validTypes: PropTypes.array.isRequired,
  surfaceTypes: PropTypes.array.isRequired,

  onValidateName: PropTypes.func.isRequired
}

const ORIENTATION_OPTIONS = [
  {
    value: 1,
    label: 'Clockwise'
  }, {
    value: 2,
    label: 'Counter-Clockwise'
  }
]

const VIRTUAL_OPTIONS = [
  {
    value: SIMULATOR_PLATFORM.realWorld,
    label: 'Real world'
  }, {
    value: SIMULATOR_PLATFORM.iRacing,
    label: 'iRacing'
  }, {
    value: SIMULATOR_PLATFORM.AssettoCorsa,
    label: 'Assetto Corsa'
  }, {
    value: SIMULATOR_PLATFORM.AssettoCorsaCompetizione,
    label: 'Assetto Corsa Competizione'
  }, {
    value: SIMULATOR_PLATFORM.RFactor,
    label: 'R Factor'
  }, {
    value: SIMULATOR_PLATFORM.RFactor2,
    label: 'R Factor 2'
  }, {
    value: SIMULATOR_PLATFORM.ProjectCars,
    label: 'Project Cars'
  }, {
    value: SIMULATOR_PLATFORM.ForzaMotorsports,
    label: 'Forza Motorsports'
  }
]

class TrackLayoutEdit extends Component {
  render () {
    const {layoutInfo, validTypes, surfaceTypes} = this.props

    let segmentsArray
    try {
      if (layoutInfo.segments instanceof Array) { segmentsArray = layoutInfo.segments } else { segmentsArray = JSON.parse(layoutInfo.segments) }
    } catch (ex) {
      segmentsArray = []
    }

    if (!segmentsArray) {
      segmentsArray = []
    }

    const selectedOrientation = _.find(ORIENTATION_OPTIONS, {value: layoutInfo.isClockwise ? 1 : 2})
    const selectedSimulator = _.find(VIRTUAL_OPTIONS, {value: layoutInfo.simulatorPlatform || 0})
    const selectedLayoutType = _.find(validTypes, {value: layoutInfo.typeTrackId})
    const selectedSurfaceType = _.find(surfaceTypes, {value: layoutInfo.surfaceType})

    return (<div className='main-info-container'>
      <Row>
        <Col xs={6}>
          <FormGroup validationState={this.props.onValidateName(layoutInfo.name) === '' ? 'success' : 'error'} >
            <ControlLabel className='info-title'>Name</ControlLabel>
            <FormControl
              type='text'
              label='Name'
              maxLength={60}
              value={layoutInfo.name || ''}
              placeholder='Layout name'
              onChange={(e) => this.props.onEditName(e, layoutInfo.id)}
              className='info-input' />
          </FormGroup>

          <FormGroup>
            <ControlLabel className='info-title'>Orientation</ControlLabel>
            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              value={selectedOrientation}
              options={ORIENTATION_OPTIONS}
              onChange={(orientation) => this.props.onEditOrientation(orientation, layoutInfo.id)} />
          </FormGroup>

          <FormGroup>
            <ControlLabel className='info-title'>Type</ControlLabel>
            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              value={selectedLayoutType}
              options={this.props.validTypes}
              onChange={(type) => this.props.onEditType(type, layoutInfo.id)} />
          </FormGroup>

          <FormGroup>
            <ControlLabel className='font-weight-normal'>Surface type</ControlLabel>
            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              value={selectedSurfaceType}
              options={this.props.surfaceTypes}
              onChange={(type) => this.props.onEditSurfaceType(type, layoutInfo.id)} />
          </FormGroup>

          <FormGroup>
            <ControlLabel className='font-weight-normal'>Simulator platform</ControlLabel>
            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              value={selectedSimulator}
              options={VIRTUAL_OPTIONS}
              onChange={(value) => this.props.onEditSimulatorPlatform(value, layoutInfo.id)} />
          </FormGroup>

          <FormGroup>
            <ControlLabel className='info-title'># of Turns :</ControlLabel>
            <FormControl
              type='number'
              label='turns'
              value={layoutInfo.turnCount || ''}
              placeholder='Turns'
              onChange={(e) => this.props.onEditTurns(e, layoutInfo.id)}
              className='smaller-input info-input' />
          </FormGroup>

          <FormGroup>
            <ControlLabel className='info-title'>Distance(miles) :</ControlLabel>
            <FormControl
              type='number'
              value={layoutInfo.distance || ''}
              placeholder='Distance'
              onChange={(e) => this.props.onEditDistance(e, layoutInfo.id)}
              className='smaller-input info-input' />
          </FormGroup>

          <FormGroup>
            <ControlLabel className='info-title'>3rd party ID :</ControlLabel>
            <FormControl
              type='text'
              value={layoutInfo.thirdPartyId || ''}
              placeholder='3rd party id'
              onChange={(e) => this.props.onEdit3rdpartyID(e, layoutInfo.id)}
              className='smaller-input info-input' />
          </FormGroup>

          <FormGroup>
            <ControlLabel className='info-title'>Start point heading:</ControlLabel>
            <FormControl
              type='number'
              value={layoutInfo.startPointAngle || ''}
              placeholder='start point heading'
              onChange={(e) => this.props.onEditStartPointHeading(e, layoutInfo.id)}
              className='smaller-input info-input' />
          </FormGroup>

          <FormGroup>
            <ControlLabel className='info-title'>End point heading:</ControlLabel>
            <FormControl
              type='number'
              value={layoutInfo.endPointAngle || ''}
              placeholder='end point heading'
              onChange={(e) => this.props.onEditEndPointHeading(e, layoutInfo.id)}
              className='smaller-input info-input' />
          </FormGroup>

          {/* this.renderLocationInputs('Location', this.props.layoutInfo.lat, this.onChangeLat.bind(this), this.props.layoutInfo.lng, this.onChangeLng.bind(this)) */}
        </Col>

        <Col xs={6}>
          <ControlLabel className='info-title'>Layout Map Image</ControlLabel>
          <Thumbnail
            src={null}
            className='image-thumbnail'
            onClick={(e) => this.props.onClickedMapImage(e, layoutInfo.id)} />

          {this.renderLocationInputs(
            'Start Line',
            layoutInfo.startPointLat,
            (e) => this.props.onEditStartLineLat(e, layoutInfo.id),
            layoutInfo.startPointLong,
            (e) => this.props.onEditStartLineLong(e, layoutInfo.id))}

          {this.renderLocationInputs('Finish Line',
            layoutInfo.endPointLat,
            (e) => this.props.onEditFinishLineLat(e, layoutInfo.id),
            layoutInfo.endPointLong,
            (e) => this.props.onEditFinishLineLong(e, layoutInfo.id))}

          <div className='separator margin-bottom' />

          <div>
            {
              segmentsArray.map((item, index) => <div key={index}>
                {
                  this.renderLocationInputs(
                    'Segment ' + (index + 1),
                    item.lat,
                    (e) => this.props.onEditSegmentLat(e, layoutInfo.id),
                    item.lon,
                    (e) => this.props.onEditSegmentLong(e, layoutInfo.id),
                    index,
                    () => this.props.onDeleteSegment(index, layoutInfo.id)
                  )
                }
              </div>)
            }
          </div>

          {/* Add segment button */}
          <Row className='hcenter'>
            <Button onClick={() => this.props.onAddSegment(layoutInfo.id)}>Add segment</Button>
          </Row>
        </Col>
      </Row>

      <Row className='hcenter'>
        <ButtonToolbar>
          <Button bsStyle='success'
            className='save-layout-button'
            onClick={() => this.props.onSaveLayout(layoutInfo)}>Save layout</Button>
          <Button bsStyle='danger'
            className='save-layout-button'
            onClick={() => this.props.onDeleteLayout(layoutInfo)}>Delete layout</Button>
        </ButtonToolbar>
      </Row>
    </div>)
  }

  renderLocationInputs (title, latValue, latCallback, lngValue, lngCallback, index, closeCallback) {
    return (
      <Row className='no-margin'>
        <Row className='no-margin'><ControlLabel className='info-title'>{title}</ControlLabel></Row>
        <Row className='no-margin'>
          <Col xs={closeCallback ? 5 : 6}>
            <FormGroup>
              <Row className='no-margin'>
                <Col xs={3}>
                  <ControlLabel className='info-title location-title'>Lat:</ControlLabel>
                </Col>

                <Col xs={9}>
                  <FormControl
                    type='number'
                    value={latValue || ''}
                    placeholder='Latitude'
                    onChange={latCallback}
                    data-index={index}
                    className='smaller-input info-input' />
                </Col>
              </Row>
            </FormGroup>
          </Col>

          <Col xs={closeCallback ? 5 : 6}>
            <FormGroup>
              <Row className='no-margin'>
                <Col xs={3}>
                  <ControlLabel className='info-title location-title'>Long:</ControlLabel>
                </Col>

                <Col xs={9}>
                  <FormControl
                    type='number'
                    value={lngValue || ''}
                    placeholder='Longitude'
                    onChange={lngCallback}
                    data-index={index}
                    className='smaller-input info-input' />
                </Col>
              </Row>
            </FormGroup>
          </Col>

          {closeCallback &&
            <Col xs={2}>
              <Button onClick={closeCallback}><FontAwesome name='times' /></Button>
            </Col>
          }
        </Row>
      </Row>
    )
  }
}

TrackLayoutEdit.propTypes = propTypes

export default TrackLayoutEdit

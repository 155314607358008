import React, { Component, Fragment } from 'react'
import { Table, Alert } from 'react-bootstrap'
import { connect } from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link'
import { Section, SignInButton, ExternalLink } from '../../components'
import { page } from '../../hocs/page'
import { GA_PAGES } from '../../api/gaHelper'
import { ROUTE_ASSETTOCORSA, ROUTE_IRACING, ROUTE_STORE, ROUTE_FAQ, ROUTE_PURCHASECOMPLETED_STRIPE, ROUTE_PRICING } from '../../utils/routingConstants'
import FA from 'react-fontawesome'
import { getAvailableSubscriptions } from '../../actions/sessionActions'
import { isPremiumUser, isPremiumUserLegacy } from '../../api/auth0'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE } from '../../constants'
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// pk_live_27LU035jVaz3iMdVc8CC0L9200vtue16Kl - LIVE
// pk_test_iZaTBetDi2uYp7c73WBr1iGH00EvIoYie9 - TEST
const stripePromise = loadStripe(STRIPE.PUBLIC_STRIPE_KEY)

const PlanTile = ({plan}) => {
  return <div className='plan'>
    <h3 className='plan-name'>{plan.name}</h3>
    <div className='plan-price'>{plan.price}</div>
    <div className='plan-price__explanation'>{plan.periodElement}</div>
    <div className='plan-description'>
      <div className='plan-description__header'>{plan.descTitle}</div>
      <ul>
        {plan.descriptionLines.map(descriptionLine => <li key={descriptionLine}>{descriptionLine}</li>)}
      </ul>
    </div>
    <div className='plan-footer'>
      {plan.signInText && <SignInButton isLink text={plan.signInText} /> }
      {plan.isCurrent && <Fragment>{plan.currentText || 'Your current plan'}</Fragment>}
      {!plan.isCurrent && plan.upgradeLinkElement && plan.upgradeLinkElement}
    </div>
  </div>
}

class PagePricing extends Component {
  componentDidMount () {
    const { dispatch, session } = this.props
    if (session.isAnonymous) { return }

    dispatch(getAvailableSubscriptions(session.token)).then(() => {
      window.setInterval(() => {
        window.sendOwl && window.sendOwl.captureLinks()
      })
    })
  }

  getPlans () {
    const {userSession} = this.props

    // consider only subscriptions and let legacy users to upgrade
    const isUserPremium = isPremiumUser(userSession) && !isPremiumUserLegacy(userSession)

    const stripeClickHandler = (key) => async (event) => {
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await stripePromise
      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          // Replace with the ID of your price
          {price: key, quantity: 1}
        ],
        mode: 'subscription',
        successUrl: `https://trackattack.io${ROUTE_PURCHASECOMPLETED_STRIPE}`,
        // go back to the current page
        cancelUrl: `https://trackattack.io${ROUTE_PRICING}`,
        customerEmail: userSession.profile.email
      })
      error && console.log(error)
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
    }

    const priceMonthly = `$8`
    const priceAnnual = `$80`
    return [
      {
        key: 'key-free',
        name: 'Free',
        price: '$0',
        periodElement: <Fragment>per user / month</Fragment>,
        descTitle: 'Basic free plan:',
        descriptionLines: [
          'Easy to use analysis software',
          'Limited cloud storage',
          'Share data with teams',
          'Compete on leaderboards'
        ],
        isCurrent: !userSession.isAnonymous && !isUserPremium,
        signInText: userSession.isAnonymous ? 'Sign up now' : null,
        upgradeLinkElement: null
      },
      {
        key: 'key-premium',
        name: 'Premium',
        price: priceMonthly,
        periodElement: (isUserPremium || userSession.isAnonymous)
          ? <span>per user / month or {priceAnnual} per user / year</span>
          : <span>per user / month or <a onClick={stripeClickHandler(STRIPE.YEARLY_SUBSCRIPTION_STRIPE_PRODUCT_ID)}> {priceAnnual} per user / year</a></span>,
        descTitle: 'Premium plan:',
        descriptionLines: [
          'Unlimited session storage',
          'Create private sessions',
          'Auto-sync data'
        ],
        isCurrent: isUserPremium,
        currentText: 'You are premium!',
        signInText: userSession.isAnonymous ? 'Sign in to upgrade' : null,
        upgradeLinkElement: !userSession.isAnonymous && <a onClick={stripeClickHandler(STRIPE.MONTHLY_SUBSCRIPTION_STRIPE_PRODUCT_ID)}>Upgrade to premium</a>
      },
      {
        key: 'key-student',
        name: 'For Students',
        price: '$30',
        periodElement: <span>per year</span>,
        descTitle: 'Student plan:',
        descriptionLines: [
          'Please contact us to receive your',
          'student specific discount code',
          <i>&nbsp;</i>,
          'Full premium features'
        ],
        isCurrent: isUserPremium,
        currentText: 'You are premium!',
        signInText: userSession.isAnonymous ? 'Sign in to upgrade' : null,
        upgradeLinkElement: !userSession.isAnonymous && <a href='mailto:feedback@offcamberdata.com?subject=Student%20Discount'>Contact Us</a>
      }
    ]
  }

  yes (element) {
    return <Fragment><FA className='yes' name='check' /> {element}</Fragment>
  }

  no (element) {
    return <Fragment><FA className='no' name='close' /> {element}</Fragment>
  }

  render () {
    return (
      <Section className='page-pricing' title='Plans for every driver and team'>
        <p>Track attack is free to use for public sessions and teams.</p>
        <p>Unlock unlimited private sessions, session cloud storage, video and lap autosync and other premium features.</p>
        {isPremiumUserLegacy(this.props.userSession) && <Alert>We noticed that you have a Legacy Track Attack license. We have transitioned to a different, subscription-based model. Please upgrade to our new Premium subscription!</Alert>}
        <div className='plan-container'>
          {this.getPlans().map(plan => <PlanTile key={plan.key} plan={plan} />)}
        </div>
        <h2>Feature comparison</h2>
        <hr />
        <p>All Track Attack plans include the tools and features drivers, coaches and teams rely on to manage and understand driving data and video.</p>
        <p>All to get faster, faster. Find the right plan for you below. <Link to={`${ROUTE_FAQ}#free_trial`}>Click here for questions on how free trial works</Link></p>
        <Table responsive>
          <thead>
            <tr>
              <th />
              <th>Free</th>
              <th>Premium</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='feature-title'>Cloud storage</td>
              <td />
              <td />
            </tr>
            <tr>
              <td>Store and sync session files</td>
              <td>25</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <td>Store and sync native original data file</td>
              <td>{this.no()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Access data on any PC or Mac</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Encrypted data storage</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td className='feature-title'>Speed Secrets Content</td>
              <td />
              <td />
            </tr>
            <tr>
              <td><Link to={`${ROUTE_STORE}?category=ebooks`}>Access to Speed Secrets eBooks</Link></td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td><Link to={`${ROUTE_STORE}?category=webinars`}>Data for Drivers Webinar</Link></td>
              <td>10% off Regular Price</td>
              <td>Included</td>
            </tr>
            <tr>
              <td><Link to={`${ROUTE_STORE}?category=webinars`}>Speed Secrets Premium Webinars</Link></td>
              <td>10% off Regular Prices</td>
              <td>30% off Regular Prices</td>
            </tr>
            <tr>
              <td className='feature-title'>Racing Simulator Support</td>
              <td />
              <td />
            </tr>
            <tr>
              <td>Assetto Corsa Telemetry Support</td>
              <td>{this.yes(<Link to={ROUTE_ASSETTOCORSA}> More Info</Link>)}</td>
              <td>{this.yes(<Link to={ROUTE_ASSETTOCORSA}> More Info</Link>)}</td>
            </tr>
            <tr>
              <td>iRacing .ibt Support</td>
              <td>{this.yes(<Link to={ROUTE_IRACING}> More Info</Link>)}</td>
              <td>{this.yes(<Link to={ROUTE_IRACING}> More Info</Link>)}</td>
            </tr>
            <tr>
              <td className='feature-title'>Any Data System</td>
              <td />
              <td />
            </tr>
            <tr>
              <td>AiM .xrk/xrz, .drk, .csv support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Alfano 6 and PROIIIEVO native support</td>
              <td>{this.no()}</td>
              <td>{this.yes(<ExternalLink to='https://cloud.offcamberdata.com/v2/purchase/alfano'>OCD for Alfano Edition</ExternalLink>)}</td>
            </tr>
            <tr>
              <td>Apex Pro Track Coach .csv support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Autosport Labs .log support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Bosch .csv support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>CMS Lap Timer .csv support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Harrys Lap Timer .vbo, .csv support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>MoTec .i2 Pro, .i2, .csv support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Porsche Precision Driving App .vbo support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>RaceLogic .vbo, .csv support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Racechrono .vbo support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>TrackAddict .csv support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>TraqMate .tqm, .csv support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>XTRacing GPX Pro .csv support</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td className='feature-title'>Data And Video</td>
              <td />
              <td />
            </tr>
            <tr>
              <td>Pair video with data session</td>
              <td>{this.no()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Auto-sync video with data</td>
              <td>{this.no()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td className='feature-title'>Teams And Sharing</td>
              <td />
              <td />
            </tr>
            <tr>
              <td>Create unlimited public teams</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Create private teams</td>
              <td>{this.no()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Make sessions private</td>
              <td>{this.no()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Role-based access for team members</td>
              <td>{this.no()}</td>
              <td>Coming Soon</td>
            </tr>
            <tr>
              <td>Create private vehicles</td>
              <td>{this.no()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td className='feature-title'>Community</td>
              <td />
              <td />
            </tr>
            <tr>
              <td>Data-based driver profile and stats</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Data-based vehicle profile and stats</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Data-based driver team and stats</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
            <tr>
              <td>Session data counts on leaderboards</td>
              <td>{this.yes()}</td>
              <td>{this.yes()}</td>
            </tr>
          </tbody>
        </Table>
      </Section>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    userSession: state.session,
    profileSubscriptions: state.store.profileSubscriptions
  }
}

export default page(connect(mapStateToProps)(PagePricing), { pageView: GA_PAGES.PRICING })

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap'
import { changeInviteStatusAction } from '../../actions/inviteActions'
import { userDisplayName } from '../../utils/modelConvertionUtils'
import { TwoLineEntry, RowEntityList } from '../'
import { ROUTE_USERS, ROUTE_TEAMS, ROUTE_SESSIONS, ROUTE_TRACKS } from '../../utils/routingConstants'
import { Link } from 'react-router-dom'
import noImage from '../../images/no_image.jpg'
import { INVITE_STATUS } from '../../api/apiConstants'
import moment from 'moment'

class InviteList extends React.Component {
  static propTypes = {
    list: PropTypes.object.isRequired,
    noRowsRenderer: PropTypes.func,
    fetchingRenderer: PropTypes.func,
    itemSelector: PropTypes.func,
    loadMoreRows: PropTypes.func,
    supressEntityInvitesRefresh: PropTypes.bool
  }

  static defaultProps = {

  }

  static defaultProps = {
    loadMoreRows: () => { },
    itemSelector: (item) => item,
    noRowsRenderer: () => (<div className={'list-noRows'}>No invites found</div>),
    fetchingRenderer: () => 'Loading invites...',
    supressEntityInvitesRefresh: false
  }

  constructor (props) {
    super(props)
    this.renderNoRows = this.renderNoRows.bind(this)
    this.renderRow = this.renderRow.bind(this)
    this.changeInviteStatus = this.changeInviteStatus.bind(this)
  }

  render () {
    const { loadMoreRows, noRowsRenderer, list, fetchingRenderer } = this.props

    return <RowEntityList
      list={list}
      loadMoreRows={loadMoreRows}
      rowRenderer={this.renderRow}
      noRowsRenderer={noRowsRenderer}
      fetchingRenderer={fetchingRenderer}
    />
  }

  renderRow ({ index, key, style }) {
    const { list, itemSelector } = this.props
    const invite = itemSelector(list.list[index])

    const { invitationType, ownerObject, targetObject, recipientObject } = invite

    if (!(targetObject && ownerObject)) {
      // protect agains malformed data
      return null
    }

    // 1-request to, 2 - invite to
    const avatarSource = invitationType === 1 ? ownerObject : recipientObject

    return <div key={key} style={style}>
      <TwoLineEntry
        image={(avatarSource && avatarSource.avatarUrl) || noImage}
        headline={this.buildDescription(invite)}
        message={<span>{invite.message}</span>}
        buttons={this.renderButtons(invite)}
      />
    </div>
  }

  renderNoRows () {
    return <div className={'list-noRows'}>No invitations</div>
  }

  renderButtons (invite) {
    if (this.isInitiatedByMe(invite)) {
      return (
        <ButtonToolbar className='list-buttons'>
          <ButtonGroup>
            <Button bsStyle='danger' onClick={() => { this.changeInviteStatus(invite, INVITE_STATUS.CANCELLED) }}>Cancel</Button>
          </ButtonGroup>
        </ButtonToolbar>
      )
    }
    return (
      <ButtonToolbar className='list-buttons'>
        <ButtonGroup>
          <Button bsStyle='primary' onClick={() => { this.changeInviteStatus(invite, INVITE_STATUS.ACCEPTED) }}>Accept</Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button bsStyle='danger' onClick={() => { this.changeInviteStatus(invite, INVITE_STATUS.REJECTED) }}>Reject</Button>
        </ButtonGroup>
      </ButtonToolbar>
    )
  }

  changeInviteStatus (invite, status) {
    this.props.dispatch(changeInviteStatusAction(this.props.session.token, invite, status, {supressEntityInvitesRefresh: this.props.supressEntityInvitesRefresh}))
  }

  isInitiatedByMe (invite) {
    const { session } = this.props
    if (session.isAnonymous) { return false }

    return invite.ownerId === this.props.session.profile.id
  }

  buildEntityLink (targetType, invite, entityLabel) {
    switch (targetType) {
      case 'team':
        return (<Link to={ROUTE_TEAMS + '/' + invite.targetObject.handle}>{entityLabel}</Link>)
      case 'session':
        return (<Link to={ROUTE_SESSIONS + '/' + invite.targetObject.id}>{entityLabel || 'the session'}</Link>)
      default:
        return entityLabel
    }
  }

  buildEntityDescription (invite, targetType) {
    if (targetType === 'session') {
      const { track, driver } = invite.targetObject
      const trackText = track ? <span>at <Link to={ROUTE_TRACKS + '/' + track.id}>@{track.handle}</Link></span> : ''
      const driverText = driver ? <span>by <Link to={ROUTE_USERS + '/' + driver.handle}>@{driver.handle}</Link></span> : ''
      return <span>{trackText} {driverText} on {moment(invite.targetObject.start).format('M/D/YYYY')}</span>
    }

    return ''
  }

  buildDescription (invite) {
    const { targetObject, recipientObject, ownerObject } = invite
    const targetType = targetObject.type.toLowerCase()
    let verb = targetType === 'team' ? 'join'
      : invite.role === 'driver' ? 'be a driver for ' : 'share'

    const entityLabel = targetObject.handle ? '@' + targetObject.handle : targetObject.name
    const entityLink = this.buildEntityLink(targetType, invite, entityLabel)
    const entityDesc = this.buildEntityDescription(invite, targetType)

    // if the invite is FROM this user then present it differently
    if (this.isInitiatedByMe(invite)) {
      const operations = ['asked', 'invited']
      const operation = operations[invite.invitationType - 1]
      // TODO fix up to allow teams to be recipients
      // ha ndle can be null (for email invites)
      const recipientLink = recipientObject && recipientObject.handle
        ? <Link to={ROUTE_USERS + '/' + recipientObject.handle}>{userDisplayName(recipientObject)}</Link>
        : <b>{userDisplayName(recipientObject)}</b>
      return (<span>You {operation} {recipientLink} to {verb} {targetType === 'session' ? '' : targetType} {entityLink} {entityDesc}</span>)
    }

    const operations = ['requested', 'invited you']
    const operation = operations[invite.invitationType - 1]
    const senderLink = <Link to={ROUTE_USERS + '/' + ownerObject.handle}>{userDisplayName(ownerObject)}</Link>

    return (<span>{senderLink} {operation} to {verb} {targetType} {entityLink} {entityDesc}</span>)
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session
  }
}

export default connect(mapStateToProps)(InviteList)

import {
  KPI_FETCH_CURRENT,
  KPI_FETCH_HISTORICAL,
  KPI_FETCH_PARSERS_CURRENT,
  KPI_FETCH_PARSERS_HISTORICAL,
  KPI_FETCH_SUBSCRIPTIONS,
  KPI_FETCH_SUBSCRIPTIONS_HISTORICAL } from '../actions/adminActions'
import { default as itemReducer } from './itemReducer'
import { combineReducers } from 'redux'

export default combineReducers({
  currentKPI: itemReducer(KPI_FETCH_CURRENT),
  subscriptionsKPI: itemReducer(KPI_FETCH_SUBSCRIPTIONS),
  historicalSubscriptionsKPI: itemReducer(KPI_FETCH_SUBSCRIPTIONS_HISTORICAL),
  historicalKPI: itemReducer(KPI_FETCH_HISTORICAL),
  currentParsersKPI: itemReducer(KPI_FETCH_PARSERS_CURRENT),
  historicaParserslKPI: itemReducer(KPI_FETCH_PARSERS_HISTORICAL)
})

import React from 'react'
import { GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'
import { Section, ExternalLink } from '../../components'
import { COMPANY_REQUISITES, COMPANY_REQUISITES_DISPLAY } from '../../constants'
import FA from 'react-fontawesome'

export default page(() => {
  return <Section title='Contact us' className='page-contacts'>
    <div className='contacts'>
      <div className='contacts-row'><a href={'mailto:' + COMPANY_REQUISITES.email}><FA name='envelope' /> {COMPANY_REQUISITES_DISPLAY.email}</a></div>
      <div className='contacts-row'><ExternalLink to={COMPANY_REQUISITES.instagram}><FA name='instagram' /> {COMPANY_REQUISITES_DISPLAY.instagram}</ExternalLink></div>
      <div className='contacts-row'><ExternalLink to={COMPANY_REQUISITES.facebook}><FA name='facebook' /> {COMPANY_REQUISITES_DISPLAY.facebook}</ExternalLink></div>
      <div className='contacts-row'><ExternalLink to={COMPANY_REQUISITES.youtube}><FA name='youtube-play' /> {COMPANY_REQUISITES_DISPLAY.youtube}</ExternalLink></div>
      <div className='contacts-row'><ExternalLink to={COMPANY_REQUISITES.twitter}><FA name='twitter' /> {COMPANY_REQUISITES_DISPLAY.twitter}</ExternalLink></div>
    </div>
  </Section>
}, {pageView: GA_PAGES.CONTACTS, uiNavigationRoute: null})

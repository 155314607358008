import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import FA from 'react-fontawesome'
import noImage from '../../images/no_image.jpg'
import classnames from 'classnames'

const DragHandle = sortableHandle(() => <FA className='listv2__item__ending__drag' name='bars' />)

const SortableItem = sortableElement(({value}) => {
  const {item, itemClick} = value

  return <div
    onClick={() => { itemClick(item) }}
    className={classnames('listv2__item', itemClick && 'listv2__item--clickable', !item.isPublished && 'listv2__item--disabled')}>
    <img className='listv2__item__image' src={item.imageUrl || noImage} alt='' />
    <div className='listv2__item__content'>
      <div className='listv2__item__content__header'><strong>[{item.idSendOwl}]</strong>&nbsp;{item.name}</div>
      <div className='listv2__item__content__text'>
        <div>
          <span className='listv2__item__content__text__value'>{`$${item.price}`}</span>
          <span className='listv2__item__content__text__value'>{item.isPublished ? 'PUBLISHED' : 'NOT PUBLISHED'}</span>
          <span className='listv2__item__content__text__value'>{item.isNew ? 'NEW' : ''}</span>
        </div>
      </div>
    </div>
    <div className='listv2__item__ending'><DragHandle /></div>
  </div>
})

const SortableContainer = sortableContainer(({children}) => {
  return <div className='listv2'>{children}</div>
})

class ProductsSortableList extends Component {
  static propTypes = {
    list: PropTypes.array,
    error: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
    isFetching: PropTypes.bool,
    listReordered: PropTypes.func,
    itemClick: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      sortedList: []
    }

    this.onSortEnd = this.onSortEnd.bind(this)
  }

  componentWillReceiveProps (newProps) {
    const hasNewList = newProps.list && newProps.list.length > 0
    const hasOldList = this.props.list && this.props.list.length > 0
    const hasDataSet = this.state.sortedList.length !== 0

    if ((hasNewList && !hasOldList) || (!hasNewList && hasOldList) || (!hasDataSet && hasNewList)) {
      this.setState({sortedList: newProps.list})
    }
  }

  onSortEnd ({oldIndex, newIndex}) {
    const {listReordered} = this.props

    this.setState(({sortedList}) => {
      const newArr = arrayMove(sortedList, oldIndex, newIndex)
      listReordered && listReordered(newArr)

      return {sortedList: newArr}
    })
  }

  render () {
    const {sortedList} = this.state
    const {itemClick, error, isFetching, list} = this.props

    const noData = !error && !isFetching && (!list || list.length === 0)
    const isError = !isFetching && error

    return (
      <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
        {isError && <div className='listv2__error'>{error.message || 'Error loading data'}</div>}
        {noData && <div className='listv2__nodata'>Nothing to show here</div>}
        {isFetching && <div className='listv2__loading'>Loading...</div>}

        {sortedList.map((item, index) => (
          <SortableItem key={`item-${index}`} index={index} value={{item, itemClick}} />
        ))}
      </SortableContainer>
    )
  }
}

export default ProductsSortableList

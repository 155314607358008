import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, ButtonToolbar, Alert } from 'react-bootstrap'
import { RowEntityList, TwoLineEntry, Overlay } from '../'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'
import { deleteSessionShareAction } from '../../actions/resultsActions'
import { confirmable, createConfirmation } from 'react-confirm'

class ShareList extends React.Component {
  static propTypes = {
    list: PropTypes.object.isRequired,
    renderButtons: PropTypes.func,
    noRowsRenderer: PropTypes.func,
    loadMoreRows: PropTypes.func,
    fetchingRenderer: PropTypes.func
  }

  static defaultProps = {
    loadMoreRows: () => { },
    noRowsRenderer: () => (<div className={'list-noRows'}>No shares found for this session</div>)
  }

  constructor (props) {
    super(props)
    this.renderRow = this.renderRow.bind(this)
    this.renderButtons = props.renderButtons || this.renderButtons.bind(this)
    this.forgetSessionShare = this.forgetSessionShare.bind(this)
  }

  render () {
    const { loadMoreRows, noRowsRenderer, list, fetchingRenderer } = this.props
    return (<RowEntityList
      list={list}
      loadMoreRows={loadMoreRows}
      rowRenderer={this.renderRow}
      noRowsRenderer={noRowsRenderer}
      fetchingRenderer={fetchingRenderer}
    />)
  }

  renderButtons ({ share }) {
    switch (this.getAccessLevel(share)) {
      case 'owned':
        return (
          <ButtonToolbar className='list-buttons'>
            <ButtonGroup>
              <Button bsStyle='danger' onClick={() => { this.forgetSessionShare(share) }}>Forget</Button>
            </ButtonGroup>
          </ButtonToolbar>)
      default:
        return null
    }
  }

  okForgetSessionOperation (session) {
    const { dispatch, userSession } = this.props
    dispatch(deleteSessionShareAction(userSession.token, session.id, session.shareId))
  }

  forgetSessionShare (session, event) {
    event.stopPropagation()
    const dialog = ({ show, proceed, dismiss, cancel, confirmation, options }) => {
      const deleteString = `Forget this shared session`
      const buttons = Overlay.createButtons([
        { [deleteString]: () => proceed(session), bsStyle: 'warning' },
        { Cancel: cancel, bsStyle: 'default' }
      ])
      return (<Overlay
        show={show}
        title={deleteString + '?'}
        closeAction={dismiss}
        buttons={buttons}>
        <Alert bsStyle='warning' >
          <p>You are about to forget this session. You will no longer be able to see its
            telemetry on the web or in Track Attack Analytics. You can always
            ask the owner to share again.</p>
        </Alert>
      </Overlay>)
    }

    const confirmer = createConfirmation(confirmable(dialog))
    confirmer('', {}).then(session => this.okForgetSessionOperation(session), () => { })
  }

  getAccessLevel (share) {
    const { userSession } = this.props
    if (userSession.isAnonymous) { return 'public' }

    const id = userSession.profile.id
    // if you own the share, are the destination or own the shared entity then enable forget
    if (share.ownerId === id || share.destinationEntityId === id || share.sharedObject.ownerId === id) { return 'owned' }
    return 'public'
  }

  getStartDate (time) {
    return moment(time).format('M/D/YYYY')
  }

  renderHeadline (share) {
    const { history } = this.props
    const { destinationEntityType, destinationObject } = share

    const clickLink = (to, text) => {
      const click = (event) => {
        event.stopPropagation()
        event.preventDefault()
        history.push(to)
      }
      return <Link to={to} onClick={click}>{text}</Link>
    }

    const destinationHandle = destinationObject.handle
    const destinationText = <span>{clickLink('/' + destinationEntityType + 's/' + destinationHandle, '@' + destinationHandle)}</span>
    const destinationIcon = destinationEntityType === 'team'
      ? <FontAwesome name='group' />
      : destinationEntityType === 'user' ? <FontAwesome name='user' /> : ''
    return (<span>Shared with {destinationText} &nbsp; {destinationIcon}</span>)
  }

  renderMessage (session) {
    const duration = moment(session.createdOn).fromNow()
    return (<span>{duration}</span>)
  }

  renderRow ({ index, key, style }) {
    const { list } = this.props
    const share = list.list[index]
    const clickHandler = () => {
    }

    return (
      <div key={key} style={style}>
        <TwoLineEntry
          // image={(track && track.avatarUrl) || noImage}
          headline={this.renderHeadline(share)}
          message={this.renderMessage(share)}
          buttons={this.renderButtons({ share })}
          onClick={clickHandler}
        />
      </div>)
  }
}

function mapStateToProps (state, ownProps) {
  return {
    userSession: state.session, shares: state.results.shares
  }
}

export default withRouter(connect(mapStateToProps)(ShareList))

import React from 'react'
import PropTypes from 'prop-types'
import { searchHandles } from '../api'
import * as _ from 'lodash'
import { HandleView } from './'
import AsyncSelect from 'react-select/lib/Async'
import { components } from 'react-select'
import FA from 'react-fontawesome'
import { HANDLE_TYPES } from '../api/apiConstants'
import { FormGroup, HelpBlock, ControlLabel, Col, InputGroup } from 'react-bootstrap'

export default class HandleSelect extends React.Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    changeHandler: PropTypes.func.isRequired,
    types: PropTypes.arrayOf(PropTypes.oneOf([null, 'user', 'team', 'track', 'vehicle'])),
    exclusions: PropTypes.arrayOf(PropTypes.string),
    multi: PropTypes.bool,
    title: PropTypes.string
  }

  static defaultProps = {
    value: [],
    multi: true
  }

  constructor (props, context) {
    super(props, context)

    this.onHandlesChanged = this.onHandlesChanged.bind(this)
    this.getOptions = this.getOptions.bind(this)
  }

  onHandlesChanged (value) {
    const { changeHandler, multi } = this.props
    if (!value) { return changeHandler(null) }
    changeHandler(multi ? value.map(entry => entry.innerHandle) : value.innerHandle)
  }

  isExcluded (handle) {
    const { exclusions } = this.props
    return _.includes(exclusions, handle.handle)
  }

  getOptions (input) {
    const { types, token } = this.props
    this.setState({ input: input })

    // return all handles on empty input
    // all handles are cached for a short time
    const typeFilter = types ? types.reduce((result, type) => result | HANDLE_TYPES[type.toUpperCase()], 0) : null
    return searchHandles(token, input, typeFilter).then((values) => {
      const opts = _.filter(values, v => !this.isExcluded(v)).map(value => this.createListEntry(value))
      return opts
    })
  }

  createListEntry (value) {
    return value ? { value: value.handle, label: value.handle, innerHandle: value } : ''
  }

  dropdownIndicator (props) {
    return components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FA name='search' />
      </components.DropdownIndicator>
    )
  }

  customOption (props) {
    return <components.Option {...props}><HandleView handle={props.data.innerHandle} /></components.Option>
  }

  render () {
    const { value, multi, title, help } = this.props

    const currentValue = multi
      ? (value ? value.map(value => this.createListEntry(value)) : [])
      : this.createListEntry(value)
    return (
      <FormGroup controlId='handle' validationState={null} >
        <Col componentClass={ControlLabel} sm={3}>{title || 'Handle'}</Col>
        <Col sm={9}>
          <InputGroup className='handleselect'>
            <InputGroup.Addon>@</InputGroup.Addon>
            <AsyncSelect
              className='react-select-container'
              classNamePrefix='react-select'
              onChange={this.onHandlesChanged}
              loadOptions={this.getOptions}
              components={{ DropdownIndicator: this.dropdownIndicator, Option: this.customOption }}
              placeholder='Type to search handles...'
              isMulti={multi}
              value={currentValue}
            />
          </InputGroup>
        </Col>
        <Col sm={8} xsOffset={3}> <HelpBlock>{help}</HelpBlock> </Col>
      </FormGroup>
    )
  }
}

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {Link, withRouter} from 'react-router-dom'
import {getLinkToEntity} from '../utils/routingUtils'

function manualClick (history, link, event) {
  event.stopPropagation()
  event.preventDefault()
  link && history.push(link)
}

const EntityLink = ({entity, supressEvent, children, history, type, isDiv, session}) => {
  const content = children || <span>{entity.handle ? '@' + entity.handle : entity.name}</span>
  const resultingType = type || entity.type
  const linkTo = getLinkToEntity(entity, resultingType)

  const boundClick = (e) => { manualClick(history, linkTo, e) }

  const linkProps = {
    to: linkTo,
    ...(supressEvent ? {onClick: boundClick} : {})
  }

  let isEntityVisible = true
  if (resultingType === 'vehicle') {
    if (session && entity.visibility === 0) {
      isEntityVisible = !(session.isAnonymous) && session.profile && entity.ownerId === session.profile.id
    }
  }

  if (!isEntityVisible) {
    return <div>{content}</div>
  }

  return isDiv ? <div onClick={boundClick} >{content}</div> : <Link {...linkProps}>{content}</Link>
}

EntityLink.propTypes = {
  entity: PropTypes.object.isRequired,
  supressEvent: PropTypes.bool,
  type: PropTypes.oneOf(['user', 'team', 'session', 'vehicle', 'track']),
  isDiv: PropTypes.bool
}

EntityLink.defaultProps = {
  supressEvent: false,
  isDiv: false
}

function mapStateToProps (state, ownProps) {
  return { session: state.session }
}

export default withRouter(connect(mapStateToProps)(EntityLink))

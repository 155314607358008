import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormGroup, InputGroup, Glyphicon } from 'react-bootstrap'
import { debounce } from 'lodash'

export default class InlineFilterBox extends Component {
  static propTypes = {
    searchFunction: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    filter: PropTypes.string
  }

  static defaultProps = {
    placeholder: 'Search'
  }

  constructor (props) {
    super(props)
    this.state = { filter: props.filter }
    this.onChange = this.onChange.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
  }

  componentWillMount () {
    this.debouncedFilter = debounce(() => this.props.searchFunction(this.state.filter), 300)
  }

  onChange (event) {
    this.setFilter(event.target.value)
  }

  clearFilter () {
    this.setFilter('')
  }

  setFilter (value = '') {
    this.setState({ filter: value })
    this.debouncedFilter()
  }

  render () {
    const { placeholder } = this.props
    return (
      // <Navbar fluid={true}>
      //   <Navbar.Form pullRight>
      <FormGroup bsSize='small' className='search-form'>
        <InputGroup>
          <FormControl type='text' placeholder={placeholder} value={this.state.filter} onChange={this.onChange} />
          <InputGroup.Addon>
            <div className='search-remove' onClick={this.clearFilter}><Glyphicon glyph='remove' /> </div>
          </InputGroup.Addon>
        </InputGroup>
      </FormGroup>
      //   </Navbar.Form>
      // </Navbar>
    )
  }
}

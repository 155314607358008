import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { validateEmail } from '../utils/validators'
import { components } from 'react-select'
import AsyncSelect from 'react-select/lib/Async'
import { FormGroup, ControlLabel, Col } from 'react-bootstrap'
import FA from 'react-fontawesome'

export default class EmailSelect extends Component {
  static propTypes = {
    changeHandler: PropTypes.func.isRequired,
    emails: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    exclusions: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string
  }

  static defaultProps = {
    emails: [],
    multi: true
  }

  isExcluded (mail) {
    const { exclusions } = this.props
    return _.includes(exclusions, mail)
  }

  dropdownIndicator (props) {
    return components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FA name='search' />
      </components.DropdownIndicator>
    )
  }

  render () {
    const { emails, changeHandler, title, multi } = this.props
    const getOptions = (input) => {
      const isUsed = this.isExcluded(input) || _.filter(emails, (e) => { return e.value === input }).length > 0

      return new Promise((resolve) => {
        resolve((validateEmail(input) && !isUsed)
          ? [{ value: input, label: input }] : [])
      })
    }

    const emailSelectChange = (emails) => {
      changeHandler && changeHandler(emails)
    }

    return (
      <FormGroup controlId='email' validationState={null} >
        <Col componentClass={ControlLabel} sm={3}>{title || 'Email'}</Col>
        <Col sm={9}>
          <div className='emailselect'>
            <AsyncSelect
              className='react-select-container'
              classNamePrefix='react-select'
              loadOptions={getOptions}
              isMulti={multi}
              components={{ DropdownIndicator: this.dropdownIndicator }}
              onChange={emailSelectChange}
              value={emails}
              placeholder='Type to select emails...'
              searchPromptText='Type valid email...'
            />
          </div>
        </Col>
      </FormGroup>
    )
  }
}

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Form, FormGroup, FormControl, HelpBlock, Button, ControlLabel, Col, Panel, Alert, Row, Tab, Nav, NavItem, ButtonToolbar } from 'react-bootstrap'
import { uiProfileSettings, uiToast } from '../actions/ui'
import { fetchOrders } from '../actions/storeActions'
import { updateProfile, updateLicenseKeyAction, cancelProfileSubscriptionAction, getAvailableSubscriptions, getSubscriptionDetails } from '../actions/sessionActions'
import { getMeasuringUnitsFromProfile, formatDate } from '../utils/modelConvertionUtils'
import { DropzoneWithImagePreview, ExternalLink, HandleEdit, Section, UrlInput, OrdersList, FieldGroupReadonly } from '../components'
import { fetchProfileVehiclesAction } from '../actions/garageActions'
import * as _ from 'lodash'
import AsyncSelect from 'react-select/lib/Async'
import Select, { components } from 'react-select'
import { getAllContries } from '../api/restContriesApi'
import { UI_INPUT_LENGTH, IS_DEVELOP } from '../constants'
import { getLicenseKey } from '../api'
import noImage from '../images/no_image.jpg'
import { Link } from 'react-router-dom'
import { ROUTE_PRICING } from '../utils/routingConstants'
import { isPremiumUser, isPremiumUserLegacy } from '../api/auth0'

class UserSettings extends Component {
  static FieldGroup ({ id, label, help, validationState, ...props }) {
    return (
      <FormGroup controlId={id || props.name} validationState={validationState} >
        <Col componentClass={ControlLabel} sm={3}>{label}</Col>
        <Col sm={9}>
          <FormControl {...props} />
        </Col>
        {help && <Col sm={9} xsOffset={3}> <HelpBlock>{help}</HelpBlock> </Col>}
      </FormGroup>
    )
  }

  constructor (props) {
    super(props)
    this.state = {
      allowEditHandleExplicitly: false,
      allowEditLicenseKey: false,
      updatingLicenseKey: false,
      revealedLicenseKey: null,
      activeTab: 'profile'
    }
    this.changeHandler = this.changeHandler.bind(this)
    this.saveSettingsOperation = this.saveSettingsOperation.bind(this)
    this.handleEdited = this.handleEdited.bind(this)
    this.handleCountryChange = this.handleCountryChange.bind(this)
    this.handleVehicleChange = this.handleVehicleChange.bind(this)
    this.updateLicenseKey = this.updateLicenseKey.bind(this)
    this.revealLicenseKey = this.revealLicenseKey.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.cancelSubscription = this.cancelSubscription.bind(this)

    this.handleEmailChange = this.handleUrlChange.bind(this, 'email')
    this.handleWebsiteChange = this.handleUrlChange.bind(this, 'website')
    this.handleInstagramChange = this.handleUrlChange.bind(this, 'instagram')
    this.handleFacebookChange = this.handleUrlChange.bind(this, 'facebook')
    this.handleTwitterChange = this.handleUrlChange.bind(this, 'twitter')

    this.allowEditLicense = this.allowEdit.bind(this, 'license')
    this.allowEditHandle = this.allowEdit.bind(this, 'handle')

    this.onTabChanged = this.onTabChanged.bind(this)
  }

  componentDidMount () {
    const { dispatch, session, mode } = this.props
    if (session.isAnonymous) { return }
    dispatch(updateProfile(session.profile, session.token, true))
    dispatch(uiProfileSettings(session.profile, { valid: (!!session.profile.handle) }))

    // in initial mode vehicles are loaded by container
    mode !== 'initial' && dispatch(fetchProfileVehiclesAction(session.token))
    mode !== 'initial' && isPremiumUser(session) && !isPremiumUserLegacy(session) && dispatch(getSubscriptionDetails(session.token))
    mode !== 'initial' && dispatch(getAvailableSubscriptions(session.token)).then(() => {
      window.setInterval(() => {
        window.sendOwl && window.sendOwl.captureLinks()
      })
    })
  }

  onTabChanged (activeKey) {
    this.setState({activeTab: activeKey})
    const {orders, dispatch, session} = this.props

    if (!orders.isFetching && (!orders.list || orders.list.length === 0)) {
      dispatch(fetchOrders(session.token))
    }
  }

  saveSettingsOperation () {
    const { session, dispatch, settings, profileUIState } = this.props
    const data = { ...session.profile, ...settings, rowVersion: session.profile.rowVersion, newAvatar: profileUIState.avatarPreview }
    dispatch(updateProfile(data, session.token, false, session.profile))
  }

  handleUrlChange (name, value, isValid) {
    this.props.dispatch(
      uiProfileSettings(
        { ...this.props.settings, [name]: value },
        { ...this.props.profileUIState }
      )
    )
  }

  changeHandler (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.props.dispatch(
      uiProfileSettings(
        { ...this.props.settings, [name]: value },
        { ...this.props.profileUIState }
      )
    )
  }

  handleEdited (handle, valid) {
    const { dispatch, settings, profileUIState } = this.props
    const newSettingsState = valid ? { ...settings, handle: handle } : settings
    dispatch(uiProfileSettings(
      newSettingsState,
      { ...profileUIState, valid: valid }
    ))
  }

  handleCountryChange (country) {
    this.props.dispatch(
      uiProfileSettings(
        { ...this.props.settings, country: country.value },
        { ...this.props.profileUIState }
      )
    )
  }

  handleVehicleChange (vehicle) {
    this.props.dispatch(
      uiProfileSettings(
        { ...this.props.settings, defaultVehicleId: vehicle.value },
        { ...this.props.profileUIState }
      )
    )
  }

  allowEdit (id, event) {
    event.stopPropagation()

    switch (id) {
      case 'handle':
        this.setState({ allowEditHandleExplicitly: true })
        break
      case 'license':
        this.setState({ allowEditLicenseKey: true })
        break
      default:
      // wtf?
    }
  }

  render () {
    const { session, mode, profileUIState, className } = this.props
    const {activeTab} = this.state

    if (session.isAnonymous) { return }
    if (session.isFetching || session.profile.isFetching) { return <Alert bsStyle='info'>Updating data...</Alert> }
    if (session.error || session.profile.error) { return <Alert bsStyle='danger'>Failed to save your changes. Please retry</Alert> }

    return (<Row className={className}>
      {mode === 'initial' ? this.renderInitial() : this.renderSettingsTabs()}
      {!session.isFetching && activeTab !== 'orders' &&
        <ButtonToolbar bsClass='settings-bottom-buttons'>
          <Button key='Save' bsStyle='primary' disabled={!profileUIState.valid} onClick={this.saveSettingsOperation}>Save</Button>
        </ButtonToolbar>}
    </Row>)
  }

  onDrop (acceptedFiles, rejectedFiles) {
    if (acceptedFiles && acceptedFiles.length === 1) {
      const { dispatch, profileUIState, settings } = this.props
      dispatch(uiProfileSettings(settings, { ...profileUIState, avatarPreview: acceptedFiles[0] }))
    }
  }

  updateLicenseKey () {
    const {settings, dispatch, session} = this.props
    const {licenseKey} = settings
    const {revealedLicenseKey} = this.state

    this.setState({
      allowEditLicenseKey: false,
      updatingLicenseKey: true
    })

    dispatch(updateLicenseKeyAction(session.token, licenseKey || revealedLicenseKey, true))
      .then(
        result => {
          this.setState({
            updatingLicenseKey: false,
            revealedLicenseKey: null
          })
          dispatch(uiToast(
            {
              text: `License key updated to ${licenseKey || revealedLicenseKey}!`,
              bsStyle: 'success'
            }
          ))
        },
        ignored => {
          this.setState({
            updatingLicenseKey: false
          })
          dispatch(uiToast(
            {
              text: 'Invalid license key: ' + licenseKey,
              bsStyle: 'danger'
            }
          ))
        }
      )
      .then(
        result => {
          // drop license key
          const {licenceKey, ...newSts} = settings
          dispatch(
            uiProfileSettings(
              newSts,
              { ...this.props.profileUIState }
            )
          )
        }
      )
  }

  customCountryOption (props) {
    return <components.Option {...props}>
      <div className='dropdown-item' key={props.data.alpha3Code}>
        <img src={props.data.flag} alt='flag' />
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  }

  customCountryValue (props) {
    const {data, options} = props
    const selectedCountry = options ? _.find(options, {value: data.value}) : null

    return <components.SingleValue {...props}>
      <div className='dropdown-item'>
        { selectedCountry && <img src={selectedCountry.flag} alt='flag' /> }
        <span>{
          options
            ? selectedCountry ? selectedCountry.label : 'Select your country'
            : 'Loading...'}
        </span>
      </div>
    </components.SingleValue>
  }

  customVehicleOption (props) {
    return <components.Option {...props}>
      <div className='dropdown-item' key={props.data.value}>
        <img src={props.data.icon || noImage} alt='icon' />
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  }

  customVehicleValue (props) {
    const {data, options} = props
    const selectedVehicle = (data.value && options) ? _.find(options, {value: data.value}) : null

    return <components.SingleValue {...props}>
      <div className='dropdown-item'>
        { selectedVehicle && <img src={selectedVehicle.icon || noImage} alt='icon' /> }
        <span>{selectedVehicle ? selectedVehicle.label : 'Loading...'}</span>
      </div>
    </components.SingleValue>
  }

  renderProfileTabContent (full = true) {
    const { settings, session, profileUIState, profileVehicles } = this.props

    const getOptions = (input) => {
      return getAllContries().then((values) => {
        const opts = _.filter(values, (v) => { return !input || v.name.toLowerCase().includes(input.toLowerCase()) })
          .map((value) => {
            return { value: value.name, label: value.name, flag: value.flag }
          })

        return opts
      })
    }

    const vehicleOptions = profileVehicles.list && profileVehicles.list.map((vehicle) => {
      return {value: vehicle.id, label: vehicle.name, icon: vehicle.avatarUrl}
    })

    return (
      <Form horizontal>
        <Row>
          <Col sm={9}>
            <UserSettings.FieldGroup
              key='firstname'
              name='firstName'
              type='text'
              label='First name'
              value={settings.firstName || ''}
              onChange={this.changeHandler}
              placeholder='Your first name'
              maxLength={UI_INPUT_LENGTH.NAME}
            />

            <UserSettings.FieldGroup
              key='lastname'
              name='lastName'
              type='text'
              label='Last name'
              value={settings.lastName || ''}
              onChange={this.changeHandler}
              placeholder='Your last name'
              maxLength={UI_INPUT_LENGTH.NAME}
            />

            <UserSettings.FieldGroup
              key='city'
              name='city'
              type='text'
              label='City'
              value={settings.city || ''}
              onChange={this.changeHandler}
              placeholder='Your city'
              maxLength={UI_INPUT_LENGTH.CITY}
            />

            <FormGroup controlId={'CountrySelect'} key='CountrySelect' >
              <Col componentClass={ControlLabel} sm={3}>Country</Col>
              <Col sm={9}>
                <AsyncSelect
                  className='react-select-container'
                  classNamePrefix='react-select'
                  onChange={this.handleCountryChange}
                  loadOptions={getOptions}
                  defaultOptions
                  components={{ Option: this.customCountryOption, SingleValue: this.customCountryValue }}
                  value={{value: settings.country}}
                />
              </Col>
            </FormGroup>

            <UserSettings.FieldGroup
              key='description'
              name='description'
              type='textarea'
              componentClass='textarea'
              label='Bio'
              value={settings.description || ''}
              onChange={this.changeHandler}
              placeholder='Your bio'
              maxLength={UI_INPUT_LENGTH.DESCRIPTION}
              rows={8}
            />

            <UrlInput
              type='facebook'
              value={settings.facebook || ''}
              onChanged={this.handleFacebookChange}
            />

            <UrlInput
              type='instagram'
              value={settings.instagram || ''}
              onChanged={this.handleInstagramChange}
            />

            <UrlInput
              type='twitter'
              value={settings.twitter || ''}
              onChanged={this.handleTwitterChange}
            />

            <UrlInput
              type='url'
              value={settings.website || ''}
              onChanged={this.handleWebsiteChange}
            />

            {full && <FormGroup controlId='defaultVehicleId' key='defaultVehicleId' >
              <Col componentClass={ControlLabel} sm={3}>Default vehicle</Col>
              <Col sm={9}>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  onChange={this.handleVehicleChange}
                  value={{value: settings.defaultVehicleId}}
                  options={vehicleOptions}
                  components={{ Option: this.customVehicleOption, SingleValue: this.customVehicleValue }}
                />
              </Col>
            </FormGroup>}

            <FormGroup controlId='units' key='units' >
              <Col componentClass={ControlLabel} sm={3}>Units</Col>
              <Col sm={9}>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  placeholder='Your preferred units'
                  value={{value: getMeasuringUnitsFromProfile(settings), label: getMeasuringUnitsFromProfile(settings) === 'i' ? 'Imperial' : 'Metric'}}
                  options={[
                    {value: 'i', label: 'Imperial'},
                    {value: 'm', label: 'Metric'}
                  ]}
                  onChange={units => this.changeHandler({ target: { name: 'unitMeasure', value: units.value } })}
                />
              </Col>
            </FormGroup>

          </Col>
          <Col sm={3}>
            <DropzoneWithImagePreview
              onDrop={this.onDrop}
              previewUrl={(profileUIState.avatarPreview && profileUIState.avatarPreview.preview) || session.profile.avatarUrl}
            />
          </Col>
        </Row>
      </Form>
    )
  }

  revealLicenseKey () {
    const {session, dispatch} = this.props

    getLicenseKey(session.token).then(
      (result) => {
        this.setState(
          { revealedLicenseKey: result.licenseKey }
        )
      },
      (ignored) => {
        dispatch(uiToast({
          text: 'Unable to read our license key',
          bsStyle: 'danger'
        }))
      }
    )
  }

  cancelSubscription () {
    const {dispatch, session} = this.props
    dispatch(cancelProfileSubscriptionAction(session.token))
  }

  renderAccountTabContent () {
    const { settings, session, profileSubscriptions } = this.props
    // allow edit via UI or edit in initial mode when handle not set
    const { allowEditHandleExplicitly, allowEditLicenseKey, updatingLicenseKey, revealedLicenseKey } = this.state
    const oldHandle = session.profile.handle
    const {profile} = session

    const licenseKeyStubValue = <div className='license'>
      {settings.licenseValid
        ? revealedLicenseKey ? <span className='license__body'>{revealedLicenseKey}</span> : <span>LICENSE KEY VALID <a className='license__reveal' onClick={this.revealLicenseKey}>reveal</a></span>
        : <span>NO VALID LICENSE </span>}
    </div>

    const subscriptionProduct = _.find(profileSubscriptions.list, (item) => { return item.url.includes(profile.subscriptionProductId) })
    const price = subscriptionProduct ? `${subscriptionProduct.recurring_price} ${subscriptionProduct.currency_code}` : null

    return (
      <Form horizontal>
        <Row>
          <Col sm={9}>
            <Panel>
              <Panel.Heading>Subscription information</Panel.Heading>
              <Panel.Body>
                { !isPremiumUser(session) &&
                  <Fragment>
                    <Link to={ROUTE_PRICING}>
                      <Alert>Upgrade now to unleash the full power of Track Attack with a premium subscription</Alert>
                    </Link>
                  </Fragment> }

                { isPremiumUserLegacy(session) &&
                  <Fragment>
                    <Link to={ROUTE_PRICING}>
                      <Alert>Update now to new Track Attack subscription system and keep your account up with automated payments!</Alert>
                    </Link>
                  </Fragment> }

                {session.profile.subscriptionUnsubscribed && isPremiumUser(session) &&
                  <Alert bsStyle='warning'>Your subscription was cancelled. Your account will be downgraded, once the current subscription period ends. You will not be charged any more.</Alert>}

                <FieldGroupReadonly label='Account type'>{isPremiumUser(session) ? isPremiumUserLegacy(session) ? 'Premium (LEGACY)' : 'Premium' : 'Free' }</FieldGroupReadonly>

                { isPremiumUser(session) && !isPremiumUserLegacy(session) &&
                  <Fragment>
                    <FieldGroupReadonly label='Subscription type'>{session.profile.subscriptionProductDescription}</FieldGroupReadonly>
                    <FieldGroupReadonly label='Subscription Started'>{formatDate(session.profile.subscriptionStartedAt)}</FieldGroupReadonly>
                    <FieldGroupReadonly label='Subscription expires'>{formatDate(session.profile.subscriptionExpiration)}</FieldGroupReadonly>
                    {price && <FieldGroupReadonly label='Subscription payment'>{price}</FieldGroupReadonly>}
                    { session.subscription &&
                      <FieldGroupReadonly label='Manage subscription'>
                        <ExternalLink to={session.subscription.subscription_management_url}>Manage (opens in a new tab)</ExternalLink>
                      </FieldGroupReadonly>
                    }
                    {!session.profile.subscriptionUnsubscribed && <Panel bsStyle='danger'>
                      <Panel.Heading><Panel.Toggle>Dangerous operations</Panel.Toggle></Panel.Heading>
                      <Panel.Collapse>
                        <Panel.Body>
                          <Alert bsStyle='danger' >
                            <p>Your account will be downgraded to FREE at the end of the current subscription period.</p>
                          </Alert>
                          <Button bsStyle='danger' onClick={this.cancelSubscription}>Stop my premium subscription</Button>
                        </Panel.Body>
                      </Panel.Collapse>
                    </Panel>}
                  </Fragment> }
              </Panel.Body>
            </Panel>
            <Panel>
              <Panel.Heading>Changing the handle</Panel.Heading>
              <Panel.Body>
                {allowEditHandleExplicitly && <HandleEdit
                  onHandleChanged={this.handleEdited}
                  apiToken={session.token}
                  oldHandle={oldHandle} />}
                {!allowEditHandleExplicitly && <FieldGroupReadonly
                  name='handle'
                  label='Handle'
                  onEdit={this.allowEditHandle}>
                  {settings.handle || ''}
                </FieldGroupReadonly>}
              </Panel.Body>
            </Panel>
            {isPremiumUserLegacy(session) && <Panel>
              <Panel.Heading>License key | legacy</Panel.Heading>
              <Panel.Body>
                <Alert bsStyle='warning'>We are updating our subscription system, but legacy licenses will work until they expire</Alert>
                {settings.maxFreeSessionsAllowed && !settings.licenseValid && <Alert bsStyle={settings.maxFreeSessionsAllowed > settings.sessionsCount ? 'warning' : 'danger'}>
                  {settings.maxFreeSessionsAllowed > settings.sessionsCount && <div>You have cloud synced <strong>{settings.sessionsCount}</strong> of <strong>{settings.maxFreeSessionsAllowed}</strong> available free sessions.</div>}
                  {settings.maxFreeSessionsAllowed <= settings.sessionsCount && <div>You have cloud synced all of your available free sessions</div>}
                </Alert>}

                {settings.licenseValid && <Alert bsStyle='success'>
                  You are premium and can sync unlimited number of sessions!
                </Alert>}

                {!updatingLicenseKey && allowEditLicenseKey && <FormGroup controlId='licenseKey' >
                  <Col componentClass={ControlLabel} sm={3}>License key</Col>
                  <Col sm={6}>
                    <FormControl name='licenseKey' onChange={this.changeHandler} value={settings.licenseKey || revealedLicenseKey || ''} />
                  </Col>
                  <Col sm={3}>
                    <Button bsStyle='primary' onClick={this.updateLicenseKey}>Submit</Button>
                  </Col>
                </FormGroup>}

                {!updatingLicenseKey && !allowEditLicenseKey && <FieldGroupReadonly
                  name='license'
                  label='License key'
                  onEdit={this.allowEditLicense}>
                  {licenseKeyStubValue}
                </FieldGroupReadonly>}

                {updatingLicenseKey && <FormGroup controlId='licenseKey' className='settings-field-stub'>
                  <Col componentClass={ControlLabel} sm={3}>License key</Col>
                  <Col sm={9}>
                    <div className='settings-field-stub_text'>Checking your license key...</div>
                  </Col>
                </FormGroup>}
              </Panel.Body>
            </Panel>}
          </Col>
        </Row>
      </Form>
    )
  }

  renderOrdersTabContent () {
    const {orders} = this.props
    return <OrdersList list={orders} />
  }

  renderEmailsTabContent () {
    const { settings } = this.props
    // allow edit via UI or edit in initial mode when handle not set

    return (
      <Form horizontal>
        <Row>
          <Col sm={9}>
            <Panel>
              <Panel.Heading>Change your email</Panel.Heading>
              <Panel.Body>
                <UrlInput
                  type='email'
                  value={settings.email || ''}
                  onChanged={this.handleEmailChange}
                />
              </Panel.Body>
            </Panel>
          </Col>
        </Row>
      </Form>
    )
  }

  rendererTestAreaTab () {
    if (!IS_DEVELOP) {
      return ''
    }

    const { session, profileSubscriptions } = this.props

    const subscriptionPeriodTextFromSubscriptionType = (subscription) => {
      switch (subscription.frequency_interval) {
        case 1: return 'day' + (subscription.frequency_value > 1 ? 's' : '')
        case 2: return 'week' + (subscription.frequency_value > 1 ? 's' : '')
        case 3: return 'month' + (subscription.frequency_value > 1 ? 's' : '')
        case 4: return 'year' + (subscription.frequency_value > 1 ? 's' : '')
        default: return 'interval'
      }
    }

    return <Form horizontal>
      <Row>
        <Col sm={9}>
          <Panel>
            <Panel.Heading>BETA | Subscription information</Panel.Heading>
            <Panel.Body>
              {session.profile.subscriptionUnsubscribed &&
                <Alert bsStyle='warning'>Your subscription was cancelled. Your account will be downgraded, once the current subscription period ends. You will not be charged any more.</Alert>}
              <FieldGroupReadonly label='Subscription Expiration'>{session.profile.subscriptionExpiration}</FieldGroupReadonly>
              <FieldGroupReadonly label='Subscription Type'>{session.profile.subscriptionType}</FieldGroupReadonly>
              <FieldGroupReadonly label='CANCEL!'><Button bsStyle='danger' onClick={this.cancelSubscription}>CANCEL SUBSCRIPTION</Button></FieldGroupReadonly>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Heading>BETA | Subscription purchase test</Panel.Heading>
            <Panel.Body>
              <Alert>
                This is not the final implementation of the design - just a stub to test the subscription purchase flow...
              </Alert>
              <div className='trackattack-subscription-list'>
                {profileSubscriptions.list && profileSubscriptions.list.map((subscription, idx) => {
                  return <div className='subscription' key={idx}>
                    <h2 className='subscription__title'>{subscription.name}</h2>
                    <div className='subscription__price'>${subscription.recurring_price}</div>
                    <div className='subscription__price__period'>{`per user/per ${subscription.frequency_value} ${subscriptionPeriodTextFromSubscriptionType(subscription)}`}</div>
                    <div className='subscription__trial'>
                      {`Activation bonus: You pay just $${subscription.trial_price} for the first ${subscription.trial_no_of_occurrences} ${subscriptionPeriodTextFromSubscriptionType(subscription)}`}
                    </div>
                    <div className='subscription__spacer' />
                    <ExternalLink className='subscription__button' to={subscription.url}>Go for it!</ExternalLink>
                  </div>
                })}
              </div>
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
    </Form>
  }

  renderInitial () {
    const { settings, session } = this.props
    const oldHandle = session.profile.handle

    return (
      <div>
        <Form horizontal>
          <p>
            Welcome to Track Attack! To complete the registration, choose a handle.
            Your handle will be how everyone refers to you Track Attack.
            The more you complete your profile, the more we can match your interests with others.
          </p>
          <Row>
            <Col sm={9}>
              <HandleEdit
                oldHandle={oldHandle}
                onHandleChanged={this.handleEdited}
                apiToken={session.token}
              />
              <FieldGroupReadonly name='email' type='email' label='Email'>{settings.email || ''}</FieldGroupReadonly>
            </Col>
          </Row>
        </Form>
        <p />
        {this.renderProfileTabContent(false)}
      </div>
    )
  }

  renderSettingsTabs () {
    return (
      <Tab.Container onSelect={this.onTabChanged} id='left-tabs-example' activeKey={this.state.activeTab}>
        <Row className='clearfix'>
          <Col sm={2}>
            <Nav bsStyle='pills' stacked>
              <NavItem eventKey='profile'>Profile</NavItem>
              <NavItem eventKey='account'>Account</NavItem>
              <NavItem eventKey='orders'>Orders</NavItem>
              <NavItem eventKey='emails'>Emails</NavItem>
              {IS_DEVELOP && <NavItem eventKey='test'>Test area</NavItem>}
              {/* <NavItem eventKey="teams" disabled={true}>Teams</NavItem>
              <NavItem eventKey="pages" disabled={true}>Pages</NavItem>
              <NavItem eventKey="billing" disabled={true}>Billing</NavItem>
              <NavItem eventKey="privacy" disabled={true}>Privacy</NavItem> */}
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content animation>
              <Tab.Pane eventKey='profile'>
                <Section className='overflown-section'>{this.renderProfileTabContent()}</Section>
              </Tab.Pane>
              <Tab.Pane eventKey='account'>
                <Section className='overflown-section'>{this.renderAccountTabContent()}</Section>
              </Tab.Pane>
              <Tab.Pane eventKey='orders'>
                <Section className='overflown-section'>{this.renderOrdersTabContent()}</Section>
              </Tab.Pane>
              <Tab.Pane eventKey='emails'>
                <Section className='overflown-section'>{this.renderEmailsTabContent()}</Section>
              </Tab.Pane>
              {IS_DEVELOP && <Tab.Pane eventKey='test'>
                <Section className='overflown-section'>{this.rendererTestAreaTab()}</Section>
              </Tab.Pane>}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session,
    settings: state.ui.profile.settings,
    profileUIState: state.ui.profile.uiState,
    profileVehicles: state.garage.profileVehicles,
    orders: state.store.orders,

    profileSubscriptions: state.store.profileSubscriptions
  }
}

export default connect(mapStateToProps)(UserSettings)

import { GARAGE_FETCH, VEHICLE_FETCH, ALL_VEHICLES_FETCH, VEHICLE_SESSIONS_FETCH, VEHICLE_STATS } from '../actions/garageActions'
import { default as listReducer } from './listReducer'
import { default as itemReducer } from './itemReducer'
import { combineReducers } from 'redux'

export default combineReducers({
  selectedVehicle: itemReducer(VEHICLE_FETCH),
  profileVehicles: listReducer(GARAGE_FETCH),
  allVehicles: listReducer(ALL_VEHICLES_FETCH),
  selectedSessions: listReducer(VEHICLE_SESSIONS_FETCH),
  selectedStats: itemReducer(VEHICLE_STATS)
})

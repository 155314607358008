import React from 'react'
import { FormGroup, ControlLabel, Col, Row } from 'react-bootstrap'
import FA from 'react-fontawesome'

const FieldGroupReadonly = ({ id, label, children, onEdit, onDelete, ...props }) => {
  return (
    <FormGroup controlId={id || props.name} className='field-stub'>
      <Row>
        <Col componentClass={ControlLabel} sm={3}>{label}</Col>
        <Col sm={8}>
          <div className='field-stub_text control-label'>{children}</div>
        </Col>
        <Col sm={1}>
          {onEdit && <a className='field-stub_edit' onClick={onEdit}><FA name='edit' /></a>}
          {onDelete && <a className='field-stub_edit' onClick={onDelete}><FA name='times' /></a>}
          {!onEdit && !onDelete && <div />}
        </Col>
      </Row>
    </FormGroup>
  )
}

export default FieldGroupReadonly

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid } from 'react-bootstrap'
import { uiNavigation } from '../actions/ui'
import { pageView } from '../api/gaHelper'
import { searchToObject } from '../utils/utils'

/**
 * Wraps page content correctly
 * Handles basic layout
 * @param PageContent the component to wrap
 * @param opts uiNavigationRoute: controls uiNavigation; pageView: controls GA
 */
export function page (PageContent, opts = {uiNavigationRoute: null, pageView: null}) {
  // implement opts if needed
  class ThePage extends Component {
    componentDidMount () {
      const {dispatch, session, history} = this.props
      const searchObj = searchToObject()

      // http://localhost:44321/sschalktalk?utm_source=test&utm_medium=manual
      if (searchObj.utm_source && searchObj.utm_medium) {
        pageView(window.location.pathname + window.location.search, session)
        if (history) {
          const {pathname} = history.location
          history.replace({pathname: pathname.replace('//', '/')})
        } else {
          console.warn('Page should be declared in the withRouter()')
        }
      } else {
        opts.pageView && pageView(opts.pageView, session)
      }

      dispatch(uiNavigation({to: opts.uiNavigationRoute}))

      // make page scroll up after navigation happens
      this.hashLinkScroll()
    }

    render () {
      return (
        <Grid className='main-container'>
          <PageContent {...this.props} />
        </Grid>
      )
    }

    hashLinkScroll () {
      // fix hash links for react
      const { hash } = window && window.location
      if (hash && hash !== '') {
        // Push onto callback queue so it runs after the DOM is updated,
        // this is required when navigating from a different page so that
        // the element is rendered on the page before trying to getElementById.
        setTimeout(() => {
          const id = hash.replace('#', '')
          const element = document.getElementById(id)
          if (element) element.scrollIntoView()
        }, 0)
      } else {
        document.getElementById('nav_header').scrollIntoView()
      }
    }
  }

  function getDisplayName (wrapped) {
    return wrapped.displayName || wrapped.name || 'WrappedPage'
  }

  function mapStateToProps (state, ownProps) {
    return { session: state.session }
  }

  ThePage.displayName = `Page(${getDisplayName(PageContent)})`

  return connect(mapStateToProps)(ThePage)
}

import React from 'react'

const MenuItemHack = ({children}) => {
  return (
    <li role='presentation'>
      <a role='menuitem' tabIndex='-1'>
        {children}
      </a>
    </li>
  )
}

export default MenuItemHack

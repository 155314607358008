import { LOGIN, LOGOUT, FETCH_PROFILE, UPDATE_PROFILE, UPDATE_WELCOME_PROGRESS, FETCH_QUOTA_USAGE, FETCH_PROFILE_SUBSCRIPTION_DETAILS } from '../actions/sessionActions'
import { default as itemReducer } from './itemReducer'

const initialState = {
  isAnonymous: true,
  isFetching: false,
  profile: {},
  error: null,
  welcomeProgress: -1
}

function onLogIn (result, error, context) {
  if (!(result || error)) {
    return {
      ...initialState,
      isFetching: true
    }
  }

  if (error) {
    return {
      ...initialState,
      error: error
    }
  } else {
    return {
      ...initialState,
      isAnonymous: false,
      profile: result,
      loggedIn: new Date(),
      token: context.token,
      authResult: context.authResult
    }
  }
}

export default function sessionReducer (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return onLogIn(action.result, action.error, action.context)
    case LOGOUT:
      return {
        ...initialState,
        error: action.error
      }
    case FETCH_PROFILE:
      return itemReducer(FETCH_PROFILE, 'profile')(state, action)
    case FETCH_PROFILE_SUBSCRIPTION_DETAILS:
      return itemReducer(FETCH_PROFILE_SUBSCRIPTION_DETAILS, 'subscription')(state, action)
    case FETCH_QUOTA_USAGE:
      return itemReducer(FETCH_QUOTA_USAGE, 'quota')(state, action)
    case UPDATE_WELCOME_PROGRESS:
      return { ...state, welcomeProgress: (!action.welcomeProgress && action.welcomeProgress !== 0) ? -1 : action.welcomeProgress }
    case UPDATE_PROFILE:
      return itemReducer(UPDATE_PROFILE, 'profile')(state, action)
    default:
      return state
  }
}

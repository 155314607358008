import React from 'react'
import PropTypes from 'prop-types'

import {Row} from 'react-bootstrap'
import classnames from 'classnames'

const Section = ({
  title,
  subtitle,
  buttons,
  children,
  className,
  headerMessage }) => {
  const resultingTitle = title || ((buttons || headerMessage) ? <div /> : null)

  return (
    <Row className={classnames('container-frame', className)}>
      {resultingTitle && <div className='section__title'>
        <h2>{resultingTitle}</h2>
        <div className='section__title__buttons'> {buttons}</div>
        <div className='section__header__message'>{headerMessage}</div>
      </div>}
      {subtitle && <div className='section__subtitle'>
        <h3>{subtitle}</h3>
      </div>}
      {(resultingTitle || subtitle) && <hr />}
      {children}
    </Row>
  )
}

Section.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttons: PropTypes.element,
  className: PropTypes.string
}

Section.defaultProps = {
  title: null,
  buttons: null,
  className: null
}

export default Section

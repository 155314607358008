import React from 'react'
import PropTypes from 'prop-types'
import {beautifulTimePeriodFromMs} from '../utils/modelConvertionUtils'

const propTypes = {
  durationMs: PropTypes.number
}

const defaultProps = {
  durationMs: null
}

const EventDuration = props => (
  <span className='event-duration-text'>
    {beautifulTimePeriodFromMs(props.durationMs)}
  </span>
)

EventDuration.propTypes = propTypes

EventDuration.defaultProps = defaultProps

export default EventDuration

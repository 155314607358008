import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Row, Button } from 'react-bootstrap'
import { fetchStorefront, fetchProduct, addProductToCartAction, unbuyProduct } from '../../actions/storeActions'
import { uiRedirect } from '../../actions/ui'
import { ROUTE_STORE } from '../../utils/routingConstants'
import { Section, Error404, SignInButton } from '../../components'
import { PageViewer } from '../../containers'
import { page } from '../../hocs/page'
import { find } from 'lodash'
import { pageView, GA_PAGES } from '../../api/gaHelper'
import noImage from '../../images/no_image.jpg'
import { PRODUCT_FILE_TYPES } from '../../api/apiConstants'
import { IS_PROD } from '../../constants'

class PageStoreProduct extends Component {
  constructor (props) {
    super(props)
    this.addToCart = this.addToCart.bind(this)
    this.unbuyProduct = this.unbuyProduct.bind(this)
  }

  componentWillMount () {
    // pageView(GA_PAGES.ADMIN_STORE_PRODUCT + product.idSendOwl)
    const { storefrontList, dispatch, session, productId } = this.props

    if ((!storefrontList.list || storefrontList.list.length === 0) && !storefrontList.fetching && !storefrontList.error) {
      dispatch(fetchStorefront(session.token))
    }

    pageView(GA_PAGES.STORE_PRODUCT + productId, session)
  }

  handleNewProduct (props) {
    const { storefrontList, selectedProduct, productId, session, dispatch } = props

    if (storefrontList.error) {
      dispatch(uiRedirect(ROUTE_STORE))
    }

    if (storefrontList.list && storefrontList.list.length && !selectedProduct.isFetching && !selectedProduct.error) {
      const selected = find(storefrontList.list, (o) => { return o && o.idSendOwl === productId })

      if (!selectedProduct.item || selectedProduct.item.idSendOwl !== productId) {
        dispatch(fetchProduct(session.token, productId, selected))
        // update sendowl links
        window.setTimeout(() => window.sendOwl.captureLinks())
      }
    }
  }

  componentWillReceiveProps (newProps) {
    this.handleNewProduct(newProps)
  }

  addToCart () {
    const {selectedProduct, dispatch} = this.props
    console.log(selectedProduct)
    dispatch(addProductToCartAction(selectedProduct.item))
  }

  unbuyProduct () {
    const {selectedProduct, session, dispatch} = this.props
    dispatch(unbuyProduct(session.token, selectedProduct.item))
  }

  renderSummaryBlock (item) {
    const {session} = this.props

    const image = <img className='page__logo' src={item.imageUrl || noImage} alt='item.name' />
    if (item.purchased) {
      return <Fragment>
        {image}
        <h5>{item.memberOwnership ? 'This item is a part of your subscription' : 'You have purchased this item!'}</h5>
        {item.productType === PRODUCT_FILE_TYPES.YOUTUBE && <Button target='_blank' href={PageViewer.createLink(item)}>
          <span>Watch</span>
        </Button>}
        {item.productType === PRODUCT_FILE_TYPES.WISTIA && <Button target='_blank' href={PageViewer.createLink(item)}>
          <span>Watch</span>
        </Button>}
        {item.productType === PRODUCT_FILE_TYPES.PDF && <Button target='_blank' href={PageViewer.createLink(item)}>
          <span>Read</span>
        </Button>}
        {!IS_PROD && !item.memberOwnership && <div>
          <Button bsStyle='danger' onClick={this.unbuyProduct}>UNBUY PRODUCT</Button>
        </div>}
      </Fragment>
    }

    const price = <Fragment>
      {!item.discount && <h5>Price: ${item.price}</h5>}
      {item.discount && <h5 className='raw-price tooltipped' data-tip-text={item.discount.name}>{`$${item.price}`}</h5>}
      {item.discount && <h5 className='discount-price tooltipped' data-tip-text={item.discount.name}>{`$${(item.price * (100 - item.discount.percentageDiscount) / 100).toFixed(2)}`}</h5>}
    </Fragment>

    return <Fragment>
      {image}
      {price}
      {/* This method does not work correctly with  */}
      {session.isAnonymous && <div><SignInButton text={item.price ? 'Sign in to purchase' : 'Sign in to get access'} /></div>}
      {/* {session.isAnonymous && <div><Button bsStyle='primary' onClick={this.addToCart}>Add to cart</Button></div>} */}
      {!session.isAnonymous && <div><Button bsStyle='primary' href={item.addToCartUrl}>Add to cart</Button></div>}
      {/* <br /> <br />
      <br /> <br />
      <div>Button 3 - BUY NOW link. Opens checkout in the modal window. Maybe add it to the product tiles on the storefront?</div>
      <Button bsStyle='primary' href={item.buyNowUrl}>buy now with link</Button> */}
    </Fragment>
  }

  // renderSessionsListForDataPaskProduct () {
  //   const { selectedProduct, dataPackSessionsList } = this.props
  //   if (!selectedProduct.item || selectedProduct.item.productType !== PRODUCT_FILE_TYPES.DATA_PACK) {
  //     return null
  //   }

  //   return <div>
  //     <SessionList list={dataPackSessionsList} showLegend={false} />
  //   </div>
  // }

  render () {
    const { selectedProduct } = this.props

    if (selectedProduct.error) {
      console.log(selectedProduct.error)
      return <Error404 title='No such product' subtitle={<span>Unfortunately we were unable to find this product. Try <Link to={ROUTE_STORE}>going back to the shop</Link></span>} />
    }

    if (!selectedProduct.item) {
      return ''
    }

    return (
      <Section title={selectedProduct.item ? selectedProduct.item.name : 'Loading...'}>
        <Col md={3} className='page-summary'>
          {this.renderSummaryBlock(selectedProduct.item)}
        </Col>
        <Col md={9}>
          <Row>
            {/* {this.renderSessionsListForDataPaskProduct()} */}
            <div dangerouslySetInnerHTML={{__html: selectedProduct.item.description}} />
          </Row>
        </Col>
      </Section>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    productId: ownProps.match.params.productId,

    selectedProduct: state.store.selectedProduct,
    storefrontList: state.store.storefront,
    session: state.session,
    dataPackSessionsList: state.store.dataPackSessionsList
  }
}

export default page(connect(mapStateToProps)(PageStoreProduct), {uiNavigationRoute: ROUTE_STORE})

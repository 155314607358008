import React, { Component } from 'react'
import { connect } from 'react-redux'
import { uiToast } from '../../actions/ui'
import { searchToObject } from '../../utils/utils'
import { page } from '../../hocs/page'
import { Section } from '../../components'

class PagePurchaseCallback extends Component {
  componentDidMount () {
    const { dispatch } = this.props
    const searchObj = searchToObject()

    console.log(searchObj)

    dispatch(uiToast({text: `You are premium now! Your subscription is now active.`, bsStyle: 'info'}))

    // dispatch(uiRedirect(ROUTE_STORE))
  }

  render () {
    return <Section>Purchase completed! Redirecting you to the store...</Section>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session
  }
}

export default page(connect(mapStateToProps)(PagePurchaseCallback))

import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import { ROUTE_STORE } from '../../utils/routingConstants'
import { EntityLink, TwoLineEntry, RowEntityList } from '../'
import moment from 'moment'

class OrdersList extends React.Component {
  static propTypes = {
    list: PropTypes.object.isRequired,
    loadMoreRows: PropTypes.func
  }

  static defaultProps = {
    fetchingRenderer: () => <div>Loading orders</div>,
    renderButtons: () => null,
    loadMoreRows: () => { },
    enableAdd: false
  }

  constructor (props) {
    super(props)
    this.renderRow = this.renderRow.bind(this)
  }

  fetchingRenderer () {
    return <div>Loading orders</div>
  }

  noRowsRenderer () {
    return <div>No orders. Shop now!</div>
  }

  render () {
    const { loadMoreRows, list } = this.props

    // dont need to sort here
    return (<RowEntityList
      list={list}
      loadMoreRows={loadMoreRows}
      rowRenderer={this.renderRow}
      noRowsRenderer={this.noRowsRenderer}
      fetchingRenderer={this.fetchingRenderer}
    />)
  }

  renderRow ({ index, key, style }) {
    const { list } = this.props
    const item = list.list[index]
    return (
      <div key={key} style={style}>
        <TwoLineEntry
          image={null}
          headline={this.renderHeadline(item)}
          message={this.renderMessage(item)}
          buttons={null}
          onClick={null} />
      </div>)
  }

  renderHeadline (item) {
    const discount = item.discount && Number(item.discount)

    const priceNoDiscount = item.cart.cart_items.reduce(
      (prevValue, currentValue, currentIndex, array) => { return currentValue.cart_item.price_at_checkout + prevValue }, 0)

    return <Fragment>
      <div className='listrow-start'>{moment(item.created_at).format('M/D/YYYY')}</div>
      <div className='listrow-end'>
        {!discount && <span className='price'>${priceNoDiscount}</span>}
        {discount && <span className='raw-price'>${priceNoDiscount}</span>}
        {discount && <span className='discount-price'>${priceNoDiscount - (discount || 0)}</span>}
      </div>
    </Fragment>
  }

  renderMessage (item) {
    const products = item.cart.cart_items.map((item) => {
      return <EntityLink key={item.cart_item.product_id} entity={{...item.cart_item, type: 'product'}}>[{item.cart_item.product_id}]</EntityLink>
    })
    return <div>Products: {products}</div>
  }
}

function mapStateToProps (state) {
  return {
    session: state.session
  }
}

export default connect(mapStateToProps)(OrdersList)

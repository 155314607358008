export const AGE_1_SEC = 1000
export const AGE_1_MIN = 60 * AGE_1_SEC
export const AGE_10_MIN = AGE_1_MIN * 10
export const AGE_30_MIN = AGE_1_MIN * 30
export const AGE_1_HR = AGE_1_MIN * 60

export const AGE_12_HR = 12 * AGE_1_HR
export const AGE_1_DAY = 24 * AGE_1_HR

export const AGE_7_DAY = 7 * AGE_1_DAY
export const AGE_30_DAY = 30 * AGE_1_DAY

const INMEM_CACHE = {}

function getDataInternal (key, maxAge) {
  if (typeof (Storage) !== 'undefined') {
    let container = JSON.parse(localStorage.getItem(key))

    if (!container) {
      // no data
      return null
    }

    if (maxAge) {
      if (!container.saved) {
        // old cache
        localStorage.removeItem(key)
        return null
      }

      if (new Date().getTime() - container.saved < maxAge) {
        // valid

        return container.value
      } else {
        // expired
        localStorage.removeItem(key)
        return null
      }
    } else {
      // valid
      return container.value
    }
  }
}

export function putData (key, value) {
  let container = { value: value, saved: new Date().getTime() }
  if (typeof (Storage) !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(container))
  }
}

export function getData (key, maxAge) {
  try {
    return getDataInternal(key, maxAge)
  } catch (error) {
    localStorage.removeItem(key)
    return null
  }
}

export function putInmemory (key, value) {
  INMEM_CACHE[key] = {saved: new Date().getTime(), value: value}
}

export function getInmemory (key, maxAge = AGE_1_MIN) {
  const cacheItem = INMEM_CACHE[key]

  if (!cacheItem) {
    return null
  }

  if (new Date().getTime() - cacheItem.saved < maxAge) {
    return cacheItem.value
  }

  console.log('EXPIRED')

  // INMEM_CACHE.removeItem(key)
  return null
}

export function clearKey (key) {
  delete INMEM_CACHE[key]
  if (typeof (Storage) !== 'undefined') {
    localStorage.removeItem(key)
  }
}

import React from 'react'
import { Panel } from 'react-bootstrap'
import { Section, ExternalLink } from '../../components'
import { page } from '../../hocs/page'
import { GA_PAGES } from '../../api/gaHelper'
import { Link } from 'react-router-dom'
import { ROUTE_SUPPORTED_DEVICES_AND_CHANNELS, ROUTE_PRICING, ROUTE_IRACING, ROUTE_ASSETTOCORSA, ROUTE_ROOT } from '../../utils/routingConstants'

const PageFaq = () => {
  return (
    <Section title='Frequently Asked Questions' className='page-legal'>
      <h3 id='hardware-and-simulators'>Hardware and Simulators</h3>
      <Panel id='hardware_explained'>
        <Panel.Heading>
          <Panel.Title toggle>
            What kind of data logging hardware do I need to buy to use Track Attack?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>Track Attack supports data files generated by many data
            loggers and racing simulators. There is no Track Attack specific
            hardware you need to buy – just keep using the logger you already
            have and import the logging file they generate.</p>
            <p>Check out the <Link to={ROUTE_PRICING}>pricing page</Link> to
            see more about what loggers and simulators are currently
            supported.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='smartphone_loggers'>
        <Panel.Heading>
          <Panel.Title toggle>
            I don’t have a super expensive data logging system, I use a smartphone app instead – can I still use Track Attack?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>Yes, you can!</p>
            <p>Check out the <Link to={ROUTE_PRICING}>pricing page</Link> to
            see more about what loggers and simulators are currently
            supported.</p>
            <p>While smartphone apps are pretty awesome for basic lap timing,
            the phone's internal GPS sensor usually does not have a
            high-enough sample rate for accurate data analysis. If you want a
            mild upgrade over your basic phone app, consider purchasing an
            external GPS sensor with at least a 5Hz sample rate.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='iracing'>
        <Panel.Heading>
          <Panel.Title toggle>
            Can I use Track Attack with iRacing telemetry data files?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>Yes! This is a new feature where you can have the full
            functionality of Track Attack with iRacing .ibt data files. See
            this <Link to={ROUTE_IRACING}>detailed
            page about how Track Attack works with
            iRacing</Link>.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='assetto-corsa'>
        <Panel.Heading>
          <Panel.Title toggle>
            Can I use Track Attack with Assetto Corsa?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>Yes! This is a new feature where you can have the full
            functionality of Track Attack with Assetto Corsa using a plugin
            we developed. See this <Link to={ROUTE_ASSETTOCORSA}>detailed page about how
            Track Attack works with Assetto Corsa</Link>.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='list_of_channels'>
        <Panel.Heading>
          <Panel.Title toggle>
            What data channels does Track Attack support?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>Many of the most commonly used channels are supported by Track Attack. Whether you are a real-world or simulator driver, a professional or an amateur, a coach or a team owner, we have you covered.</p>
            <p>Here is a <Link to={ROUTE_SUPPORTED_DEVICES_AND_CHANNELS}>link</Link> to the specific channels we support.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <h3 id='privacy-and-sharing'>Privacy and Sharing</h3>
      <Panel id='privacy'>
        <Panel.Heading>
          <Panel.Title toggle>
            Is the data I share with Track Attack public?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>All data that is imported and stored on the Track
            Attack cloud is by default set to visibility of “Summary only”.
            This means that the record of the session exists, showing data
            points that are similar to what you’ll see on the official time
            sheets from a race.</p>
            <p>Here is an <Link to='/sessions/c69b9ee5-a3e9-4ecc-ba22-f90eff142076'>example session</Link>.</p>
            <p>Other users will be able to:</p>
            <ul>
              <li>See the session exists</li>
              <li>See the metadata/summary data about the session, such as the
              driver, the car, the track, the lap times, and some basic stats.
              </li>
              <li>The lap times and other data from the session will be
              considered as part of any leaderboards.</li>
            </ul>
            <p>Other users will not be able to access the specific telemetry
            data, view it, or compare with their own data, unless you make
            the session completely public.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='private_data'>
        <Panel.Heading>
          <Panel.Title toggle>
            I want to have my data saved to the cloud, but I don’t want other
            people to even know it exists. How do I make my sessions
            completely private?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>Easy! If you are a premium user, in the desktop Track Attack
            application, open the share dialogue for the session and set the
            visibility setting to “Everyone else can see nothing.”</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='coaching'>
        <Panel.Heading>
          <Panel.Title toggle>
            I’m working with a coach and want to share data with them but not anyone else, how do I do that?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>To share with a coach, there are two options:</p>
            <ol>
              <li>Share the session directly with the coach, using the
              coach’s email address or Track Attack user handle.</li>
              <ol type='a'>
                <li>In the desktop application</li>
                <ol type='i'>
                  <li>In the currently open session list, right-click the desired session header and select the ‘share’ button.</li>
                  <li>Enter the coach’s email address or their user handle and then hit share.</li>
                  <li>Alternatively, if no sessions are open, from the "Open Session" dialogue you can also right-click and 'share'.</li>
                </ol>
              </ol>
              <ol type='a'>
                <li>In the website</li>
                <ol type='i'>
                  <li>After you log in, navigate to the session you wish to share.</li>
                  <li>Click the ‘share’ button and enter the coach’s email address or user handle and then hit share.</li>
                </ol>
              </ol>
            </ol>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='teams_support'>
        <Panel.Heading>
          <Panel.Title toggle>
            I have a team of drivers and I want to make sure they have access to all of the session data but not anyone else. How do I do that?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>Easy! First, in the desktop client, open the session you want to
            share with your team. Right click on the session header and
            select the ‘share’ option. In the share dialogue you can enter
            the TA team handle to share with the team and you can also change
            the visibility settings for that session.</p>
            <p>By default all imported sessions have the “Everyone else can see
            summary” setting. This means that other people can see that your
            session exists, who drove it, what car, what track, lap times and
            basic stats that are typically found in a race results sheet.</p>
            <p>If you want to make the session completely private, select
            “Everyone else can see nothing.” Your team members will see
            everything, and no one else will even know the session exists.
            These sessions also will not count for public leaderboards.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='inviting_drivers'>
        <Panel.Heading>
          <Panel.Title toggle>
            I have session files for other drivers, but they aren’t on Track Attack, how do I assign them as the driver?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>On the website (<Link to={ROUTE_ROOT}>www.trackattack.io</Link>), make
            sure you are logged in and then go to the session in question.
            Click on the “Edit” tab of the session and there you will see the
            option for changing the driver. You can set the driver to someone
            who is already on Track Attack by entering their user tag or
            their email address (assuming the email address you enter is the
            same one they used to register for TA).</p>
            <p>If the user is not on Track Attack, just enter their email
            address and they will get an email invitation, letting them know
            that you added them as a driver. Once they register for an
            account (with that same email address), they will see a
            notification with your request to tag them as the driver. When
            they accept, they will then be shown as the driver for that session.</p>
            <p>The stats for the session will count towards the driver’s
            profile stats and will show them as the driver on leaderboards.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='inviting_to_team'>
        <Panel.Heading>
          <Panel.Title toggle>
            I created a team and want to invite people to join but they aren’t on Track Attack, what do I do?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>After you’ve created the team, when you’re adding members, the
            dialogue window gives you an option to invite people to your team
            by entering their email address instead of their Track Attack
            user handle.</p>
            <p>When you invite someone via their email address, they will get
            an email invitation to join TA and your team. When they register
            for an account using the same email address, they will see a
            notification on the website, waiting for them to join your team.
            Once they accept that invitation, they will become a part of your
            team. Any sessions already shared with that team will then be
            visible to the new driver.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <h3 id='internet-and-synchronization'>Internet and Synchronization</h3>
      <Panel id='connectivity'>
        <Panel.Heading>
          <Panel.Title toggle>
            Do I always need to have an internet connection for Track Attack to work?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>No. Track Attack is a cloud-based service but you don’t need
            to have an internet connection for Track Attack to work. For the
            best experience, make sure that, after you first download and
            install the software, you sign in to the software with your Track
            Attack account and let the software synchronize completely one
            time.</p>
            <p>The first synchronization will ensure that the latest track
            database is downloaded and available to properly detect the track
            and your laps when a session file is imported. </p>
            <p>When you connect to the internet again later, Track Attack
            will automatically synchronize your data with the cloud.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='sessions_synchronised_or_not'>
        <Panel.Heading>
          <Panel.Title toggle>
            How do I know which sessions have been synchronized and not?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>At the bottom right of the Track Attack (TA) desktop client, you'll
            see a button indicating the sync status.</p>
            <p>By default, TA checks for changes to synchronize every 30
            minutes when connected to the internet or after something
            is changed in your sessions.</p>
            <p>Doing any of the following will cause a re-sync to be started:
            </p>
            <ul>
              <li>Changing the car</li>
              <li>Changing the driver</li>
              <li>Importing a session</li>
              <li>Deleting a session</li>
            </ul>
            <p>Clicking the button will give you the option to pause, resume,
            or start syncing, depending on the current condition. Pausing
            syncing can be useful if you are on a slow or limited internet
            connection. Don't forget to resume again later when you have good
            internet again!</p>
            <p>There are various statuses that can be indicated on this button:</p>
            <ul>
              <li><b>Not synced</b>: you are not connected to the internet or
              you manually stopped syncing.</li>
              <li><b>Partially synced</b>: a sync was interrupted due to an
              error or a disruption in the internet connection while
              syncing.</li>
              <li><b>Cloud synced</b>: TA has
              successfully synchronized with the cloud.</li>
              <ol type='i'><li>Note, TA will retry to successfully sync
              that session the next time it tries to sync and has a data
              connection.</li></ol>
            </ul>
            <p>Any time Track Attack has a problem syncing, it will try again
            later to fix the problem automatically. You don't have to worry
            about losing internet or closing the program in the middle of
            syncing.</p>
            <p>While a sync process is happening, you can hover over the sync
            button and see what is syncing.</p>
            <p>When you click the "Open Session..." button, you will see a
            list of your existing imported sessions. The status of whether or
            not the session is synchronized to the cloud is indicated with an icon.</p>
            <ul>
              <li><b>Green check</b>: session successfully synchronized</li>
              <li><b>Circular arrows</b>: in queue for sync</li>
              <li><b>Yellow icon</b>: unsuccessful sync</li>
            </ul>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='new_computer'>
        <Panel.Heading>
          <Panel.Title toggle>
            I've been using Track Attack and have lots of imported data. I just got a new computer. How do I get my data on the new computer?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>Assuming Track Attack has synchronized successfully on the old
            computer, follow these steps:</p>
            <ol>
              <li>Download and install Track Attack on the new computer</li>
              <li>Make sure you are connected to the internet</li>
              <li>Click the Sign In button in the Track Attack program to attach your account</li>
              <li>Let Track Attack synchronize with the cloud service</li>
              <li>That’s it!</li>
            </ol>
            <p>Synchronization time will vary based on the amount of sessions
            you have and your internet connection. For example, a user with
            150 sessions and a strong internet connection, will see the
            synchronization complete in 10-15 minutes.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <h3 id='troubleshooting'>Troubleshooting</h3>
      <Panel id='lap_detection'>
        <Panel.Heading>
          <Panel.Title toggle>
            I imported a session file but I don’t see any laps for my session. What’s going on?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>There are a few things that could be causing this:</p>
            <ol>
              <li>Make sure you are logged in to the desktop application of TA with your TA account and have let the app sync fully at least one time. This initial sync downloads the latest track database, which is needed to calculate lap times.</li>
              <li>Make sure the right track layout is selected. When a session is imported, TA looks at the GPS coordinates in your session and looks for the closest tracks within a 5 mile radius. It guesses which track is the right one but some tracks have multiple layouts and there might be multiple tracks near each other. Make sure the right track layout is selected.</li>
              <li>If you are importing an AiM .drk file, make sure the corresponding .gpk file for that session, is in the same folder as the .drk file. The .gpk file contains the raw GPS coordinate data that is needed to assign the track and calculate lap times.</li>
              <li>Last resort is that there is a small chance we don’t have the track you drove in our track database. You can check which tracks and layouts we have here: <ExternalLink to='https://trackattack.io/tracks' /> if we’re missing your track, message us on Facebook at <ExternalLink to='https://www.facebook.com/trackattackapp' /></li>
              <li>If we do not have your specific track in the Track Attack database, you can also create your own custom tracks/layouts.  <ExternalLink to='https://youtu.be/n9EVKSEDoeg'>Here is an instructional video</ExternalLink> of how you can do that.</li>
            </ol>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='changing_track'>
        <Panel.Heading>
          <Panel.Title toggle>
            I imported a session but I selected the wrong track, what do I do?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>With either the session open, or in the ‘Add Run’ window, you can right-click to select the ‘Run Properties’. Here, you can select the drop down the contains the current track for a session. You can select another track/layout.</p>
            <p>Select the new track/layout and then make sure to click the ‘Recalculate GPS Times’. You should see the lap times expected. Once you have an internet connection, you’ll see the data for that session updated on the cloud as well. If the session has the visibility set to ‘Everyone else can see a summary’, the updated data points will show up in the public view of the session, driver stats, car stats and the track leaderboards.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='missing_channels'>
        <Panel.Heading>
          <Panel.Title toggle>
            I imported a session but I don’t see all of the channels from my original data file, where are they?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>In Track Attack, we have several supported channels. Unfortunately, the naming conventions of channels within and across data logging systems are not standard.  Track Attack has hundreds of supported channels and channel names but don’t have coverage for every available permutation.</p>
            <p>So there is a manual mapping feature in Track Attack, where if you have a channel that we don’t automatically pick up, you can manually make sure it is covered.  Here is how:</p>
            <ol>
              <li>Import your session file</li>
              <li>In the ‘Import Window’, there is a tab called ‘Un-mapped channels’, click on that.</li>
              <ol type='a'>
                <li>This is a list of channels that were detected but TA does not know what they are.</li>
              </ol>
              <li>Manually map the channel to a known TA channel</li>
              <ol>
                <li>For each un-mapped channel, you can select a known TA channel</li>
                <li>The first section of available channels are of channels TA believes it has found a corresponding channel in the session file.</li>
                <li>Below the line, there are ‘New [channel name]’ options.  These are TA channels in which TA did not find any potential channel matches in the session file.</li>
                <li>Selecting one of these ‘New [channel name]’ channels will create a net-new channel.</li>
                <li>Any future sessions you import on the same computer with the same channel names will automatically be mapped with this logic.  They will still show up in the un-mapped channel tab each time a session is imported but will have the mappings applied.  You can always change the manual mappings but note that this will not change the data mappings for previously imported sessions.</li>
                <li>If you want to change the manual mappings for a session, you can do so by re-importing the session.</li>
              </ol>
            </ol>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <h3 id='subscriptions-and-payment'>Subscriptions and Payment</h3>
      <Panel id='free_vs_premium'>
        <Panel.Heading>
          <Panel.Title toggle>
            What’s the difference between the free version of Track Attack and the premium version?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>Most of the functionality of Track Attack is the same; better
            analysis tool, cloud syncing, easy sharing and works with all
            major data logging systems. For the free membership level, a user
            can import and use Track Attack locally, as much as they want.
            However, only 25 sessions will be synced to the cloud and
            leverage the power of the Track Attack cloud platform.</p>
            <p>After a user has imported and synced 50 sessions, to keep
            syncing online, they will need to upgrade to the premium version
            of Track Attack.</p>
            <p>There are also specific features that are only available for
            premium users, like the ability to have private teams, sessions
            and vehicles and many more that are coming over the next several
            months!</p>
            <p>Track Attack premium is an annual subscription and costs less
            than a weekend’s worth of fuel – an incredible value!</p>
            <p>Track Attack is a subscription product because it is a
            cloud-based service. Your subscription provides all the latest
            updates, features, and benefits that are exclusive to premium
            users, and helps fund our continued development!</p>
            <p>You do want to support us, right?</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='free_trial'>
        <Panel.Heading>
          <Panel.Title toggle>
            How does the premium free trial work?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>Like other subscription services such as Spotify or Netflix,
            when you sign up for premium, you will get a 30-day free trial.
            This means that for those first 30 days, you will have full
            access to the premium membership and will not be charged. At the
            end of those 30 days, you will be charged the monthly
            subscription cost and every 30 days from then on.</p>
            <p>You can cancel your premium membership at any time during or
            after the 30-day free trial. If you cancel the premium free trial
            before the end of the 30 days, you will not be charged at the end
            of the 30 days.</p>
            <p>Please note that all subscription transactions are final.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='subscription_cancellation'>
        <Panel.Heading>
          <Panel.Title toggle>
            What happens if I cancel my premium membership mid-month?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>You can cancel your premium membership anytime, even after
            you’ve started paying (post 30-day free trial). We have a no
            refund policy for the premium membership because it is a service.
            Upon cancellation, we will stop any further charges and your
            account will remain a premium account until the end of the
            billing cycle. When the billing cycle is completed your account
            will be downgraded into a free member account.</p>
            <p>If you purchased the premium membership with annual renewals,
            it will work similarly. If you decide to cancel, your account
            will remain a premium account until the end of the 1-year period.
            On the renewal date your card will not be charged and your
            account will be downgraded to a free membership.</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

      <Panel id='session-cap'>
        <Panel.Heading>
          <Panel.Title toggle>
            What is the session limit for free users?
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <p>Free users have a limit on how many sessions they can import.
            Our systems and infrastructure costs money to operate and, as
            such, we have to limit the total number of sessions that each
            free user can have imported and synced with our servers. </p>
            <p>Currently, free users may import a maximum of 25 sessions.
            Once the session limit is reached, imported sessions must be
            deleted before new sessions can be imported again. </p>
            <p>In the "Open Session" menu, you can left-click (a single
            session) or shift-click or control-click (multiple sessions) and
            then right-click and choose "Delete Session".</p>
            <p>You can also choose "Export as OCD File" to export one or more
            sessions before deleting, just in case you want to access them
            later when you sign up for premium!</p>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>

    </Section>
  )
}

export default page(PageFaq, { pageView: GA_PAGES.FAQ })

import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, List, InfiniteLoader } from 'react-virtualized'
import styles from 'react-virtualized/styles.css'

export default class InfiniteList extends React.Component {
  static propTypes = {
    listHeight: PropTypes.number,
    rowHeight: PropTypes.number,
    totalRows: PropTypes.func,
    currentRows: PropTypes.func,
    isRowLoaded: PropTypes.func,
    loadMoreRows: PropTypes.func,
    rowRenderer: PropTypes.func,
    noRowsRenderer: PropTypes.func,
    // use this to force list update when the data is changed, but list length is the same
    listHash: PropTypes.string
  }

  static defaultProps = {
    loadMoreRows: () => {},
    listHeight: 500,
    rowHeight: 50
  }

  render () {
    const { isRowLoaded, loadMoreRows, listHeight, totalRows, currentRows, rowHeight, rowRenderer, noRowsRenderer, listHash } = this.props

    let height = Math.min(currentRows() * rowHeight, listHeight)

    if (noRowsRenderer) {
      // show noRowsRenderer won't be called with zero height
      height = Math.max(height, rowHeight)
    }

    return (
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={totalRows()}>
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                listHash={listHash}
                ref='registerChild'
                className={styles.List}
                height={height}
                onRowsRendered={onRowsRendered}
                noRowsRenderer={noRowsRenderer}
                rowCount={currentRows()}
                rowHeight={rowHeight}
                rowRenderer={rowRenderer}
                width={width}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>)
  }
}

import ReactGA from 'react-ga'
import { GOOGLE_ANALYTICS_PROPERTY_ID } from '../constants'

export const GA_PAGES = {
  SESSIONS: 'sessions',
  SESSION: 'session/',
  TRACKS: 'tracks',
  TRACKS_DB: 'tracks/db',
  TRACK: 'track/',
  DOWNLOADS: 'downloads',
  VEHICLES: 'vehicles',
  VEHICLE: 'vehicle/',
  PRIVACY: 'privacy',
  TERMS: 'terms',
  PROFILECHECK: 'profilecheck',
  SETTINGS: 'settings',
  TEAMS: 'teams',
  TEAM: 'team/',
  USERS: 'users',
  USER: 'user/',
  STORE: 'store',
  STORE_CART: 'store/cart',
  LEADERBOARDS: 'leaderboards/',
  STORE_PRODUCT: 'store/',
  LANDING: 'landing',
  FAQ: 'faq',
  PRICING: 'pricing',
  PRICING_STUDENTS: 'pricing/students',
  VALUE_TEAMS: 'solutions/teams',
  VALUE_COACHES: 'solutions/coaches',
  VALUE_TRACKDAYS: 'solutions/trackdays',
  VALUE_CLUBRACERS: 'solutions/clubracers',
  VALUE_IRACING: 'iracing',
  VALUE_ASSETTOCORSA: 'assettocorsa',
  LICENCECHECK: 'licensecheck',
  ERROR404: 'error404',
  ADMIN_TRACK_EDIT: 'admin/edittrack/',
  ADMIN_KPI: 'admin/kpi',
  ADMIN_STORE: 'admin/store',
  ADMIN_STORE_PRODUCT: 'admin/store/',
  CONTACTS: 'contacts',
  VIEWER: 'viewer',
  NEWS: 'news',

  ADMIN_NOTIFICATIONS: 'admin/notifications',

  WELCOME_DIALOG_1: 'user-profile-create-step1',
  WELCOME_DIALOG_2: 'user-profile-create-step2',
  WELCOME_DIALOG_3: 'user-profile-create-step3',
  WELCOME_DIALOG_4: 'user-profile-create-step4'
}

const GA_EVENTS = {
  DOWNLOAD: {
    category: 'download'
  },
  CREATE: {
    category: 'create',
    action_vehicle: 'vehicle',
    action_team: 'team'
  },
  SHARE: {
    category: 'share',
    action_session: 'session'
  }
}

const GA_USERTYPES = {
  guest: 'guest',
  member: 'member'
}

// called from downloads page
export function pageView (page, userSession) {
  if (GOOGLE_ANALYTICS_PROPERTY_ID) {
    ReactGA.set({ dimension1: userSession && !userSession.isAnonymous ? GA_USERTYPES.member : GA_USERTYPES.guest })
  }

  GOOGLE_ANALYTICS_PROPERTY_ID && ReactGA.pageview(page)
}

export function eventDownload (platform, userSession) {
  if (GOOGLE_ANALYTICS_PROPERTY_ID) {
    ReactGA.set({ dimension1: userSession && !userSession.isAnonymous ? GA_USERTYPES.member : GA_USERTYPES.guest })
  }

  GOOGLE_ANALYTICS_PROPERTY_ID && ReactGA.event({
    category: GA_EVENTS.DOWNLOAD.category,
    action: platform
  })
}

export function eventCreateVehicle (vehicle) {
  GOOGLE_ANALYTICS_PROPERTY_ID && ReactGA.set({dimension1: GA_USERTYPES.member})

  GOOGLE_ANALYTICS_PROPERTY_ID && ReactGA.event({
    category: GA_EVENTS.CREATE.category,
    action: GA_EVENTS.CREATE.action_vehicle,
    label: vehicle.handle
  })
}

export function eventCreateTeam (team) {
  GOOGLE_ANALYTICS_PROPERTY_ID && ReactGA.set({dimension1: GA_USERTYPES.member})

  GOOGLE_ANALYTICS_PROPERTY_ID && ReactGA.event({
    category: GA_EVENTS.CREATE.category,
    action: GA_EVENTS.CREATE.action_team,
    label: team.handle
  })
}

export function eventShareSession () {
  GOOGLE_ANALYTICS_PROPERTY_ID && ReactGA.set({dimension1: GA_USERTYPES.member})

  GOOGLE_ANALYTICS_PROPERTY_ID && ReactGA.event({
    category: GA_EVENTS.SHARE.category,
    action: GA_EVENTS.SHARE.action_session
  })
}

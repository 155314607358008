import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateTeamAction, teamInvitesFetch, teamSummaryFetch, deleteTeamMemberAction, selectedTeamFetch, teamMembersFetch, teamVehiclesFetch, teamSessionsFetch } from '../../actions/teamDbActions'
import { inviteTeamMembersAction } from '../../actions/inviteActions'
import { uiRedirect } from '../../actions/ui'
import { ROUTE_TEAMS } from '../../utils/routingConstants'
import { UserList, SessionList, InviteList, Section, SocialIcons, InvitationOverlay, Overlay, StatisticDataList, VehicleList, Error404, EntityLink } from '../../components'
import { TeamSettings, RequestOverlay } from '../'
import { find } from 'lodash'
import { Row, Grid, Col, ButtonToolbar, Button, ButtonGroup, Alert, Tabs, Tab, Badge } from 'react-bootstrap'
import { confirmable, createConfirmation } from 'react-confirm'
import noImage from '../../images/no_image.jpg'
import moment from 'moment'
import { pageView, GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'
import classnames from 'classnames'

class PageTeamProfile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      innerTab: 'members'
    }
    this.inviteMembers = this.inviteMembers.bind(this)
    this.loadTeamMembers = this.loadTeamMembers.bind(this)
    this.loadSessions = this.loadSessions.bind(this)
    this.deleteMemberHandler = this.deleteMemberHandler.bind(this)
    this.saveSettingsOperation = this.saveSettingsOperation.bind(this)
    this.handleSelectInnerNav = this.handleSelectInnerNav.bind(this)
  }

  componentWillMount () {
    const { selectedTeam, handle, teams, session } = this.props
    if (!selectedTeam || !selectedTeam.item || selectedTeam.item.handle !== handle) {
      const target = teams.list ? find(teams.list, { handle: handle }) : null
      this.handleNewTeam(handle, target)
    }
    pageView(GA_PAGES.TEAM + handle, session)
  }

  componentWillReceiveProps (newProps) {
    const { dispatch, selectedTeam, session } = this.props
    if (newProps.selectedTeam.item && !selectedTeam.item) {
      // when there is a selected team and the current user is the owner, show invitations
      if (this.isTeamOwner(session.profile, newProps.selectedTeam)) {
        dispatch(teamInvitesFetch(session.token, newProps.selectedTeam.item.handle))
      }
    } else if (selectedTeam.item && newProps.selectedTeam.item && selectedTeam.item.handle !== newProps.selectedTeam.item.handle) {
      this.handleNewTeam(newProps.selectedTeam.item.handle)
    }
  }

  handleNewTeam (newHandle, target) {
    const { dispatch, session, handle } = this.props

    if (handle && newHandle && handle !== newHandle) {
      // handle was changed - redirect to new vehicle
      dispatch(uiRedirect(`${ROUTE_TEAMS}/${newHandle}`))
    } else if (newHandle) {
      // step 0 - clear previos user data from state
      dispatch(selectedTeamFetch())
      dispatch(selectedTeamFetch(session.token, newHandle, target))
      dispatch(teamMembersFetch(session.token, newHandle))
      dispatch(teamVehiclesFetch(session.token, newHandle))
      dispatch(teamSessionsFetch(session.token, newHandle))
      dispatch(teamSummaryFetch(session.token, newHandle))
    }
  }

  isTeamMember (user = this.props.session.profile) {
    if (!user.id) { return false }
    const { members } = this.props
    return members.list && members.list.findIndex(member => { return member.memberUserId === user.id }) >= 0
  }

  inviteMembers (spec) {
    const { dispatch, session } = this.props
    dispatch(inviteTeamMembersAction(session.token, spec))
  }

  getTeamMemberHandles () {
    return this.props.members.list.map(member => member.member.handle)
  }

  isTeamOwner (user = this.props.session.profile, selectedTeam = this.props.selectedTeam) {
    if (!user.id) { return false }
    return selectedTeam.item && user.id === selectedTeam.item.ownerId
  }

  loadTeamMembers ({ startIndex, stopIndex }) {
    const { dispatch, session, members, handle } = this.props
    const total = members.headers ? members.headers.pagination.totalCount : -1
    if ((total < 0 || startIndex < total) && !members.isFetching) {
      return dispatch(teamMembersFetch(session.token, handle, startIndex))
    }
  }

  deleteMemberHandler (user, event) {
    event.stopPropagation()
    const dialog = ({ show, proceed, dismiss, cancel, confirmation, options }) => {
      const deleteString = `Remove @${user.handle} from the team`
      const buttons = Overlay.createButtons([
        { [deleteString]: () => proceed(user), bsStyle: 'warning' },
        { Cancel: cancel, bsStyle: 'default' }
      ])
      return (<Overlay
        show={show}
        title={deleteString + '?'}
        closeAction={dismiss}
        buttons={buttons}>
        <Alert bsStyle='warning' >
          <p>You are about to remove <b>@{user.handle}</b> from your team.
            They will loose access to all data shared with the team.
            You can reinvite them later if you want and they will regain access.
          </p>
        </Alert>
      </Overlay>)
    }

    const confirmer = createConfirmation(confirmable(dialog))
    confirmer('', {}).then(user => this.okDeleteUserOperation(user), () => { })
  }

  okDeleteUserOperation (user) {
    const { dispatch, session, handle, selectedTeam } = this.props
    dispatch(deleteTeamMemberAction(session.token, selectedTeam.item, user))
    dispatch(teamMembersFetch(session.token, handle))
  }

  renderTeamMembers (members) {
    if (!members) { return '' }

    const { teamInvites } = this.props
    const noContent = () => <div className='list-noRows'>No one has joined this team</div>
    const renderButtons = member => {
      const canDelete = this.props.selectedTeam.item && this.isTeamOwner() && !this.isTeamOwner(member)
      return (<ButtonToolbar>
        <ButtonGroup>
          {canDelete && <Button bsStyle='danger' onClick={(e) => { this.deleteMemberHandler(member, e) }}>Remove</Button>}
        </ButtonGroup>
      </ButtonToolbar>)
    }

    // const buttons = <ButtonToolbar>{this.inviteButton()}</ButtonToolbar>
    return (<Section>
      <UserList
        list={members}
        loadMoreRows={this.loadTeamMembers}
        noRowsRenderer={noContent}
        renderButtons={renderButtons}
        itemSelector={item => item.member}
        entitySentInvites={teamInvites.list ? teamInvites.list : null}
      />
    </Section>)
  }

  renderInvites (invites) {
    if (!invites || !this.isTeamOwner()) { return '' }

    return <Section><InviteList list={invites} /></Section>
  }

  loadSessions ({ startIndex, stopIndex }) {
    const { dispatch, session, sessions } = this.props
    const total = sessions.headers ? sessions.headers.pagination.totalCount : -1
    if ((total < 0 || startIndex < total) && !sessions.isFetching) {
      return dispatch(teamSessionsFetch(session.token, this.props.handle, startIndex))
    }
  }

  renderSessions () {
    const { sessions } = this.props
    // const selectSession = ({ event, index, rowData }) => {
    //   history.push(ROUTE_SESSIONS + '/' + rowData.id)
    // }
    return (
      <Section>
        <SessionList list={sessions} loadMoreRows={this.loadSessions} />
      </Section>)
  }

  saveSettingsOperation () {
    // This should only ever be run when editing an existing team
    const { session, dispatch, selectedTeam, mode, settings, teamUiState } = this.props
    if (mode !== 'create') {
      const spec = { ...selectedTeam.item, ...settings, newAvatar: teamUiState.avatarPreview, rowVersion: selectedTeam.item.rowVersion }
      dispatch(updateTeamAction(session.token, spec))
    }
  }

  renderSettings (team) {
    if (!team) { return '' }
    const { teamUiState } = this.props
    return (
      <Section>
        <TeamSettings />
        <ButtonToolbar bsClass='settings-bottom-buttons'>
          <Button key='Save' bsStyle='primary' disabled={!(teamUiState && teamUiState.valid)} onClick={this.saveSettingsOperation}>Save</Button>
        </ButtonToolbar>
      </Section>)
  }

  followTeam (team) {
  }

  renderSummary (team) {
    if (!team) { return '' }

    const { members, teamInvites } = this.props

    const joinButton = <RequestOverlay entity={team} />

    const nameText = (<div>
      <h4>{team.name}</h4>
      <h5>{'@' + team.handle}</h5>
    </div>)
    const memberExclusions = members.list.map(entry => entry.member)
    const teamOwner = find(memberExclusions, {ownerId: team.ownerId})
    return (
      <div>
        <img className='page__logo' src={team.avatarUrl || noImage} alt='team' />
        <Badge className={classnames('visibility', {'private': (team.visibility === 0)})}>{team.visibility === 0 ? 'private' : 'public'}</Badge>
        {nameText}
        {teamOwner && <div>
          <h4>Owner: </h4>
          <EntityLink entity={teamOwner} type='user'>
            <img className='avatar' alt='vehicle owner' src={teamOwner.avatarUrl || noImage} />
            <span className='avatar-text'>{'@' + teamOwner.handle}</span>
          </EntityLink>
        </div>}
        <ButtonToolbar bsClass='vertical-buttons'>
          {this.isTeamOwner() && <InvitationOverlay
            block
            handleTypes={['user']}
            entity={team}
            excludedUsers={memberExclusions}
            entitySentInvites={teamInvites.list}
          />}
          {!this.isTeamOwner() && joinButton}
        </ButtonToolbar>
        <SocialIcons entity={team} />
        <h5>
          {team.description}
        </h5>
      </div>
    )
  }

  renderTeamStats (summary) {
    const data = summary ? summary.item || {} : {}
    const tempTime = moment.duration(data.sessionsDuration || 0)
    const hours = `${tempTime.hours()}.${Math.floor(tempTime.minutes() / 6)}`
    const stats = {
      'Drivers': data.driversCount,
      'Tracks driven': data.sessionsTracks,
      'Total laps': data.lapsCount,
      'Total hours': hours
    }
    return <StatisticDataList stats={stats} />
  }

  handleSelectInnerNav (eventKey) {
    this.setState({ innerTab: eventKey })
  }

  renderTeamVehicles (vehicles) {
    return <Section>
      <VehicleList
        list={vehicles}
      />
    </Section>
  }

  renderInnerTabs (team) {
    if (!team) { return '' }

    const { members, teamInvites, sessions, selectedTeam, vehicles } = this.props
    const { innerTab } = this.state
    const getCount = (listObject) => {
      return (listObject.headers && listObject.headers.pagination) ? listObject.headers.pagination.totalCount
        : listObject.list ? listObject.list.length : null
    }
    const renderBadge = counter => counter || counter === 0 ? <Badge>{counter}</Badge> : null

    const titleMembers = <span>Members {renderBadge(getCount(members))}</span>
    const titleVehicles = <span>Vehicles {renderBadge(getCount(vehicles))}</span>
    const titleInvites = <span>Invitations {renderBadge(getCount(teamInvites))}</span>
    const titleSessions = <span>Sessions {renderBadge(getCount(sessions))}</span>

    return (
      <Tabs activeKey={innerTab} onSelect={this.handleSelectInnerNav} id='inner_tabs'>
        <Tab eventKey='members' title={titleMembers}>{this.renderTeamMembers(members)}</Tab>
        <Tab eventKey='sessions' title={titleSessions}>{this.renderSessions()}</Tab>
        <Tab eventKey='vehicles' title={titleVehicles}>{this.renderTeamVehicles(vehicles)}</Tab>
        {this.isTeamOwner() && <Tab eventKey='invites' title={titleInvites}>{this.renderInvites(teamInvites)}</Tab>}
        {this.isTeamOwner() && <Tab eventKey='settings' title='Settings'>{this.renderSettings(selectedTeam)}</Tab>}
      </Tabs>
    )
  }

  render () {
    const { selectedTeam, summary } = this.props

    if (selectedTeam.error) {
      return <Error404 />
    }

    return (
      <Row>
        <Col md={3}>
          {this.renderSummary(selectedTeam.item)}
        </Col>
        <Col md={9}>
          <Grid fluid>
            <Row>
              {this.renderTeamStats(summary)}
            </Row>
            {this.renderInnerTabs(selectedTeam.item)}
          </Grid>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    handle: ownProps.match.params.handle,
    selectedTeam: state.teams.selectedTeam,
    members: state.teams.selectedMembers,
    vehicles: state.teams.selectedVehicles,
    sessions: state.teams.selectedSessions,
    summary: state.teams.summary,
    session: state.session,
    teams: state.teams,
    // inviteMembers: state.ui.teams.inviteMembers,
    // confirm: state.ui.general.confirm,
    settings: state.ui.teams.settings,
    teamUiState: state.ui.teams.uiState,
    teamInvites: state.invites.teamInvites
  }
}

export default page(connect(mapStateToProps)(PageTeamProfile), { uiNavigationRoute: ROUTE_TEAMS })

import React, {Component} from 'react'
import {connect} from 'react-redux'
import { uiRedirect } from '../../actions/ui'
import {ROUTE_VEHICLES} from '../../utils/routingConstants'
import {fetchVehicleAction, updateVehicle, vehicleSessionsFetch, fetchVehicleStatsAction} from '../../actions/garageActions'
import { getMeasuringUnitsFromProfile, getSpeed, getSpeedUnitsName } from '../../utils/modelConvertionUtils'
import {find} from 'lodash'
import noImage from '../../images/no_image.jpg'
import {VehicleSettings} from '../'
import { StatisticDataList, Section, SessionList, EntityLink, Error404 } from '../../components'
import {Grid, Row, Col, Tab, Tabs, ButtonToolbar, Button, Badge} from 'react-bootstrap'
import { pageView, GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'
import classnames from 'classnames'

class PageVehicle extends Component {
  constructor (props, context) {
    super(props, context)

    this.loadMoreSessions = this.loadMoreSessions.bind(this)
    this.saveSettingsOperation = this.saveSettingsOperation.bind(this)
  }

  componentWillMount () {
    const {dispatch, selectedVehicle, handle, garage, session} = this.props
    if (!selectedVehicle || !selectedVehicle.item || selectedVehicle.item.handle !== handle) {
      const target = garage.list
        ? find(garage.list, {handle: handle})
        : null

      this.handleNewVehicle(handle, target)
      dispatch(vehicleSessionsFetch())
      dispatch(fetchVehicleStatsAction())
    }

    pageView(GA_PAGES.VEHICLE + handle, session)
  }

  handleNewVehicle (newHandle, target) {
    const { dispatch, session, handle } = this.props

    if (handle && newHandle && handle !== newHandle) {
      // handle was changed - redirect to new vehicle
      dispatch(uiRedirect(`${ROUTE_VEHICLES}/${newHandle}`))
    } else {
      dispatch(fetchVehicleAction(session.token, newHandle, target))
      dispatch(vehicleSessionsFetch(session.token, newHandle))
      dispatch(fetchVehicleStatsAction(session.token, newHandle))
    }
  }

  componentWillReceiveProps (newProps) {
    const { selectedVehicle } = this.props
    const newVehicle = newProps.selectedVehicle

    if (!selectedVehicle.item && newVehicle.item) {
      // skip
    } else if (selectedVehicle.item && newVehicle.item && selectedVehicle.item.handle !== newVehicle.item.handle) {
      this.handleNewVehicle(newVehicle.item.handle)
    }
  }

  isVehicleOwner () {
    const {selectedVehicle, session} = this.props
    if (session.isAnonymous) { return false }
    return selectedVehicle.item && session.profile.id === selectedVehicle.item.ownerId
  }

  renderSummary (vehicle) {
    if (!vehicle) { return '' }

    const tileText = (<div>
      <h4>{vehicle.name}</h4>
      {vehicle.handle && <h5>{'@' + vehicle.handle}</h5>}
    </div>)
    // const aliasText = (
    //   <div>
    //     <h4>Alias:</h4>
    //     <h5>{vehicle.alias}</h5>
    //   </div>
    // )
    const { session } = this.props
    return (
      <div>
        <img className='page__logo' src={vehicle.avatarUrl || noImage} alt='avatar' />
        {session.profile && session.profile.defaultVehicleId === vehicle.id && <Badge>Default vehicle</Badge>}
        <Badge className={classnames('visibility', {'private': (vehicle.visibility === 0)})}>{vehicle.visibility === 0 ? 'private' : 'public'}</Badge>
        {tileText}
        {vehicle.description && <h5>{vehicle.description}</h5>}
        {vehicle.owner && <div>
          <h4>Owner: </h4>
          <EntityLink entity={vehicle.owner} type='user'>
            <img className='avatar' alt='vehicle owner' src={vehicle.owner.avatarUrl || noImage} />
            <span className='avatar-text'>{'@' + vehicle.owner.handle}</span>
          </EntityLink>
        </div>}
      </div>
    )
  }

  renderStats () {
    const {selectedStats, session} = this.props
    const data = selectedStats.item || {}
    const units = getMeasuringUnitsFromProfile(session.profile)

    const topSpeedTitle = 'Top speed, ' + getSpeedUnitsName(units)

    const stats = {
      'Total sessions': data.totalSessions,
      'Total laps': data.totalLaps,
      [topSpeedTitle]: getSpeed(data.topSpeed, units, 1, false),
      'Total hours': Math.round(data.drivedTime / (1000 * 60 * 6)) / 10
    }
    return <StatisticDataList stats={stats} />
  }

  loadMoreSessions ({ startIndex = 0, stopIndex } = {}) {
    const { dispatch, session, selectedSessions, selectedVehicle } = this.props
    const total = selectedSessions.headers ? selectedSessions.headers.pagination.totalCount : -1
    if ((total < 0 || startIndex < total) && !selectedSessions.isFetching) {
      return dispatch(vehicleSessionsFetch(session.token, selectedVehicle.item.handle || selectedVehicle.item.id, startIndex))
    }
  }

  renderSessions () {
    const {selectedSessions} = this.props
    return <Section>
      <SessionList list={selectedSessions} loadMoreRows={this.loadMoreSessions} />
    </Section>
  }

  saveSettingsOperation () {
    const { updatingVehicle, vehicleUIState, selectedVehicle, dispatch, session } = this.props
    const spec = {
      ...selectedVehicle.item,
      ...updatingVehicle,
      rowVersion: selectedVehicle.item.rowVersion,
      owner: null,
      newAvatar: vehicleUIState.avatarPreview || null
    }
    dispatch(updateVehicle(spec, session.token))
  }

  renderSettings () {
    const {vehicleUIState, selectedVehicle} = this.props

    return <Section>
      <VehicleSettings mode='edit' />
      {!selectedVehicle.isFetching && !selectedVehicle.error && <ButtonToolbar bsClass='settings-bottom-buttons'>
        <Button key='Save' bsStyle='primary' disabled={!vehicleUIState.valid} onClick={this.saveSettingsOperation}>Save</Button>
      </ButtonToolbar>}
    </Section>
  }

  renderTabs () {
    return (
      <Tabs id='inner_tabs'>
        <Tab eventKey='history' title='Sessions'>{this.renderSessions()}</Tab>
        {this.isVehicleOwner() && <Tab eventKey='settings' title='Settings'>{this.renderSettings()}</Tab>}
      </Tabs>
    )
  }

  render () {
    const { selectedVehicle } = this.props

    if (selectedVehicle.error) {
      return <Error404 />
    }

    return (
      <Row>
        <Col md={3}>
          {this.renderSummary(selectedVehicle.item)}
        </Col>
        <Col md={9}>
          <Grid fluid>
            <Row>
              {this.renderStats()}
            </Row>
            {this.renderTabs()}
          </Grid>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    handle: ownProps.match.params.handle,
    selectedVehicle: state.garage.selectedVehicle,
    session: state.session,
    garage: state.garage.allVehicles,
    vehicleUIState: state.ui.vehicles.uiState,
    updatingVehicle: state.ui.vehicles.settings,
    selectedSessions: state.garage.selectedSessions,
    selectedStats: state.garage.selectedStats
  }
}

export default page(connect(mapStateToProps)(PageVehicle), {uiNavigationRoute: ROUTE_VEHICLES})

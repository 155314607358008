import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { page } from '../../hocs/page'
import { Section, SignInButton, WistiaViewer } from '../../components'

import { Col, Grid, Button } from 'react-bootstrap'

// j38ihh83m5
const WISTIA_CHT_PROMO_ID = '7gcubqdt5v'
const CHALKTALK_REGISTRATION_URL = 'https://events.genndi.com/register/169105139238465287/fd63aad4d3'

class PageChalkTalk extends Component {
  renderRegistrationButton () {
    const {session} = this.props

    return session.isAnonymous ? <SignInButton bsStyle='info' /> : <Button href={CHALKTALK_REGISTRATION_URL} target='_blank' rel='noopener noreferrer' bsStyle='success'>Access webinar</Button>
  }

  render () {
    return <Section title='Speed Secrets Chalktalk' className='webinar'>
      <div>Exclusively for Track Attack members, Ross Bentley will take on any question you have about driving more consistently and faster.</div>
      <Grid className='webinar__container'>
        <Col sm={7}>
          <WistiaViewer wistiaID={WISTIA_CHT_PROMO_ID} />
        </Col>
        <Col sm={5}>
          <div className='webinar__info'>
            <h3 className='webinar__title'>Next Chalktalk</h3>
            <div className='webinar__datetime'>Tuesday, May 14 2019</div>
            <div className='webinar__datetime'>6pm PST/9pm EST</div>
            {this.renderRegistrationButton()}
            <div className='webinar__free'>It's FREE!</div>
          </div>
        </Col>
      </Grid>
      <div><strong>Webinar Access Link: </strong> Sign in with a (free) Track Attack account</div>
      <div>
        {this.renderRegistrationButton()}
      </div>
      <hr />
      <div className='webinar__about'>
        <h4>Will the webinar be available afterwards?</h4>
        <p>
          Yes! The webinar will be available to all Track Attack members (free and premium) as a membership benefit.
        </p>
      </div>
      <div className='webinar__about'>
        <h4>Will there be more Chalktalks?</h4>
        <p>
          Yes, our goal is to have about 1x per quater and we'll adjust the format and theme based on your interaction and feedback.
        </p>
      </div>
    </Section>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session
  }
}

export default page(withRouter(connect(mapStateToProps)(PageChalkTalk), {pageView: null, uiNavigationRoute: null}))

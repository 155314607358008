import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, FormGroup, FormControl, HelpBlock, ControlLabel, Col, Alert, Row, Panel, Button } from 'react-bootstrap'
import { uiTeamEdit } from '../actions/teamUIActions'
import { isPremiumUser } from '../api/auth0'
import { deleteTeamAction } from '../actions/teamDbActions'
import FA from 'react-fontawesome'
import { Overlay, DropzoneWithImagePreview, HandleEdit, UrlInput, UpgradeLink } from '../components'
import { UI_INPUT_LENGTH } from '../constants'
import { confirmable, createConfirmation } from 'react-confirm'
import Select, { components } from 'react-select'
import * as _ from 'lodash'

class TeamSettings extends Component {
  static FieldGroup ({ id, label, help, validationState, ...props }) {
    return (
      <FormGroup controlId={id || props.name} validationState={validationState} >
        <Col componentClass={ControlLabel} sm={3}>{label}</Col>
        <Col sm={9}>
          <FormControl {...props} />
        </Col>
        {help && <Col sm={9} xsOffset={3}> <HelpBlock>{help}</HelpBlock> </Col>}
      </FormGroup>
    )
  }

  static FieldStub ({ id, label, value, onEdit, ...props }) {
    return (
      <FormGroup controlId={id || props.name} className='settings-field-stub'>
        <Col componentClass={ControlLabel} sm={3}>{label}</Col>
        <Col sm={8}>
          <div className='settings-field-stub_text'>{value}</div>
        </Col>
        <Col sm={1}>
          {onEdit && <a className='settings-field-stub_edit' onClick={onEdit}><FA name='edit' /></a>}
        </Col>
      </FormGroup>
    )
  }

  constructor (props) {
    super(props)
    this.changeHandler = this.handleChange.bind(this)
    this.state = {
      handleValid: true,
      nameValid: true
    }

    this.handleEdited = this.handleEdited.bind(this)
    this.handleCountryChange = this.handleCountryChange.bind(this)
    this.onDrop = this.onDrop.bind(this)

    this.handleWebsiteChange = this.handleUrlChange.bind(this, 'website')
    this.handleInstagramChange = this.handleUrlChange.bind(this, 'instagram')
    this.handleFacebookChange = this.handleUrlChange.bind(this, 'facebook')
    this.handleTwitterChange = this.handleUrlChange.bind(this, 'twitter')

    this.handleDelete = this.handleDelete.bind(this)
  }

  isValid (state = this.state) {
    return state.handleValid && state.nameValid
  }

  componentDidMount () {
    const { dispatch, mode, selectedTeam } = this.props
    if (mode === 'create') {
      dispatch(uiTeamEdit({ name: '', description: '', avatarUrl: '', handle: '', visibility: 1 }, { valid: false }))
      this.setState({
        handleValid: false,
        nameValid: false
      })
    } else {
      dispatch(uiTeamEdit(selectedTeam.item, { valid: true }))
    }
  }

  okDeleteTeamOperation (team) {
    const { dispatch, userSession } = this.props
    dispatch(deleteTeamAction(userSession.token, team.handle))
  }

  handleDelete (event) {
    event.stopPropagation()
    const { selectedTeam } = this.props

    const dialog = ({ show, proceed, dismiss, cancel, confirmation, options }) => {
      const deleteString = `Delete the @${selectedTeam.item.handle} team forever`
      const buttons = Overlay.createButtons([
        { [deleteString]: () => proceed(selectedTeam.item), bsStyle: 'danger' },
        { Cancel: cancel, bsStyle: 'default' }
      ])
      return (<Overlay
        show={show}
        title={deleteString + '?'}
        closeAction={dismiss}
        buttons={buttons}>
        <Alert bsStyle='danger' >
          <p>You are about to delete the @{selectedTeam.item.handle} team forever.</p>
          <p><strong>This action cannot be undone!</strong></p>
        </Alert>
      </Overlay>)
    }

    const confirmer = createConfirmation(confirmable(dialog))
    confirmer('', {}).then(team => this.okDeleteTeamOperation(team), () => { })
  }

  handleChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    let nameValid = true

    if (name === 'name') {
      nameValid = value.length > 0
      this.setState({ nameValid: nameValid })
    }

    this.props.dispatch(
      uiTeamEdit(
        { ...this.props.settings, [name]: value },
        { ...this.props.profileUIState, valid: this.isValid({ ...this.state, nameValid: nameValid }) }
      )
    )
  }

  handleUrlChange (name, value, valid) {
    this.props.dispatch(
      uiTeamEdit(
        { ...this.props.settings, [name]: value },
        { ...this.props.profileUIState }
      )
    )
  }

  handleEdited (handle, valid) {
    this.setState({ handleValid: valid })

    const { dispatch, settings, profileUIState } = this.props
    const newSettingsState = valid ? { ...settings, handle: handle } : settings
    dispatch(uiTeamEdit(
      newSettingsState,
      { ...profileUIState, valid: this.isValid({ ...this.state, handleValid: valid }) }
    ))
  }

  handleCountryChange (country) {
    this.props.dispatch(
      uiTeamEdit(
        { ...this.props.settings, country: country.value },
        { ...this.props.profileUIState }
      )
    )
  }

  onDrop (acceptedFiles, rejectedFiles) {
    if (acceptedFiles && acceptedFiles.length === 1) {
      const { dispatch, profileUIState, settings } = this.props
      dispatch(uiTeamEdit(settings, { ...profileUIState, avatarPreview: acceptedFiles[0] }))
    }
  }

  customOption (props) {
    return <components.Option {...props}>
      <div className='dropdown-item' key={props.data.value}>
        <div><strong>{props.data.label}</strong></div>
        <div className='description'>{props.data.desc}</div>
      </div>
    </components.Option>
  }

  render () {
    const { userSession, settings, selectedTeam, profileUIState, mode } = this.props
    if (!settings || !selectedTeam) {
      return ''
    }

    if (selectedTeam.isFetching) { return <Alert bsStyle='info'>Updating data...</Alert> }

    if (selectedTeam.error) { return <Alert bsStyle='danger'>Failed to save your changes. Please retry</Alert> }

    const oldHandle = mode === 'create' ? null : selectedTeam && selectedTeam.item && selectedTeam.item.handle
    const oldAvatar = mode === 'create' ? null : (selectedTeam.item ? selectedTeam.item.avatarUrl : null)

    const visibilityOptions = [
      { value: 0, label: 'Private', desc: 'Only team members can see the team' },
      { value: 1, label: 'Public', desc: 'Anyone can see the team and it\'s basic stats.' }
    ]

    const selectedV = _.find(visibilityOptions, {value: settings && settings.visibility === 0 ? 0 : 1})

    const full = mode !== 'create'
    return (
      <Form horizontal>
        <Row>
          <Col sm={9}>
            <TeamSettings.FieldGroup
              key='name'
              name='name'
              type='text'
              label='Name'
              maxLength={UI_INPUT_LENGTH.TEAM_NAME}
              value={settings.name || ''}
              onChange={this.changeHandler}
              placeholder="The team's name"
              help={!this.state.nameValid ? 'Choose the name for your team' : null}
              validationState={this.state.nameValid ? 'success' : 'error'}
            />

            <HandleEdit
              onHandleChanged={this.handleEdited}
              apiToken={userSession.token}
              oldHandle={oldHandle} />

            <FormGroup controlId='visibility' validationState={null}>
              <Col componentClass={ControlLabel} sm={3}>{full ? 'Team visibility' : 'Visibility'}</Col>
              <Col sm={9}>
                <div>
                  <Select
                    className='react-select-container'
                    classNamePrefix='react-select'
                    isDisabled={!isPremiumUser(userSession)}
                    components={{ Option: this.customOption }}
                    value={selectedV}
                    options={visibilityOptions}
                    onChange={visibility => this.handleChange({ target: { name: 'visibility', value: visibility.value } })} />
                  {!isPremiumUser(userSession) && <UpgradeLink className='bottom-link'>Go premium to enable private teams</UpgradeLink>}
                </div>
              </Col>
            </FormGroup>

            <TeamSettings.FieldGroup
              key='description'
              name='description'
              type='textarea'
              componentClass='textarea'
              label='Description'
              maxLength={UI_INPUT_LENGTH.DESCRIPTION}
              value={settings.description || ''}
              onChange={this.changeHandler}
              placeholder="Team's description"
              rows={8}
            />

            <UrlInput
              type='facebook'
              value={settings.facebook || ''}
              onChanged={this.handleFacebookChange}
            />

            <UrlInput
              type='instagram'
              value={settings.instagram || ''}
              onChanged={this.handleInstagramChange}
            />

            <UrlInput
              type='twitter'
              value={settings.twitter || ''}
              onChanged={this.handleTwitterChange}
            />

            <UrlInput
              type='url'
              value={settings.website || ''}
              onChanged={this.handleWebsiteChange}
            />

            {full &&
              <div>
                <hr />
                <Panel bsStyle='danger'>
                  <Panel.Heading><Panel.Toggle>Dangerous operations</Panel.Toggle></Panel.Heading>
                  <Panel.Collapse>
                    <Panel.Body>
                      <Alert bsStyle='danger' >
                        <p>Deleting this team permanently removes it from Track Attack and drops all members.
                      While no data is deleted, former members of the team will no longer be able
                      to see data shared with the team.
                        </p>
                        <p>
                          <strong>This action cannot be undone!</strong>
                        </p>
                      </Alert>
                      <Button bsStyle='danger' onClick={this.handleDelete}>Delete this team and drop all members</Button>
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel>
              </div>
            }
          </Col>
          <Col sm={3}>
            <DropzoneWithImagePreview
              onDrop={this.onDrop}
              previewUrl={(profileUIState && profileUIState.avatarPreview && profileUIState.avatarPreview.preview) || oldAvatar}
            />
          </Col>
        </Row>
      </Form>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return { userSession: state.session, selectedTeam: state.teams.selectedTeam, settings: state.ui.teams.settings, profileUIState: state.ui.teams.uiState }
}

export default connect(mapStateToProps)(TeamSettings)

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { InviteForm, Overlay } from './'
import { uiInvite, uiToast } from '../actions/ui'
import { sendInvitesAction, inviteTeamMembersAction } from '../actions/inviteActions'
import { Button, Alert } from 'react-bootstrap'

class InvitationOverlay extends React.Component {
  static propTypes = {
    block: PropTypes.any,
    entity: PropTypes.object.isRequired,
    handleTypes: PropTypes.arrayOf(PropTypes.oneOf([null, 'user', 'team'])),
    excludedUsers: PropTypes.arrayOf(PropTypes.object),
    entitySentInvites: PropTypes.arrayOf(PropTypes.object)
  }

  static defaultProps = {
    handleTypes: ['user']
  }

  constructor (props) {
    super(props)
    this.state = { show: false, alertText: false }
    this.okOperation = this.okOperation.bind(this)
    this.cancelOperation = this.cancelOperation.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.openOverlay = this.openOverlay.bind(this)
  }

  generateOverlayTitle () {
    const { entity, title } = this.props
    if (title) { return title }
    if (entity.type === 'Team') { return `Invite users to the ${entity.name} team` }
    if (entity.type === 'Session') { return 'Share this session with someone' }

    console.error('Unexpected entity type: ' + entity.type)
    return 'Share'
  }

  generateOpenButton () {
    const { entity, block } = this.props
    const btnText = entity.type === 'Team' ? 'Invite' : 'Share'
    return <Button bsStyle='success' block={block} onClick={this.openOverlay}>{btnText}</Button>
  }

  openOverlay (event) {
    event.stopPropagation()
    const { entity } = this.props
    this.setState({ sharableEntity: entity, show: true })
  }

  changeHandler (uiState) {
    const { dispatch } = this.props
    dispatch(uiInvite(uiState))
  }

  render () {
    const { recipients, handleTypes, session, entity, excludedUsers, entitySentInvites } = this.props
    const saveButtonText = entity.type === 'Team' ? 'Invite' : 'Share'
    const userHandles = excludedUsers ? excludedUsers.map((usr) => { return usr.handle }) : []
    const inviteHandles = entitySentInvites ? entitySentInvites.map((inv) => { return inv.recipientObject.handle || inv.recipientObject.email }) : []

    return <div>
      {this.generateOpenButton()}
      {this.state.show && <Overlay
        show
        title={this.generateOverlayTitle()}
        buttons={Overlay.createButtons([{ [saveButtonText]: this.okOperation }, { Cancel: this.cancelOperation }])}
        closeAction={this.cancelOperation}>
        {this.state.alertText && <Alert bsStyle='info'><p>{this.state.alertText}</p></Alert>}
        <InviteForm
          handleTypes={handleTypes}
          exclusions={[...userHandles, ...inviteHandles]}
          token={session.token}
          recipients={recipients}
          changeHandler={this.changeHandler} />
      </Overlay>}
    </div>
  }

  cancelOperation () {
    this.setState({ show: false })
    this.props.dispatch(uiInvite({ emails: [], handles: [], message: '' }))
  }

  okOperation () {
    const { entity, recipients, dispatch } = this.props
    const handleSpecs = recipients.handles.map(handle => this.buildSpec(handle, entity, recipients.message))
    const emailSpecs = recipients.emails.map(email => this.buildSpec(email, entity, recipients.message))
    this.sendInvites(handleSpecs.concat(emailSpecs))
    dispatch(uiInvite({ emails: [], handles: [], message: '' }))
  }

  buildSpec (recipient, entity, message) {
    const recipientSpec = recipient.handle || recipient.value
    return {
      recipientSpec,
      recipientType: recipient.handle ? recipient.type : 'user',
      targetSpec: entity.id,
      targetType: entity.type,
      message
    }
  }

  sendInvites (specs) {
    const { entity, dispatch, session } = this.props
    let workPromise
    switch (entity.type.toLowerCase()) {
      case 'team':
        workPromise = dispatch(inviteTeamMembersAction(session.token, specs))
        break
      case 'session':
        workPromise = dispatch(sendInvitesAction(session.token, specs))
        break
      default:
        workPromise = Promise.reject(new Error('Unexpected entity type: ' + entity.type_))
    }
    return this.notifiedOperation(dispatch, workPromise, "Workin' on it...", `The invitations have been sent`)
  }

  notifiedOperation (dispatch, promise, actionText, doneText, errorText = 'There was an error: ') {
    const timeout = setTimeout(() => this.setState({ ...this.state, alertText: actionText }), 300)
    return promise
      .then(result => {
        return dispatch(uiToast({ text: doneText, bsStyle: 'info' }))
      })
      .catch(error => {
        return dispatch(uiToast({ text: `${errorText}${error.message}`, bsStyle: 'warning' }))
      })
      .then(whatever => {
        clearTimeout(timeout)
        this.setState({ ...this.state, show: false, alertText: null })
      })
  }
}

function mapStateToProps (state) {
  return { recipients: state.ui.invites.recipients, session: state.session }
}

export default connect(mapStateToProps)(InvitationOverlay)

import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  dateStr: PropTypes.string
}

const defaultProps = {
  dateStr: ''
}

const getTextToRender = (dateStr) => {
  if (!dateStr) {
    // invalid date
    return ['-', '', '']
  }

  let date = new Date(dateStr)

  if (isNaN(date.getTime())) {
    // invalid date
    return ['-', '', '']
  }

  return [
    date.toLocaleDateString(),
    '\u00a0',
    date.toLocaleTimeString()
  ]
}

const EventDate = props => (
  <span>
    {getTextToRender(props.dateStr).map((elem, i) => {
      return elem === '\u00a0'
        ? <span key='sep' className='event-date-text-separator'>{elem}</span>
        : <span key={i} className='event-date-text'>{elem}</span>
    })}
  </span>
)

EventDate.propTypes = propTypes

EventDate.defaultProps = defaultProps

export default EventDate

import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import { InfiniteList, InfiniteGrid } from '../../components'
import classnames from 'classnames'
import { hashCode } from '../../utils/utils'

export default class RowEntityList extends React.Component {
  static propTypes = {
    list: PropTypes.object.isRequired,
    listHeight: PropTypes.number,
    rowHeight: PropTypes.number,
    rowRenderer: PropTypes.func.isRequired,
    loadMoreRows: PropTypes.func.isRequired,
    fetchingRenderer: PropTypes.func,
    noRowsRenderer: PropTypes.func,

    header: PropTypes.element,
    isGrid: PropTypes.bool
  }

  static defaultProps = {
    isGrid: false,
    fetchingRenderer: () => <div className={'list-noRows'}>Loading...</div>,
    noRowsRenderer: () => <div className={'list-noRows'}>Nothing found</div>
  }

  constructor (props) {
    super(props)
    this.isRowLoaded = this.isRowLoaded.bind(this)
    this.length = this.length.bind(this)
    this.listLength = this.listLength.bind(this)
  }

  calculateListHash (list) {
    if (!list || !list.list || !list.list[0]) {
      return 'empty'
    }

    if (list.list[0].updatedOn) {
      const h = list.list.reduce((result, value) => { return result + value.updatedOn }, '')
      return hashCode(h) + ''
    } else {
      return (list.list[0].id || list.list[0].sessionId) + ''
    }
  }

  renderList () {
    if (this.isEmpty()) {
      return
    }

    const { loadMoreRows, listHeight, rowRenderer, list, rowHeight } = this.props

    return <InfiniteList
      isRowLoaded={this.isRowLoaded}
      loadMoreRows={loadMoreRows}
      listHeight={listHeight}
      totalRows={this.listLength}
      currentRows={this.length}
      rowRenderer={rowRenderer}
      rowHeight={rowHeight}
      listHash={this.calculateListHash(list)}
    />
  }

  renderGrid () {
    if (this.isEmpty()) {
      return
    }

    const { loadMoreRows, listHeight, rowRenderer, list, rowHeight } = this.props

    return <InfiniteGrid
      isRowLoaded={this.isRowLoaded}
      loadMoreRows={loadMoreRows}
      listHeight={listHeight}
      totalRows={this.listLength}
      currentRows={this.length}
      rowRenderer={rowRenderer}
      rowHeight={rowHeight}
      listHash={this.calculateListHash(list)}
    />
  }

  renderEmptyListContent () {
    const { noRowsRenderer, fetchingRenderer, list } = this.props

    return <Fragment>
      { this.isEmpty() && <div className='list-loading'>
        {list && list.isFetching ? fetchingRenderer() : noRowsRenderer()}
      </div> }
    </Fragment>
  }

  render () {
    const { header, className, isGrid } = this.props

    return (<div className={classnames('rowentitylist', className)}>
      {header && <div className='list-header'>
        {header}
      </div>}
      {this.renderEmptyListContent()}
      { isGrid ? this.renderGrid() : this.renderList() }
    </div>)
  }

  listLength () {
    const { list } = this.props
    return (list.headers && list.headers.pagination) ? list.headers.pagination.totalCount : 0
    // const addField = addButton ? 1 : 0
    // return  addField + (list.headers ? list.headers.pagination.totalCount : 0)
  }

  isEmpty () {
    const {list} = this.props

    return (!list || !list.list || list.list.length === 0)
  }

  isRowLoaded ({ index }) {
    return this.length() > index
  }

  length () {
    const { list } = this.props
    return list && list.list ? list.list.length : 0
  }
}

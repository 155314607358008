import sessionReducer from './sessionReducers'
import uiReducers from './uiReducers'
import resultsReducer from './resultsReducers'
import tracksReducer from './trackDbReducers'
import layoutsReducer from './layoutDbReducers'
import teamsReducer from './teamDbReducers'
import usersReducer from './userDbReducers'
import entityFileReducer from './entityFileReducers'
import garageReducer from './garageReducers'
import inviteReducer from './invitesReducers'
import adminReducers from './adminReducers'
import storeReducers from './storeReducers'
import leaderboards from './leaderboardsReducers'
import notifications from './notificationsReducers'
import news from './blogReducers'
import { LOOKUPS_FETCH_ALL } from '../actions/basicDataActions'
import { combineReducers } from 'redux'
import { default as itemReducer } from './itemReducer'

const rootReducer = combineReducers({
  session: sessionReducer,
  ui: uiReducers,
  results: resultsReducer,
  tracks: tracksReducer,
  teams: teamsReducer,
  users: usersReducer,
  files: entityFileReducer,
  garage: garageReducer,
  lookups: itemReducer(LOOKUPS_FETCH_ALL),
  invites: inviteReducer,
  layouts: layoutsReducer,
  admin: adminReducers,
  store: storeReducers,
  leaderboards,
  news,
  notifications
})

export default rootReducer

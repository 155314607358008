import {
  UI_NAVIGATION,
  UI_SIDEBAR_TOGGLE,
  UI_ANALYTICS_SELECT_ENTITY,
  UI_CONFIRM,
  UI_PROFILE_SETTINGS,
  UI_INVITE,
  UI_REQUEST,
  UI_VEHICLE_EDIT,
  UI_TOAST,
  UI_SESSION_RELATIONSHIPS,
  UI_TEAMS_RELATIONSHIPS,
  UI_VEHICLES_RELATIONSHIPS,
  UI_TRACK_TYPE,
  UI_SIM_PLATFORM,
  UI_REDIRECT,
  UI_TEAMS_FILTER,
  UI_USERS_FILTER,
  UI_VEHICLES_FILTER,
  UI_TRACKS_FILTER,
  UI_SESSION_SETTINGS,
  UI_SESSION_SELECTED_TRACK,
  UI_ADMIN_SELECTED_PRODUCT,
  UI_STORE_FILTER,
  UI_START_ADD_SESSION_EXTRA,
  UI_EDIT_SESSION_EXTRA,
  UI_TCO_PROGRESS
} from '../actions/ui'

import {
  ROUTE_ROOT,
  ROUTE_SESSIONS,
  ROUTE_TRACKS,
  ROUTE_TEAMS,
  ROUTE_USERS,
  ROUTE_VEHICLES,
  ROUTE_DOWNLOADS,
  ROUTE_ROOT_SOLUTIONS,
  ROUTE_IRACING,
  ROUTE_ASSETTOCORSA,
  ROUTE_LEADERBOARDS,
  ROUTE_STORE,
  ROUTE_NEWS
} from '../utils/routingConstants'

import { UI_TEAM_EDIT } from '../actions/teamUIActions'
import { combineReducers } from 'redux'
import { FETCH_PROFILE, UPDATE_PROFILE } from '../actions/sessionActions'
import { ZIPPING_SESSION_SOURCE_FILE } from '../actions/entityFileActions'
import { default as itemReducer } from './itemReducer'
import { guid } from '../utils/utils'
import { FILE_CATEGORY_TYPES, SIMULATOR_PLATFORM, PRODUCT_FILE_TYPES } from '../api/apiConstants'

// items are [ 'name', ... ]
const initialStateHeader = {
  sidebarVisible: false
}

const initialAnalyticsBoard = {
  selected: {
    lap: null,
    session: null
  }
}

/**
 * protected:
 * -1 - only public
 * 0 - common
 * 1 - only protected
 * 2 - only admins
 */
const initialStateNavigation = [{
  title: 'Home',
  to: ROUTE_ROOT,
  protected: -1,
  isSelected: true,
  className: 'home'
},
{
  title: 'Data',
  to: ROUTE_SESSIONS,
  protected: 0,
  id: 'data',
  subItems: [
    {
      title: 'Sessions',
      to: ROUTE_SESSIONS
    },
    {
      title: 'Tracks',
      to: ROUTE_TRACKS
    },
    {
      title: 'Vehicles',
      to: ROUTE_VEHICLES
    },
    {
      title: 'Teams',
      to: ROUTE_TEAMS
    },
    {
      title: 'Users',
      to: ROUTE_USERS
    }
  ]
},
{
  title: 'Product',
  to: ROUTE_ROOT_SOLUTIONS,
  protected: -1,
  id: 'product',
  subItems: [
    {
      title: 'For Coaches',
      to: `${ROUTE_ROOT_SOLUTIONS}/coaches`
    },
    {
      title: 'For Teams',
      to: `${ROUTE_ROOT_SOLUTIONS}/teams`
    },
    {
      title: 'For Track Days',
      to: `${ROUTE_ROOT_SOLUTIONS}/trackdays`
    },
    {
      title: 'For Club Racers',
      to: `${ROUTE_ROOT_SOLUTIONS}/clubracers`
    },
    {
      title: 'iRacing support',
      to: ROUTE_IRACING
    },
    {
      title: 'Assetto Corsa support',
      to: ROUTE_ASSETTOCORSA
    },
    {
      title: 'Downloads',
      to: ROUTE_DOWNLOADS
    }
  ]
},
{
  title: 'Store',
  to: ROUTE_STORE,
  protected: 0
},
{
  title: 'Leaderboards',
  to: ROUTE_LEADERBOARDS,
  protected: 0
},
{
  title: 'Downloads',
  to: ROUTE_DOWNLOADS,
  protected: 0
},
{
  title: 'News',
  to: ROUTE_NEWS,
  protected: 0
}
]

const initialTeamUI = {
  settings: {
    handle: '',
    name: '',
    description: ''
  },
  uiState: {
    valid: true,
    avatarPreview: null
  },
  inviteMembers: {
    show: false
  },
  filter: '',
  relationships: 'shared'
}

const initialInvites = {
  recipients: { handles: [], emails: [] },
  show: false
}

const initialRequests = {
  requests: {},
  show: false
}

const initialProfileSettings = {
  settings: {
    handle: '',
    firstName: '',
    lastName: '',
    email: '',
    city: '',
    country: '',
    unitMeasure: ''
  },
  uiState: {
    valid: true,
    avatarPreview: null
  }
}

const initialSessions = {
  settings: {
    driver: null,
    track: null,
    vehicle: null,
    name: '',
    description: ''
  },
  selectedTrack: {},
  uiState: {
    valid: true
  },
  zippingSourceFile: false
  // relationships: "shared"
}

const initialUserUI = {
  filter: ''
}

const initialTracks = {
  trackType: null,
  simulatorPlatform: SIMULATOR_PLATFORM.All,
  filter: ''
}

const initialVehicleSettings = {
  settings: {
    handle: '',
    name: ''
  },
  uiState: {
    valid: true
  },
  filter: '',
  relationships: null
}

const initialGeneralUI = {
  confirm: {
    show: false
  }
}

const initialAdminState = {
  selectedProduct: {}
}

const initialToast = {
}

const initialRedirect = {
}

const initialStoreFilter = {
  search: '',
  purchased: true,
  productType: PRODUCT_FILE_TYPES.UNDEFINED
}

const initialEntityFiles = {
  selected: null
}

const initialTCO = {
  progress: 0,
  name: null
}

const header = (state = initialStateHeader, action) => {
  switch (action.type) {
    case UI_SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarVisible: !state.sidebarVisible
      }
    case UI_NAVIGATION:
      return {
        ...state,
        sidebarVisible: false
      }
    default:
      return state
  }
}

const navigation = (state = initialStateNavigation, action) => {
  switch (action.type) {
    case UI_NAVIGATION:
      const selected = action.to
      return state.map(nav => {
        return {
          ...nav,
          isSelected: selected.to === nav.to
        }
      })
    default:
      return state
  }
}

const analyticsBoard = (state = initialAnalyticsBoard, action) => {
  switch (action.type) {
    case UI_ANALYTICS_SELECT_ENTITY:
      return {
        ...state,
        selected: {
          ...action.selected
        }
      }
    default:
      return state
  }
}

const selectedTrack = itemReducer(UI_SESSION_SELECTED_TRACK)
const sessions = (state = initialSessions, action) => {
  switch (action.type) {
    case UI_SESSION_RELATIONSHIPS:
      return { ...state, relationships: action.relationships }
    case UI_SESSION_SETTINGS:
      if (!action.settings.track) { return { ...state, settings: { ...action.settings, layout: null }, uiState: action.uiState } }
      return { ...state, settings: action.settings, uiState: action.uiState }
    case UI_SESSION_SELECTED_TRACK:
      const newTrackState = selectedTrack(state.selectedTrack, action)
      if (!action.result) { return { ...state, selectedTrack: newTrackState } }
      const oldTrack = state.selectedTrack && state.selectedTrack.item
      const newTrack = newTrackState.item
      if ((!oldTrack && newTrack) || (newTrack && newTrack.id !== oldTrack.id)) {
        const newLayout = action.result.layouts && action.result.layouts[0]
        const newSettings = { ...state.settings, layout: { label: newLayout.name, value: newLayout.id } }
        return { ...state, selectedTrack: newTrackState, settings: newSettings }
      }
      if (!newTrack) { return { ...state, selectedTrack: newTrackState, settings: { ...state.settings, layout: null } } }
      return { ...state, selectedTrack: newTrackState }
    case ZIPPING_SESSION_SOURCE_FILE:
      return {...state, zippingSourceFile: action.zipping}
    default:
      return state
  }
}

const tracks = (state = initialTracks, action) => {
  switch (action.type) {
    case UI_TRACK_TYPE:
      return { ...state, trackType: action.trackType }
    case UI_SIM_PLATFORM:
      return { ...state, simulatorPlatform: action.simulatorPlatform }
    case UI_TRACKS_FILTER:
      return { ...state, filter: action.filter }
    default:
      return state
  }
}

const teams = (state = initialTeamUI, action) => {
  switch (action.type) {
    case UI_TEAM_EDIT:
      return { ...state, settings: action.settings, uiState: action.uiState }
    case UI_TEAMS_FILTER:
      return { ...state, filter: action.filter }
    case UI_TEAMS_RELATIONSHIPS:
      return { ...state, relationships: action.relationships }
    default:
      return state
  }
}

const users = (state = initialUserUI, action) => {
  switch (action.type) {
    case UI_USERS_FILTER:
      return { ...state, filter: action.filter }
    default:
      return state
  }
}

const invites = (state = initialInvites, action) => {
  switch (action.type) {
    case UI_INVITE:
      return {
        ...state,
        recipients: { ...state.recipients, ...action }
      }
    default:
      return state
  }
}

const requests = (state = initialRequests, action) => {
  switch (action.type) {
    case UI_REQUEST:
      return {
        ...state,
        requests: { ...state.requests, ...action }
      }
    default:
      return state
  }
}

const profile = (state = initialProfileSettings, action) => {
  switch (action.type) {
    case UI_PROFILE_SETTINGS:
      return { ...state, settings: action.settings, uiState: action.uiState }
    case FETCH_PROFILE:
    case UPDATE_PROFILE:
      return action.result ? { ...state, settings: { ...state.settings, rowVersion: action.result.rowVersion } } : state
    default:
      return state
  }
}

const vehicles = (state = initialVehicleSettings, action) => {
  switch (action.type) {
    case UI_VEHICLE_EDIT:
      return { ...state, settings: action.settings, uiState: action.uiState }
    case UI_VEHICLES_FILTER:
      return { ...state, filter: action.filter }
    case UI_VEHICLES_RELATIONSHIPS:
      return { ...state, relationships: action.relationships }
    // case FETCH_PROFILE:
    // case UPDATE_PROFILE:
    //   return action.result ? { ...state, vehicle: { ...state.vehicle, rowVersion: action.result.rowVersion } } : state
    default:
      return state
  }
}

const general = (state = initialGeneralUI, action) => {
  switch (action.type) {
    case UI_CONFIRM:
      return {
        ...state,
        confirm: action.confirm
      }
    default:
      return state
  }
}

const toast = (state = initialToast, action) => {
  switch (action.type) {
    case UI_TOAST:
      return {
        ...action.toast,
        text: action.toast ? action.toast.text : null
      }
    default:
      return state
  }
}

const redirect = (state = initialRedirect, action) => {
  switch (action.type) {
    case UI_REDIRECT:
      return {
        ...state,
        to: action.to
      }
    default:
      return state
  }
}

const admin = (state = initialAdminState, action) => {
  switch (action.type) {
    case UI_ADMIN_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.product
      }
    default:
      return state
  }
}

const storeFilter = (state = initialStoreFilter, action) => {
  switch (action.type) {
    case UI_STORE_FILTER:
      if (action.filter) {
        return {...state, ...action.filter}
      } else {
        return initialStoreFilter
      }
    default:
      return state
  }
}

const entityFiles = (state = initialEntityFiles, action) => {
  switch (action.type) {
    case UI_EDIT_SESSION_EXTRA:
      return {...state, selected: action.entityFile || null}
    case UI_START_ADD_SESSION_EXTRA:
      // create entity file stub
      return {
        ...state,
        selected: action.file ? {
          descr: action.file.name,
          entityId: action.session.id,
          entityType: 'session',
          id: guid(),
          isPublic: false,
          order: 0,
          size: action.file.size,
          status: 0,
          typeCategoryId: FILE_CATEGORY_TYPES.EXTRAS,
          typeSubCategoryId: FILE_CATEGORY_TYPES.EXTRAS,
          file: action.file
        } : null
      }
    default: return state
  }
}

const tco = (state = initialTCO, action) => {
  switch (action.type) {
    case UI_TCO_PROGRESS:
      return {
        ...state,
        name: action.name,
        progress: action.progress
      }
    default: return state
  }
}

export default combineReducers({
  header,
  navigation,
  analyticsBoard,
  teams,
  invites,
  requests,
  profile,
  general,
  vehicles,
  toast,
  redirect,
  sessions,
  tracks,
  users,
  admin,
  storeFilter,
  entityFiles,
  tco
})

import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl, HelpBlock, ControlLabel } from 'react-bootstrap'
import { HandleSelect, EmailSelect } from './'
import {UI_INPUT_LENGTH} from '../constants'

export default class InviteForm extends React.Component {
  static propTypes = {
    changeHandler: PropTypes.func.isRequired,
    exclusions: PropTypes.arrayOf(PropTypes.string),
    explanation: PropTypes.string,
    handleTypes: PropTypes.arrayOf(PropTypes.string),
    messaage: PropTypes.string,
    recipients: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired
  }

  static defaultProps = {
  }

  static FieldGroup ({ id, label, help, ...props }) {
    return (
      <FormGroup controlId={id}>
        <ControlLabel>{label}</ControlLabel>
        <FormControl {...props} />
        {help && <HelpBlock>{help}</HelpBlock>}
      </FormGroup>
    )
  }

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.props.changeHandler({ [name]: value })
  }

  render () {
    const { exclusions, explanation, handleTypes, token, changeHandler, recipients } = this.props
    const emailsHandler = value => changeHandler({ emails: value })
    const handlesHandler = value => changeHandler({ handles: value })
    const typeString = handleTypes
      ? (handleTypes.length === 1 ? handleTypes[0] + 's' : `${handleTypes[0]}s or ${handleTypes[1]}s`)
      : 'users or teams'
    const userTeam = `Invite existing ${typeString} by handle`

    return (
      <Form>
        {explanation && <FormControl.Static>{explanation}</FormControl.Static>}
        <h5>Invite users by email</h5>
        <FormGroup title='Invite users by email'>
          <EmailSelect exclusions={exclusions} emails={recipients.emails} changeHandler={emailsHandler} />
        </FormGroup>

        <h5>{userTeam}</h5>
        <FormGroup title={userTeam}>
          <HandleSelect exclusions={exclusions} value={recipients.handles} types={handleTypes} changeHandler={handlesHandler} token={token} />
        </FormGroup>
        <InviteForm.FieldGroup
          name='message'
          type='text'
          label='Message'
          value={recipients.message || ''}
          onChange={this.handleChange}
          placeholder='Message to go along with this invitation'
          maxLength={UI_INPUT_LENGTH.MESSAGE}
        />
      </Form>
    )
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {handleAuthentication} from '../../api/auth0'
import { login, logout } from '../../actions/sessionActions'
import { Redirect } from 'react-router-dom'
import { ROUTE_SESSIONS, ROUTE_ROOT, ROUTE_USERS } from '../../utils/routingConstants'

class PageAuthCallback extends Component {
  componentWillMount () {
    const {session} = this.props
    // stop!
    // dont do anything if the session is ok!
    if (session.isAnonymous) {
      handleAuthentication(this.onLoginSuccess.bind(this), this.onLoginError.bind(this))
    }
  }

  onLoginSuccess (authResult) {
    const {dispatch} = this.props

    dispatch(login(authResult.idToken, authResult))
  }

  onLoginError (err) {
    const {dispatch} = this.props

    dispatch(logout(err))
  }

  render () {
    const {session} = this.props

    if (session.error) {
      return <Redirect to={ROUTE_ROOT} />
    }

    if (!session.isAnonymous) {
      const appStateObj = session.authResult.appState

      if (appStateObj && appStateObj.to === 'invites') {
        // -- special case for 'invites' --
        if (!session.profile.handle) {
          // wait for handle!
          return (
            <div className='loader-container'>
              <div className='loader'>Loading...</div>
            </div>
          )
        }
        return <Redirect to={`${ROUTE_USERS}/${session.profile.handle}?tab=invites`} />
      } else {
        return <Redirect to={(appStateObj && appStateObj.to) || ROUTE_SESSIONS} />
      }
    }

    return (
      <div className='loader-container'>
        <div className='loader'>Loading...</div>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return { session: state.session }
}

export default connect(mapStateToProps)(PageAuthCallback)

import React from 'react'
import {PropTypes} from 'prop-types'
import wheel from '../images/steering-wheel.png'

const propTypes = {
  rotation: PropTypes.number
}

const defaultProps = {
  rotation: 0
}

const SteeringWheel = ({rotation}) => (
  <div className='steeringwheel'>
    <img
      alt=''
      src={wheel}
      style={{
        width: '100%',
        transform: `rotate(${rotation}deg)`
      }} />
  </div>
)

SteeringWheel.propTypes = propTypes

SteeringWheel.defaultProps = defaultProps

export default SteeringWheel

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { ROUTE_NEWS } from '../../utils/routingConstants'
import { Section, NewsItemCard } from '../../components'
import { fetchNewsArticle } from '../../actions/blogActions'
import { GA_PAGES, pageView } from '../../api/gaHelper'
import { page } from '../../hocs/page'
import moment from 'moment'
import {unzip, chunk} from 'lodash'
import { isMobile, decodeHtml } from '../../utils/utils'
import {Helmet} from 'react-helmet'

const COL_COUNT = isMobile() ? 1 : 2

class PageNewsArticle extends Component {
  componentDidMount () {
    const {dispatch, id, session} = this.props

    dispatch(fetchNewsArticle(id))
    pageView(`${GA_PAGES.NEWS}/${id}`, session)
  }

  componentWillReceiveProps (newProps) {
    const {dispatch, id, session} = this.props
    const newId = newProps.id

    if (id && id !== newId) {
      dispatch(fetchNewsArticle(newId))
      pageView(`${GA_PAGES.NEWS}/${newId}`, session)
      // make page scroll up after navigation happens
      document.getElementById('nav_header').scrollIntoView()
    }
  }

  renderArticle () {
    const {article} = this.props

    if (article.isFetching) {
      return <div className='loading-message'>
        Loading article...
      </div>
    }

    if (!article.item) {
      return null
    }

    const mDate = moment(article.item.date)
    const date = mDate.isValid() ? mDate.format('MMMM DD, YYYY') : article.item.date

    const imageSrc = article.item.jetpack_featured_media_url || (article.item.img && article.item.img.src)

    return <article>
      <Helmet>
        <title>{decodeHtml(article.item.title.rendered || article.item.title)}</title>
      </Helmet>
      <h1 dangerouslySetInnerHTML={{ __html: article.item.title.rendered }} />
      <div className='newsarticle__date'>{date}</div>
      {imageSrc && <img className='newsarticle__image' src={imageSrc} alt='Article cover' />}
      <article dangerouslySetInnerHTML={{ __html: article.item.content.rendered }} />
    </article>
  }

  renderRelatedArticles () {
    const {article} = this.props

    if (article.isFetching || !article.item) {
      return null
    }

    const articlesArray = article.item['jetpack-related-posts']

    if (!articlesArray || articlesArray.length === 0) {
      return null
    }

    const chunkedList = unzip(chunk(articlesArray, COL_COUNT))

    return <Fragment>
      <h3>Related articles</h3>
      <div className='newspage__col__container'>
        { chunkedList.map(columnset => <div key={columnset[0].url} className='newspage__col'>
          {columnset.map(item => item ? <NewsItemCard newsitem={item} key={item.id} /> : null)}
        </div>) }
      </div>
    </Fragment>
  }

  render () {
    return <Section className='newspage newsarticle'>
      {this.renderArticle()}
      {this.renderRelatedArticles()}
    </Section>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    news: state.news.news,
    session: state.session,
    id: ownProps.match.params.id,
    article: state.news.article
  }
}

export default page(connect(mapStateToProps)(PageNewsArticle), { uiNavigationRoute: ROUTE_NEWS })

// import {
//   ENTITY_FILE_UPLOAD
// } from '../actions/entityFileActions'

// object with entityFileId key for each file
const initialStateFiles = {
  empty: true
}

const files = (state = initialStateFiles, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default files

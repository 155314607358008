import React, { Component } from 'react'
import { connect } from 'react-redux'
import { login, logout } from '../../actions/sessionActions'
import { Link } from 'react-router-dom'
import { ROUTE_USERS, ROUTE_ROOT } from '../../utils/routingConstants'
import { Section } from '../../components'
import { Row, Col, Alert } from 'react-bootstrap'
import { pageView, GA_PAGES } from '../../api/gaHelper'

/**
 * CALLBACK page
 * checks current profile state
 * accepts 1 hash parameter 'id_token'
*/
class PageProfileCheck extends Component {
  constructor (props) {
    super(props)
    this.state = {
      noToken: false
    }
  }

  componentWillMount () {
    const { session, dispatch } = this.props

    if (session.isAnonymous) {
      // console.log("0 handle params")
      this.handleUrlParams()
    } else {
      // console.log("0 log out")
      dispatch(logout(null, false))
    }

    pageView(GA_PAGES.PROFILECHECK)
  }

  componentWillReceiveProps (newProps) {
    const { session } = this.props
    if (!session.isAnonymous && newProps.session.isAnonymous) {
      // we have just logout
      // console.log("1 logged out")
      this.handleUrlParams()
    }

    // if (session.isAnonymous && !newProps.session.isAnonymous) {

    //   //in 5 seconds redirect to home
    //   setTimeout(function() {
    //     history.push(ROUTE_ROOT)
    //   }, 5000)
    // }
  }

  handleUrlParams () {
    const { dispatch } = this.props

    // console.log("2", window.location)
    // console.log("2: do handle params")

    const token = window.location.hash && window.location.hash.replace('#id_token=', '')
    if (!token) {
      // console.log("3: NO token detected")
      this.setState({ noToken: true })
    } else {
      // console.log("3: token detected. try log in.")
      const fakeAuthResult = { idToken: token }
      dispatch(login(token, fakeAuthResult))
    }
  }

  render () {
    const { session } = this.props
    const { noToken } = this.state

    const hideLoader = (session.profile && session.profile.handle) || noToken || session.error

    const renderInternalMessage = () => {
      // success!
      if (session.profile && session.profile.handle) {
        return <Alert bsStyle='success'>
          <div>Thanks, for finishing up your <Link to={`${ROUTE_USERS}/${session.profile.handle}`}>profile</Link></div>
          <div>You can start exploring the <Link to={ROUTE_ROOT}>TrackAttack website</Link> or close this page and go back to your client app</div>
        </Alert>
      }

      if (noToken || session.error) {
        return <Alert bsStyle='danger'>
          <div>Unable to verify your profile. Close this window and try logging in again. If the issue persists please contact us.</div>
        </Alert>
      }

      return <Alert bsStyle='info'>
        <div>Verifying your profile...</div>
      </Alert>
    }

    return (
      <div className='loader-container'>
        <Row>
          <Col sm={8} smOffset={2} >
            <Section title='Account verifcation'>
              {!hideLoader && <div className='loader'>Loading...</div>}
              {renderInternalMessage()}
            </Section>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return { session: state.session }
}

export default connect(mapStateToProps)(PageProfileCheck)

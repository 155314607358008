import { fetchCurrentKPI, fetchHistoricalKPI, fetchCurrentParsersKPI, fetchHistoricaParserslKPI, fetchSubscriptionsKPI, fetchHistoricalSubscriptionsKPI } from '../api'
import { asyncActions } from './index'
import { getParserData } from '../api/apiConstants'
import moment from 'moment'
export const KPI_FETCH_CURRENT = 'KPI_FETCH_CURRENT'
export const KPI_FETCH_SUBSCRIPTIONS = 'KPI_FETCH_SUBSCRIPTIONS'
export const KPI_FETCH_SUBSCRIPTIONS_HISTORICAL = 'KPI_FETCH_SUBSCRIPTIONS_HISTORICAL'
export const KPI_FETCH_HISTORICAL = 'KPI_FETCH_HISTORICAL'

export const KPI_FETCH_PARSERS_CURRENT = 'KPI_FETCH_PARSERS_CURRENT'
export const KPI_FETCH_PARSERS_HISTORICAL = 'KPI_FETCH_PARSERS_HISTORICAL'

export function fetchCurrentKPIAction (token) {
  return dispatch => {
    const actions = asyncActions(KPI_FETCH_CURRENT)
    dispatch(actions.start())
    return fetchCurrentKPI(token).then(
      result => dispatch(actions.success(result)),
      error => dispatch(actions.error(error))
    )
  }
}

export function fetchSubscriptionsKPIAction (token) {
  return dispatch => {
    const actions = asyncActions(KPI_FETCH_SUBSCRIPTIONS)
    dispatch(actions.start())
    return fetchSubscriptionsKPI(token).then(
      result => dispatch(actions.success(result)),
      error => dispatch(actions.error(error))
    )
  }
}

export function fetchCurrentParsersKPIAction (token, lookups) {
  return dispatch => {
    const actions = asyncActions(KPI_FETCH_PARSERS_CURRENT)
    dispatch(actions.start())
    return fetchCurrentParsersKPI(token).then(
      result => {
        // assume 1-element array here
        if (result.length === 1) {
          dispatch(actions.success(result[0].stats.map((item) => {
            return { ...item, ...getParserData(item.parserCategoryId, lookups && lookups.typeSubCategories) }
          })))
        } else {
          dispatch(actions.error({message: 'Unexpected server response'}))
        }
      },
      error => dispatch(actions.error(error))
    )
  }
}

function fetchKPIHistorical (token, dates) {
  return dispatch => {
    const actions = asyncActions(KPI_FETCH_HISTORICAL)
    dispatch(actions.start())
    return fetchHistoricalKPI(token, dates).then(
      result => dispatch(actions.success(result)),
      error => dispatch(actions.error(error))
    )
  }
}

function fetchKPISubscriptionsHistorical (token, dates) {
  return dispatch => {
    const actions = asyncActions(KPI_FETCH_SUBSCRIPTIONS_HISTORICAL)
    dispatch(actions.start())
    return fetchHistoricalSubscriptionsKPI(token, dates).then(
      result => dispatch(actions.success(result)),
      error => dispatch(actions.error(error))
    )
  }
}

function fetchParsersKPIHistorical (token, lookups, dates) {
  return dispatch => {
    const actions = asyncActions(KPI_FETCH_PARSERS_HISTORICAL)
    dispatch(actions.start())
    return fetchHistoricaParserslKPI(token, dates).then(
      result => {
        if (result.length) {
          dispatch(actions.success(result.map((item) => {
            return {
              ...item,
              stats: item.stats.map(singleItem => { return {...singleItem, ...getParserData(singleItem.parserCategoryId, lookups && lookups.typeSubCategories)} })
            }
          })))
        } else {
          dispatch(actions.error({message: 'Unexpected server response'}))
        }
      },
      error => dispatch(actions.error(error))
    )
  }
}

export function fetchKPIMonths (token, months = 12) {
  let dates = []
  let d = moment()

  d.days(0)

  for (let i = 0; i < months; i++) {
    dates.push(d.toDate())
    d.subtract(1, 'months')
  }

  return fetchKPIHistorical(token, dates.reverse())
}

export function fetchKPIDaysFromCurrent (token, days = 21) {
  let dates = []
  let d = moment()

  for (let i = 0; i < days; i++) {
    dates.push(d.toDate())
    d.subtract(1, 'days')
  }

  return fetchKPIHistorical(token, dates.reverse())
}

export function fetchParsersKPIMonths (token, lookups, months = 12) {
  let dates = []
  let d = moment()

  d.days(0)

  for (let i = 0; i < months; i++) {
    dates.push(d.toDate())
    d.subtract(1, 'months')
  }

  return fetchParsersKPIHistorical(token, lookups, dates.reverse())
}

export function fetchParsersKPIDaysFromCurrent (token, lookups, days = 21) {
  let dates = []
  let d = moment()

  for (let i = 0; i < days; i++) {
    dates.push(d.toDate())
    d.subtract(1, 'days')
  }

  return fetchParsersKPIHistorical(token, lookups, dates.reverse())
}

export function fetchKPISubscritionsDaysFromCurrent (token, days = 21) {
  let dates = []
  let d = moment()

  for (let i = 0; i < days; i++) {
    dates.push(d.toDate())
    d.subtract(1, 'days')
  }

  return fetchKPISubscriptionsHistorical(token, dates.reverse())
}

export function fetchKPISubscritionsMonths (token, months = 12) {
  let dates = []
  let d = moment()

  d.days(0)

  for (let i = 0; i < months; i++) {
    dates.push(d.toDate())
    d.subtract(1, 'months')
  }

  return fetchKPISubscriptionsHistorical(token, dates.reverse())
}

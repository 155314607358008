import React from 'react'
import { Button } from 'react-bootstrap'
import { login } from '../api/auth0'

const loginHandler = (e) => {
  e.stopPropagation()
  login({to: window.location.pathname})
}

const SignInButton = ({block, text = 'Sign In', isLink, linkClassName, bsStyle = 'warning'}) => {
  if (isLink) {
    return <a className={linkClassName} onClick={loginHandler}>{text}</a>
  }

  return <Button block={block} bsStyle={bsStyle} onClick={loginHandler}>{text}</Button>
}

export default SignInButton

import {
  TRACKS_FETCH,
  SINGLE_TRACK_FETCH,
  TRACKS_FEATURED_FETCH,
  TRACKS_COUNT,
  TRACKS_NEARBY_FETCH,
  TRACKS_RECENT_FETCH,
  TRACKS_POPULAR_FETCH,
  TRACKS_INSERT_NEW,
  TRACKS_UPDATE,
  TRACKS_DELETE,
  TRACKS_UPLOAD_AVATAR,
  TRACK_FETCH_FASTEST_LAPS
} from '../actions/trackDbActions'
import { combineReducers } from 'redux'
import { default as listReducer } from './listReducer'
import { default as itemReducer, initialState as initialItemState } from './itemReducer'

const selectedFastestLaps = (state = initialItemState, action) => {
  if ((!state.item && !action.filter) || (action.context && action.context.clear)) {
    // default item reducer
    return itemReducer(TRACK_FETCH_FASTEST_LAPS)(state, action)
  }

  if (action.type === TRACK_FETCH_FASTEST_LAPS) {
    // filtered!

    const layoutId = action.result && action.result.filter && action.result.filter.layoutId
    const innerState = state.item && state.item[layoutId]
    const appendMe = action.result && action.result[layoutId]

    if (!(layoutId && innerState && appendMe && innerState.list && appendMe.list)) {
      return state
    }

    // modify state
    return {
      ...state,
      item: {
        ...state.item,
        [layoutId]: {
          // append items to list
          ...appendMe,
          list: [...innerState.list, ...appendMe.list]
        }
      }
    }
  } else {
    return state
  }
}

export default combineReducers({
  count: itemReducer(TRACKS_COUNT),
  featured: itemReducer(TRACKS_FEATURED_FETCH),
  selected: itemReducer(SINGLE_TRACK_FETCH),
  nearby: listReducer(TRACKS_NEARBY_FETCH),
  popular: listReducer(TRACKS_POPULAR_FETCH),
  recent: listReducer(TRACKS_RECENT_FETCH),
  all: listReducer(TRACKS_FETCH),
  insert: itemReducer(TRACKS_INSERT_NEW),
  update: itemReducer(TRACKS_UPDATE),
  _delete: itemReducer(TRACKS_DELETE),
  avatar: itemReducer(TRACKS_UPLOAD_AVATAR),
  selectedFastestLaps
})

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { getDuration, getFastestLapTime, getSlowestLapTime } from '../../utils/modelConvertionUtils'
import * as _ from 'lodash'
import classnames from 'classnames'
import FA from 'react-fontawesome'
import { updateSessionAction } from '../../actions/resultsActions'

// const LAP_TIME_BREAKDOWN = 1.07 //107%

let updState

class BarLapList extends Component {
  static propTypes = {
    session: PropTypes.object.isRequired,
    noRowsRenderer: PropTypes.func,
    isOwner: PropTypes.bool
  }

  static defaultProps = {
    noRowsRenderer: () => { return <div>No rows were recordered for this session</div> },
    isOwner: false
  }

  constructor (props) {
    super(props)
    this.state = {
      renderWidth: false
    }

    this.swapLapHiddenState = this.swapLapHiddenState.bind(this)
  }

  componentDidMount () {
    const { session } = this.props

    if (session.laps.length) {
      const updateState = () => {
        this.setState({ renderWidth: true })
      }
      updState = setTimeout(updateState, 250)
    }
  }

  componentWillReceiveProps (newProps) {
    const newSession = newProps.session
    const oldSession = this.props.session

    // 1. session may be replaced
    // 2. session may be loaded without the laps at first

    if (oldSession.id !== newSession.id) {
      updState && clearTimeout(updState)
      updState = null
      this.setState({renderWidth: false})

      if (newSession.laps.length) {
        const updateState = () => {
          this.setState({ renderWidth: true })
        }
        updState = setTimeout(updateState, 250)
      }

      return
    }

    if (!oldSession.laps.length && newSession.laps.length) {
      updState && clearTimeout(updState)
      updState = null
      this.setState({renderWidth: false})

      const updateState = () => {
        this.setState({ renderWidth: true })
      }
      updState = setTimeout(updateState, 250)
    }
  }

  swapLapHiddenState (lap) {
    const {userSession, dispatch, session} = this.props

    const spec = { ...session,
      driver: null,
      track: null,
      layout: null,
      vehicle: null
    }

    const lapIdx = _.findIndex(spec.laps, {order: lap.order})

    spec.laps = [
      ...spec.laps.slice(0, lapIdx),
      {...spec.laps[lapIdx], hidden: !spec.laps[lapIdx].hidden},
      ...spec.laps.slice(lapIdx + 1)
    ]

    // fix fastest
    spec.fastestLap = getFastestLapTime(spec)

    dispatch(updateSessionAction(userSession.token, spec, {toast: false}))
  }

  calculateLapColor (lap, fastestLapTime, slowestLapTime) {
    if (lap.hidden) {
      return 'hsl(60, 0%, 75%)'
    }

    if (lap.duration >= slowestLapTime) {
      return 'red'
    }

    if (lap.duration === fastestLapTime) {
      return 'forestgreen'
    }

    const h = Math.min(Math.floor(120 - 120 * (lap.duration - fastestLapTime) / (slowestLapTime - fastestLapTime)))
    const s = '100%'
    const l = '50%'

    return `hsl(${h},${s},${l})`
  }

  calculateLapStyle (lap, fastestLapTime, slowestLapTime) {
    if (lap.hidden) {
      return 'hidden-lap'
    }

    if (fastestLapTime === slowestLapTime) {
      return 'normal'
    }

    if (lap.duration === fastestLapTime) {
      return 'fast-lap'
    }

    if (lap.duration >= slowestLapTime) {
      return 'slow-lap'
    }

    return `normal-lap`
  }

  renderLapBar (lap, fastestLapTime, slowestLapTime) {
    const {isOwner} = this.props

    const order = lap.lapType === 1 ? 'OUT'
      : lap.lapType === -1 ? 'IN' : lap.order

    const percentWidth = (lap.duration <= 0 || isNaN(lap.duration)) ? 0
      : fastestLapTime === slowestLapTime ? 50
        : Math.max(10,
          Math.min(100, 10 + Math.floor(90 * (lap.duration - fastestLapTime) / (slowestLapTime - fastestLapTime)))
        )

    // use 107% of fastest as slowest lap time for color calc
    // let style = { background: this.calculateLapColor(lap, fastestLapTime, fastestLapTime * LAP_TIME_BREAKDOWN) }
    // let style = { background: this.calculateLapColor(lap, fastestLapTime, slowestLapTime) }

    // no color calculation for now. Use set of the fixed styles until we have some feedback
    let style = {}

    if (this.state.renderWidth) {
      style.width = `${percentWidth}%`
    }

    const textStyle = (fastestLapTime === slowestLapTime) ? 'normal-lap'
      : lap.hidden ? 'hidden-lap'
        : lap.duration === fastestLapTime ? 'fast-lap'
          : lap.duration >= slowestLapTime ? 'slow-lap'
            : 'normal-lap'

    const renderEye = isOwner && lap.lapType === 0 && lap.order !== 'AVG'

    const intervalText = lap.lapType !== 0 ? '-'
      : lap.duration === fastestLapTime ? 'Interval'
        : `${isNaN(lap.duration) ? '' : (lap.duration > fastestLapTime ? '+' : '-')}${getDuration(Math.abs(lap.duration - fastestLapTime))}`

    return <div className='lap' key={lap.id}>
      <div className={classnames('lap-order', textStyle)}>
        {renderEye && <span onClick={() => { this.swapLapHiddenState(lap) }} className='lap-visibility'><FA name={lap.hidden ? 'eye-slash' : 'eye'} /></span>}
        {order}
      </div>
      <div className={classnames('lap-time', textStyle)}>{getDuration(lap.duration)}</div>
      <div className={classnames('lap-time', textStyle)}>{intervalText}</div>
      <div className='lap-bar'>
        <span style={style} className={this.calculateLapStyle(lap, fastestLapTime, slowestLapTime)} />
      </div>
    </div>
  }

  render () {
    const { session, noRowsRenderer } = this.props

    let normalLapsCount = 0
    const totalLapTime = _.reduce(session.laps, (result, value) => {
      if (value.hidden || value.lapType !== 0) {
        return result
      }
      normalLapsCount++
      return result + value.duration
    }, 0)

    const slowestLap = getSlowestLapTime(session)
    const fastestLap = getFastestLapTime(session)

    const avgLapTime = totalLapTime / normalLapsCount

    if (!session.laps || !session.laps.length) {
      return <div className='barlaplist'>
        {noRowsRenderer()}
      </div>
    }

    return <div className='barlaplist'>
      {session.laps.map((l) => { return this.renderLapBar(l, fastestLap, slowestLap) })}
      <hr />
      {this.renderLapBar({ id: 'avg', duration: avgLapTime, order: 'AVG', lapType: 0 }, fastestLap, slowestLap)}
    </div>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    userSession: state.session
  }
}

export default connect(mapStateToProps)(BarLapList)

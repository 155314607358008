import { asyncActions } from './'
import { deleteLayout, putLayout, postLayout } from '../api'

export const LAYOUT_DELETE = 'LAYOUTS_DELETE'
export const LAYOUT_UPDATE = 'LAYOUT_UPDATE'
export const LAYOUT_INSERT = 'LAYOUT_INSERT'

export function delLayout (token, uuid) {
  return (dispatch) => {
    const actions = asyncActions(LAYOUT_DELETE)
    dispatch(actions.start())

    return deleteLayout(token, uuid).then((result) => {
      dispatch(actions.success(result))
    }, (error) => {
      dispatch(actions.error(error))
    })
  }
}

export function updateLayout (token, uuid, layout) {
  return (dispatch) => {
    const actions = asyncActions(LAYOUT_UPDATE)
    dispatch(actions.start())

    return putLayout(token, uuid, layout).then((result) => {
      dispatch(actions.success(result))
    }, (error) => {
      dispatch(actions.error(error))
    })
  }
}

export function insertLayout (token, trackUUID, layout) {
  return (dispatch) => {
    const actions = asyncActions(LAYOUT_INSERT)
    dispatch(actions.start())

    return postLayout(token, trackUUID, layout).then((result) => {
      dispatch(actions.success(result))
    }, (error) => {
      dispatch(actions.error(error))
    })
  }
}

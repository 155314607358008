import React from 'react'
import { PropTypes } from 'prop-types'
import * as _ from 'lodash'

const propTypes = {
  stats: PropTypes.object,
  className: PropTypes.string
}

const defaultProps = {
  stats: [{ 'No data': 'No data' }],
  className: ''
}

const getBeautifulText = (value) => {
  if (typeof (value) === 'string') {
    return value
  }

  return (value === null || value === undefined) ? '-?-' : isNaN(value) ? 0 : value
}

const StatisticDataList = ({ className, stats }) => {
  return <div className={className + ' statslist-container'}>
    {_.toPairs(stats).map((p) => {
      return <div key={p[0]} className='stats-object'>
        <div className='stats-object__value'>
          {getBeautifulText(p[1])}
        </div>
        <div className='stats-object__name'>{p[0]}</div>
      </div>
    })}
  </div>
}

StatisticDataList.propTypes = propTypes

StatisticDataList.defaultProps = defaultProps

export default StatisticDataList

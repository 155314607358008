
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl, HelpBlock, ControlLabel, Col, Panel, Row, Alert, Button } from 'react-bootstrap'
import { uiVehicleEdit } from '../actions/ui'
import { isPremiumUser } from '../api/auth0'
import { deleteVehicleAction } from '../actions/garageActions'
import { updateDefaultVehicle } from '../actions/sessionActions'
import { HandleEdit, DropzoneWithImagePreview, UpgradeLink } from '../components'
import { UI_INPUT_LENGTH } from '../constants'
import Select, { components } from 'react-select'
import * as _ from 'lodash'

class VehicleSettings extends Component {
  static propTypes = {
    mode: PropTypes.oneOf(['create', 'edit'])
  }

  static defaultProps = {
    mode: 'create'
  }

  static FieldGroup ({ id, label, help, validationState, ...props }) {
    return (
      <FormGroup controlId={id || props.name} validationState={validationState} >
        <Col componentClass={ControlLabel} sm={3}>{label}</Col>
        <Col sm={9}>
          <FormControl {...props} />
        </Col>
        {help && <Col sm={9} xsOffset={3}> <HelpBlock>{help}</HelpBlock> </Col>}
      </FormGroup>
    )
  }

  constructor (props) {
    super(props)
    this.changeHandler = this.handleChange.bind(this)
    this.state = {
      handleValid: true,
      nameValid: true
    }

    this.handleEdited = this.handleEdited.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.makeVehicleDefault = this.makeVehicleDefault.bind(this)
  }

  isValid (state = this.state) {
    return state.handleValid && state.nameValid
  }

  componentWillMount () {
    const { dispatch, mode, selectedVehicle } = this.props

    if (mode === 'edit') {
      dispatch(uiVehicleEdit(selectedVehicle.item, { valid: true }))
    } else {
      dispatch(uiVehicleEdit({ handle: '', name: 'New Vehicle', visibility: 1 }, { valid: false }))
    }
  }

  handleChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value

    const name = target.name

    const { updatingVehicle, dispatch, vehicleUIState } = this.props

    let nameValid = true
    if (name === 'name') {
      nameValid = value.length > 0
      this.setState({ nameValid: nameValid })
    }

    dispatch(
      uiVehicleEdit(
        { ...updatingVehicle, [name]: value },
        { ...vehicleUIState, valid: this.isValid({ ...this.state, nameValid: nameValid }) }
      )
    )
  }

  handleEdited (newHandle, valid) {
    this.setState({ handleValid: valid })

    const { updatingVehicle, dispatch, vehicleUIState } = this.props
    dispatch(
      uiVehicleEdit(
        { ...updatingVehicle, handle: newHandle },
        { ...vehicleUIState, valid: this.isValid({ ...this.state, handleValid: valid }) }
      )
    )
  }

  handleDelete () {
    const { dispatch, selectedVehicle, session } = this.props
    selectedVehicle.item && dispatch(deleteVehicleAction(session.token, selectedVehicle.item.id))
  }

  onDrop (acceptedFiles, rejectedFiles) {
    if (acceptedFiles && acceptedFiles.length === 1) {
      const { dispatch, vehicleUIState, updatingVehicle } = this.props
      dispatch(uiVehicleEdit(updatingVehicle, { ...vehicleUIState, avatarPreview: acceptedFiles[0] }))
    }
  }

  render () {
    const { selectedVehicle, mode } = this.props

    if (selectedVehicle.isFetching) { return <Alert bsStyle='info'>Updating data...</Alert> }

    if (selectedVehicle.error) { return <Alert bsStyle='danger'>Failed to save your changes. Please retry</Alert> }

    return this.renderProfileTabContent(mode !== 'create')
  }

  makeVehicleDefault () {
    const {dispatch, session, selectedVehicle} = this.props

    dispatch(updateDefaultVehicle(session.profile, session.token, selectedVehicle.item))
  }

  customOption (props) {
    return <components.Option {...props}>
      <div className='dropdown-item' key={props.data.value}>
        <div><strong>{props.data.label}</strong></div>
        <div className='description'>{props.data.desc}</div>
      </div>
    </components.Option>
  }

  renderProfileTabContent (full = true) {
    const { session, updatingVehicle, selectedVehicle, vehicleUIState, mode } = this.props
    const oldHandle = mode === 'create' ? null : selectedVehicle && selectedVehicle.item && selectedVehicle.item.handle
    const oldAvatar = mode === 'create' ? null : (selectedVehicle.item ? selectedVehicle.item.avatarUrl : null)

    const renderDefaultView = selectedVehicle.item && session.profile && session.profile.id === selectedVehicle.item.ownerId && mode === 'edit'
    const isDefault = renderDefaultView && session.profile.defaultVehicleId === selectedVehicle.item.id

    const visibilityOptions = [
      { value: 0, label: 'Private', desc: 'Only you can see the vehicle' },
      { value: 1, label: 'Public', desc: 'Anyone can see the vehicle and it\'s basic stats.' }
    ]

    const selectedV = _.find(visibilityOptions, {value: updatingVehicle && updatingVehicle.visibility === 0 ? 0 : 1})

    return (
      <Form horizontal>
        {mode === 'create' && <p>
          Great idea creating a vehicle!
        </p>}
        <Row>
          <Col sm={9}>
            <VehicleSettings.FieldGroup
              key='name'
              name='name'
              type='text'
              label='Name'
              maxLength={UI_INPUT_LENGTH.NAME}
              value={(updatingVehicle && updatingVehicle.name) || ''}
              onChange={this.changeHandler}
              placeholder="The vehicle's name"
              help={!this.state.nameValid ? 'Choose the name for your vehicle' : null}
              validationState={this.state.nameValid ? 'success' : 'error'}
            />

            {renderDefaultView && <FormGroup controlId='default-vehicle'>
              <Col componentClass={ControlLabel} sm={3} />
              <Col sm={9}>
                {isDefault && <div>Default vehicle</div>}
                {!isDefault && <a onClick={this.makeVehicleDefault}>Set as deafault vehicle</a>}
              </Col>
            </FormGroup>}

            <HandleEdit
              onHandleChanged={this.handleEdited}
              apiToken={session.token}
              oldHandle={oldHandle} />

            <FormGroup controlId='visibility' validationState={null}>
              <Col componentClass={ControlLabel} sm={3}>{full ? 'Vehicle visibility' : 'Visibility'}</Col>
              <Col sm={9}>
                <div>
                  <Select
                    className='react-select-container'
                    classNamePrefix='react-select'
                    isDisabled={!isPremiumUser(session)}
                    value={selectedV}
                    components={{ Option: this.customOption }}
                    options={visibilityOptions}
                    onChange={visibility => this.handleChange({ target: { name: 'visibility', value: visibility.value } })} />
                  {!isPremiumUser(session) && <UpgradeLink className='bottom-link'>Go premium to enable private vehicles</UpgradeLink>}
                </div>
              </Col>
            </FormGroup>

            <VehicleSettings.FieldGroup
              key='alias'
              name='alias'
              type='text'
              label='Alias'
              maxLength={UI_INPUT_LENGTH.NAME}
              value={(updatingVehicle && updatingVehicle.alias) || ''}
              onChange={this.changeHandler}
              placeholder="The vehicle's alias"
              help={!this.state.nameValid ? 'Choose the alias for your vehicle' : null}
            />

            <VehicleSettings.FieldGroup
              key='description'
              name='description'
              type='textarea'
              componentClass='textarea'
              label='Description'
              maxLength={UI_INPUT_LENGTH.DESCRIPTION}
              value={(updatingVehicle && updatingVehicle.description) || ''}
              onChange={this.changeHandler}
              placeholder="Vehicle's description"
              rows={8}
            />
            {full &&
              <div>
                <hr />
                <Panel bsStyle='danger'>
                  <Panel.Heading><Panel.Toggle>Dangerous operations</Panel.Toggle></Panel.Heading>
                  <Panel.Collapse>
                    <Panel.Body>
                      <Alert bsStyle='danger' >
                        <p>Deleting this vehicle permanently removes it from Track Attack but
                          leaves all related session data. The vehicle can be recreated but you will have to
                          re-associate it with any sessions.
                        </p>
                        <p>
                          <strong>This action cannot be undone!</strong>
                        </p>
                      </Alert>
                      <Button bsStyle='danger' onClick={this.handleDelete}>Delete this vehicle</Button>
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel>
              </div>}
          </Col>
          <Col sm={3}>
            <DropzoneWithImagePreview
              onDrop={this.onDrop}
              previewUrl={(vehicleUIState && vehicleUIState.avatarPreview && vehicleUIState.avatarPreview.preview) || oldAvatar}
            />
          </Col>
        </Row>
      </Form>)
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session,
    updatingVehicle: state.ui.vehicles.settings,
    vehicleUIState: state.ui.vehicles.uiState,
    selectedVehicle: state.garage.selectedVehicle
  }
}

export default connect(mapStateToProps)(VehicleSettings)

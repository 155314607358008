import { BREAKOUTS } from '../constants'

const KB = 1024
const MB = KB * 1024

export function searchToObject (search) {
  const pairs = (search || window.location.search).substring(1).split('&')
  let obj = {}

  for (let i in pairs) {
    if (pairs[i] === '') continue

    let pair = pairs[i].split('=')
    obj[ decodeURIComponent(pair[0]) ] = decodeURIComponent(pair[1])
  }

  return obj
}

export function getInnerWindowWidth () {
  return (window && window.innerWidth) || BREAKOUTS.LARGE_BREAKOUT
}

export function isMobile () {
  const deviceWidth = (window && window.innerWidth) || BREAKOUTS.LARGE_BREAKOUT
  return deviceWidth <= BREAKOUTS.MOBILE_BREAKOUT
}

export function hashCode (str) { // java String#hashCode
  let hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  return hash
}

export function intToRGB (i) {
  let c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase()

  return '00000'.substring(0, 6 - c.length) + c
}

export function beautifulFileSizeString (bytes) {
  if (bytes > MB) {
    return `${Math.round(bytes * 100 / MB) / 100} MB`
  }

  return `${Math.round(bytes * 100 / KB) / 100} kB`
}

export function guid () {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4()
}

export function downloadUrl (url) {
  const oldIfr = document.getElementById('downloader')
  oldIfr && document.body.removeChild(oldIfr)

  const ifr = document.createElement('iframe')
  ifr.id = 'downloader'
  ifr.src = url
  ifr.style.display = 'none'
  document.body.appendChild(ifr)
}

export function decodeHtml (html) {
  const oldText = document.getElementById('t-area')
  oldText && document.body.removeChild(oldText)

  let tArea = document.createElement('textarea')
  tArea.id = 't-area'
  tArea.style.display = 'none'
  tArea.innerHTML = html

  return tArea.value
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'

class WistiaViewer extends Component {
  static propTypes = {
    wistiaID: PropTypes.string.isRequired,
    allowDownload: PropTypes.bool
  }

  static defaultProps = {
    allowDownload: false
  }

  componentDidMount () {
    const script = document.createElement('script')

    script.src = '//fast.wistia.net/assets/external/E-v1.js'
    script.async = true

    document.body.appendChild(script)

    const {wistiaID, allowDownload} = this.props

    window._wq = window._wq || []
    allowDownload && window._wq.push({
      id: wistiaID,
      options: {
        plugin: {
          share: {
            channels: 'download'
          }
        }
      }
    })
  }

  render () {
    const {wistiaID} = this.props

    return <div id='video-viewer'>
      {/* <iframe
        title='Wistia video player'
        frameBorder='0'
        scrolling='no'
        className='wistia_embed'
        name='wistia_embed'
        allowFullScreen
        mozallowfullscreen='true'
        webkitallowfullscreen='true'
        oallowfullscreen='true'
        msallowfullscreen='true'
        width='100%'
        height='100%'
        src={wistiaID} /> */}
      <div className={`wistia_embed wistia_async_${wistiaID} videoFoam=true playerColor=F26522`}>&nbsp;</div>
    </div>
  }
}

export default WistiaViewer

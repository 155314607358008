import { find } from 'lodash'
// SEE https://docs.google.com/spreadsheets/d/12DmOwkZ7dp-4Q2WPHnxTxBlnsKuyHQJm5nYnTF0xvSk/edit#gid=0 for filetypes

export const FILE_CATEGORY_TYPES = {
  VIDEO: 1000,
  TELEMETRY: 2000,
  TELEMETRY_ORIGINAL: 3000,
  EXTRAS: 4000,
  PICTURES: 5000
}
// lookups: state.lookups.item

export const FILE_SUBCATEGORY_TYPES = {
  VIDEO_ORIGINAL: 1001,
  VIDEO_ENCODED: 1002,

  LATITUDE: 2001,
  LONGITUDE: 2002,
  SPEED_GPS: 2003,
  SPEED_WHEEL: 2004,
  ELEVATION: 2005,
  LAT_G: 2006,
  LON_G: 2007,
  STEERING: 2008,
  BRAKE_PRESSURE_FRONT: 2009,
  THROTTLE_POSITION: 2010,
  TEMP_WATER: 2011,
  TEMP_OIL: 2012,
  AIR_TO_FUEL: 2013,
  BOOST_TO_BAR: 2014,
  ENGINE_RPM: 2015,
  DISTANCE: 2017,
  DISTANCE_GPS: 2018
}

export const FILE_SOURCE_TYPES = {
  UNKNOWN_PARSER: 3000,
  OCD: 3001,
  UNIPRO: 3002,
  UNIPRO_OLD: 3003,
  UNIGO: 3004,
  BJR: 3005,
  AIM_CSV: 3006,
  RACEPACK: 3007,
  OCD_ALFANO: 3008,
  PE: 3009,
  ALFANO: 3010,
  BOSCH_CSV: 3011,
  RACE_LOGIC_VBO: 3012,
  AUTO_SPORT_LABS: 3013,
  AIM_XRK: 3014,
  AIM_DRK: 3015,
  MOTEC: 3016,
  RACE_CAPTURE: 3017,
  TRAQMATE: 3018,
  HARRYS_LAP_TIMER: 3019,
  APEX: 3020,
  ALFANO_CSV: 3021,
  TRAQMATE_CSV: 3022,
  TRACK_ADDICT_VBO: 3023,
  AIM_GPK: 3024,
  IRACING_MOTEC: 3025,
  ASSETTO_CORSA_MOTEC: 3026,
  RFACTOR_MOTEC: 3027,
  MOTEC_CSV: 3028,
  IRACING_IBT: 3029,
  CMS_LAP_TIMER_CSV: 3030,
  RACE_TECHNOLOGY_CSV: 3031,
  PORSCHE_CSV: 3032,
  ASSETTO_CORSA_CSV: 3033
}

export const INVITE_STATUS = {
  PENDING_REGISTRATION: 1,
  PENDING: 2,
  ACCEPTED: 4,
  REJECTED: 8,
  CANCELLED: 16
}

export const INVITE_TYPE = {
  REQUEST_TO: 1,
  INVITE_TO: 2
}

export const HANDLE_TYPES = {
  USER: 1,
  TEAM: 2,
  TRACK: 4,
  VEHICLE: 8
}

export const INVITE_TARGET_ENTITY_TYPES = {
  SESSION: 'session',
  TEAM: 'team'
}

export const SIMULATOR_PLATFORM = {
  realWorld: 0, // fake. Keep it to be consistent
  iRacing: 1,
  AssettoCorsa: 2,
  RFactor2: 3,
  ProjectCars: 4,
  ForzaMotorsports: 5,
  AssettoCorsaCompetizione: 6,
  RFactor: 7,
  ProjectCars2: 8,
  All: 9999
}

export const TRACKMANAGER_USER_HANDLE = 'trackattack'

export function getParserData (sourceFileType, typeSubCategories = []) {
  const targetSubCategory = find(typeSubCategories, {typeId: sourceFileType})
  return targetSubCategory ? {...targetSubCategory, id: targetSubCategory.typeId, name: targetSubCategory.description} : {id: sourceFileType, name: 'unknown [' + sourceFileType + ']'}
}

export const PRODUCT_OWNERSHIP_TYPES = {
  NO_ACCESS: 0,
  CAN_VIEW: 1,
  CAN_DOWNLOAD: 2
}

export const SUBSCRIPTION_TYPES = {
  BASIC_FREE: 0,
  PREMIUM: 1
}

export const PRODUCT_FILE_TYPES = {
  PDF: 0,
  WISTIA: 1,
  DATA_PACK: 2,
  ANALYSE: 3,
  YOUTUBE: 4,
  UNDEFINED: 10000,
  SUBSCRIPTIONS: 10001 // UNUSED IN API
}

export const PRODUCT_OWNERSHIP_TYPES_NAMES = [
  'No Access', 'View', 'Download'
]

export const PRODUCT_FILE_TYPES_NAMES = [
  'PDF', 'WISTIA', 'Data Pack', 'Data Analysis', 'YouTube video'
]

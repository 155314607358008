import React from 'react'
import { PropTypes } from 'prop-types'
import { BING_MAPS_KEY } from '../constants'

export default class BingMapWidget extends React.Component {
  static propTypes = {
    track: PropTypes.object
  }

  generateMapUrl (start, finish) {
    // URL format https://msdn.microsoft.com/en-us/library/ff701724.aspx
    if (finish) {
      return `https://dev.virtualearth.net/REST/v1/Imagery/Map/Aerial/?pp=${start.lat},${start.lon};46;S&pp=${finish.lat},${finish.lon};46;F&key=${BING_MAPS_KEY}`
    } else {
      return `https://dev.virtualearth.net/REST/v1/Imagery/Map/Aerial/?pp=${start.lat},${start.lon};46;S&key=${BING_MAPS_KEY}`
    }
  }

  renderLoading (track) {
    if (track && track.locationLat) {
      return ''
    }
    return (<div className='weatherwidget-loading'>Loading map...</div>)
  }

  renderMapImage (track) {
    if (!track || !track.locationLat) {
      return ''
    }
    let url = this.generateMapUrl({ lat: track.locationLat, lon: track.locationLong })
    return (
      <div className='bingmap-widget-map'>
        <img className='bingmap-widget-map-img' alt='The map' src={url} />
      </div>)
  }

  render () {
    const { track } = this.props

    if (track && (!track.locationLat || !track.locationLong)) {
      return null
    }

    return (
      <div className='bingmap-widget-root'>
        {this.renderLoading(track)}
        {this.renderMapImage(track)}
      </div>
    )
  }
}

import React from 'react'
import { Link } from 'react-router-dom'
import { Section, ExternalLink } from '../../components'
import { page } from '../../hocs/page'
import { GA_PAGES } from '../../api/gaHelper'
import ReactPlayer from 'react-player'
import img01 from '../../images/iracing/img01.jpg'
import img02 from '../../images/iracing/img02.jpg'
import img03 from '../../images/iracing/img03.jpg'
import img04 from '../../images/iracing/img04.jpg'

const PageIRacing = () => {
  return (
    <Section title='iRacing with Track Attack' subtitle='What it is and how to use it'>
      <p>Track Attack is the most modern and easy to use motorsports analysis solution in the world and we believe simulator driving is the same for actual driving. Driving in a simulator has come such a long way over the past couple of decades, where with the right equipment, driving in a simulator is 90-95% of the real-world experience. Further, there are more simulator drivers, driving more often in practice, wheel to wheel racing and time attack sessions than there are in the real world already.</p>
      <p>Drivers in simulators take their driving and competitiveness just as serious as those in the real world (many drivers, coaches and teams straddling both worlds) and that is why we decided to make Track Attack work with the most popular simulator platforms, starting with iRacing.</p>

      <h3>What is it?</h3>
      <p>Track Attack as a product, will expand coverage to take iRacing telemetry (.ibt files) as a valid data file type. This means that the full functionality you’ve come to know (and love!) of Track Attack will now work and be officially supported for iRacing telemetry data.</p>

      <h3>How does it work?</h3>
      <p>iRacing gives users the option to log telemetry data for each time you drive in a session; test, practice, qualifying and a race. By default, logging can be turned on by hitting the “ALT + L” anytime when you are in the simulator. After the session, a telemetry file is created and saved in a default directory location “C:\\Users\[your computer user name]\Documents\iRacing\Telemetry”. The .ibt file contains all the telemetry data that is available for that car (channels vary by car), environmental, position and general simulator data such as the state of the track, position changes, when a local yellow has come out, etc…</p>
      <div className='landing__videocontainer'>
        <ReactPlayer url='https://www.youtube.com/watch?v=dn2OYSXpke0' />
      </div>
      <p>Here are the steps to getting your iRacing .ibt data into Track Attack:</p>

      <ol>
        <li>Make sure you have v110 or newer of Track Attack. <Link to='/downloads'>Download here if you don’t have it.</Link></li>
        <li>Make sure you are logged-in to your Track Attack account in the Track Attack desktop software.</li>
        <ol type='a'>
          <li>You need to be signed-in to your TA account so that the desktop client can download the latest track database, which will contain the iRacing track layouts.</li>
        </ol>
        <li>Start an iRacing driving session and before you start driving, hit “ALT + L” to turn on the telemetry logging feature. You’ll know it is working because it will display the below icon.</li>
        <li>After you are done driving, exit the simulator.</li>
        <li>Open Track Attack and click on the big green “Import” button</li>
        <li>iRacing by default saves telemetry log files to the below location – find the .ibt file for the session you want and select it.</li>
        <ol type='a'>
          <li>Default telemetry save location:</li>
          <li>Note – you can import multiple sessions at once.</li>
        </ol>
        <li>Track Attack will read the file and display a preview of the data in the ‘Import Windows’. Review the data and make sure all looks good before clicking “Import”. Click “Import” when you’re ready.</li>
        <ol type='a'>
          <li><strong>Make sure the right track and layout are selected</strong>. All iRacing track layouts start with “iRacing [track and layout name]”. Some tracks have multiple layouts – make sure the right one is selected.</li>
          <li><strong>Review the lap times</strong> – we recalculate the lap times to ensure they are comparable with all other data from the same track, including real-world data sessions so they lap times may differ by ~.1 seconds or less.</li>
          <li><strong>Make sure the right driver is selected</strong> – by default we have the currently logged in user as the driver, but the driver can be changed to anyone you are connected to in Track Attack across teams or people you’ve shared data within the past.</li>
          <li>Add any notes you may want and enter any individual or team you want to share the session with.</li>
        </ol>
        <li>That’s it! After the session(s) have imported, you will automatically be able to view the data however you want. TA automatically selects the fastest three laps of the session(s) you imported.</li>
        <ol type='a'>
          <li>The data session(s) will automatically start synchronizing a few seconds after import.</li>
          <li>If you entered any user handles, email addresses or team handles when importing the session, when the synchronization happens, it will initiate those share requests.</li>
        </ol>
      </ol>

      <h3>Can I compare real-world with iRacing data?</h3>
      <p>Absolutely and this is one of the most common scenarios that drove this feature request. For example, let’s say you are a Global Mazda MX-5 Cup driver, coach or team and you want to compare real-world data from Laguna Seca with iRacing data from Laguna Seca. When you import the real-world session, you will select “Laguna Seca” as the track and layout (there is only one). When you import the iRacing session, you will select “iRacing Laguna Seca”.</p>
      <p>Open both sessions up and you will notice that there are two sessions open on two different tracks/layouts but when the sessions are viewed in ‘Distance Mode’ with the ‘Time Lost’ graph enabled, TA shows the time delta from any of the laps selected, even across both sessions.</p>

      <div className='landing__imgcontainer'><img src={img01} alt='iRacing' /></div>

      <h3>Is the track map real?</h3>
      <p>Of course it’s real, you see it, right? <span role='img' aria-label=':)'>😊</span> One of the great things about iRacing is that the platform outputs extremely high-quality GPS data for where the car is on-track, just like a real-world logging system. We can only assume that is because iRacing has done mapping of their laser-scanning of each track, with the corresponding GPS coordinates. THANKS IRACING!</p>
      <p>So, what you are looking at is indeed the closest representation to where you would be driving in the real-world! Now, you might notice that the driven line might show that you drove off the track and that’s because of the inaccuracies of the actual satellite maps. That is a topic for another post but yes, that is all real.</p>

      <div className='landing__imgcontainer'><img src={img02} alt='iRacing' /></div>

      <h3>Will split time/segment analysis still work?</h3>
      <p>Yes! We have created standard segment/split definitions for all of the major road-course and roval tracks in iRacing. These segments were created based on a methodology used by several professional coaches and racing teams, where tracks are broken up into at least three segments and each segment is created right before a major braking zone, with the exception of start/finish.</p>
      <p>To see the split time/segment analysis, open a session and click on the "Split Times" button in the main menu section at the top of the application. Here is how to read the report:</p>
      <ol>
        <li>Each row is a lap time in the session with the columns being the total lap time and each of the segments for the track/layout.</li>
        <li>Each cell is the time for that specific segment on that specific lap.</li>
        <li>Fastest segment times are highlighted in yellow.</li>
        <li><strong>Theoretical Best:</strong> This is the theoretical fastest lap time if the driver was able to combine the fastest segments across all the laps, into one lap.</li>
        <li><strong>Rolling Best:</strong> This is considered a more accurate display of a driver’s potential ‘pace’ in that session. It ignores the official start/finish location and determines the fastest complete lap time driven, regardless of which segment contains the start/finish.</li>
        <li><strong>Range:</strong> A calculation of the time range for each segment from the slowest to the fastest time. The largest range is a good indication of where to work on being more consistent. #speedsecret</li>
        <li>If you want to look analyze specific laps in your session, you have a couple options:</li>
        <ol type='a'>
          <li>CTRL + Select all the laps you want to analyze. This will update range row with only the laps you have selected.</li>
          <li>Right Click + Hide Lap will hide or disable a lap. This will remove it from laps considered for theoretical fastest lap, best rolling lap and the lap range.</li>
        </ol>
      </ol>

      <div className='landing__imgcontainer'><img src={img03} alt='iRacing' /></div>

      <h3>Does this work for Mac?</h3>
      <p>Yes – the Track Attack functionality will work exactly as described for Windows and Mac. iRacing is primarily supported on Windows PC’s, so if you want to analyze your iRacing data on a Mac, you will need to get the iRacing .ibt file to a Mac via a USB stick, a cloud storage service such as OneDrive or DropBox or emailing the file.</p>

      <h3>Can I synchronize video with my iRacing data?</h3>
      <p>Yes – many of our beta testers used screen capture/recording solutions such as the built-in WINDOWS_KEY + G to record the screen, of which then outputs to an .mp4 file. Some users have also recorded their iRacing driving sessions with an external camera that records their screens and their own driver inputs.</p>
      <p>The advanced auto-sync video feature has not been validated on either of these methods so results may vary. However, we expect it to generally work and there is still a mechanism for manually aligning the data and video – <ExternalLink to={'https://www.youtube.com/watch?v=DMImztOnVzo&feature=youtu.be'}>see this video for step-by-step instructions</ExternalLink>.</p>

      <h3>Will my session data still sync with the cloud?</h3>
      <p>Yes – iRacing .ibt sessions are treated like any other supported session within Track Attack. For free members, it will count towards the 50 sessions that are synced to the cloud. The 51st and higher sessions will still be importable and viewable locally on your computer but those will not sync with the cloud, unless you are a premium user.</p>

      <h3>Will my session data still count towards leaderboards?</h3>
      <p>Yes – all sessions that are imported and associated with an official track and layout, will count towards the current track leaderboards. The main difference is that we’ve created new layouts for each of the iRacing supported tracks and therefore, iRacing lap times will only count towards the iRacing specific track layout leaderboards.</p>

      <div className='landing__imgcontainer'><img src={img04} alt='iRacing' /></div>
    </Section>
  )
}

export default page(PageIRacing, { pageView: GA_PAGES.VALUE_IRACING })

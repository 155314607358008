import 'whatwg-fetch'
// import { default as fetchJsonp } from 'fetch-jsonp'
// import { getData, putData, AGE_30_MIN } from './apiCache'
// import { DARK_SKY_API_KEY } from '../constants'

// https://api.darksky.net/forecast/85fdf0debf8b062c88778e86b697a8cd/37.8267,-122.4233?exclude=minutely,flags&lang=en&callback=JSON_CALLBACK
// const API_URL = `https://api.darksky.net/forecast/${DARK_SKY_API_KEY}/`
// const REQ_PARAMS = '?exclude=minutely,flags&lang=en'

export const UNITS_SI = 'si'
export const UNITS_IMPERIAL = 'us'

/*
const UNITS_SI_VALUES = {
  nearestStormDistance: 'km',
  precipIntensity: 'mm/h',
  precipIntensityMax: 'mm/h',
  precipAccumulation: 'cm',
  temperature: 'C',
  temperatureMin: 'C',
  temperatureMax: 'C',
  apparentTemperature: 'C',
  dewPoint: 'C',
  windSpeed: 'mps',
  pressure: 'hPa',
  visibility: 'km'
}

const UNITS_IMPERIAL_VALUES = {
  nearestStormDistance: 'mi',
  precipIntensity: 'in/h',
  precipIntensityMax: 'in/h',
  precipAccumulation: 'in',
  temperature: 'F',
  temperatureMin: 'F',
  temperatureMax: 'F',
  apparentTemperature: 'F',
  dewPoint: 'F',
  windSpeed: 'mph',
  pressure: 'mbar',
  visibility: 'mi'
}
*/

export function getWeather (lat, lon, units = UNITS_IMPERIAL) {
//  if (!lat && !lon) {
//    return null
//  }
/*
  const url = `${API_URL}${lat},${lon}${REQ_PARAMS}&units=${units}`

  const cached = getData(url, AGE_30_MIN)
  if (cached) {
    console.log('HIT CACHE!')
    return new Promise((resolve, reject) => resolve(cached))
  }

  return fetchJsonp(url, { jsonpCallback: 'callback' })
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      putData(url, {
        weather: response,
        units: units === UNITS_IMPERIAL ? UNITS_IMPERIAL_VALUES : UNITS_SI_VALUES
      })

      return {
        weather: response,
        units: units === UNITS_IMPERIAL ? UNITS_IMPERIAL_VALUES : UNITS_SI_VALUES
      }
    })
      */
  return null
}

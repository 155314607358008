import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default class TwoLineEntry extends React.Component {
  static propTypes = {
    buttons: PropTypes.element,
    image: PropTypes.string,
    headline: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onClick: PropTypes.func,
    decorator: PropTypes.func
  }

  static defaultProps = {
    onClick: () => { }
  };

  render () {
    const { buttons, image, headline, message, onClick, decorator, className } = this.props
    return (
      <div className={classnames(className, 'list-row')}>
        {decorator && decorator()}
        <div className='list-row-content' onClick={onClick} >
          {image && <img className='list-avatar' src={image} alt='' />}
          <div className='list-message'>
            <div className='list-name'>
              {headline}
            </div>
            <div className='list-index'>
              {message}
            </div>
          </div>
        </div>
        {buttons}
      </div>
    )
  }
}

import { asyncActions } from './'
import {
  fetchTracks,
  fetchTrack,
  fetchFeaturedTrack,
  fetchTracksCount,
  fetchNearByTracks,
  fetchRecentTracks,
  fetchPopularTracks,
  postTrack,
  putTrack,
  deleteTrack,
  postTrackAvatar,
  getTrackLeaderboardFastlaps } from '../api'

import { reverseGeocode } from '../api/bingMapApi'
import { getCountryByCode } from '../api/restContriesApi'
import { getLocation } from '../api/location'
import { getWeather, UNITS_IMPERIAL, UNITS_SI } from '../api/darkSkyWeatherApi'
import { generateTrackDeletedToast, generateTrackUpdatedToast, generateTrackCreatedToast } from '../utils/toastCreator'
import { uiToast } from './ui'
import * as _ from 'lodash'
import moment from 'moment'

export const TRACKS_FETCH = 'TRACKS_FETCH'
export const TRACKS_FEATURED_FETCH = 'TRACKS_FEATURED_FETCH'
export const SINGLE_TRACK_FETCH = 'SINGLE_TRACK_FETCH'
export const TRACKS_COUNT = 'TRACKS_COUNT'
export const TRACKS_RECENT_FETCH = 'TRACKS_RECENT_FETCH'
export const TRACKS_NEARBY_FETCH = 'TRACKS_NEARBY_FETCH'
export const TRACKS_POPULAR_FETCH = 'TRACKS_POPULAR_FETCH'
export const TRACKS_INSERT_NEW = 'TRACKS_INSERT_NEW'
export const TRACKS_UPDATE = 'TRACKS_UPDATE'
export const TRACKS_DELETE = 'TRACKS_DELETE'
export const TRACKS_UPLOAD_AVATAR = 'TRACKS_UPLOAD_AVATAR'
export const TRACK_FETCH = 'TRACK_FETCH'
export const TRACK_FETCH_FASTEST_LAPS = 'TRACK_FETCH_FASTEST_LAPS'

export function fetchTracksAction (token, skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(TRACKS_FETCH)

    dispatch(actions.start({ filter: filter }))
    return fetchTracks(token, skip, filter).then(
      (result) => {
        dispatch(actions.success(result, { append: skip !== 0, filter: filter }))
      },
      (error) => dispatch(actions.error(error))
    )
  }
}

export function fetchTrackAction (token, id, actionName = TRACK_FETCH) {
  return (dispatch) => {
    const actions = asyncActions(actionName)
    dispatch(actions.start())
    return fetchTrack(token, id).then(
      result => dispatch(actions.success(result)),
      error => dispatch(actions.error(error))
    )
  }
}

export function fetchRecentTracksAction (token) {
  return (dispatch) => {
    const actions = asyncActions(TRACKS_RECENT_FETCH)
    dispatch(actions.start())
    return fetchRecentTracks(token).then(
      (result) => {
        dispatch(actions.success(result))
      },
      (error) => dispatch(actions.error(error))
    )
  }
}

export function fetchPopularTracksAction (token) {
  return (dispatch) => {
    const actions = asyncActions(TRACKS_POPULAR_FETCH)
    dispatch(actions.start())
    return fetchPopularTracks(token).then(
      (result) => {
        dispatch(actions.success(result))
      },
      (error) => dispatch(actions.error(error))
    )
  }
}

export function fetchNearByTracksAction (token, skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(TRACKS_NEARBY_FETCH)
    dispatch(actions.start())

    return getLocation()
      .then(location => fetchNearByTracks(token, location.lat, location.lon, skip, filter))
      .then(result => dispatch(actions.success(result, { append: skip !== 0, filter: filter })))
      .catch(error => dispatch(actions.error(error)))
  }
}

export function countTracksAction (token) {
  return (dispatch) => {
    const actions = asyncActions(TRACKS_COUNT)

    dispatch(actions.start())
    return fetchTracksCount(token).then(
      (result) => {
        dispatch(actions.success({ count: result }))
      },
      (error) => dispatch(actions.error(error))
    )
  }
}

export function fetchFeaturedTrackAction (token) {
  return (dispatch) => {
    const actions = asyncActions(TRACKS_FEATURED_FETCH)

    let track = null

    dispatch(actions.start())
    return fetchFeaturedTrack(token)
      .then(
        (result) => {
          track = result
          dispatch(actions.success(track))
          return reverseGeocode({ lat: track.locationLat, lon: track.locationLong })
        })
      .then(
        (result) => {
          track = { ...track, locationData: result }
          dispatch(actions.success(track))
        }
      )
      .catch(
        (error) => dispatch(actions.error(error))
      )
  }
}

export function singleTrackFetch (token, id, fetchedOne, units) {
  return (dispatch) => {
    const actions = asyncActions(SINGLE_TRACK_FETCH)

    dispatch(actions.start({clear: true}))
    let track = null

    fetchedOne && dispatch(actions.success(fetchedOne))

    // const trackPromise = fetchedOne
    //   ? new Promise((resolve, reject) => { resolve(fetchedOne) })
    //   : fetchTrack(token, id)

    return fetchTrack(token, id)
      .then(
        (result) => {
          dispatch(actions.success(result))
          track = result
          return getCountryByCode(track.countryCode)
        })
      .then(
        (result) => {
          track = { ...track, ...result }
          dispatch(actions.success(track))
          return reverseGeocode({ lat: track.locationLat, lon: track.locationLong })
        })
      .then(
        (result) => {
          track = {
            ...track,
            locationData: result
          }
          dispatch(actions.success(track))
          return getWeather(track.locationLat, track.locationLong, units === 'i' ? UNITS_IMPERIAL : UNITS_SI)
        })
      .then((result) => {
        dispatch(actions.success({
          ...track,
          meteo: result
        }))
      })
      .catch((error) => {
        dispatch(actions.error(error))
      })
  }
}

export function insertNewTrack (token, track) {
  return (dispatch) => {
    const actions = asyncActions(TRACKS_INSERT_NEW)
    dispatch(actions.start())

    return postTrack(token, track).then((result) => {
      dispatch(actions.success(result))
      dispatch(uiToast(generateTrackCreatedToast(track)))
    }, (error) => {
      dispatch(actions.error(error))
    })
  }
}

export function updateTrack (token, id, track) {
  return (dispatch) => {
    const actions = asyncActions(TRACKS_UPDATE)
    const fetchActions = asyncActions(SINGLE_TRACK_FETCH)

    dispatch(actions.start())

    return putTrack(token, id, track).then((result) => {
      dispatch(actions.success(result))
      dispatch(fetchActions.success(result))

      dispatch(uiToast(generateTrackUpdatedToast(track)))
    }, (error) => {
      dispatch(actions.error(error))
    })
  }
}

export function removeTrack (token, track) {
  return (dispatch) => {
    const actions = asyncActions(TRACKS_DELETE)
    dispatch(actions.start())

    return deleteTrack(token, track.id).then((result) => {
      dispatch(actions.success(result))
      dispatch(uiToast(generateTrackDeletedToast(track)))
    }, (error) => {
      dispatch(actions.error(error))
    })
  }
}

export function uploadTrackAvatar (token, id, avatar) {
  return (dispatch) => {
    const actions = asyncActions(TRACKS_UPLOAD_AVATAR)
    dispatch(actions.start())

    return postTrackAvatar(token, id, avatar).then((result) => {
      dispatch(actions.success(result))
    }, (error) => {
      dispatch(actions.error(error))
    })
  }
}

function prepareLeaderboardFilter (filter = {}) {
  const DT_FORMAT = 'YYYY-MM-DD'

  // keep only certain opts
  return {
    ...(filter.layoutId && {layoutId: filter.layoutId}),
    ...(filter.skip && {skip: filter.skip}),
    ...(filter.startDate && {startDate: moment(filter.startDate).format(DT_FORMAT)}),
    ...(filter.endDate && {endDate: moment(filter.endDate).format(DT_FORMAT)})
  }
}

export function fetchFastLapLeaderboards (token, track, filter) {
  const preparedFilter = prepareLeaderboardFilter(filter)
  return (dispatch) => {
    const actions = asyncActions(TRACK_FETCH_FASTEST_LAPS)

    if (!filter) {
      dispatch(actions.start({clear: true}))

      const getters = track.layouts.map((layout) => { return getTrackLeaderboardFastlaps(token, {layoutId: layout.id}) })

      Promise.all(getters)
        .then((results) => {
          const layoutIds = track.layouts.map((layout) => { return layout.id })

          const res = _.zipObject(layoutIds, results)
          dispatch(actions.success(res))
        })
        // .catch((err) => {
        //   console.log(err)
        // })
    } else {
      if (!preparedFilter.skip) {
        dispatch(actions.start({clear: true}))
      }

      getTrackLeaderboardFastlaps(token, preparedFilter)
        .then((result) => {
          const res = {[preparedFilter.layoutId]: result, preparedFilter}
          dispatch(actions.success(res))
        })
    }
  }
}

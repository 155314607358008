import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/footer_logo.png'
import logo2 from '../images/footer_logo_2.png'
import { IS_PROD, IS_DEVELOP, COMPANY_REQUISITES } from '../constants'
import { SocialIcons } from './'
import { Col, Grid, Clearfix } from 'react-bootstrap'
import * as ROUTES from '../utils/routingConstants'

const propTypes = {}

const defaultProps = {}

const getApiEndpoint = () => {
  if (IS_DEVELOP) {
    return (<font color='green'>DEVELOPMENT</font>)
  }

  return null
}

const Footer = props => {
  return <footer className='app-footer'>
    <div className='app-footer-strip1'>
      {!IS_PROD && <div className='app-footer__app-ver'>
        <div>Api endpoint: {getApiEndpoint()}</div>
      </div>}
    </div>
    <div className='app-footer-strip2'>
      <Grid>
        <Col lg={5} md={4} smHidden >
          {/* empty */}
        </Col>
        <Col xs={12} sm={6} md={2} lg={2}>
          <ul>
            <li className='title'>Product</li>
            <li><Link to={`${ROUTES.ROUTE_ROOT_SOLUTIONS}/coaches`}>For Coaches</Link></li>
            <li><Link to={`${ROUTES.ROUTE_ROOT_SOLUTIONS}/teams`}>For Teams</Link></li>
            <li><Link to={`${ROUTES.ROUTE_ROOT_SOLUTIONS}/trackdays`}>For Track Days</Link></li>
            <li><Link to={`${ROUTES.ROUTE_ROOT_SOLUTIONS}/clubracers`}>For Club Racers</Link></li>
            <li><Link to={ROUTES.ROUTE_DOWNLOADS}>Downloads</Link></li>
            <li><Link to={`${ROUTES.ROUTE_IRACING}`}>iRacing support</Link></li>
            <li><Link to={`${ROUTES.ROUTE_ASSETTOCORSA}`}>Assetto Corsa support</Link></li>
            <li><Link to={ROUTES.ROUTE_FAQ}>FAQ</Link></li>
            <li><Link to={ROUTES.ROUTE_PRICING}>Pricing</Link></li>
          </ul>
        </Col>
        <Col xs={12} sm={6} md={2} lg={2}>
          <ul>
            <li className='title'>Company</li>
            <li><Link to={ROUTES.ROUTE_NEWS}>News</Link></li>
            {/* <li><Link to={ROUTES.ROUTE_CONTACTS}>Contact Us</Link></li> */}
            <li><Link to={ROUTES.ROUTE_TOS}>Terms Of Use</Link></li>
            <li><Link to={ROUTES.ROUTE_PRIVACY}>Privacy Policy</Link></li>
          </ul>
        </Col>
        <Clearfix visibleXsBlock visibleSmBlock />
        <Col sm={12} md={4} lg={3} className='app-footer-socials-container'>
          <SocialIcons className='app-footer__socials' entity={COMPANY_REQUISITES} />
          <div className='app-footer__copy'>© Situne AS 2024</div>
        </Col>
      </Grid>
    </div>
    <img src={logo} className='app-footer__bottom-logo' alt='logo' />
    <img src={logo2} className='app-footer__bottom-logo2' alt='logo2' />

  </footer>
}

Footer.propTypes = propTypes

Footer.defaultProps = defaultProps

export default Footer

import { asyncActions } from './'
import { postInvite, fetchInvites, fetchInvitesSummary, changeInviteStatus, fetchTeamInvites, fetchUserTeams } from '../api'
import { INVITE_STATUS, INVITE_TARGET_ENTITY_TYPES } from '../api/apiConstants'
import { checkErrorAndDispatchlogoutIfNeeded } from './sessionActions'
import { uiToast } from './ui'
import { TEAMS_PROFILE_USER_FETCH, TEAMS_INVITES_FETCH } from './teamDbActions'
import { generateRequestToast } from '../utils/toastCreator'
import * as _ from 'lodash'
import { eventShareSession } from '../api/gaHelper'

export const SEND_INVITE = 'SEND_INVITE'
export const FETCH_INVITES = 'FETCH_INVITES'
export const FETCH_INVITES_SUMMARY = 'FETCH_INVITES_SUMMARY'
export const CHANGE_INVITE_STATUS = 'CHANGE_INVITE_STATUS'
export const INVITE_TEAM_MEMBER = 'INVITE_TEAM_MEMBER'

function preValidateInvites (invites) {
  return invites && _.filter(invites, (i) => { return i.targetObject && i.ownerObject })
}

// export function sendInviteAction (token, invite) {
//   return (dispatch) => {
//     const actions = asyncActions(SEND_INVITE)
//     dispatch(actions.start())
//     return postInvite(token, invite).then(
//       result => dispatch(actions.success(result)),
//       err => dispatch(actions.error(err))
//     )
//   }
// }

export function fetchInvitesSummaryAction (token) {
  return (dispatch) => {
    const actions = asyncActions(FETCH_INVITES_SUMMARY)
    dispatch(actions.start())
    return fetchInvitesSummary(token).then(
      result => {
        return dispatch(actions.success(result))
      },
      error => {
        dispatch(actions.error(error))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

export function fetchAllProfileInvitesAction (token) {
  return (dispatch) => {
    const actions = asyncActions(FETCH_INVITES)
    dispatch(actions.start())
    return fetchInvites(token).then(
      result => {
        return dispatch(actions.success({ ...result, list: preValidateInvites(result.list) }))
      },
      error => {
        dispatch(actions.error(error))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

function handleTargetsInvites (token, invite, dispatch) {
  if (invite.targetType === 'team') {
    const fetchInvitesActions = asyncActions(TEAMS_INVITES_FETCH)
    dispatch(fetchInvitesActions.start())
    fetchTeamInvites(token, invite.targetObject.handle)
      .then((result) => {
        dispatch(fetchInvitesActions.success({ list: result }))
      })
      .catch(
        (err) => {
          dispatch(fetchInvitesActions.error(err))
        }
      )
  }
}

function handleteamInviteAccept (token, dispatch) {
  const actions = asyncActions(TEAMS_PROFILE_USER_FETCH)
  dispatch(actions.start())
  return fetchUserTeams(token, 0, { relationship: 'all' }).then(
    (result) => {
      dispatch(actions.success(result))
    }
  )
}

export function changeInviteStatusAction (token, invite, status, opts = {supressEntityInvitesRefresh: false}) {
  const acceptTeamInvite = status === INVITE_STATUS.ACCEPTED && invite.targetType === INVITE_TARGET_ENTITY_TYPES.TEAM

  return (dispatch) => {
    const actions = asyncActions(CHANGE_INVITE_STATUS)
    const fetchActions = asyncActions(FETCH_INVITES)

    dispatch(actions.start({ invite, status }))
    return changeInviteStatus(token, invite, status)
      .then(result => {
        dispatch(actions.success(result))
        // update the invites of the target entity
        !opts.supressEntityInvitesRefresh && handleTargetsInvites(token, invite, dispatch, opts)
        // update profile teams if needed
        acceptTeamInvite && handleteamInviteAccept(token, dispatch)
        // update header invites count
        fetchInvitesSummaryAction(token)(dispatch)
        // remove invite from ui
        status !== INVITE_STATUS.PENDING && status !== INVITE_STATUS.PENDING_REGISTRATION && dispatch(fetchActions.success({ remove: invite }))
      })
      .catch(
        error => {
          dispatch(actions.error(error))
          checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
        }
      )
  }
}

export function sendInvitesAction (token, specs) {
  return (dispatch) => {
    const actions = asyncActions(SEND_INVITE)
    const fetchActions = asyncActions(FETCH_INVITES)

    dispatch(actions.start())
    const promises = specs.map((spec) => {
      return postInvite(token, spec)
    })
    return Promise.all(promises)
      .then(result => {
        result.forEach(invt => {
          invt.targetType === INVITE_TARGET_ENTITY_TYPES.SESSION && eventShareSession()
          fetchActions.success({ add: invt })
        })
        dispatch(actions.success(result))
      })
      .catch(err => {
        dispatch(actions.error(err))
        checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
        throw (err)
      })
  }
}

export function inviteTeamMembersAction (token, specs) {
  return (dispatch) => {
    const actions = asyncActions(SEND_INVITE)
    const fetchInvitesActions = asyncActions(TEAMS_INVITES_FETCH)
    const fetchActions = asyncActions(FETCH_INVITES)

    dispatch(actions.start())
    const promises = specs.map((spec) => {
      return postInvite(token, spec)
    })

    return Promise.all(promises)
      .then(result => {
        result.forEach((invt) => {
          fetchActions.success({ add: invt })
        })
        dispatch(fetchInvitesActions.start())
        return fetchTeamInvites(token, specs[0].targetSpec)
      })
      .then((result) => {
        dispatch(fetchInvitesActions.success({ list: result }))
        // dispatch(uiToast(generateInvitesSentToast(specs, "team")))
      })
      .catch(
        (err) => {
          dispatch(actions.error(err))
          checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
        }
      )
  }
}

export function requestTeamMembershipOrSessionAccessAction (token, spec) {
  return (dispatch) => {
    const actions = asyncActions(SEND_INVITE)
    const fetchActions = asyncActions(FETCH_INVITES)

    dispatch(actions.start())
    return postInvite(token, spec)
      .then((result) => {
        dispatch(actions.success(result))
        dispatch(uiToast(generateRequestToast(spec)))
        dispatch(fetchActions.success({ add: result }))
      })
      .catch(
        (err) => {
          dispatch(actions.error(err))
          checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
        }
      )
  }
}

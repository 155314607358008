import * as _ from 'lodash'
import { AGE_30_DAY, getData, putData } from './apiCache'

const ALL_COUNTRIES_FILTERED_FIELDS = 'https://restcountries.com/v2/all?fields=name,flag,alpha2Code,alpha3Code'
const CACHE_KEY = 'countries_cache2'

export const getAllContries = () => {
  let cached = getData(CACHE_KEY, AGE_30_DAY)

  if (cached) {
    console.log('HIT CACHE')
    return new Promise((resolve, reject) => resolve(cached))
  }

  return fetch(ALL_COUNTRIES_FILTERED_FIELDS)
    .then((result) => {
      return result.json()
    })
    .then((result) => {
      putData(CACHE_KEY, result)
      return result
    })
}

export const getCountryByCode = (iso2code) => {
  return iso2code ? getAllContries()
    .then(
      (result) => {
        const country = _.find(result, (o) => { return o.alpha2Code === iso2code })
        return country ? { countryCode: country.alpha2Code, countryFlag: country.flag, countryName: country.name } : { countryCode: null, countryFlag: null, countryName: null }
      }
    ) : new Promise((resolve, reject) => { resolve({ countryCode: null, countryFlag: null, countryName: null }) })
}

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link'
import { Section, SignInButton, ExternalLink } from '../../components'
import { page } from '../../hocs/page'
import { GA_PAGES } from '../../api/gaHelper'
import { ROUTE_FAQ } from '../../utils/routingConstants'
import { getAvailableSubscriptions4Students } from '../../actions/sessionActions'
import { isPremiumUser } from '../../api/auth0'

const PlanTile = ({plan}) => {
  return <div className='plan'>
    <h3 className='plan-name'>{plan.name}</h3>
    <div className='plan-price'>{plan.price}</div>
    <div className='plan-price__explanation'>{plan.periodElement}</div>
    <div className='plan-description'>
      <div className='plan-description__header'>{plan.descTitle}</div>
      <ul>
        {plan.descriptionLines.map(descriptionLine => <li key={descriptionLine}>{descriptionLine}</li>)}
      </ul>
    </div>
    <div className='plan-footer'>
      {plan.signInText && <SignInButton isLink text={plan.signInText} /> }
      {plan.isCurrent && <Fragment>{plan.currentText || 'Your current plan'}</Fragment>}
      {!plan.isCurrent && plan.upgradeLinkElement && plan.upgradeLinkElement}
    </div>
  </div>
}

class PageStudentsSubscriptions extends Component {
  componentDidMount () {
    const { dispatch, session } = this.props
    if (session.isAnonymous) { return }

    dispatch(getAvailableSubscriptions4Students(session.token)).then(() => {
      window.setInterval(() => {
        window.sendOwl && window.sendOwl.captureLinks()
      })
    })
  }

  getPlans () {
    const {userSession, profileSubscriptionsStudents} = this.props

    // concider only subscriptions and let legacy users to upgrade
    const isUserPremium = isPremiumUser(userSession)

    if (!userSession.isAnonymous && (!profileSubscriptionsStudents.list || !profileSubscriptionsStudents.list.length)) {
      return []
    }

    const subscriptionStudent = profileSubscriptionsStudents.list[0]
    const price = `$${subscriptionStudent ? Number(subscriptionStudent.recurring_price) : 4}`

    return [
      {
        key: 'key-premium-student',
        name: 'Premium Student',
        price: price,
        periodElement: <span>per user / year</span>,
        descTitle: 'Premium plan:',
        descriptionLines: [
          'Unlimited session storage',
          'Create private sessions',
          'Auto-sync data and video'
        ],
        isCurrent: isUserPremium,
        currentText: 'You are already premium!',
        signInText: userSession.isAnonymous ? 'Sign in to upgrade' : null,
        upgradeLinkElement: !userSession.isAnonymous && <ExternalLink to={subscriptionStudent.url}>Go for premium</ExternalLink>
      }
    ]
  }

  render () {
    const {userSession} = this.props

    return (
      <Section className='page-pricing' title='Premium plan for students'>
        <p>Students who have submitted a picture of a valid student ID and graduation date
          to <a href='mailto:feedback@offcamberdata.com?subject=Student%20Discount'>feedback@offcamberdata.com</a> are eligible
          for purchasing the discounted student subscription. Student pricing is only valid while you are a student and will automatically
          convert to standard pricing after your graduation date.
        </p>
        <div className='plan-container'>
          {!userSession.isAnonymous && this.getPlans().map(plan => <PlanTile key={plan.key} plan={plan} />)}
          {userSession.isAnonymous && <SignInButton text='Sign in to see available plans' />}
        </div>
        <hr />
        <p>All Track Attack plans include the tools and features drivers, coaches and teams rely on to manage and understand driving data and video.</p>
        <p>All to get faster, faster. Find the right plan for you below. <Link to={`${ROUTE_FAQ}#free_trial`}>Click here for questions on how free trial works</Link></p>
      </Section>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    userSession: state.session,
    profileSubscriptionsStudents: state.store.profileSubscriptionsStudents
  }
}

export default page(connect(mapStateToProps)(PageStudentsSubscriptions), { pageView: GA_PAGES.PRICING_STUDENTS })

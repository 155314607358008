import { FETCH_INVITES, FETCH_INVITES_SUMMARY } from '../actions/inviteActions'
import { TEAMS_INVITES_FETCH } from '../actions/teamDbActions'
import { SESSION_INVITES_FETCH } from '../actions/resultsActions'
import { default as listReducer } from './listReducer'
import { default as itemReducer } from './itemReducer'
import { combineReducers } from 'redux'

export default combineReducers({
  profileInvites: listReducer(FETCH_INVITES),
  invitesSummary: itemReducer(FETCH_INVITES_SUMMARY),
  teamInvites: listReducer(TEAMS_INVITES_FETCH),
  sessionInvites: listReducer(SESSION_INVITES_FETCH)
})

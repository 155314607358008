import { TEAM_SUMMARY_FETCH, TEAM_SESSIONS_FETCH, TEAMS_VEHICLES_FETCH, TEAM_MEMBERS_FETCH, TEAMS_CREATE, TEAMS_FETCH, TEAMS_PROFILE_USER_FETCH, SELECTED_TEAM_FETCH, TEAM_DELETE, TEAM_UPDATE } from '../actions/teamDbActions'
import { combineReducers } from 'redux'
import { default as listReducer } from './listReducer'
import { default as itemReducer } from './itemReducer'

function onTeamCreate (result, error, context, state) {
  if (!(result || error)) {
    return state
  }

  if (error) {
    return {
      ...state,
      error: error
    }
  }

  if (result) {
    return {
      ...state,
      profileTeams: {
        list: [result].concat(state.profileTeams.list || [])
      }
    }
  }
}

const teamDeleted = (state, team) => {
  if (team && state.selectedTeam && state.selectedTeam.item.id === team.id) {
    return {...state, selectedTeam: {...state.selectedTeam, isDeleted: true}}
  }

  return state
}

const rootReducer = combineReducers({
  selectedTeam: itemReducer(SELECTED_TEAM_FETCH),
  updateTeam: itemReducer(TEAM_UPDATE),
  selectedMembers: listReducer(TEAM_MEMBERS_FETCH),
  selectedVehicles: listReducer(TEAMS_VEHICLES_FETCH),
  selectedSessions: listReducer(TEAM_SESSIONS_FETCH),
  profileTeams: listReducer(TEAMS_PROFILE_USER_FETCH),
  allTeams: listReducer(TEAMS_FETCH),
  summary: itemReducer(TEAM_SUMMARY_FETCH)
})

export default (state = {}, action) => {
  switch (action.type) {
    case TEAMS_CREATE:
      return onTeamCreate(action.result, action.error, action.context, state)
    case TEAM_DELETE:
      return teamDeleted(state, action.result)
    default:
      return rootReducer(state, action)
  }
}

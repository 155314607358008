import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter, Link} from 'react-router-dom'
import classnames from 'classnames'
import {filter} from 'lodash'
import {userDisplayName, userInitials} from '../utils/modelConvertionUtils'
import {logout} from '../actions/sessionActions'

class MobileNavigation extends Component {
  render () {
    const {session, navigation, dispatch} = this.props

    const filterExpr = session.isAnonymous
      ? (o) => {
        return o.protected !== 1
      }
      : (o) => {
        return o.protected !== -1
      }

    const renderUser = (userProfile) => {
      if (!userProfile) {
        return (
          <div className='mob-navigation-user'>TRACK ATTACK</div>
        )
      }

      return (
        <div className='mob-navigation-user'>
          <div className='mob-navigation-user_pic'>
            <div className='mob-navigation-user_initals'>
              {userInitials(userProfile)}
            </div>
          </div>
          <div className='mob-navigation-user_name'>{userDisplayName(userProfile, 16)}</div>
        </div>
      )
    }

    const doLogout = (userProfile) => {
      dispatch(logout())
    }

    return (
      <div className='mob-navigation-root'>
        {renderUser(session.profile)}
        <nav className='mob-navigation'>
          <ul>
            {filter(navigation, filterExpr).map(navItem => {
              return (
                <li
                  key={navItem.to}
                  className={classnames('mob-navigation-item', {'mob-navigation-item-active': navItem.isSelected})}>
                  <Link to={navItem.to} className='mob-navigation-link'>
                    {navItem.title}
                  </Link>
                </li>
              )
            })}
            {!session.isAnonymous && <li className='mob-navigation-item-separator' />}
            {!session.isAnonymous && <li className='mob-navigation-item'>
              <a href='' className='mob-navigation-link' onClick={e => doLogout(e)}>
                                    Logout
              </a>
            </li>}
          </ul>
        </nav>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {navigation: state.ui.navigation, session: state.session}
}

export default withRouter(connect(mapStateToProps)(MobileNavigation))

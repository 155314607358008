import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {fetchTracksAction} from '../actions/trackDbActions'
import {debounce} from 'lodash'

class TracksFilter extends Component {
  constructor () {
    super()
    this.handleInputChange = this
      .handleInputChange
      .bind(this)
    this.state = {}
    this.doDispatch = debounce(this.doDispatch, 400)
  }

  componentDidMount () {
    let {tracks, sort} = this.props
    // dispatch(fetchTracksAction(session.token, 0, tracks.filter))
    this.setState({
      ...tracks.filter,
      sort: sort
    })
  }

  componentWillUpdate (nextProps, nextState) {
    if (nextState.sort !== nextProps.sort) {
      this.setState({
        ...nextState,
        sort: nextProps.sort
      })
    }

    if (this.state !== nextState) {
      this.doDispatch(nextState)
    }
  }

  doDispatch (filter) {
    const {dispatch, session} = this.props
    dispatch(fetchTracksAction(session.token, 0, filter))
  }

  handleInputChange (event) {
    const target = event.target
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value

    const name = target.name

    if (name === 'filterSearch') {
      this.setState({
        ...this.state,
        name: value
      })
    }
  }

  render () {
    const filter = this.state
    const {trackHeaders} = this.props

    return (
      <div className='tracks-filter-container'>
        <label>SEARCH
          <input
            value={filter.name
              ? filter.name
              : ''}
            onChange={this.handleInputChange}
            type='text'
            name='filterSearch' />
        </label>
        <div className='tracks-filter-stats'>
          {trackHeaders && trackHeaders.pagination && <div className='tracks-filter-stats__item'>Filtered:&nbsp;{trackHeaders.pagination.totalCount}&nbsp;tracks</div>}
        </div>
      </div>
    )
  }
}
function mapStateToProps (state, ownProps) {
  return {tracks: state.tracks.all, session: state.session, sort: `${ownProps.sortName} ${ownProps.sortOrder}`, trackHeaders: state.tracks.all.headers}
}

export default withRouter(connect(mapStateToProps)(TracksFilter))

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import logo from '../images/header_logo.jpg'
import logoPremium from '../images/header_logo_premium.jpg'
import { login, isAdmin, isTrackmanager, isPremiumUser } from '../api/auth0'
import { logout } from '../actions/sessionActions'
import { withRouter } from 'react-router-dom'
import { ROUTE_ROOT, ROUTE_SETTINGS, ROUTE_USERS, ROUTE_ADMIN_NEW_TRACK, ROUTE_DOWNLOADS, ROUTE_ADMIN_KPI, ROUTE_ADMIN_STORE, ROUTE_PRICING, ROUTE_ADMIN_NOTIFICATIONS } from '../utils/routingConstants'
import FA from 'react-fontawesome'
import { MenuItem, Nav, NavDropdown, Navbar, NavItem, Badge } from 'react-bootstrap'
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap'
import { userInitials } from '../utils/modelConvertionUtils'
import { filter, mapValues } from 'lodash'
import { VehicleEditOverlay, TeamEditOverlay } from './'
import { MenuItemHack } from '../components'
import { pixelTrackSigninButton } from '../api/facebookPixelHelper'
import { uiRedirect } from '../actions/ui'
import { SENDOWL_JS_MERCHANT_ID } from '../constants'
import classnames from 'classnames'

class Header extends Component {
  constructor (props, context) {
    super(props, context)

    this.onClickedCreateNewTrack = this.onClickedCreateNewTrack.bind(this)
    this.doLogin = this.doLogin.bind(this)

    this.state = {
      menuItemsOpenState: {},
      cartWidgetInited: false
    }

    this.openMenuItem = this.openMenuItem.bind(this)
    this.closeMenuItem = this.closeMenuItem.bind(this)
  }

  doLogout (e) {
    e.preventDefault()
    this.props.dispatch(logout())
    this.props.dispatch(uiRedirect(ROUTE_ROOT))
  }

  doLogin (e) {
    e.preventDefault()
    pixelTrackSigninButton()
    login({to: window.location.pathname})
  }

  renderLogin () {
    return (
      <Nav id='nav_profile' className='sign-in' bsStyle='pills' activeKey='0' pullRight>
        <NavItem eventKey={1} onClick={this.doLogin} ><FA name='sign-in' className='signin__icon' /> <span className='signin__text' /></NavItem>
      </Nav>
    )
  }

  initCartWidget () {
    const parent = document.getElementById('cart-widget-placeholder')
    if (!parent || !parent.children || parent.children.length > 0) {
      return
    }

    const { profile } = this.props.session
    window.sendOwl && window.sendOwl.cartWidget({
      parent: document.getElementById('cart-widget-placeholder'),
      merchantID: SENDOWL_JS_MERCHANT_ID,
      size: 'small',
      varianl: 'light',
      customStyle: true,
      cartPhrase: '<span aria-hidden="true" class="fa fa-shopping-cart"></span>',
      useCartImage: false,
      urlAppend: `tag=${profile.id}&buyer_email=${profile.email}&buyer_name=${profile.firstName || ''} ${profile.lastName || ''}`
    })
    this.setState({cartWidgetInited: true})
  }

  deleteCartWidget () {
    const parent = document.getElementById('cart-widget-placeholder')
    if (!parent || !parent.children || parent.children.length > 0) {
      return
    }

    while (parent.firstChild) {
      parent.removeChild(parent.firstChild)
    }

    this.setState({cartWidgetInited: false})
  }

  componentDidUpdate (newProps, newState) {
    if (!newState.cartWidgetInited) {
      const { profile } = newProps.session
      profile && profile.id && this.initCartWidget(profile)
    }

    if (newState.isCartWidgetInited) {
      const { profile } = newProps.session
      profile && profile.id && this.deleteCartWidget(profile)
    }
  }

  renderCartAnonymous () {
    // no anonymous cart for now
    // const {cartItemsCount} = this.props

    // return <Nav id='nav_cart' bsStyle='pills' activeKey='0' pullRight>
    //   <NavItem eventKey={99} onClick={this.doLogin}>
    //     <FA name='shopping-cart' />
    //     {cartItemsCount > 0 && <Badge>{cartItemsCount}</Badge>}
    //   </NavItem>
    // </Nav>
  }

  renderCartUser () {
    return <Nav id='nav_cart-2' bsStyle='pills' activeKey='0' pullRight>
      <NavItem>
        <div id='cart-widget-placeholder' />
      </NavItem>
    </Nav>
  }

  renderProfileMenu (profile) {
    const {session} = this.props

    const myProfilePath = ROUTE_USERS + '/' + profile.handle

    const profileTitle = profile.avatarUrl ? <img className='avatar' src={profile.avatarUrl} alt='avatar' />
      : <span className='avatar-text'>{userInitials(profile, 1)}</span>

    const renderUpgradeLink = () => {
      return <Fragment>
        <MenuItem divider />
        <LinkContainer to={ROUTE_PRICING} key={ROUTE_PRICING}>
          <MenuItem eventKey='999'><FA name='trophy' />&nbsp; Go Premium</MenuItem>
        </LinkContainer>
      </Fragment>
    }

    return (
      <Nav id='nav_profile' bsStyle='pills' activeKey='0' pullRight>
        <NavDropdown eventKey='0' title={profileTitle} id='nav-dropdown' pullRight>
          <MenuItem header>
            {`Signed in as @${profile.handle}`}
          </MenuItem>

          { !isPremiumUser(session) && renderUpgradeLink()}
          <MenuItem divider />

          <LinkContainer to={myProfilePath} key={myProfilePath}>
            <MenuItem eventKey='1'><FA name='user' />&nbsp; Your profile</MenuItem>
          </LinkContainer>
          <LinkContainer to={myProfilePath + '?tab=garage'} key={myProfilePath + '?tab=garage'}>
            <MenuItem eventKey='2'><FA name='cab' />&nbsp; Your garage</MenuItem>
          </LinkContainer>
          <LinkContainer to={myProfilePath + '?tab=teams'} key={myProfilePath + '?tab=teams'}>
            <MenuItem eventKey='3'><FA name='group' />&nbsp; Your teams</MenuItem>
          </LinkContainer>
          {/* <LinkContainer to={myProfilePath + '?tab=notifications'} key={myProfilePath + '?tab=notifications'}>
            <MenuItem eventKey='4'><FA name='envelope' />&nbsp; Messages</MenuItem>
          </LinkContainer> */}

          <MenuItem divider />
          <LinkContainer to={ROUTE_DOWNLOADS} key={ROUTE_DOWNLOADS}>
            <MenuItem eventKey='13'><FA name='download' />&nbsp; Downloads</MenuItem>
          </LinkContainer>
          <LinkContainer to={ROUTE_SETTINGS} key={ROUTE_SETTINGS}>
            <MenuItem eventKey='10'><FA name='cog' />&nbsp; Settings</MenuItem>
          </LinkContainer>
          <LinkContainer to='#logout' key='logout'>
            <MenuItem eventKey='4' onClick={e => this.doLogout(e)}><FA name='sign-out' />&nbsp; Logout</MenuItem>
          </LinkContainer>

          {/* render admin menu */}
          {/* HACK: Wrapping into fragment causes weird dropdown issues */}
          {isAdmin(session) && <MenuItem divider />}
          {isAdmin(session) && <MenuItem header>
              Admin level operations
          </MenuItem>}
          {isAdmin(session) && <LinkContainer to={ROUTE_ADMIN_KPI} key='kpi'>
            <MenuItem eventKey='101'><FA name='bar-chart' />&nbsp; KPI</MenuItem>
          </LinkContainer>}
          {isAdmin(session) && <LinkContainer to={ROUTE_ADMIN_STORE} key='storeadmin'>
            <MenuItem eventKey='102'><FA name='shopping-cart' />&nbsp; Store admin</MenuItem>
          </LinkContainer>}
          {isAdmin(session) && <LinkContainer to={ROUTE_ADMIN_NOTIFICATIONS} key='notifdmin'>
            <MenuItem eventKey='103'><FA name='envelope' />&nbsp; Notifications admin</MenuItem>
          </LinkContainer>}

        </NavDropdown>
      </Nav>)
  }

  onClickedCreateNewTrack () {
    const { dispatch } = this.props
    dispatch(uiRedirect(ROUTE_ADMIN_NEW_TRACK))
  }

  renderAddMenu () {
    const {session} = this.props
    return (
      <Nav id='nav_add' bsStyle='pills' activeKey='0' pullRight>
        <NavDropdown eventKey='0' title={<span className='toggle'><FA name='plus' /></span>} id='nav-dropdown-add'>
          <MenuItemHack><VehicleEditOverlay openButton={<div><FA name='cab' />&nbsp; Create new vehicle</div>} /></MenuItemHack>
          <MenuItemHack><TeamEditOverlay openButton={<div><FA name='group' />&nbsp; Create new team</div>} /></MenuItemHack>
          {isTrackmanager(session) && <MenuItemHack><div onClick={this.onClickedCreateNewTrack}><FA name='cab' />&nbsp; Create new track</div></MenuItemHack>}
        </NavDropdown>
      </Nav>)
  }

  renderNotificationsMenu () {
    const {invitesSummary, session} = this.props
    const myProfilePath = ROUTE_USERS + '/' + session.profile.handle

    // const hasNotifications = unreadNotifications.item && unreadNotifications.item.list && unreadNotifications.item.list.length > 0
    const hasInvites = !invitesSummary.isFetching && invitesSummary.item && invitesSummary.item.invitationsFromOthers > 0

    const somethingHappened = hasInvites // || hasNotifications

    const heading = somethingHappened ? 'You have some updates'
      : invitesSummary.isFetching ? 'Loading...' : 'Nothing has happened yet'

    const profileInvitesCount = invitesSummary.item ? invitesSummary.item.invitationsFromOthers : 0
    const notificationsCount = 0
    // unreadNotifications.item
    //   ? (unreadNotifications.item.headers && unreadNotifications.item.headers.pagination && unreadNotifications.item.headers.pagination.totalCount) || 0
    //   : 0

    return (
      <Nav id='nav_notifications' bsStyle='pills' activeKey='0' pullRight>
        <NavDropdown eventKey='0' title={<span className='toggle'>
          <FA name='bell' />
          {somethingHappened && <Badge>{profileInvitesCount + notificationsCount}</Badge>}
        </span>} id='nav-dropdown-add'>
          <MenuItem header>
            {heading}
          </MenuItem>

          {somethingHappened && <MenuItem divider /> }

          {profileInvitesCount > 0 && <LinkContainer to={myProfilePath + '?tab=invites'} key={myProfilePath + '?tab=invites'}>
            <MenuItem eventKey='1'><FA name='group' />&nbsp; {`You have ${profileInvitesCount} new invite(s)`}</MenuItem>
          </LinkContainer>}
          {notificationsCount > 0 && <LinkContainer to={myProfilePath + '?tab=notifications'} key={myProfilePath + '?tab=notifications'}>
            <MenuItem eventKey='2'><FA name='envelope' />&nbsp; {`You have ${notificationsCount} unread messages`}</MenuItem>
          </LinkContainer>}

        </NavDropdown>
      </Nav>)
  }

  openMenuItem (navItem) {
    const {menuItemsOpenState} = this.state
    const allClosed = mapValues(menuItemsOpenState, () => false)

    this.setState({
      menuItemsOpenState: {...allClosed, [navItem.id]: true}
    })
  }

  closeMenuItem (navItem) {
    const {menuItemsOpenState} = this.state
    const allClosed = mapValues(menuItemsOpenState, () => false)

    this.setState({
      menuItemsOpenState: allClosed
    })
  }

  generateDropdownWithSubItems (navItem, key) {
    const {menuItemsOpenState} = this.state

    return (
      <NavDropdown
        onMouseEnter={() => { this.openMenuItem(navItem) }}
        onMouseLeave={() => { this.closeMenuItem(navItem) }}
        onToggle={() => {}}
        eventKey='0'
        open={menuItemsOpenState[navItem.id]}
        id={`nav-dropdown-${navItem.id}`}
        title={<span className='toggle'>{navItem.title}</span>}
        key={key} className={classnames('nav-dropdown-mainmenu', {'active': navItem.isSelected})} >
        {navItem.subItems.map((subItem, idx) => {
          return <LinkContainer to={subItem.to} key={idx}>
            <MenuItem eventKey={idx + 1}>{subItem.title}</MenuItem>
          </LinkContainer>
        })}
      </NavDropdown>
    )
  }

  renderNavigation (navigation, session) {
    const filterExpr = session.isAnonymous
      ? o => o.protected <= 0 // common & public only
      : isAdmin(session)
        ? o => o.protected >= 0 // common & user
        : o => (o.protected >= 0 && o.protected < 2) // common & user & admin

    return (
      <Nav bsStyle='pills'>
        {filter(navigation, filterExpr).map((navItem, i) => {
          if (navItem.subItems) {
            return this.generateDropdownWithSubItems(navItem, i)
          } else {
            return (
              <IndexLinkContainer className={navItem.className} active={navItem.isSelected} activeClassName='active' to={navItem.to} key={i}>
                <NavItem>
                  <div>{navItem.title}</div>
                </NavItem>
              </IndexLinkContainer>
            )
          }
        })}
      </Nav>
    )
  }

  render () {
    const { session, navigation } = this.props
    return (
      <Navbar inverse id='nav_header'>
        <Navbar.Header className={classnames({'premium': isPremiumUser(session)})}>
          <Navbar.Brand>
            <LinkContainer to={ROUTE_ROOT}>
              <img src={isPremiumUser(session) ? logoPremium : logo} alt='logo' />
            </LinkContainer>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          {this.renderNavigation(navigation, session)}

          {session.isAnonymous && !session.isFetching && this.renderLogin()}
          {!session.isAnonymous && !session.isFetching && this.renderProfileMenu(session.profile)}
          {!session.isAnonymous && this.renderCartUser()}
          {/* {session.isAnonymous && this.renderCartAnonymous()} */}
          {!session.isAnonymous && !session.isFetching && this.renderAddMenu()}
          {!session.isAnonymous && !session.isFetching && this.renderNotificationsMenu() }
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    navigation: state.ui.navigation,
    session: state.session,
    ui: state.ui.header,
    invitesSummary: state.invites.invitesSummary,
    cartItemsCount: state.store.cartItemsCount,
    unreadNotifications: state.notifications.unreadNotifications
  }
}

export default withRouter(connect(mapStateToProps)(Header))

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Overlay } from '../components'
import { Button } from 'react-bootstrap'
import { VehicleSettings } from './'
import { createVehicle, updateVehicle } from '../actions/garageActions'

class VehicleEditOverlay extends React.Component {
  static propTypes = {
    mode: PropTypes.oneOf(['create', 'edit']),
    openButton: PropTypes.element
  }

  static defaultProps = {
    mode: 'create'
  }

  constructor (props) {
    super(props)
    this.state = { show: false }

    this.okOperation = this.okOperation.bind(this)
    this.cancelOperation = this.cancelOperation.bind(this)
    this.openOverlay = this.openOverlay.bind(this)
  }

  generateOverlayTitle () {
    const { mode, selectedVehicle } = this.props

    return mode === 'edit' ? `Edit ${selectedVehicle.item.name}` : 'Create new vehicle'
  }

  generateOpenButton () {
    const { openButton, mode } = this.props

    if (openButton) {
      return <span onClick={this.openOverlay}>{openButton}</span>
    }

    return <Button onClick={this.openOverlay}>{mode === 'edit' ? 'Edit' : 'Create vehicle'}</Button>
  }

  openOverlay (event) {
    event.stopPropagation()
    event.preventDefault()

    this.setState({ show: true })
  }

  render () {
    const { session, vehicleUIState, mode } = this.props

    if (session.isAnonymous) {
      return null
    }

    return <div>
      { this.generateOpenButton() }
      { this.state.show && <Overlay
        show
        title={this.generateOverlayTitle()}
        buttons={Overlay.createButtons([{ Save: this.okOperation, disabled: !vehicleUIState.valid, bsStyle: 'primary' }, { Cancel: this.cancelOperation }])}
        closeAction={this.cancelOperation}>
        <VehicleSettings mode={mode} />
      </Overlay>}
    </div>
  }

  cancelOperation () {
    this.setState({ show: false })
  }

  okOperation () {
    const { updatingVehicle, vehicleUIState, dispatch, session, mode } = this.props

    if (mode === 'edit') {
      dispatch(updateVehicle({ ...updatingVehicle, newAvatar: vehicleUIState.avatarPreview || null }, session.token))
    } else {
      dispatch(createVehicle({ ...updatingVehicle, newAvatar: vehicleUIState.avatarPreview || null }, session.token))
    }

    this.setState({ show: false })
  }
}

function mapStateToProps (state) {
  return {
    session: state.session,
    updatingVehicle: state.ui.vehicles.settings,
    vehicleUIState: state.ui.vehicles.uiState,
    selectedVehicle: state.garage.selectedVehicle
  }
}

export default connect(mapStateToProps)(VehicleEditOverlay)

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ROUTE_NEWS } from '../utils/routingConstants'
import PropTypes from 'prop-types'
import moment from 'moment'
import noImage from '../images/no_image.jpg'

class NewsItemCard extends Component {
  static propTypes = {
    newsitem: PropTypes.object.isRequired
  }

  render () {
    const {newsitem} = this.props

    const imageSrc = newsitem.jetpack_featured_media_url || (newsitem.img && newsitem.img.src) || noImage

    const mDate = moment(newsitem.date, moment.ISO_8601)
    // if the date is not ISO 8601 assume the date is provided as human-readable text

    const date = mDate.isValid() ? mDate.format('MMMM DD, YYYY') : newsitem.date

    return <Link to={`${ROUTE_NEWS}/${newsitem.id}`}>
      <div className='newscard' {...this.props} >
        <h2 dangerouslySetInnerHTML={{ __html: (newsitem.title.rendered || newsitem.title) }} />
        <div className='newscard__date'>{date}</div>
        <img className='newscard__img' src={imageSrc} alt={newsitem.title.rendered || newsitem.title} />
        <div className='newscard__text' dangerouslySetInnerHTML={{ __html: (newsitem.excerpt.rendered || newsitem.excerpt) }} />
      </div>
    </Link>
  }
}

export default NewsItemCard

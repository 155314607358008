// Delays loading untill the store is rehydrated
import { persistStore, createTransform } from 'redux-persist'
import {
  ROUTE_ROOT,
  ROUTE_SESSIONS,
  ROUTE_TRACKS,
  ROUTE_TEAMS,
  ROUTE_USERS,
  ROUTE_TRACKS_DB,
  ROUTE_SETTINGS,
  ROUTE_ADMIN_NEW_TRACK,
  ROUTE_ADMIN_EDIT_TRACK,
  ROUTE_AUTH_CALLBACK,
  ROUTE_AUTH_PROFILECHECK,
  ROUTE_VEHICLES,
  ROUTE_DOWNLOADS,
  ROUTE_PRIVACY,
  ROUTE_TOS,
  ROUTE_ADMIN_KPI,
  ROUTE_FAQ,
  ROUTE_AUTH_LICENSECHECK,
  ROUTE_PRICING,
  ROUTE_ROOT_SOLUTIONS,
  ROUTE_IRACING,
  ROUTE_ASSETTOCORSA,
  ROUTE_STORE_CART,
  ROUTE_STORE,
  ROUTE_ADMIN_STORE,
  ROUTE_PURCHASECOMPLETED,
  ROUTE_VIEWER,
  ROUTE_SUPPORTED_DEVICES_AND_CHANNELS,
  ROUTE_SSCHALKTALK,
  ROUTE_PRICING_STUDENTS,
  ROUTE_LEADERBOARDS,
  ROUTE_NEWS,
  ROUTE_ADMIN_NOTIFICATIONS,
  ROUTE_PURCHASECOMPLETED_STRIPE
} from '../utils/routingConstants'
import { configureStore } from '../configureStore'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import {
  App,
  Landing,
  // SessionDashboard,
  PageTrackProfile,
  PageTracksDatabase,
  PageTracks,
  PageTeams,
  PageTeamProfile,
  PageUsers,
  PageUserProfile,
  PageGarage,
  PageSessions,
  PageSessionProfile,
  PageVehicle,
  PageSettings,
  PageAuthCallback,
  PageProfileCheck,
  PageDownloads,
  PageTrackEdit,
  PagePrivacyPolicy,
  PageTermsOfService,
  PageKPI,
  PageLicenseCheck,
  Page404,
  PageFaq,
  PagePricing,
  PageCart,
  PageStore,
  LandingValuePage,
  LandingIRacingPage,
  LandingAssettoCorsaPage,
  PageStoreAdmin,
  PageStoreAdminProduct,
  PageStoreProduct,
  PagePurcahseCallback,
  PageViewer,
  PageSupportedChannelsAndDevices,
  PageSpeedSecretsChalcTalk,
  PageStudentsSubscriptions,
  PageLeaderboards,
  PageNews,
  PageNewsArticle,
  PageAdminNotifications
} from './'
import { omit } from 'lodash'
import React, { Component } from 'react'
import ProtectedRoute from './ProtectedRoute'
import { ROLES } from '../api/auth0'
import PageStripePurcahseCallback from './pages/PageStripePurcahseCallback'

const store = configureStore()

// * store only SESSION
// * do not persist isFetching from the session
// * state in, state out, whitelist
const transformRemoveFetchErr = createTransform((state) => omit(state, ['isFetching', 'error']), (state) => (state), { whitelist: ['session'] })

class RehydratationDelayedProvider extends Component {
  constructor () {
    super()
    this.state = {
      rehydrated: false
    }
  }

  componentWillMount () {
    persistStore(store, {
      whitelist: [
        'misc', 'session'
      ],
      transforms: [transformRemoveFetchErr]
    }, () => {
      console.log('STORE RECOVERED!')
      console.log(store.getState())
      this.setState({ rehydrated: true })
    })
  }

  getUserPath () {
    const session = store.getState().session
    return session.isAnonymous ? '/' : `${ROUTE_USERS}/${session.profile.handle}`
  }

  render () {
    if (!this.state.rehydrated) {
      return <div className='loading-site-root'>Loading...</div>
    }
    return (
      <Provider store={store}>
        <Router>
          <App>
            <Switch>
              <Route path={ROUTE_TOS} component={PageTermsOfService} />
              <Route path={ROUTE_PRIVACY} component={PagePrivacyPolicy} />

              <Route path={ROUTE_TRACKS_DB} component={PageTracksDatabase} />
              <Route path={ROUTE_TRACKS + '/:handle'} component={PageTrackProfile} />
              <Route path={ROUTE_TRACKS} component={PageTracks} />
              <Route path={ROUTE_AUTH_CALLBACK} component={PageAuthCallback} />
              <Route path={ROUTE_AUTH_PROFILECHECK} component={PageProfileCheck} />
              <Route path={ROUTE_AUTH_LICENSECHECK} component={PageLicenseCheck} />
              <Route path={ROUTE_USERS + '/:handle'} component={PageUserProfile} />
              <Route path={ROUTE_USERS} component={PageUsers} />
              <Route path={ROUTE_TEAMS + '/:handle'} component={PageTeamProfile} />
              <Route path={ROUTE_TEAMS} component={PageTeams} />
              <Redirect path={'/me'} to={this.getUserPath()} />
              <Redirect path={'/user'} to={this.getUserPath()} />
              <Route path={ROUTE_SESSIONS + '/:uuid'} component={PageSessionProfile} />
              <Route path={ROUTE_SESSIONS} component={PageSessions} />
              <Route path={ROUTE_VEHICLES + '/:handle'} component={PageVehicle} />
              <Route path={ROUTE_VEHICLES} component={PageGarage} />
              <Route path={ROUTE_DOWNLOADS} component={PageDownloads} />
              <Route path={ROUTE_FAQ} component={PageFaq} />
              <Route path={ROUTE_PRICING_STUDENTS} component={PageStudentsSubscriptions} />
              <Route path={ROUTE_PRICING} component={PagePricing} />
              <Route path={ROUTE_NEWS + '/:id'} component={PageNewsArticle} />
              <Route path={ROUTE_NEWS} component={PageNews} />
              <Route path={ROUTE_SUPPORTED_DEVICES_AND_CHANNELS} component={PageSupportedChannelsAndDevices} />
              {/* <Route path={ROUTE_CONTACTS} component={PageContacts} /> */}

              <ProtectedRoute path={ROUTE_STORE_CART} component={PageCart} />
              <Route path={ROUTE_STORE + '/:productId'} component={PageStoreProduct} />
              <Route path={ROUTE_STORE} component={PageStore} />
              <Route path={ROUTE_PURCHASECOMPLETED} component={PagePurcahseCallback} />
              <Route path={ROUTE_PURCHASECOMPLETED_STRIPE} component={PageStripePurcahseCallback} />

              <Route path={ROUTE_VIEWER + '/:params'} component={PageViewer} />

              <Route path={ROUTE_LEADERBOARDS} component={PageLeaderboards} />
              <ProtectedRoute path={ROUTE_SETTINGS} component={PageSettings} />

              <ProtectedRoute roles={[ROLES.TRACKMANAGER]} path={ROUTE_ADMIN_NEW_TRACK} component={PageTrackEdit} />
              <ProtectedRoute roles={[ROLES.TRACKMANAGER]} path={ROUTE_ADMIN_EDIT_TRACK + '/:uuid'} component={PageTrackEdit} />

              <ProtectedRoute roles={[ROLES.TRACKMANAGER, ROLES.ADMIN]} path={ROUTE_ADMIN_NOTIFICATIONS} component={PageAdminNotifications} />
              <ProtectedRoute roles={[ROLES.TRACKMANAGER, ROLES.ADMIN]} path={ROUTE_ADMIN_KPI} component={PageKPI} />
              <ProtectedRoute roles={[ROLES.TRACKMANAGER, ROLES.ADMIN]} path={ROUTE_ADMIN_STORE + '/:productId'} component={PageStoreAdminProduct} />
              <ProtectedRoute roles={[ROLES.TRACKMANAGER, ROLES.ADMIN]} path={ROUTE_ADMIN_STORE} component={PageStoreAdmin} />

              <Route path={ROUTE_ROOT_SOLUTIONS + '/:pageType'} component={LandingValuePage} />

              <Route path={ROUTE_SSCHALKTALK} component={PageSpeedSecretsChalcTalk} />

              <Route path={ROUTE_IRACING} component={LandingIRacingPage} />
              <Route path={ROUTE_ASSETTOCORSA} component={LandingAssettoCorsaPage} />
              <Route path={ROUTE_ROOT} exact component={Landing} />
              <Route component={Page404} />
            </Switch>
          </App>
        </Router>
      </Provider>
    )
  }
}

export default RehydratationDelayedProvider

import {
  FETCH_STOREFRONT,
  FETCH_PURCHASES,
  FETCH_ADMIN_PRODUCTS,
  UPDATE_ADMIN_PRODUCTS,
  FETCH_PRODUCT,
  FETCH_CART_COUNT,
  FETCH_PRODUCT_CONTENT,
  FETCH_ORDERS,
  FETCH_DATA_PACK_SESSIONS_LIST
} from '../actions/storeActions'
import {FETCH_PROFILE_SUBSCRIPTIONS_LIST, FETCH_PROFILE_SUBSCRIPTIONS_LIST_STUDENTS} from '../actions/sessionActions'

import { default as listReducer } from './listReducer'
import { default as itemReducer } from './itemReducer'
import { combineReducers } from 'redux'

const initialadminStoreFrontUpdateState = {
  updatedProducts: [],
  importedProducts: []
}

const storeUpdateResult = (state = initialadminStoreFrontUpdateState, action) => {
  switch (action.type) {
    case UPDATE_ADMIN_PRODUCTS:
      return action.result || initialadminStoreFrontUpdateState
    case FETCH_ADMIN_PRODUCTS:
      // reset
      return action.context && action.context.persistStoreUpdate ? state : initialadminStoreFrontUpdateState
    default:
      return state
  }
}

const cartCountReducer = (state = 0, action) => {
  switch (action.type) {
    case FETCH_CART_COUNT:
      return action.result || 0
    default:
      return state
  }
}

export default combineReducers({
  storefront: listReducer(FETCH_STOREFRONT),
  purchases: listReducer(FETCH_PURCHASES),
  storeProductsList: listReducer(FETCH_ADMIN_PRODUCTS),
  selectedProduct: itemReducer(FETCH_PRODUCT),
  storeUpdateResult,
  cartItemsCount: cartCountReducer,
  productContent: itemReducer(FETCH_PRODUCT_CONTENT),
  dataPackSessionsList: listReducer(FETCH_DATA_PACK_SESSIONS_LIST),
  orders: listReducer(FETCH_ORDERS),
  profileSubscriptions: listReducer(FETCH_PROFILE_SUBSCRIPTIONS_LIST),
  profileSubscriptionsStudents: listReducer(FETCH_PROFILE_SUBSCRIPTIONS_LIST_STUDENTS)
})

import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Footer } from '../components'
import { Header, MobileNavigation, WelcomeConfigurationOverlayWithTabs } from '../containers'
import { Redirect } from 'react-router'
import { withRouter } from 'react-router-dom'
import { fetchAllLookups } from '../actions/basicDataActions'
import { uiToggleSidebar, uiRedirect, uiToast } from '../actions/ui'
import { cartItemsCount } from '../actions/storeActions'
import { fetchProfileAction, getAvailableSubscriptions } from '../actions/sessionActions'
import { fetchInvitesSummaryAction, fetchAllProfileInvitesAction } from '../actions/inviteActions'
// import { fetchUnreadNotifications } from '../actions/notificationsActions'

import Sidebar from 'react-sidebar'
import { ToastContainer, toast } from 'react-toastify'
import { Alert } from 'react-bootstrap'

class App extends Component {
  static Container (props) {
    return <div>{props.children}</div>
  }

  componentWillMount () {
    const {dispatch, session} = this.props
    dispatch(fetchAllLookups(session.token))
    dispatch(cartItemsCount())
    session.token &&
      dispatch(fetchProfileAction(session.token)) &&
      dispatch(fetchInvitesSummaryAction(session.token)) &&
      dispatch(fetchAllProfileInvitesAction(session.token)) &&
      dispatch(getAvailableSubscriptions(session.token))
      // dispatch(fetchUnreadNotifications(session.token))
  }

  componentWillReceiveProps (newProps) {
    const { uiStateToast, dispatch } = this.props

    if (!uiStateToast.text && newProps.uiStateToast.text) {
      // dismiss all toasts
      // clear UI state

      // Use Container to avoid warning
      // https://github.com/fkhadra/react-toastify/issues/14
      const element = <App.Container><Alert bsStyle={newProps.uiStateToast.bsStyle || 'info'}>
        <div className='toast-message'>{newProps.uiStateToast.text}</div>
        <div className='toast-close-btn'>✖</div>
      </Alert></App.Container>

      // toast.dismiss()
      toast(
        element, {
          // onClose: ()=>{ },
          closeButton: false,
          className: 'toast-body'
        }
      )

      // clear state for upcomming toasts
      dispatch(uiToast({}))
    }

    const {history, uiStateRedirect} = newProps

    if (uiStateRedirect.to && history.location.pathname === uiStateRedirect.to) {
      dispatch(uiRedirect())
    }

    const {session} = this.props
    const newSession = newProps.session
    if (session && session.isAnonymous && !newSession.isAnonymous) {
      // fetch invites after the login completed!
      dispatch(fetchInvitesSummaryAction(newSession.token))
      dispatch(fetchAllProfileInvitesAction(newSession.token))
    }
  }

  render () {
    const {dispatch, session, uiStateRedirect, history} = this.props
    const hasHandle = session && session.profile && session.profile.handle

    const onSetSidebarOpen = (open) => {
      dispatch(uiToggleSidebar())
    }

    if (uiStateRedirect.to && history.location.pathname !== uiStateRedirect.to) {
      return <Redirect to={uiStateRedirect.to} />
    }

    const {children, ui} = this.props
    return (
      <div className='app-root'>
        { !session.isAnonymous && (session.welcomeProgress >= 0 || !hasHandle) && <WelcomeConfigurationOverlayWithTabs />}
        <ToastContainer
          autoClose={5000}
          position={toast.POSITION.TOP_CENTER}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          className={'toast-root'}
        />
        <Sidebar
          sidebar={<MobileNavigation />}
          open={ui.header.sidebarVisible}
          onSetOpen={onSetSidebarOpen}>
          <div className='content-root'>
            <Header />
            <main className='app-content'>
              {children}
            </main>
            <Footer />
          </div>
        </Sidebar>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {session: state.session, ui: state.ui, uiStateToast: state.ui.toast, uiStateRedirect: state.ui.redirect}
}

export default withRouter(connect(mapStateToProps)(App))

import {
  NOTIFICATIONS_USER_ALL_FETCH,
  NOTIFICATIONS_ADMIN_ALL_FETCH,
  NOTIFICATIONS_UNREAD_FETCH
} from '../actions/notificationsActions'
import { default as listReducer } from './listReducer'
import { default as itemReducer } from './itemReducer'
import { combineReducers } from 'redux'

export default combineReducers({
  allUserNotifications: listReducer(NOTIFICATIONS_USER_ALL_FETCH),
  allAdminNotifications: listReducer(NOTIFICATIONS_ADMIN_ALL_FETCH),
  unreadNotifications: itemReducer(NOTIFICATIONS_UNREAD_FETCH)
})

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { singleTrackFetch } from '../../actions/trackDbActions'
import { ROUTE_TRACKS, ROUTE_ADMIN_EDIT_TRACK } from '../../utils/routingConstants'
import { LayoutList, WeatherWidget, Section, TrackDescription, SocialIcons, Leaderboard, Error404, SimBadge } from '../../components'
import { Row, Grid, Col, Button, Badge } from 'react-bootstrap'
import { find, uniqBy, sortBy } from 'lodash'
import noImage from '../../images/no_image.jpg'
import { getMeasuringUnitsFromProfile } from '../../utils/modelConvertionUtils'
import { pageView, GA_PAGES } from '../../api/gaHelper'
import { isTrackmanager } from '../../api/auth0'
import { page } from '../../hocs/page'
import { uiToast } from '../../actions/ui'

class PageTrackProfile extends Component {
  constructor (props, context) {
    super(props, context)

    this.onClickEdit = this.onClickEdit.bind(this)
    this.layoutClicked = this.layoutClicked.bind(this)
  }

  componentWillMount () {
    const { selected, handle, session } = this.props
    if (!selected || !selected.item || selected.item.handle !== handle) {
      this.handleTrackSelected(handle)
    }
    pageView(GA_PAGES.TRACK + handle, session)
  }

  componentWillReceiveProps (nextProps) {
    const { handle } = this.props

    if (handle && handle !== nextProps.handle) {
      this.handleTrackSelected(nextProps.handle)
    }
  }

  handleTrackSelected (trackHandle) {
    const { dispatch, selected, session, tracks } = this.props
    if (!selected || !selected.item || selected.item.handle !== trackHandle) {
      const target = tracks.list ? find(tracks.list, { handle: trackHandle }) : null
      const units = getMeasuringUnitsFromProfile(session.profile)
      dispatch(singleTrackFetch(session.token, trackHandle, target, units))
    }
  }

  layoutClicked (layout) {
    const { selected, dispatch } = this.props

    if (selected.item) {
      const link = `${window.location.origin}${ROUTE_TRACKS}/${selected.item.handle}?leaderboard=${layout.id}`

      const textField = document.createElement('textarea')
      textField.innerText = link
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()

      dispatch(uiToast({text: 'Leaderboard link was copied to the clipboard'}))
    }
  }

  renderMeteo (track) {
    if (track && (!track.locationLat || !track.locationLong)) {
      return null
    } else {
      return <Section title={'Weather'}><WeatherWidget track={track} meteo={!track || !track.meteo ? null : track.meteo} /></Section>
    }
  }

  follow (track) {
  }

  getTrackAddr (track) {
    try {
      return track.locationData.resourceSets[0].resources[0].address.formattedAddress
    } catch (err) {
      return null
    }
  }

  renderSummary (track) {
    if (!track) {
      return ''
    }

    const layouts = track.layouts ? sortBy(uniqBy(track.layouts, 'simulatorPlatform'), 'simulatorPlatform') : []
    const handle = track.handle || track.name.replace(/\s/g, '').slice(0, 12).toLowerCase()
    return (
      <div>
        <img className='page__logo' src={track.avatarUrl || noImage} alt='track' />
        {track.isOfficial && <Badge className='official'>official</Badge>}
        {layouts.map((layout) => <SimBadge key={layout.id} target={layout} />)}
        <h4>{track.name}</h4>
        <h5>{'@' + handle}</h5>
        {track.countryCode && <div className='page__country'>
          <img src={track.countryFlag} alt='flag' />
          <span>{track.countryName}</span>
        </div>}
        {/* <div><Button block onClick={this.follow.bind(this, track)}>Follow</Button></div> */}
        <SocialIcons entity={track} />
        {this.getTrackAddr(track)}
      </div>
    )
  }

  onClickEdit () {
    const { selected, history } = this.props
    const track = selected.item
    history.push(ROUTE_ADMIN_EDIT_TRACK + '/' + track.id)
  }

  renderFastestLaps () {
    return <Leaderboard type='fastlap' />
  }

  render () {
    const { selected, lookups, session } = this.props

    const track = selected.item

    if (selected.error) {
      return <Error404 />
    }

    if (!track || !lookups) { return null }

    const units = getMeasuringUnitsFromProfile(session.profile)
    return (
      <Row>
        <Col md={3}>
          {this.renderSummary(track)}
          {isTrackmanager(session) && <Button bsStyle='danger' block onClick={this.onClickEdit}>Edit track</Button>}
        </Col>
        <Col md={9}>
          <Grid fluid>
            {track && lookups && <TrackDescription track={track} title={track.name} lookups={lookups} />}
            {this.renderMeteo(track)}
            <Section title='Layouts'>
              <LayoutList list={{ list: track.layouts }} lookups={lookups} units={units} onLayoutClicked={this.layoutClicked} />
            </Section>
            <Section title='Leaderboard: Fastest laps'>{this.renderFastestLaps()}</Section>
          </Grid>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    handle: ownProps.match.params.handle,
    selected: state.tracks.selected,
    session: state.session,
    tracks: state.tracks.all,
    lookups: state.lookups.item
  }
}

export default page(connect(mapStateToProps)(PageTrackProfile), { uiNavigationRoute: ROUTE_TRACKS })

// see https://msdn.microsoft.com/en-us/library/ff701710.aspx
// https://www.bingmapsportal.com/Application
import 'whatwg-fetch'
import { default as fetchJsonp } from 'fetch-jsonp'
import { getData, putData, AGE_30_DAY } from './apiCache'
import { BING_MAPS_KEY } from '../constants'

const REVERSE_GEOCODE_URL = 'https://dev.virtualearth.net/REST/v1/Locations/'
const REVERSE_GEOCODE_QUERY = '?includeEntityTypes=Address&key=' + BING_MAPS_KEY

export function reverseGeocode (latLng) {
  if (!latLng.lat || !latLng.lon) {
    return null
  }

  // lat then lon
  let reqUrl = encodeURI(`${REVERSE_GEOCODE_URL}${latLng.lat},${latLng.lon}${REVERSE_GEOCODE_QUERY}`)

  let cached = getData(reqUrl, AGE_30_DAY)
  if (cached) {
    console.log('HIT CACHE!')
    return new Promise((resolve, reject) => resolve(cached))
  }

  return fetchJsonp(reqUrl, { jsonpCallback: 'jsonp' })
    .then((response) => {
      // reject if code is out of range 200-299
      if (!response || !response.ok) {
        let err = new Error(response ? response.statusText : 'Error')
        if (response) {
          err.status = response.status
        }

        throw err
      }

      return response.json()
    })
    .then((response) => {
      putData(reqUrl, response)
      return response
    })
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'
import { Section, NotificationsList, Overlay, FieldGroup } from '../../components'
import { fetchAllAdminNotifications, createNotificationAction, deleteNotificationAction } from '../../actions/notificationsActions'
import { uiToast } from '../../actions/ui'
import {Button, Form, Alert} from 'react-bootstrap'
import { UI_INPUT_LENGTH } from '../../constants'
import { confirmable, createConfirmation } from 'react-confirm'

class PageAdminNotifications extends Component {
  constructor (props) {
    super(props)
    this.loadNotifications = this.loadNotifications.bind(this)
    this.createNotification = this.createNotification.bind(this)
    this.saveEditingNotification = this.saveEditingNotification.bind(this)
    this.cancelEditingNotification = this.cancelEditingNotification.bind(this)
    this.onEditChange = this.onEditChange.bind(this)
    this.onDeleteNotification = this.onDeleteNotification.bind(this)
    this.state = {
      editingNotification: null
    }
  }

  componentDidMount () {
    const { dispatch, session } = this.props
    dispatch(fetchAllAdminNotifications(session.token))
  }

  loadNotifications ({startIndex, stopIndex}) {
    const { dispatch, session } = this.props
    dispatch(fetchAllAdminNotifications(session.token, startIndex))
  }

  createNotification () {
    this.setState({
      editingNotification: {
        title: '',
        priority: 5,
        exerpt: '',
        text: ''
      }
    })
  }

  saveEditingNotification () {
    const {editingNotification} = this.state
    const {session, dispatch} = this.props
    // validate
    if (editingNotification.title.trim().length === 0 ||
        editingNotification.exerpt.trim().length === 0 ||
        editingNotification.text.trim().length === 0) {
      dispatch(uiToast({text: 'You need to fill in all fields before saving this notification', bsStyle: 'warning'}))
      return
    }

    dispatch(createNotificationAction(session.token, editingNotification))
    this.setState({editingNotification: null})
  }

  cancelEditingNotification () {
    this.setState({editingNotification: null})
  }

  onEditChange (e) {
    this.setState(
      {
        editingNotification: {
          ...this.state.editingNotification,
          [e.target.name]: e.target.value
        }
      })
  }

  onDeleteNotification (notification) {
    const {dispatch, session} = this.props

    const dialog = ({ show, proceed, dismiss, cancel, confirmation, options }) => {
      const deleteString = `Forget this shared session`
      const buttons = Overlay.createButtons([
        { Delete: () => proceed(notification), bsStyle: 'warning' },
        { Cancel: cancel, bsStyle: 'default' }
      ])
      return (<Overlay
        show={show}
        title={deleteString + '?'}
        closeAction={dismiss}
        buttons={buttons}>
        <Alert bsStyle='warning' >
          <p>You are about to delete this notification for all users. Do you want to proceed?</p>
        </Alert>
      </Overlay>)
    }

    const confirmer = createConfirmation(confirmable(dialog))
    confirmer('', {}).then(notification => dispatch(deleteNotificationAction(session.token, notification)))
  }

  render () {
    const {allNotifications} = this.props
    const {editingNotification} = this.state

    return <Section title={'Notifications administator console'}>
      <Button onClick={this.createNotification}>Create notification</Button>
      <br /><br />
      <Overlay
        show={!!editingNotification}
        closeAction={this.cancelEditingNotification}
        title={editingNotification && editingNotification.id ? 'Edit notification' : 'Create notification'}
        buttons={[
          <Button key={'save'} bsStyle='success' onClick={this.saveEditingNotification}>Save</Button>,
          <Button key={'cancel'} bsStyle='danger' onClick={this.cancelEditingNotification}>Cancel</Button>
        ]}>
        <Form onSubmit={() => {}}>
          <FieldGroup
            name='title'
            label='Title'
            type='text'
            onChange={this.onEditChange}
            value={(editingNotification && editingNotification.title) || ''}
            placeholder='Notification title'
          />
          <FieldGroup
            name='exerpt'
            label='Exerpt'
            type='text'
            onChange={this.onEditChange}
            value={(editingNotification && editingNotification.exerpt) || ''}
            placeholder='Exerpt'
          />
          <FieldGroup
            name='priority'
            label='Priority'
            type='number'
            onChange={this.onEditChange}
            value={(editingNotification && editingNotification.priority) || -1}
            min={0}
            max={10}
          />
          <FieldGroup
            name='text'
            label='Text'
            type='textarea'
            onChange={this.onEditChange}
            placeholder='Notification text goes here'
            rows={5}
            maxLength={UI_INPUT_LENGTH.DESCRIPTION}
            componentClass='textarea'
            value={(editingNotification && editingNotification.text) || ''}
          />
        </Form>
      </Overlay>
      <NotificationsList
        list={allNotifications}
        loadMoreRows={this.loadNotifications}
        adminMode itemSelector={item => item}
        onDelete={this.onDeleteNotification} />
    </Section>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session,

    allNotifications: state.notifications.allAdminNotifications
  }
}

export default page(connect(mapStateToProps)(PageAdminNotifications), {pageView: GA_PAGES.ADMIN_NOTIFICATIONS, uiNavigationRoute: null})

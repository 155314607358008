import { asyncActions } from '.'
import { getLeaderboardsSpeed, getLeaderboardsHours, getLeaderboardsLaps, getLeaderboardsSessions, getLeaderboardsTracks } from '../api'
import * as _ from 'lodash'

export const LEADERBOARD_USERLAPS_FETCH = 'LEADERBOARD_USERLAPS_FETCH'
export const LEADERBOARD_USERSPEED_FETCH = 'LEADERBOARD_USERSPEED_FETCH'
export const LEADERBOARD_USERSESSIONS_FETCH = 'LEADERBOARD_USERSESSIONS_FETCH'
export const LEADERBOARD_USERTRACKS_FETCH = 'LEADERBOARD_USERTRACKS_FETCH'
export const LEADERBOARD_USERHOURS_FETCH = 'LEADERBOARD_USERHOURS_FETCH'
export const LEADERBOARD_FILTER_CHANGED = 'LEADERBOARD_FILTER_CHANGED'

function prepareFilter (filter = {}) {
  if (!filter.filter) {
    return {}
  }

  // clear all opts.
  // leave only filter
  // dates not used for now
  return {
    ...(filter.filter === 'all' ? {} : {filter: filter.filter})
  }
}

export function fetchLapsLeaderboard (token, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(LEADERBOARD_USERLAPS_FETCH)
    dispatch(actions.start({clear: true}))
    return getLeaderboardsLaps(token, 0, prepareFilter(filter)).then(
      (result) => {
        dispatch(actions.success(result))
      },
      (error) => {
        dispatch(actions.error(error))
        console.log(error)
      }
    )
  }
}

export function fetchHoursLeaderboard (token, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(LEADERBOARD_USERHOURS_FETCH)
    dispatch(actions.start({clear: true}))
    return getLeaderboardsHours(token, 0, prepareFilter(filter)).then(
      (result) => {
        dispatch(actions.success(result))
      },
      (error) => {
        dispatch(actions.error(error))
        console.log(error)
      }
    )
  }
}

export function fetchSessionsLeaderboard (token, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(LEADERBOARD_USERSESSIONS_FETCH)
    dispatch(actions.start({clear: true}))
    return getLeaderboardsSessions(token, 0, prepareFilter(filter)).then(
      (result) => {
        dispatch(actions.success(result))
      },
      (error) => {
        dispatch(actions.error(error))
        console.log(error)
      }
    )
  }
}

export function fetchSpeedLeaderboard (token, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(LEADERBOARD_USERSPEED_FETCH)
    dispatch(actions.start({clear: true}))
    return getLeaderboardsSpeed(token, 0, prepareFilter(filter)).then(
      (result) => {
        const filtered = {
          ...result,
          list: _.filter(result.list, (value) => {
            return value.topSpeed < 103 // 103 meters per sec ~ 230 mph
          })}
        dispatch(actions.success(filtered))
      },
      (error) => {
        dispatch(actions.error(error))
        console.log(error)
      }
    )
  }
}

export function fetchTracksLeaderboard (token, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(LEADERBOARD_USERTRACKS_FETCH)
    dispatch(actions.start({clear: true}))
    return getLeaderboardsTracks(token, 0, prepareFilter(filter)).then(
      (result) => {
        dispatch(actions.success(result))
      },
      (error) => {
        dispatch(actions.error(error))
        console.log(error)
      }
    )
  }
}

export function changeLederboardFilter (leaderboardType, filter) {
  return {
    type: LEADERBOARD_FILTER_CHANGED,
    leaderboardType,
    filter
  }
}

import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ROUTE_TRACKS } from '../../utils/routingConstants'
import { SIMULATOR_PLATFORM } from '../../api/apiConstants'
import { uiTrackType, uiFilterTracks, uiSimPlatform } from '../../actions/ui'
import { fetchTracksAction, fetchFeaturedTrackAction, countTracksAction, fetchNearByTracksAction } from '../../actions/trackDbActions'
import { TrackList, TrackDescription, Section, InlineSearchBox, SimBadge } from '../../components'
import { Row, Col, Tab, Tabs, ToggleButton, ToggleButtonGroup, Navbar } from 'react-bootstrap'
import { includes, differenceBy, filter } from 'lodash'
import { GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'

class PageTracks extends Component {
  constructor (props) {
    super(props)
    this.state = {
      innerTab: this.getTabFromProps(props)
    }
    this.loadMoreTracks = this.loadMoreTracks.bind(this)
    this.loadFilteredTracks = this.loadFilteredTracks.bind(this)
    this.handleSelectInnerNav = this.handleSelectInnerNav.bind(this)
  }

  componentDidMount () {
    const { dispatch, session } = this.props

    // fetch data
    dispatch(fetchTracksAction(session.token))
    dispatch(fetchFeaturedTrackAction(session.token))
    dispatch(countTracksAction(session.token))
    // !session.isAnonymous && dispatch(fetchRecentTracksAction(session.token))
    dispatch(fetchNearByTracksAction(session.token))
    // dispatch(fetchPopularTracksAction(session.token))
  }

  componentWillReceiveProps (newProps) {
    const { trackType, session, filter, simulatorPlatform } = this.props
    if (trackType !== newProps.trackType || simulatorPlatform !== newProps.simulatorPlatform || filter !== newProps.filter) {
      // when we change the filter, re-fetch all lists
      this.getTracksFetcher(session.token, newProps.trackType, newProps.simulatorPlatform, 'nearby', newProps.filter)()
      this.getTracksFetcher(session.token, newProps.trackType, newProps.simulatorPlatform, 'all', newProps.filter)()
    }
  }

  renderFilterBar () {
    const { dispatch, trackType, lookups, simulatorPlatform } = this.props
    if (!lookups) { return null }

    const onChange = value => {
      dispatch(uiTrackType(value === -1 ? null : value))
    }

    const onPlatformChange = value => {
      dispatch(uiSimPlatform(value))
    }

    const types = filter(differenceBy(lookups.trackTypes, [{ typeId: 100, layoutsCount: 1 }], 'typeId'), (item) => { return item.layoutsCount > 0 })
    const allPlatforms = Object.keys(SIMULATOR_PLATFORM).map(key => { return {simulatorPlatform: SIMULATOR_PLATFORM[key]} })

    const buttons = [...types, {description: 'All', typeId: -1}].map(type => (
      <ToggleButton key={type.typeId} value={type.typeId}>
        {type.description}
      </ToggleButton>))

    const platformButtons = allPlatforms.map(({simulatorPlatform}) => (
      <ToggleButton key={simulatorPlatform} value={simulatorPlatform}>
        <SimBadge target={{simulatorPlatform}} />
      </ToggleButton>))

    return <Fragment>
      <Navbar className='search-bar fixed' fluid>
        <Navbar.Form>
          <ToggleButtonGroup
            name='trackType'
            type='radio'
            value={trackType || -1}
            onChange={onChange}
            bsSize='small'>
            {buttons}
          </ToggleButtonGroup>
          <InlineSearchBox filter={this.props.filter} searchFunction={this.loadFilteredTracks} />
          <ToggleButtonGroup
            name='simPlatform'
            type='radio'
            value={simulatorPlatform || 0}
            onChange={onPlatformChange}
            bsSize='small' >
            {platformButtons}
          </ToggleButtonGroup>
        </Navbar.Form>
      </Navbar>
    </Fragment>
  }

  getTracksLoader (list, fetcher) {
    return ({ startIndex = 0, stopIndex } = {}) => {
      const total = list.headers ? list.headers.pagination.totalCount : -1
      if ((total < 0 || startIndex < total) && !list.isFetching) { return fetcher(startIndex) }
    }
  }

  getTracksFetcher (token, trackType = null, simulatorPlatform = 0, tab = null, filter = null) {
    const { innerTab } = this.state
    return (start = 0) => {
      const { dispatch } = this.props
      const params = { trackType, filter, ...(simulatorPlatform !== SIMULATOR_PLATFORM.All && {simulatorPlatform}) }
      const action = (tab || innerTab) === 'nearby' ? fetchNearByTracksAction(token, start, params) : fetchTracksAction(token, start, params)
      dispatch(action)
    }
  }

  getTabFromProps (propsObject) {
    const { hash } = propsObject

    let innerNav = hash && hash.substr(1)
    const allowedHash = ['nearby', 'all']
    return includes(allowedHash, innerNav) ? innerNav : allowedHash[0]
  }

  handleSelectInnerNav (eventKey) {
    this.setState({ innerTab: eventKey })
  }

  renderTabs (tracks) {
    const { all, nearby } = tracks
    const { innerTab } = this.state
    // const recentTitle = <span>Recent</span>
    const nearbyTitle = <span>Nearby</span>
    const allTitle = <span>All</span>

    return (
      <Tabs activeKey={innerTab} onSelect={this.handleSelectInnerNav} id='inner_tabs'>
        {/* <Tab eventKey='recent' title={recentTitle}>{this.renderRecent(recent)}</Tab> */}
        <Tab eventKey='nearby' title={nearbyTitle}>{this.renderNearby(nearby)}</Tab>
        <Tab eventKey='all' title={allTitle}>{this.renderAll(all)}</Tab>
      </Tabs>
    )
  }

  loadFilteredTracks (filter) {
    this.props.dispatch(uiFilterTracks(filter))
  }

  // renderFilterBar() {
  //   return (<InlineSearchBox filter={this.props.filter} searchFunction={this.loadFilteredTracks}/>)
  // }

  loadMoreTracks ({ startIndex, stopIndex }) {
    const { session, tracks, trackType, simulatorPlatform, filter } = this.props
    const { innerTab } = this.state
    const list = innerTab === 'nearby' ? tracks.nearby : tracks.all
    const total = list.headers ? list.headers.pagination.totalCount : -1
    if ((total < 0 || startIndex < total) && !list.isFetching) {
      return this.getTracksFetcher(session.token, trackType, simulatorPlatform, null, filter)(startIndex)
    }
  }

  renderAll (tracks) {
    return (
      <Section>
        <TrackList list={tracks} loadMoreRows={this.loadMoreTracks} filterBar={this.renderFilterBar()} />
      </Section>)
  }

  // renderRecent(tracks) {
  //   return <Section title="Recent tracks">
  //     <TrackList list={tracks} />
  //   </Section>
  // }

  renderNearby (tracks) {
    return <Section>
      <TrackList list={tracks} loadMoreRows={this.loadMoreTracks} filterBar={this.renderFilterBar()} />
    </Section>
  }

  render () {
    const { tracks, lookups } = this.props

    const featured = tracks.featured
    return (
      <Row>
        <Col md={1} />
        <Col md={10}>
          {featured.item && lookups && <TrackDescription
            track={featured.item}
            title={`Featured track -- ${featured.item.name}`}
            lookups={lookups} />}
          {this.renderTabs(tracks)}
        </Col>
      </Row>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    tracks: state.tracks,
    session: state.session,
    lookups: state.lookups.item,
    trackType: state.ui.tracks.trackType,
    simulatorPlatform: state.ui.tracks.simulatorPlatform,
    filter: state.ui.tracks.filter
  }
}

export default page(withRouter(connect(mapStateToProps)(PageTracks)), { pageView: GA_PAGES.TRACKS, uiNavigationRoute: ROUTE_TRACKS })

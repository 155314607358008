import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'

class YouTubeViewer extends Component {
  static propTypes = {
    youtubeUrl: PropTypes.string.isRequired
  }

  static defaultProps = {
    allowDownload: false
  }

  render () {
    const {youtubeUrl} = this.props

    return <div id='video-viewer'>
      <ReactPlayer
        url={youtubeUrl}
        controls
        style={{margin: 'auto'}}
      />
    </div>
  }
}

export default YouTubeViewer

import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import classnames from 'classnames'

const DropzoneWithImagePreview = ({onDrop, previewUrl, noImageText, className, maxSize, avatarPreview}) => {
  return (
    <div>
      <Dropzone
        className={classnames(className, 'dropzone-with-preview')}
        onDrop={onDrop}
        multiple={false}
        accept='image/*'>
        {!previewUrl && <div className='dropzone-with-preview_stub'>
          <span>Click here to pick an image or drag it here<br />Use 200x200 image for best results</span>
        </div>}
        {previewUrl && <img className='dropzone-with-preview_img' src={previewUrl} alt='avatar' /> }
      </Dropzone>
      { previewUrl && avatarPreview && <div className='dropzone-avatar-preview'>
        <span>Preview: </span>
        <img className='avatar' src={previewUrl} alt='avatar' />
      </div>}
    </div>
  )
}

DropzoneWithImagePreview.propTypes = {
  onDrop: PropTypes.func.isRequired,
  previewUrl: PropTypes.string,
  noImageText: PropTypes.string.isRequired,
  maxSize: PropTypes.number,
  className: PropTypes.string,
  avatarPreview: PropTypes.bool
}

DropzoneWithImagePreview.defaultProps = {
  avatarPreview: true,
  noImageText: 'Click here to pick an image or drag it here'
}

export default DropzoneWithImagePreview

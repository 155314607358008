import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { fetchHandle } from '../api'
import * as _ from 'lodash'
import { FormGroup, FormControl, HelpBlock, ControlLabel, Col, InputGroup } from 'react-bootstrap'
import {UI_INPUT_LENGTH} from '../constants'

class HandleEdit extends Component {
  static propTypes = {
    onHandleChanged: PropTypes.func.isRequired,
    oldHandle: PropTypes.string,
    apiToken: PropTypes.string.isRequired,
    layoutType: PropTypes.oneOf(['row', 'column']),
    optional: PropTypes.bool,
    title: PropTypes.string
  }

  static defaultProps = {
    layoutType: 'column',
    optional: false,
    title: 'Handle'
  }

  constructor (props) {
    super(props)
    this.changeHandler = this.handleChange.bind(this)
    this.state = {
      isHandleExist: false,
      isHandleFetching: false,
      isHandleValid: true,
      isHandleDirty: false,
      newHandle: props.oldHandle ? props.oldHandle : null
    }
    this.validateHandle = _.debounce(this.validateHandle, 500)
  }

  handleChange (event) {
    const target = event.target
    const value = target.value

    let valid = true

    const { oldHandle, onHandleChanged, optional } = this.props

    // validate.
    if ((oldHandle && oldHandle === value) || (optional && !value)) {
      // there is a value and it is the same as the old one => valid by default
      // having a value is optional and there isnt one => valid by definition
      this.setState({
        isHandleExist: false,
        isHandleFetching: false,
        isHandleValid: true,
        isHandleDirty: false,
        newHandle: value})
    } else {
      // data is not valid until we check that the handle is available
      valid = false

      if (value.length === 0 || value.search(/^[a-z0-9_-]+$/i) < 0) {
        this.setState({ isHandleValid: false, newHandle: value })
      } else {
        // separate logic for handle validation
        this.setState({ isHandleDirty: true, isHandleValid: true, newHandle: value })
        this.validateHandle(value)
      }
    }

    onHandleChanged(value, valid)
  }

  validateHandle (handle) {
    this.setState({isHandleFetching: true, isHandleDirty: false})

    const {apiToken, onHandleChanged} = this.props
    fetchHandle(apiToken, handle)
      .then(
        (result) => {
          this.setState({isHandleFetching: false, isHandleExist: true})
        },
        (ignoredError) => {
          this.setState({isHandleFetching: false, isHandleExist: false})
          onHandleChanged(handle, true)
        }
      )
  }

  render () {
    const {oldHandle, title, optional} = this.props
    const {isHandleFetching, isHandleDirty, isHandleExist, isHandleValid, newHandle} = this.state

    let help = ''
    let validationState = null

    if (oldHandle) {
      help =
        !isHandleValid ? `Handle ${newHandle} is not valid`
          : (isHandleFetching || isHandleDirty) ? `Checking the handle ${newHandle}...`
            : isHandleExist ? `Handle is taken. Try a different one.`
              : oldHandle === newHandle ? '' : 'Available'

      validationState = !isHandleValid || isHandleExist || (!optional && (!newHandle || newHandle.length === 0)) ? 'error'
        : isHandleFetching || isHandleDirty || oldHandle === newHandle || (optional && newHandle.length === 0) ? null : 'success'
    } else {
      help =
      (!optional && (!newHandle || newHandle.length === 0)) ? 'You need to select the handle!'
        : !isHandleValid ? `Handle ${newHandle} is not valid`
          : (isHandleFetching || isHandleDirty) ? `Checking the handle ${newHandle}...`
            : isHandleExist ? `Handle taken. Try a different one`
              : `Available`

      validationState = (!optional && (!newHandle || newHandle.length === 0)) || !isHandleValid || isHandleExist ? 'error'
        : isHandleFetching || isHandleDirty ? null : 'success'
    }

    const {layoutType} = this.props

    if (layoutType === 'column') {
      return (
        <FormGroup controlId='handle' validationState={validationState} >
          <Col componentClass={ControlLabel} sm={3}>{title}</Col>
          <Col sm={9}>
            <InputGroup>
              <InputGroup.Addon>@</InputGroup.Addon>
              <FormControl
                maxLength={UI_INPUT_LENGTH.HANDLE}
                value={newHandle || ''}
                onChange={this.changeHandler}
                placeholder='Type the handle here and we will check if it is available'
                required />
            </InputGroup>
          </Col>
          <Col sm={8} xsOffset={3}> <HelpBlock>{help}</HelpBlock> </Col>
        </FormGroup>
      )
    }

    return (
      <FormGroup controlId='handle' validationState={validationState} >
        <ControlLabel>Handle</ControlLabel>
        <InputGroup>
          <InputGroup.Addon>@</InputGroup.Addon>
          <FormControl
            maxLength={UI_INPUT_LENGTH.HANDLE}
            value={newHandle || ''}
            onChange={this.changeHandler}
            placeholder='Type the handle here and we will check if it is available'
            required />
        </InputGroup>
        <HelpBlock>{help}</HelpBlock>
      </FormGroup>
    )
  }
}

export default HandleEdit

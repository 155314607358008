import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { Document, Page } from 'react-pdf'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import Measure from 'react-measure'
import {getInnerWindowWidth} from '../../utils/utils'

class PdfViewer extends Component {
  static propTypes = {
    pdfUrl: PropTypes.string.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      numPages: 1,
      pageWidth: getInnerWindowWidth()
    }

    this.onDocumentLoaded = this.onDocumentLoaded.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  shouldComponentUpdate (newProps, newState) {
    return (!this.state.target && newState.target) ||
      (this.state.numPages !== newState.numPages) ||
      (this.state.pageWidth !== newState.pageWidth)
  }

  onDocumentLoaded ({numPages}) {
    this.setState({numPages})
  }

  onResize (contentRect) {
    const {pageWidth} = this.state

    if (pageWidth !== contentRect.bounds.width) {
      this.setState({ pageWidth: contentRect.bounds.width })
    }
  }

  render () {
    const {pdfUrl} = this.props
    const pages = Array
      .from({length: this.state.numPages}, (val, key) => { return key + 1 })
      .map(value => <Page key={value} width={this.state.pageWidth} pageNumber={value} />)

    return (
      <Measure
        bounds
        onResize={this.onResize}>
        {({ measureRef }) => (
          <div ref={measureRef} id='pdf-viewer'>
            <Document file={pdfUrl} onLoadSuccess={this.onDocumentLoaded}>
              {pages}
            </Document>
          </div>
        )}
      </Measure>
    )
  }
}

export default PdfViewer

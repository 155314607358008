import { asyncActions } from './'
import {
  deleteSession,
  updateSession,
  fetchSessionInvites,
  fetchSessionShares,
  fetchUserSessions,
  fetchPublicSessions,
  fetchSelectedSession,
  deleteSessionShares,
  loadEntityFilesForSession,
  fetchUser } from '../api'

import { checkErrorAndDispatchlogoutIfNeeded } from './sessionActions'
import { uiToast, uiRedirect } from './ui'
import { generateSessionSettingsUpdatedToast, generateSessionDeletedToast } from '../utils/toastCreator'
import { ROUTE_SESSIONS } from '../utils/routingConstants'

export const SESSIONS_DELETE = 'SESSIONS_DELETE'
export const SESSION_UPDATE = 'SESSION_UPDATE'
export const SESSION_INVITES_FETCH = 'SESSION_INVITES_FETCH'
export const PUBLIC_SESSIONS_FETCH = 'PUBLIC_SESSIONS_FETCH'
export const PROFILE_SESSIONS_FETCH = 'PROFILE_SESSIONS_FETCH'
export const SESSIONS_SELECTED_FETCH = 'SESSIONS_SELECTED_FETCH'
export const SESSIONS_SELECTED_SHARES_FETCH = 'SESSIONS_SELECTED_SHARES_FETCH'
export const SESSIONS_SELECTED_ENTITYFILES_FETCH = 'SESSIONS_SELECTED_ENTITYFILES_FETCH'
export const SESSIONS_SELECTED_SHARES_DELETE = 'SESSIONS_SELECTED_SHARES_DELETE'

export function deleteSessionAction (token, id) {
  return dispatch => {
    const actions = asyncActions(SESSIONS_DELETE)
    const updateActions = asyncActions(SESSIONS_SELECTED_FETCH)
    dispatch(actions.start())
    dispatch(updateActions.start())
    return deleteSession(token, id).then(
      result => {
        dispatch(actions.success(result))
        dispatch(updateActions.success({ deleted: true }))
        dispatch(uiToast(generateSessionDeletedToast()))
        dispatch(uiRedirect(ROUTE_SESSIONS))
      },
      error => dispatch(actions.error(error))
    )
  }
}

export function updateSessionAction (token, session, opts = {toast: true}) {
  return dispatch => {
    const actions = asyncActions(SESSION_UPDATE)
    const updateActions = asyncActions(SESSIONS_SELECTED_FETCH)
    dispatch(actions.start())
    dispatch(updateActions.start())
    return updateSession(token, session.id, session).then(
      result => {
        dispatch(actions.success(result))
        dispatch(updateActions.success(result))
        opts.toast && dispatch(uiToast(generateSessionSettingsUpdatedToast()))
      },
      error => dispatch(actions.error(error))
    )
  }
}

export function fetchSessionSharesAction (token, id) {
  return dispatch => {
    const actions = asyncActions(SESSIONS_SELECTED_SHARES_FETCH)
    dispatch(actions.start())
    return fetchSessionShares(token, id).then(
      result => dispatch(actions.success(result)),
      error => dispatch(actions.error(error))
    )
  }
}

export function fetchSessionEntityFilesAction (token, id) {
  return dispatch => {
    const actions = asyncActions(SESSIONS_SELECTED_ENTITYFILES_FETCH)
    dispatch(actions.start({ clear: true }))
    return loadEntityFilesForSession(id, token).then(
      result => dispatch(actions.success(result)),
      error => dispatch(actions.error(error))
    )
  }
}

export function deleteSessionShareAction (token, id, shareId) {
  return dispatch => {
    const actions = asyncActions(SESSIONS_SELECTED_SHARES_DELETE)
    const updateActions = asyncActions(SESSIONS_SELECTED_SHARES_FETCH)
    dispatch(actions.start())
    return deleteSessionShares(token, id, shareId).then(
      result => {
        dispatch(actions.success(result))
        dispatch(updateActions.success({ remove: result }))
      },
      error => dispatch(actions.error(error))
    )
  }
}

export function fetchPublicSessionsAction (token, skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(PUBLIC_SESSIONS_FETCH)
    dispatch(actions.start())
    return fetchPublicSessions(token, skip, filter)
      .then(result => dispatch(actions.success(result, { append: skip !== 0 })))
      .catch(error => {
        if (error.status === 204) {
          dispatch(actions.success({ list: [] }, { append: skip !== 0 }))
        } else {
          dispatch(actions.error(error))
          checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
        }
      })
  }
}

export function fetchProfileSessionsAction (token, relationship = 'all', skip = 0, filter = {}) {
  return (dispatch) => {
    const actions = asyncActions(PROFILE_SESSIONS_FETCH)
    const params = { ...filter, relationship }
    dispatch(actions.start())
    return fetchUserSessions(token, skip, params)
      .then(result => dispatch(actions.success(result, { append: skip !== 0 })))
      .catch(error => {
        if (error.status === 204) {
          dispatch(actions.success({ list: [] }, { append: skip !== 0 }))
        } else {
          dispatch(actions.error(error))
          checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
        }
      })
  }
}

export function fetchSelectedSessionAction (token, uuid, fetchedOne, opts = {fetchOwner: false}) {
  return (dispatch) => {
    const actions = asyncActions(SESSIONS_SELECTED_FETCH)
    if (!uuid) {
      dispatch(actions.start({ clear: true }))
      return
    }

    dispatch(actions.start())

    fetchedOne && dispatch(actions.success(fetchedOne))

    // some props of the pre-selected session may be empty, so fetch data once again
    let session = null
    return fetchSelectedSession(token, uuid)
      .then(
        result => {
          dispatch(actions.success(result))
          session = result
          const requestOwner = opts.fetchOwner && !result.owner && result.ownerId
          return requestOwner ? fetchUser(token, result.ownerId) : new Promise((resolve) => { resolve(null) })
        }
      )
      .then(
        result => {
          dispatch(actions.success({...session, owner: result}))
        }
      )
      .catch(
        error => {
          dispatch(actions.error(error))
          checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
        }
      )
  }
}

export function fetchSessionInvitesAction (token, handle) {
  return (dispatch) => {
    const actions = asyncActions(SESSION_INVITES_FETCH)

    if (!handle) {
      dispatch(actions.start({ clear: true }))
      return
    }

    dispatch(actions.start())
    return fetchSessionInvites(token, handle).then(
      (result) => {
        dispatch(actions.success({ list: result }))
      },
      (error) => {
        dispatch(actions.error(error))
        checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
      }
    )
  }
}

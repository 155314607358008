import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchSelectedSessionAction, fetchSessionSharesAction, updateSessionAction, fetchSessionEntityFilesAction } from '../../actions/resultsActions'
import { startDownloadEntityFileDownload, addSessionExtra, updateSessionExtra, deleteSessionExtra } from '../../actions/entityFileActions'
import { uiAddSessionExtra, uiEditSessionExtra } from '../../actions/ui'
import { ROUTE_SESSIONS } from '../../utils/routingConstants'
import { BarLapList, ProgressBar, Section, ShareList, StatisticDataList, InvitationOverlay, EntityLink, Error404, SignInButton, SimBadge, Overlay, FieldGroup, FieldGroupReadonly, UpgradeLink } from '../../components'
import { SessionSettings, RequestOverlay } from '../../containers'
import { find, includes, filter, orderBy } from 'lodash'
import { Row, Grid, Col, Tab, Tabs, Button, ButtonToolbar, Badge, Form, Panel, Alert } from 'react-bootstrap'
import { getMeasuringUnitsFromProfile, getDuration, getSpeed, formatDate, getSpeedUnitsName, getFastestLapTime } from '../../utils/modelConvertionUtils'
import {beautifulFileSizeString} from '../../utils/utils'
import {isPremiumUser} from '../../api/auth0'
import noImage from '../../images/no_image.jpg'
import { pageView, GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'
import { FILE_CATEGORY_TYPES, getParserData } from '../../api/apiConstants'
import classnames from 'classnames'
import FA from 'react-fontawesome'
import Dropzone from 'react-dropzone'
import { confirmable, createConfirmation } from 'react-confirm'

const ENFORSED_FILESIZE_LIMIT_BYTES = 50 * 1024 * 1024 // 50MB

class PageSessionProfile extends Component {
  constructor (props) {
    super(props)

    this.state = {
      innerTab: this.getTabFromProps(props)
    }

    this.saveSettingsOperation = this.saveSettingsOperation.bind(this)
    this.handleSelectInnerNav = this.handleSelectInnerNav.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.saveEntityFile = this.saveEntityFile.bind(this)
    this.cancelEditEntityFile = this.cancelEditEntityFile.bind(this)
    this.entityFileChangeHandler = this.entityFileChangeHandler.bind(this)
    this.deleteEntityFile = this.deleteEntityFile.bind(this)
    this.proceedEntityFileDeletion = this.proceedEntityFileDeletion.bind(this)
  }

  componentDidMount () {
    const { selectedSession, currentId, sessions, userSession } = this.props
    if (!selectedSession || !selectedSession.item || selectedSession.item.id !== currentId) {
      this.handleNewSession(sessions, currentId)
    }
    pageView(GA_PAGES.SESSION + currentId, userSession)
  }

  componentWillReceiveProps (newProps) {
    // Look at the new props and see if the user is changing. If so, kick off some loading to get all the state in order
    const newSessionId = newProps.currentId
    if (newSessionId && this.props.currentId !== newSessionId) { this.handleNewSession(newProps.sessions, newSessionId) }

    const oldTab = this.getTabFromProps(this.props)
    const newTab = this.getTabFromProps(newProps)
    if (oldTab !== newTab) { this.setState({ innerTab: newTab }) }

    // load additional data
    const newSession = newProps.selectedSession.item
    const oldSession = this.props.selectedSession.item
    const {dispatch, userSession} = this.props
    if (userSession.isAnonymous) {
      return
    }

    if ((newSession && !oldSession) || (newSession && oldSession && newSession.id !== oldSession.id)) {
      const access = this.getAccessLevel(newProps.selectedSession)
      dispatch(fetchSessionEntityFilesAction(userSession.token, newSessionId))
      if (access === 'shared' || access === 'owned') {
        dispatch(fetchSessionSharesAction(userSession.token, newSessionId))
      }
    }
  }

  // A new session has been seleted, fetch all the details
  handleNewSession (sessions, newSessionId) {
    const { dispatch, userSession } = this.props
    // step 0 - clear previos user data from state
    dispatch(fetchSelectedSessionAction())
    // load the new session leveraging ones we currently have
    const target = sessions.list ? find(sessions.list, { id: newSessionId }) : null
    dispatch(fetchSelectedSessionAction(userSession.token, newSessionId, target, {fetchOwner: true}))

    if (userSession.isAnonymous) {
      // if anonymous ensure we land on a valid tab
      this.setState({ innerTab: 'laps' })
    }
  }

  getTabFromProps (props) {
    const { hash, selectedSession } = props
    let innerNav = hash && hash.substr(1)
    const access = this.getAccessLevel(selectedSession)
    const accessLevels = {
      public: ['laps'],
      shared: ['laps', 'shares'],
      owned: ['laps', 'shares', 'edit']
    }
    const allowedHash = accessLevels[access]
    return includes(allowedHash, innerNav) ? innerNav : allowedHash[0]
  }

  getAccessLevel (selectedSession) {
    const session = selectedSession ? selectedSession.item : null
    const { userSession } = this.props
    if (!session || userSession.isAnonymous) {
      return 'public'
    }
    if (this.isOwner(selectedSession)) {
      return 'owned'
    }
    if (session.shareId) {
      return 'shared'
    }

    return 'public'
  }

  isProfileUser (handle = this.props.handle) {
    const { userSession } = this.props
    return !userSession.isAnonymous && (userSession.profile.handle === handle)
  }

  isOwner (selectedSession = this.props.selectedSession) {
    const { userSession } = this.props
    return selectedSession && selectedSession.item && selectedSession.item.ownerId === userSession.profile.id
  }

  handleSelectInnerNav (eventKey) {
    this.setState({ innerTab: eventKey })
  }

  renderTabs () {
    const { innerTab } = this.state
    const { selectedSession } = this.props
    const access = this.getAccessLevel(selectedSession)
    return (
      <Tabs activeKey={innerTab} onSelect={this.handleSelectInnerNav} id='inner_tabs'>
        <Tab eventKey='laps' title='Laps'>{this.renderLaps()}</Tab>
        <Tab eventKey='files' title='Files'>{this.renderFiles()}</Tab>
        {(access === 'owned' || access === 'shared') && <Tab eventKey='shares' title='Shares'>{this.renderShares()}</Tab>}
        {access === 'owned' && <Tab eventKey='edit' title='Edit'>{this.renderSettings()}</Tab>}
      </Tabs>
    )
  }

  renderSessionStats (session) {
    const { userSession } = this.props
    const units = getMeasuringUnitsFromProfile(userSession.profile)

    const topSpeedTitle = 'Top speed, ' + getSpeedUnitsName(units)

    const stats = {
      'Total duration': getDuration(session.duration, false),
      'Total laps': session.lapCount,
      'Fastest time': getDuration(getFastestLapTime(session)),
      [topSpeedTitle]: getSpeed(session.topSpeed || 0, units, 1, false)
    }

    return <StatisticDataList stats={stats} />
  }

  renderShares () {
    const { dispatch, userSession, shares, selectedSession } = this.props

    if (userSession.isAnonymous) {
      return (
        <Section >
          <div className='list-noRows'>Sign in to see who is getting faster by studying this session.</div>
          <SignInButton />
        </Section>
      )
    }

    if (!selectedSession.item || !shares.list) { return null }

    const loader = ({ startIndex = 0, stopIndex } = {}) => {
      const total = shares.headers ? shares.headers.pagination.totalCount : -1
      if ((total < 0 || startIndex < total) && !shares.isFetching) { return dispatch(fetchSessionSharesAction(userSession.token, selectedSession.id, startIndex)) }
    }

    return (
      <Section >
        {shares.list && <ShareList list={shares} loadMoreRows={loader} />}
      </Section>
    )
  }

  onDrop (files) {
    const {dispatch, selectedSession} = this.props
    dispatch(uiAddSessionExtra(selectedSession.item, files[0]))
  }

  renderFiles () {
    const {selectedSession, selectedSessionFiles, userSession, dispatch, zippingSourceFile, lookups} = this.props

    if (!selectedSession.item) {
      return null
    }

    if (userSession.isAnonymous) {
      return <Section>
        <SignInButton text='Sign in to view' />
      </Section>
    }

    const access = this.getAccessLevel(selectedSession)

    const canUpload = access === 'owned'
    const canDownload = access === 'owned' || access === 'shared'

    const filesList0 = filter((selectedSessionFiles.list || []), {typeCategoryId: FILE_CATEGORY_TYPES.TELEMETRY_ORIGINAL})
    // we may have several source entityt files but need only one
    const filesList = filesList0.length ? [orderBy(filesList0, ['updatedOn'])[0]] : []

    const extrasList = filter((selectedSessionFiles.list || []), item => (item.typeCategoryId === FILE_CATEGORY_TYPES.PICTURES || item.typeCategoryId === FILE_CATEGORY_TYPES.EXTRAS))
    const renderDownloadButton = (file) => {
      if (!canDownload) {
        return null
      }

      return file.typeCategoryId === FILE_CATEGORY_TYPES.TELEMETRY_ORIGINAL && zippingSourceFile
        ? <Button disabled>Zipping...</Button>
        : <Button bsStyle='info' onClick={(e) => { dispatch(startDownloadEntityFileDownload(file, userSession.token)) }}><FA name='cloud-download' /> Download</Button>
    }

    const fileRenderer = (file, opts = {}) => <div key={file.id} className='fileview'>
      <FA name={opts.icon || 'file'} className='fileview__icon' />
      <div className='fileview__text'>{opts.name || getParserData(file.typeSubCategoryId, lookups && lookups.typeSubCategories).name + ' source files'} - {beautifulFileSizeString(file.size)}</div>
      <ButtonToolbar className='fileview__action'>
        {file.typeCategoryId !== FILE_CATEGORY_TYPES.TELEMETRY_ORIGINAL && canUpload &&
          <Button bsStyle='warning' onClick={(e) => { dispatch(uiEditSessionExtra(file)) }}><FA name='edit ' /> Edit</Button>}
        {renderDownloadButton(file)}
      </ButtonToolbar>
    </div>

    return <Section>
      {selectedSessionFiles.isFetching && <div className='fileview__loading'>Loading...</div>}
      {selectedSessionFiles.error && selectedSessionFiles.error.status !== 204 && <div className='fileview__error'>Error loading files</div>}
      {selectedSessionFiles.error && selectedSessionFiles.error.status === 204 && <div className='fileview__nofiles'>No session files available</div>}

      {!selectedSessionFiles.isFetching && !selectedSessionFiles.error && <h4>Data files</h4>}
      {!selectedSessionFiles.isFetching && !selectedSessionFiles.error && filesList.length === 0 && <div className='fileview__nofiles'>No data files</div>}
      {!selectedSessionFiles.isFetching && filesList.map((file) => {
        return fileRenderer(file)
      })}

      {!selectedSessionFiles.isFetching && !selectedSessionFiles.error && <h4>Session extras</h4>}
      {!selectedSessionFiles.isFetching && !selectedSessionFiles.error && extrasList.length === 0 && <div className='fileview__nofiles'>No extras attached to this session</div>}
      {!selectedSessionFiles.isFetching && extrasList.map((file) => {
        return fileRenderer(file, {name: file.descr || 'Attachement file', icon: 'file-o'})
      })}
      <div className='fileview__upload'>
        {canUpload && isPremiumUser(userSession) && <Dropzone
          className='fileview__dropzone'
          onDrop={this.onDrop}
          multiple={false}>
          <div className='fileview__dropzone-content'><FA name='upload' /> Drop files to upload or click to select them</div>
        </Dropzone>}
        {canUpload && !isPremiumUser(userSession) && <UpgradeLink>Update to premium to upload extra session files</UpgradeLink> }
        {!canDownload && (filesList.length + extrasList.length) > 0 && <RequestOverlay requestButtonText='Request access to download the files' entity={selectedSession.item} block />}
      </div>
    </Section>
  }

  renderLaps () {
    const { selectedSession, userSession } = this.props
    if (!selectedSession.item) {
      return null
    }

    const signInToView = userSession.isAnonymous &&
      selectedSession.item &&
      selectedSession.item.lapCount &&
      selectedSession.item.laps &&
      !selectedSession.item.laps.length

    return (
      <Section>
        <BarLapList
          session={selectedSession.item}
          isOwner={this.isOwner()}
          noRowsRenderer={() => { return signInToView ? <SignInButton text='Sign in to view' /> : null }}
        />
      </Section>
    )
  }

  saveEntityFile (e) {
    e && e.preventDefault()
    const {selectedEntityFile, dispatch, userSession} = this.props
    dispatch(uiEditSessionExtra({...selectedEntityFile, uploading: true}))
    dispatch(selectedEntityFile.rowVersion ? updateSessionExtra(selectedEntityFile, userSession.token) : addSessionExtra(selectedEntityFile, userSession.token))
  }

  cancelEditEntityFile () {
    const {dispatch} = this.props
    dispatch(uiEditSessionExtra(null))
  }

  deleteEntityFile () {
    const dialog = ({ show, proceed, dismiss, cancel, confirmation, options }) => {
      const deleteString = `Delete session attachement forever`
      const buttons = Overlay.createButtons([
        { [deleteString]: proceed, bsStyle: 'warning' },
        { Cancel: cancel, bsStyle: 'default' }
      ])
      return (<Overlay
        show={show}
        title='Delete session attachement forever?'
        closeAction={dismiss}
        buttons={buttons}>
        <Alert bsStyle='warning' >
          <p>You are about to delete thit file. This action cannot be undone. Doyou wish to proceed?</p>
        </Alert>
      </Overlay>)
    }

    const confirmer = createConfirmation(confirmable(dialog))
    confirmer('', {}).then(this.proceedEntityFileDeletion, () => { console.log('2') })
  }

  proceedEntityFileDeletion () {
    const {selectedEntityFile, dispatch, userSession} = this.props
    dispatch(deleteSessionExtra(selectedEntityFile, userSession.token))
  }

  entityFileChangeHandler (e) {
    const { dispatch, selectedEntityFile } = this.props

    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    dispatch(uiEditSessionExtra({...selectedEntityFile, [name]: value}))
  }

  renderEntityFileEditSection () {
    const {selectedEntityFile, tco} = this.props

    if (!selectedEntityFile) return null

    const isSizeOk = selectedEntityFile.size < ENFORSED_FILESIZE_LIMIT_BYTES

    const buttons = selectedEntityFile.uploading ? <div>
      <div>{`Uploading ${tco.name}...`}</div>
    </div>
      : <ButtonToolbar bsClass='bottom-buttons'>
        <Button bsStyle='warning' onClick={this.cancelEditEntityFile}>Cancel</Button>
        <Button disabled={!isSizeOk} bsStyle='success' onClick={this.saveEntityFile}>{selectedEntityFile.rowVersion ? 'Save' : 'Upload'}</Button>
      </ButtonToolbar>

    return <Overlay
      closeButton={false}
      show
      buttons={buttons}
      manualFooter={selectedEntityFile.uploading ? <ProgressBar progress={tco.progress} /> : null}
      title={selectedEntityFile.rowVersion ? 'Edit file' : 'Add file'}>
      <Form onSubmit={this.saveEntityFile}>
        <FieldGroup
          name='descr'
          label='File name'
          onChange={this.entityFileChangeHandler}
          type='text'
          value={selectedEntityFile.descr || ''} />
        <FieldGroupReadonly name='size' label='File size'>{beautifulFileSizeString(selectedEntityFile.size)}</FieldGroupReadonly>
        {!isSizeOk && <Alert bsStyle='danger'>File is too large! Maximum file size is 50 MB</Alert>}
        {selectedEntityFile.rowVersion && <Panel bsStyle='danger'>
          <Panel.Heading><Panel.Toggle>Dangerous operations</Panel.Toggle></Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <Alert bsStyle='danger' >
                <p>Deleting this permanently removes the file from the server.</p>
                <p><strong>This action cannot be undone!</strong></p>
              </Alert>
              <Button bsStyle='danger' onClick={this.deleteEntityFile}>Delete this file forever</Button>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>}
      </Form>
    </Overlay>
  }

  saveSettingsOperation () {
    // This should only ever be run when editing an existing session
    const { userSession, dispatch, selectedSession, settings } = this.props
    const spec = { ...selectedSession.item }
    spec.driver = null
    spec.driverId = settings.driver && settings.driver.entityId
    spec.track = null
    spec.layout = null
    spec.layoutId = settings.layout && settings.layout.value
    spec.vehicle = null
    spec.vehicleId = settings.vehicle && settings.vehicle.entityId
    spec.name = settings.name
    spec.description = settings.description
    spec.visibility = settings.visibility
    dispatch(updateSessionAction(userSession.token, spec))
  }

  renderSettings () {
    const { selectedSession, sessionUiState } = this.props
    if (!selectedSession.item) { return '' }

    return (
      <Section>
        <SessionSettings />
        <ButtonToolbar bsClass='settings-bottom-buttons'>
          <Button key='Save' bsStyle='primary' disabled={!(sessionUiState && sessionUiState.valid)} onClick={this.saveSettingsOperation}>Save</Button>
        </ButtonToolbar>
      </Section>)
  }

  renderSummary (session) {
    if (!session) { return '' }

    const tileText = (<div>
      {session.driver && <h4><EntityLink entity={session.driver} type='user'>Driver:</EntityLink></h4>}
      {session.driver && <h5><EntityLink entity={session.driver} type='user' /></h5>}
      {session.owner && <h4><EntityLink entity={session.owner} type='user'>Owner:</EntityLink></h4>}
      {session.owner && <h5><EntityLink entity={session.owner} type='user' /></h5>}
      {session.track && <h4><EntityLink entity={session.track} type='track'>Track:</EntityLink></h4>}
      {session.track && <h5><EntityLink entity={session.track} type='track' /></h5>}
      {session.vehicle && <h4><EntityLink entity={session.vehicle} type='vehicle'>Vehicle:</EntityLink></h4>}
      {session.vehicle && <h5><EntityLink entity={session.vehicle} type='vehicle' /></h5>}
      {session.name && <h4>{session.name}</h4>}
      {session.start && <h4>On {formatDate(session.start)}</h4>}
      {session.description && <h5>{session.description}</h5>}
    </div>)
    return (
      <div>
        <img className='page__logo' src={(session.driver && session.driver.avatarUrl) || noImage} alt='driver' />
        <Badge className={classnames('visibility', {'private': (session.visibility === 0)})}>{session.visibility === 0 ? 'private' : 'public'}</Badge>
        <SimBadge target={session} />
        {tileText}
        <ButtonToolbar bsClass='vertical-buttons'>
          {!this.isOwner() && <RequestOverlay entity={session} block />}
          {this.isOwner() && <InvitationOverlay
            block
            handleTypes={['user', 'team']}
            entity={session}
            excludedUsers={[this.props.userSession.profile]}
          />}
        </ButtonToolbar>
      </div>
    )
  }

  render () {
    const { selectedSession } = this.props

    if (selectedSession.error) {
      return <Error404 />
    }

    if (!selectedSession.item) { return null }

    return (
      <Row>
        <Col md={3}>
          {this.renderSummary(selectedSession.item)}
        </Col>
        <Col md={9}>
          <Grid fluid>
            <Row>
              {this.renderSessionStats(selectedSession.item)}
            </Row>
            {this.renderTabs()}
          </Grid>
        </Col>
        {this.renderEntityFileEditSection()}
      </Row>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    currentId: ownProps.match.params.uuid,
    userSession: state.session,
    sessions: state.results.publicSessions,
    selectedSession: state.results.selectedSession,
    selectedSessionFiles: state.results.selectedSessionFiles,
    shares: state.results.shares,
    settings: state.ui.sessions.settings,
    zippingSourceFile: state.ui.sessions.zippingSourceFile,
    sessionUiState: state.ui.sessions.uiState,
    selectedEntityFile: state.ui.entityFiles.selected,
    tco: state.ui.tco,
    lookups: state.lookups.item
  }
}

export default page(connect(mapStateToProps)(PageSessionProfile), {uiNavigationRoute: ROUTE_SESSIONS})

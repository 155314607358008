import ReactPixel from 'react-facebook-pixel'
import { FACEBOOK_PIXEL_ID } from '../constants'

// called from downloads page
export function pixelTrackDownload (build) {
  FACEBOOK_PIXEL_ID && ReactPixel.trackCustom('DownloadDesktopClient', {
    build: build
  })
}

// called from header
export function pixelTrackSigninButton () {
  FACEBOOK_PIXEL_ID && ReactPixel.trackCustom('SignInButtonClick', {
  })
}

// called sessions actions - when we update profile without handle and get the profile with handle
export function pixelTrackSigninCompleted (profile) {
  FACEBOOK_PIXEL_ID && ReactPixel.trackCustom('UserSignInCompleted', {
    handle: profile.handle,
    id: profile.id
  })
}

// called from session actions - once user fetches his profile for the first time
export function pixelTrackLoginOrSignIn (profile) {
  FACEBOOK_PIXEL_ID && ReactPixel.trackCustom(profile.handle ? 'UserLogIn' : 'UserSignIn', {
    ...(profile.handle && { handle: profile.handle }),
    id: profile.id,
    handle: profile.handle
  })
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Section } from '../../components'
import { ROUTE_DOWNLOADS } from '../../utils/routingConstants'
import { page } from '../../hocs/page'
import { DOWNLOAD_MAC_LATEST, DOWNLOAD_WIN_LATEST, DOWNLOAD_WIN_BETA, DOWNLOAD_MAC_BETA } from '../../constants'
import { pixelTrackDownload } from '../../api/facebookPixelHelper'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import p1 from '../../images/download_carousel/download_p1.jpg'
import p2 from '../../images/download_carousel/download_p2.jpg'
import p3 from '../../images/download_carousel/download_p3.jpg'
import p4 from '../../images/download_carousel/download_p4.jpg'
import p5 from '../../images/download_carousel/download_p5.jpg'
import FA from 'react-fontawesome'
import { GA_PAGES, eventDownload } from '../../api/gaHelper'

class PageDownloads extends Component {
  constructor (props) {
    super(props)
    this.macDownloadTracker = this.trackDownload.bind(this, 'Mac')
    this.winDownloadTracker = this.trackDownload.bind(this, 'Win')
  }

  trackDownload (build, event) {
    pixelTrackDownload(build)
    eventDownload(build, this.props.session)
  }

  render () {
    const images = [p1, p2, p3, p4, p5]
    return (
      <Section title='Downloads' className='page-downloads'>
        <div className='carousel-container'>
          <Carousel
            showStatus={false}
            showThumbs={false}
            infiniteLoop
            autoPlay
            interval={4000}
            transitionTime={700}>
            {images.map((src, idx) => {
              return <div key={idx} >
                <img alt='carousel slide' src={src} />
              </div>
            })}
          </Carousel>
        </div>
        <h3>Select your platform</h3>
        <div className='download-links'>
          <a href={DOWNLOAD_WIN_LATEST} onClick={this.winDownloadTracker}>
            <span className='download-links__os'><FA name='windows' /></span>
            <div>WINDOWS</div>
          </a>
          <a href={DOWNLOAD_MAC_LATEST} onClick={this.macDownloadTracker}>
            <span className='download-links__os'><FA name='apple' /></span>
            <div>MAC</div>
          </a>
        </div>
        <h3>You can also download: </h3>
        <h3>Our latest Beta build below</h3>
        <div className='download-links'>
          <a href={DOWNLOAD_WIN_BETA} onClick={this.winDownloadTracker}>
            <span className='download-links__os'><FA name='windows' /></span>
            <div>WINDOWS</div>
          </a>
          <a href={DOWNLOAD_MAC_BETA} onClick={this.macDownloadTracker}>
            <span className='download-links__os'><FA name='apple' /></span>
            <div>MAC</div>
          </a>
        </div>
      </Section>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return { session: state.session }
}

export default page(connect(mapStateToProps)(PageDownloads), {pageView: GA_PAGES.DOWNLOADS, uiNavigationRoute: ROUTE_DOWNLOADS})

// import {createBlobServiceWithSas} from 'azure-storage'
import {
  Aborter,
  BlobURL,
  BlockBlobURL,
  StorageURL,
  AnonymousCredential,
  uploadBrowserDataToBlockBlob
} from '@azure/storage-blob'

export function uploadFileToBlob (entityFile, sasToken, blobUrl, reportProgress) {
  // anonymous for SAS tokened url
  const anonymousCredential = new AnonymousCredential()

  // Use credential to create a pipeline
  const pipeline = StorageURL.newPipeline(anonymousCredential)

  // dont need service or container urls. Just use the one the blob url directly
  // const serviceURL = new ServiceURL(
  //   // When using AnonymousCredential, following url should include a valid SAS or support public access
  //   serviceurlString,
  //   pipeline
  // )

  // const containerURL = ContainerURL.fromServiceURL(serviceURL, container)
  const blobURL = new BlobURL(blobUrl + sasToken, pipeline)
  const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL)

  // TODO: use progress for visual feedback when uploading large files
  const opts = {
    progress: (uploadPregress) => reportProgress(Math.floor(100 * uploadPregress.loadedBytes / entityFile.file.size)),
    blobHTTPHeaders: {
      // blobContentType: 'application/octetstream',
      blobContentDisposition: `attachment; filename="${entityFile.file.name}"`
    }
  }

  return uploadBrowserDataToBlockBlob(Aborter.none, entityFile.file, blockBlobURL, opts)
}

import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
// import createLogger from 'redux-logger'
import rootReducer from './reducers'
import { autoRehydrate } from 'redux-persist'

// const loggerMiddleware = createLogger()

export function configureStore (preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    compose(
      applyMiddleware(
        // async actions support
        thunkMiddleware
        // loggerMiddleware
      ),
      autoRehydrate()
    )
  )
}

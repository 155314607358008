import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ROUTE_NEWS } from '../../utils/routingConstants'
import { Section, NewsItemCard } from '../../components'
import { fetchNews } from '../../actions/blogActions'
import { GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'
import {unzip, chunk} from 'lodash'
import { isMobile } from '../../utils/utils'

const COL_COUNT = isMobile() ? 1 : 2

class PageNews extends Component {
  componentDidMount () {
    const {dispatch} = this.props
    dispatch(fetchNews())
  }

  renderNewsCards () {
    const {news} = this.props

    if (news.isFetching) {
      return <div className='loading-message'>
        Loading latest news...
      </div>
    }

    if (!news.list || news.list.length === 0) {
      return
    }

    const chunkedList = unzip(chunk(news.list, COL_COUNT))

    return chunkedList.map(columnset => <div key={columnset[0].guid.rendered} className='newspage__col'>
      {columnset.map(item => item ? <NewsItemCard newsitem={item} key={item.id} /> : null)}
    </div>)
  }

  render () {
    return <Section title='News' className='newspage'>
      <div className='newspage__col__container'>
        {this.renderNewsCards()}
      </div>
    </Section>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    news: state.news.news
  }
}

export default page(connect(mapStateToProps)(PageNews), { pageView: GA_PAGES.NEWS, uiNavigationRoute: ROUTE_NEWS })

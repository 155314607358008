import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchProfileSessionsAction, fetchPublicSessionsAction } from '../actions/resultsActions'
import { uiSessionRelationship } from '../actions/ui'
import { SessionList, Section } from '../components'
import { ToggleButton, ToggleButtonGroup, Navbar } from 'react-bootstrap'

class SessionsView extends Component {
  static propTypes = {
    title: PropTypes.string,
    validRelationships: PropTypes.arrayOf(PropTypes.string),
    userHandle: PropTypes.string,
    publicOnly: PropTypes.bool
  }

  static defaultProps = {
    validRelationships: null,
    publicOnly: false
  }

  constructor (props) {
    super(props)
    this.getSessionFetcher = this.getSessionFetcher.bind(this)
  }

  componentWillMount () {
    const { dispatch, validRelationships } = this.props
    if (validRelationships && validRelationships.length > 0) { dispatch(uiSessionRelationship(validRelationships[0])) }
  }

  componentDidMount () {
    const { userSession, relationships } = this.props
    if (relationships) { this.getSessionFetcher(userSession.token, relationships)() }
  }

  componentWillReceiveProps (newProps) {
    const { relationships, userSession } = this.props
    if (relationships !== newProps.relationships) {
      this.getSessionFetcher(userSession.token, newProps.relationships)()
    }
  }

  isRelationshipAllowed (type) {
    const { validRelationships } = this.props
    return validRelationships ? validRelationships.includes(type) : true
  }

  renderFilterBar () {
    const { dispatch, relationships, validRelationships } = this.props
    if (!relationships || (validRelationships && validRelationships.length === 1)) { return null }

    const onChange = value => {
      dispatch(uiSessionRelationship(value))
    }

    return (
      <Navbar className='search-bar' fluid>
        <Navbar.Form>
          <ToggleButtonGroup
            name='relationship'
            type='radio'
            value={relationships}
            onChange={onChange}
            bsSize='small'
          >
            {this.isRelationshipAllowed('mine') && <ToggleButton value={'mine'}>Owned</ToggleButton>}
            {this.isRelationshipAllowed('shared') && <ToggleButton value={'shared'}>Owned+Shared</ToggleButton>}
            {this.isRelationshipAllowed('public') && <ToggleButton value={'public'}>All</ToggleButton>}
          </ToggleButtonGroup>
        </Navbar.Form>
      </Navbar>
    )
  }

  getSessionLoader (list, fetcher) {
    return ({ startIndex = 0, stopIndex } = {}) => {
      const total = list.headers ? list.headers.pagination.totalCount : -1
      if ((total < 0 || startIndex < total) && !list.isFetching) { return fetcher(startIndex) }
    }
  }

  getSessionFetcher (token, newRelationship = null) {
    return (start = 0) => {
      const { dispatch, userHandle, publicOnly } = this.props
      if (publicOnly || (newRelationship && newRelationship === 'public')) {
        dispatch(fetchPublicSessionsAction(token, start, { driverId: userHandle }))
      } else {
        const relationshipFilter = !newRelationship || newRelationship === 'shared' ? 'all' : newRelationship
        dispatch(fetchProfileSessionsAction(token, relationshipFilter, start))
      }
    }
  }

  render () {
    const { sessions, userSession, relationships, title, publicOnly } = this.props
    if (!relationships) { return null }

    const { publicSessions, profileSessions } = sessions
    const token = userSession.token
    const list = publicOnly || relationships === 'public' ? publicSessions : profileSessions
    const loader = this.getSessionFetcher(token, relationships)
    return (
      <Section title={title}>
        {this.renderFilterBar()}
        <SessionList list={list} loadMoreRows={this.getSessionLoader(list, loader)} />
      </Section>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return { sessions: state.results, userSession: state.session, relationships: state.ui.sessions.relationships }
}

export default connect(mapStateToProps)(SessionsView)

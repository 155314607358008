import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, Collection, InfiniteLoader } from 'react-virtualized'
import styles from 'react-virtualized/styles.css'
import { BREAKOUTS } from '../../constants'
import {getInnerWindowWidth} from '../../utils/utils'

// Defines a pattern of sizes and positions for a range of 10 rotating cells
// These cells cover an area of 600 (wide) x 400 (tall)
const CELL_WIDTH = 180
const GUTTER_SIZE = CELL_WIDTH * 0.2

export default class InfiniteGrid extends React.Component {
  static propTypes = {
    listHeight: PropTypes.number,
    cellHeight: PropTypes.number,
    totalRows: PropTypes.func,
    currentRows: PropTypes.func,
    isRowLoaded: PropTypes.func,
    loadMoreRows: PropTypes.func,
    rowRenderer: PropTypes.func,
    noRowsRenderer: PropTypes.func,
    // use this to force update when the data is changed, but list length is the same
    listHash: PropTypes.string
  }

  static defaultProps = {
    loadMoreRows: () => {},
    listHeight: CELL_WIDTH * 4.1,
    cellHeight: CELL_WIDTH * 1.5
  }

  constructor (props) {
    super(props)
    this._cellSizeAndPositionGetter = this._cellSizeAndPositionGetter.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions)
  }

  // componentWillReceiveProps (nextProps) {
  //   console.log(this._registerChild)
  // }

  _cellSizeAndPositionGetter (args) {
    // TODO: fix autosizer 0 width issue & center the items
    const {index} = args
    const {cellHeight} = this.props
    const deviceWidth = getInnerWindowWidth()

    const columnCount = deviceWidth > BREAKOUTS.XLARGE_BREAKOUT ? 5
      : deviceWidth > BREAKOUTS.LARGE_BREAKOUT ? 4
        : deviceWidth > BREAKOUTS.TABLET_BREAKOUT ? 3 : 2

    const columnPosition = index % columnCount

    const x = columnPosition * (GUTTER_SIZE + CELL_WIDTH)
    const y = Math.floor(index / columnCount) * (GUTTER_SIZE + cellHeight)

    return {
      height: cellHeight,
      width: CELL_WIDTH,
      x,
      y
    }
  }

  updateDimensions () {
    this.registerChild && this.registerChild.recomputeCellSizesAndPositions()
  }

  render () {
    const { isRowLoaded, loadMoreRows, listHeight, totalRows, currentRows, cellHeight, rowRenderer, noRowsRenderer, listHash } = this.props

    let height = Math.min(currentRows() * cellHeight, listHeight)

    if (noRowsRenderer) {
      // show noRowsRenderer won't be called with zero height
      height = Math.max(height, cellHeight)
    }

    return (
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={totalRows()}>
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer disableHeight ref={(registerChild2) => { this.registerChild2 = registerChild2 }} >
            {({ width }) => {
              return <Collection
                cellCount={currentRows()}
                listHash={listHash}
                ref={(registerChild) => { this.registerChild = registerChild }}
                className={styles.Collection}
                height={height}
                cellRenderer={rowRenderer}
                onRowsRendered={onRowsRendered}
                noRowsRenderer={noRowsRenderer}
                rowHeight={cellHeight}
                width={width}
                cellSizeAndPositionGetter={(e) => { return this._cellSizeAndPositionGetter({...e, width}) }}
              />
            }}
          </AutoSizer>
        )}
      </InfiniteLoader>)
  }
}

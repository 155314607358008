import React from 'react'
import { Section, ExternalLink } from '../../components'
import { page } from '../../hocs/page'
import { GA_PAGES } from '../../api/gaHelper'

const PagePrivacyPolicy = () => {
  return (
    <Section title='Privacy Policy' className='page-legal'>
      <div className='page-legal-updated'>Last Updated: January 23, 2018</div>
      <h3>Track Attack Privacy Policy</h3>

      <div className='page-legal-paragraph'>
        <h4>Track Attack Privacy Statement</h4>
        <p>
          This privacy statement explains what personal data we collect from you and how we use it. References to Track Attack services in this statement include Track Attack websites, apps and software.
        </p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>Personal Data We Collect</h4>
        <p>
          Track Attack collects data to operate effectively and provide you the best experiences with our services. You provide some of this data directly, such as when you sign up for an account, our newsletter or contact us for support. We get some of it by recording how you interact with our services by, for example, using technologies like cookies, and receiving error reports or usage data from software running on your device. Our racing data collection system collects certain user information when it gathers race data, which is automatically uploaded to our servers. For example, it collects information that you provide to your lap timer or other device that gathers race data. The kind of personal information you might provide to the system includes your name, if you input it. Other data gathered by the system includes RPM, speed and timestamped latitude and longitude gathered during your race drive time as GPS data. We also may obtain data from third parties (including other companies).
        </p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>How We Use Personal Data</h4>
        <p>
          Track Attack uses the data we collect to provide you the services we offer, which includes using data to improve and personalize your experiences. We also may use the data to communicate with you – for example, informing you about security updates and product information.
        </p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>Reasons We Share Personal Data</h4>
        <p>
          We share your personal data with your consent or as necessary to complete any transaction or provide any service you have requested or authorized. We also share data with certain affiliates, with vendors working on our behalf, when required by law or to respond to legal process, to maintain the security of our services, and to protect the rights or property of Track Attack. We do not sell or share your personal data for any other reasons, without your explicit consent.
        </p>
        <p>
          Viewing Your Personal Data And Opting Out Of Promotional Email
          You can view your personal data online for some Track Attack services. You can choose whether you wish to receive promotional email from Track Attack. You can opt out of promotional emails we send to you or unsubscribe to our newsletter by selecting the “unsubscribe” link located at the bottom of each communication. You can also opt out of our racing data collection system by using the preferences settings in the program.
        </p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>Cookies &amp; Similar Technologies</h4>
        <p>
          Track Attack uses cookies (small text files stored by your web browser when you use websites) by using Google, a third-party service provider, for analytics. When you go to our site, you get a Google cookie. Cookies allow us, among other things, to count the number of unique visitors to a web page or service and to develop other statistics about the performance of our services. These technologies also enable the analytics providers to set or read their own cookies or other identifiers on your device, through which they can collect information about your online activities across applications, websites or other services.
        </p>
        <p>
          Third-party service providers, such as Google, may use electronic images known as web beacons (also called single-pixel gifs) that help deliver cookies on our websites and count users who have visited those websites. You can opt out of data collection or use by clicking the following link: Google Analytics: <ExternalLink to='http://tools.google.com/dlpage/gaoptout'>http://tools.google.com/dlpage/gaoptout</ExternalLink> (requires you to install a browser add-on). We may use additional cookies at some point in the future. We also use similar technology, such as machine fingerprints that identifies machines, not users, to provide our services and help collect data.
        </p>
        <p>
          You have a variety of tools to control cookies, including browser controls to block and delete cookies and controls from some third-party analytics service providers to opt out of data collection. However, if you disable cookies entirely, it may impact your experiences with our services. For example, your ability to make purchases may be disabled.
        </p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>Disclosure</h4>
        <p>
          We may access or disclose information about you, or your use of the Services, (a) when it is required by law (such as when we receive a valid subpoena or search warrant); (b) to respond to your requests for customer service support; or (c) when we, in our discretion, think it is necessary to protect the rights, property, or personal safety of us, our users, or the public.
        </p>
      </div>
    </Section>
  )
}

export default page(PagePrivacyPolicy, { pageView: GA_PAGES.PRIVACY })

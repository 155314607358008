const WP_API_ROOT = 'https://public-api.wordpress.com/wp/v2/sites/trackattackinc.wordpress.com/'

const POSTS = 'posts'
const POST_ARTICLE = 'posts/'
const PAGE_SIZE = 50 // 25

export function fetchBlogEntries (skip = 0) {
  let scope = {}
  return fetch(`${WP_API_ROOT}${POSTS}?per_page=${PAGE_SIZE}&offset=${skip}`)
    .then((response) => {
      scope.total = response.headers.get('x-wp-total')
      scope.totalPages = response.headers.get('x-wp-totalpages')
      return response.json()
    })
    .then((result) => {
      return {
        list: result,
        hasMore: (skip + result.length) < scope.total,
        headers: scope
      }
    })
}

export function fetchArticle (id) {
  return fetch(`${WP_API_ROOT}${POST_ARTICLE}${id}`)
    .then((response) => {
      return response.json()
    })
}

import { asyncActions } from '.'
import { fetchBlogEntries, fetchArticle } from '../api/wpApi'

export const BLOG_ENTRIES_FETCH = 'BLOG_ENTRIES_FETCH'
export const BLOG_ARTICLE_FETCH = 'BLOG_ARTICLE_FETCH'

export function fetchNews (skip = 0) {
  return (dispatch) => {
    const actions = asyncActions(BLOG_ENTRIES_FETCH)
    dispatch(actions.start({clear: true}))
    return fetchBlogEntries(skip).then(
      (result) => {
        dispatch(actions.success(result))
      },
      (error) => {
        dispatch(actions.error(error))
      }
    )
  }
}

export function fetchNewsArticle (id) {
  return (dispatch) => {
    const actions = asyncActions(BLOG_ARTICLE_FETCH)
    dispatch(actions.start({clear: true}))
    return fetchArticle(id).then(
      (result) => {
        dispatch(actions.success(result))
      },
      (error) => {
        dispatch(actions.error(error))
      }
    )
  }
}

import {
  BLOG_ENTRIES_FETCH,
  BLOG_ARTICLE_FETCH
} from '../actions/blogActions'
import { default as listReducer } from './listReducer'
import { default as itemReducer } from './itemReducer'
import { combineReducers } from 'redux'

export default combineReducers({
  news: listReducer(BLOG_ENTRIES_FETCH),
  article: itemReducer(BLOG_ARTICLE_FETCH)
})

import React from 'react'
import { PropTypes } from 'prop-types'
import classnames from 'classnames'
import { ExternalLink } from '../components'

const propTypes = {
  meteo: PropTypes.object,
  numDays: PropTypes.number,
  track: PropTypes.object
}

const wDays = [
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT'
]

const defaultProps = {
  numDays: 10
}

const fixWiIconNames = (iconName) => {
  if (iconName.includes('-night')) {
    return 'wi-night-' + iconName.replace('-night', '')
  }

  if (iconName.includes('-day')) {
    return 'wi-day-' + iconName.replace('-day', '').replace('clear', 'sunny').replace('partly-', '')
  }

  return 'wi-' + iconName
}

const renderDailyWeather = (daily, units) => {
  return (
    <div key={daily.time} className='weatherwidget-item'>
      <div className='weatherwidget-item_wday'>{wDays[new Date(daily.time * 1000).getDay()]}</div>
      <i
        className={classnames('wi', 'weatherwidget-item_icon', fixWiIconNames(daily.icon))} />
      <div className='weatherwidget-item-main'>{`${Math.round(daily.temperatureMax)} ${units.temperatureMax}`}</div>
      <div className='weatherwidget-item-sub'>{`${Math.round(daily.temperatureMin)} ${units.temperatureMin}`}</div>
      <div className='weatherwidget-item-sub'>
        <div>
          <i className='wi wi-raindrop' />
          <span>{`${Math.round(daily.precipProbability * 100)}%`}</span>
        </div>
      </div>
    </div>
  )
}

const renderCurrentWeather = (meteo) => {
  if (!meteo) {
    return ''
  }

  const { currently } = meteo.weather
  const { units } = meteo

  return (
    <div className='weatherwidget-current'>
      <div className='weatherwidget-main'>
        <div>NOW:</div>
        <i className={classnames('wi', fixWiIconNames(currently.icon))} />
        <div>
          {`${Math.round(currently.temperature)} ${units.temperature}`}
        </div>
      </div>
      <div className='weatherwidget-sub'>
        <div className='weatherwidget-data-item'>
          <i className='wi wi-humidity' />
          <span>{`${Math.round(currently.humidity * 100)}%`}</span>
        </div>
        <div className='weatherwidget-data-item'>
          <i className='wi wi-raindrop' />
          <span>{`${currently
            .precipIntensity} ${units
            .precipIntensity} (${Math
            .round(currently.precipProbability * 100)}%)`}</span>
        </div>
        <div className='weatherwidget-data-item'>
          <i
            className={classnames('wi', 'wi-wind', `from-${currently.windBearing}-deg`)} />
          <span>{`${currently.windSpeed} ${units.windSpeed}`}</span>
        </div>
      </div>
    </div>
  )
}

const renderWeather = (meteo, numDays) => {
  if (!meteo) {
    return ''
  }

  const { daily } = meteo.weather
  const { units } = meteo

  return (
    <div className='weatherwidget-items-container'>
      {/* FORECAST */}
      {daily
        .data
        .slice(0, numDays)
        .map(daily => {
          return renderDailyWeather(daily, units)
        })}
    </div>
  )
}

const WeatherWidget = ({ meteo, numDays, track }) => {
  if (track && (!track.locationLat || !track.locationLong)) {
    return <div className='weatherwidget-loading'>Weather data not available</div>
  }

  return (
    <div className='weatherwidget-root'>
      {meteo == null && <div className='weatherwidget-loading'>Loading forecast...</div>}
      {renderCurrentWeather(meteo)}
      {renderWeather(meteo, numDays)}
      {meteo && <div className='weatherwidget-message'>
        {meteo.weather.daily.summary}
      </div>}
      {meteo && <div className='weatherwidget-poweredby'>
        <ExternalLink to='https://darksky.net/poweredby'>Powered by DarkSky</ExternalLink>
      </div>}
    </div>
  )
}

WeatherWidget.propTypes = propTypes

WeatherWidget.defaultProps = defaultProps

export default WeatherWidget

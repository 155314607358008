import { PUBLIC_SESSIONS_FETCH, PROFILE_SESSIONS_FETCH, SESSIONS_SELECTED_FETCH, SESSIONS_SELECTED_SHARES_FETCH, SESSIONS_SELECTED_ENTITYFILES_FETCH } from '../actions/resultsActions'
import { combineReducers } from 'redux'
import { default as listReducer } from './listReducer'
import { default as itemReducer } from './itemReducer'

export default combineReducers({
  selectedSession: itemReducer(SESSIONS_SELECTED_FETCH),
  shares: listReducer(SESSIONS_SELECTED_SHARES_FETCH),
  profileSessions: listReducer(PROFILE_SESSIONS_FETCH),
  publicSessions: listReducer(PUBLIC_SESSIONS_FETCH),
  selectedSessionFiles: listReducer(SESSIONS_SELECTED_ENTITYFILES_FETCH)
})

import { USERS_FETCH, SELECTED_USER_FETCH, SELECTED_USER_FOLLOWERS_FETCH, SELECTED_USER_FOLLOWING_FETCH, SELECTED_USER_TEAMS_FETCH, SELECTED_USER_STATS_FETCH, SELECTED_USER_VEHICLES_FETCH, SELECTED_USER_SESSIONS_FETCH } from '../actions/userDbActions'
import { combineReducers } from 'redux'
import { default as listReducer } from './listReducer'
import { default as itemReducer } from './itemReducer'

const selectedUserReducer = combineReducers({
  user: itemReducer(SELECTED_USER_FETCH, 'user'),
  followers: listReducer(SELECTED_USER_FOLLOWERS_FETCH),
  following: listReducer(SELECTED_USER_FOLLOWING_FETCH),
  vehicles: listReducer(SELECTED_USER_VEHICLES_FETCH),
  sessions: listReducer(SELECTED_USER_SESSIONS_FETCH),
  teams: listReducer(SELECTED_USER_TEAMS_FETCH),
  stats: itemReducer(SELECTED_USER_STATS_FETCH)
})

export default combineReducers({
  selectedUser: selectedUserReducer,
  allUsers: listReducer(USERS_FETCH)
})

import React, { Component, Fragment } from 'react'
import { fetchStorefront } from '../../actions/storeActions'
import { uiFilterStore } from '../../actions/ui'
import { connect } from 'react-redux'
import { ROUTE_STORE, ROUTE_PRICING } from '../../utils/routingConstants'
import { IS_DEVELOP } from '../../constants'
import { PRODUCT_FILE_TYPES, PRODUCT_FILE_TYPES_NAMES } from '../../api/apiConstants'
import { Section, ProductsList, InlineSearchBox } from '../../components'
import { GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'
import {ToggleButton, ToggleButtonGroup, Navbar} from 'react-bootstrap'
import { searchToObject } from '../../utils/utils'
import { includes, filter } from 'lodash'
// import ssChtlk from '../../images/ss_chtlk.jpg'

class PageStore extends Component {
  constructor (props) {
    super(props)
    this.rowClickHandler = this.rowClickHandler.bind(this)
    this.purchaseModeChanged = this.purchaseModeChanged.bind(this)
    this.producttypeChanged = this.producttypeChanged.bind(this)
    this.searchChanged = this.searchChanged.bind(this)
  }

  getTabFromProps (propsObject) {
    const { search } = propsObject

    const searchObj = searchToObject(search)

    let innerNav = searchObj.category
    const allowedHash = ['all', 'ebooks', 'webinars', 'datapack', 'subscriptions']
    return includes(allowedHash, innerNav) ? innerNav : allowedHash[0]
  }

  componentWillMount () {
    const { dispatch, userSession, storeFilter } = this.props

    const startingCategory = this.getTabFromProps(this.props)

    const textToCategoryFilter = (categoryText) => {
      if (!categoryText.toLowerCase) {
        return PRODUCT_FILE_TYPES.UNDEFINED
      }

      switch (categoryText.toLowerCase()) {
        case 'ebooks': return PRODUCT_FILE_TYPES.PDF
        case 'webinars': return PRODUCT_FILE_TYPES.WISTIA
        case 'datapack': return PRODUCT_FILE_TYPES.DATA_PACK
        case 'subscriptions': return PRODUCT_FILE_TYPES.SUBSCRIPTIONS
        default: return PRODUCT_FILE_TYPES.UNDEFINED
      }
    }

    const actualStoreFilter = {...storeFilter, productType: textToCategoryFilter(startingCategory)}

    if (userSession.isAnonymous && actualStoreFilter.purchased) {
      // no purchased for anonymous
      dispatch(uiFilterStore({...actualStoreFilter, purchased: false}))
      return
    }

    if (storeFilter.productType !== actualStoreFilter.productType) {
      // show available products on selected filter
      dispatch(uiFilterStore({...actualStoreFilter, purchased: false}))
    } else {
      dispatch(fetchStorefront(userSession.token, actualStoreFilter))
        .then(result => {
          if (result && !userSession.isAnonymous) {
            const {filter, products} = result
            // when no purchased products - switch to 'available' by default on page load
            filter && products && filter.purchased && products.length === 0 && dispatch(uiFilterStore({...actualStoreFilter, purchased: false}))
          }
        })
    }
  }

  componentWillReceiveProps (newProps) {
    const {storeFilter, userSession} = newProps
    const oldStoreFilter = this.props.storeFilter

    if (!storeFilter || !oldStoreFilter) {
      return
    }
    // if user has signed out
    if (userSession.isAnonymous && storeFilter.purchased) {
      this.purchaseModeChanged(false)
      return
    }

    if (storeFilter.purchased !== oldStoreFilter.purchased || storeFilter.search !== oldStoreFilter.search || storeFilter.productType !== oldStoreFilter.productType) {
      this.loadStoreFront(storeFilter)
    }
  }

  loadStoreFront (filter = this.props.storeFilter) {
    const { dispatch, userSession } = this.props
    dispatch(fetchStorefront(userSession.token, filter))
  }

  rowClickHandler (product) {
    const {history} = this.props

    if (product.forcedRedirect) {
      history.push(product.forcedRedirect)
    } else if (product.productType === PRODUCT_FILE_TYPES.SUBSCRIPTIONS) {
      history.push(ROUTE_PRICING)
    } else {
      history.push(ROUTE_STORE + '/' + product.idSendOwl)
    }
  }

  purchaseModeChanged (purchased) {
    const {dispatch, storeFilter} = this.props
    dispatch(uiFilterStore({...storeFilter, purchased}))
  }

  producttypeChanged (productType) {
    const {dispatch, storeFilter} = this.props
    dispatch(uiFilterStore({...storeFilter, productType}))
  }

  searchChanged (search) {
    const {dispatch, storeFilter} = this.props
    dispatch(uiFilterStore({...storeFilter, search}))
  }

  renderFilterBar () {
    const {storeFilter, userSession} = this.props

    return <Navbar className='search-bar fixed' fluid>
      <Navbar.Form>
        <InlineSearchBox filter={storeFilter.search} searchFunction={this.searchChanged} />
        { !userSession.isAnonymous && <Fragment>
          <ToggleButtonGroup
            name='purchased'
            type='radio'
            value={storeFilter.purchased}
            onChange={this.purchaseModeChanged}
            bsSize='small'>
            <ToggleButton key='own' value>Owned</ToggleButton>
            <ToggleButton key='other' value={false}>Store</ToggleButton>
          </ToggleButtonGroup>
          <br />
        </Fragment>}
        <ToggleButtonGroup
          name='productType'
          type='radio'
          value={storeFilter.productType}
          onChange={this.producttypeChanged}
          bsSize='small'>
          <ToggleButton key={PRODUCT_FILE_TYPES.PDF} value={PRODUCT_FILE_TYPES.PDF}>Ebooks</ToggleButton>
          <ToggleButton key={PRODUCT_FILE_TYPES.WISTIA} value={PRODUCT_FILE_TYPES.WISTIA}>Webinars</ToggleButton>
          {IS_DEVELOP && <ToggleButton key={PRODUCT_FILE_TYPES.DATA_PACK} value={PRODUCT_FILE_TYPES.DATA_PACK}>{PRODUCT_FILE_TYPES_NAMES[PRODUCT_FILE_TYPES.DATA_PACK]}</ToggleButton>}
          <ToggleButton key={PRODUCT_FILE_TYPES.SUBSCRIPTIONS} value={PRODUCT_FILE_TYPES.SUBSCRIPTIONS}>Subscriptions</ToggleButton>
          <ToggleButton key={PRODUCT_FILE_TYPES.UNDEFINED} value={PRODUCT_FILE_TYPES.UNDEFINED}>All</ToggleButton>
        </ToggleButtonGroup>
      </Navbar.Form>
    </Navbar>
  }

  generateMixinProductsList () {
    const {storeFilter, profileSubscriptions, userSession} = this.props

    const storeSubscriptions = (() => {
      if (userSession.isAnonymous || !userSession.profile.subscriptionProductId) {
        return profileSubscriptions.list
      }

      return filter(profileSubscriptions.list, (item) => !item.url.includes(userSession.profile.subscriptionProductId))
    })()

    const ownedSubscriptions = (() => {
      if (userSession.isAnonymous || !userSession.profile.subscriptionProductId) {
        return []
      }

      return filter(profileSubscriptions.list, (item) => item.url.includes(userSession.profile.subscriptionProductId))
    })()

    const subscriptionMixins = storeFilter.purchased ? ownedSubscriptions : storeSubscriptions

    const webinarMixins = []
    // {
    //   productType: PRODUCT_FILE_TYPES.WISTIA,
    //   price: 0,
    //   name: 'Speed Secrets May 2019 Chalktalk',
    //   id: 'SOME_FAKE_GUID',
    //   discount: null,
    //   imageUrl: ssChtlk,
    //   forcedRedirect: ROUTE_SSCHALKTALK + '?utm_source=trackattack&utm_medium=store&utm_campaign=speedsecrets_chalktalk_20190514'
    // }]

    switch (storeFilter.productType) {
      case PRODUCT_FILE_TYPES.UNDEFINED: return [...webinarMixins, ...subscriptionMixins]
      case PRODUCT_FILE_TYPES.SUBSCRIPTIONS: return subscriptionMixins
      case PRODUCT_FILE_TYPES.WISTIA: return webinarMixins
      default: return []
    }
  }

  render () {
    const {storefrontList, storeFilter} = this.props
    const noRowsRenderer = () => <div className={'grid-noRows'}>{storeFilter.purchased && !storeFilter.search ? 'You have not purchased anything' : 'No products to match your filter'}</div>

    return (
      <Section title='Track Attack Store'>
        {this.renderFilterBar()}
        <ProductsList
          noRowsRenderer={noRowsRenderer}
          list={storefrontList}
          mixinList={this.generateMixinProductsList()}
          isGrid
          onClick={this.rowClickHandler} />
      </Section>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    search: ownProps.location.search,

    userSession: state.session,
    storefrontList: state.store.storefront,
    storeFilter: state.ui.storeFilter,
    profileSubscriptions: state.store.profileSubscriptions
  }
}

export default page(connect(mapStateToProps)(PageStore), { pageView: GA_PAGES.STORE, uiNavigationRoute: ROUTE_STORE })

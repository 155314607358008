import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Section } from '../components'
import { UserSettings } from '../containers'
import classnames from 'classnames'
import ReactPlayer from 'react-player'
import FA from 'react-fontawesome'
import { DOWNLOAD_MAC_LATEST, DOWNLOAD_WIN_LATEST } from '../constants'
import { welcomeProgress } from '../actions/sessionActions'
import { uiRedirect } from '../actions/ui'
import { Circle } from 'better-react-spinkit'
import { ButtonToolbar, Button } from 'react-bootstrap'
import { ROUTE_USERS } from '../utils/routingConstants'
import { pageView, GA_PAGES } from '../api/gaHelper'

import t1 from '../images/welcomedialog/t1.png'
import t2 from '../images/welcomedialog/t2.png'
import t3 from '../images/welcomedialog/t3.png'
import t1Active from '../images/welcomedialog/t1_active.png'
import t2Active from '../images/welcomedialog/t2_active.png'
import t3Active from '../images/welcomedialog/t3_active.png'

const pageTitles = [
  'Welcome to Track Attack!',
  'The best analysis software, for all major data systems!',
  'Importing sessions into Track Attack is super easy!'
]

const TABS_COUNT = pageTitles.length

const TAB_USER_STS = 0
const TAB_DOWNLOADS = 1
const TAB_DONE = 2
const PSEUDO_TAB_COMPLETED = 3

const tabTitles = [
  '1. Create a profile',
  '2. Download the software',
  '3. Import Sessions'
]

const contentTitles = [
  'Choose a unique user handle.',
  'Track Attack works on your computer - PC or Mac.',
  'Importing sessions is super easy - watch this video.'
]

const contentSubTitles = [
  "Like in other social sites. This is how people will know it's you.",
  'Unipro and ALFANO users: Skip this step, you already have the software!',
  'Works for AiM, MoTec, RaceCapture, RaceLogic and more! Unipro and Alfano Users: Skip this step, you import data via the USB or Bluetooth connections.'
]

const tabImages = [t1, t2, t3]
const tabImagesActive = [t1Active, t2Active, t3Active]

class WelcomeConfigurationOverlayWithTabs extends Component {
  constructor (props) {
    super(props)
    this.skipButtonHandler = this.onSkip.bind(this)
  }

  componentDidMount () {
    const { session } = this.props
    this.handleProps(session.welcomeProgress)
  }

  componentWillReceiveProps (newProps) {
    this.handleProps(newProps.session.welcomeProgress)
  }

  handleProps (newTab) {
    const { dispatch, session } = this.props

    if (session.isAnonymous) {
      return
    }

    if (!session.profile.handle && newTab !== TAB_USER_STS) {
      console.log('Stay on tab 0 until the handle is not set')
      this.setPage(TAB_USER_STS)
      return
    }

    if (newTab === -1) {
      console.log('Tab -1. got to step 0 or 1')
      this.setPage((session && session.profile && session.profile.handle) ? TAB_DOWNLOADS : TAB_USER_STS)
      return
    }

    if (newTab >= TABS_COUNT) {
      this.setPage(-1)
      dispatch(uiRedirect(ROUTE_USERS + '/' + session.profile.handle))
      return
    }

    // now handle vehicles and teams
    let nextTab = newTab

    if (nextTab === TAB_USER_STS && session.profile.handle) {
      console.log('Profile ok. Move to tab downloads')
      newTab = TAB_DOWNLOADS
    }

    if (newTab !== nextTab) {
      this.setPage(newTab)
    }
  }

  setPage (newTab) {
    const { dispatch, session } = this.props
    if (session.welcomeProgress === newTab) {
      return
    }

    dispatch(welcomeProgress(newTab))

    // report to GA
    switch (newTab) {
      case TAB_USER_STS:
        pageView(GA_PAGES.WELCOME_DIALOG_1, session)
        break
      case TAB_DOWNLOADS:
        pageView(GA_PAGES.WELCOME_DIALOG_2, session)
        break
      case TAB_DONE:
        pageView(GA_PAGES.WELCOME_DIALOG_3, session)
        break
      case PSEUDO_TAB_COMPLETED:
        pageView(GA_PAGES.WELCOME_DIALOG_4, session)
        break
      default:
        console.log('unexpected page: ' + newTab)
        break
    }
  }

  onSkip () {
    const { session } = this.props
    this.setPage(session.welcomeProgress + 1)
  }

  isLoading () {
    const { session } = this.props
    return session.isFetching
  }

  renderTabs (tab) {
    let images = [...tabImages]
    images[tab] = tabImagesActive[tab]

    return <Section>
      <h1>{pageTitles[tab]}</h1>
      <div className='tab-headers'>
        {images.map((image, index) => {
          return <div key={index} className='tab-thumb'>
            <img src={image} alt={tabTitles[index]} />
            <div className={classnames('tab-title', index === tab && 'active')} >{tabTitles[index]}</div>
          </div>
        })}
      </div>
    </Section>
  }

  renderTabBody (tab) {
    return <Section
      title={contentTitles[tab]}
      subtitle={contentSubTitles[tab]}>
      {tab === TAB_USER_STS && this.renderStep0Content()}
      {tab === TAB_DOWNLOADS && this.renderStep1Content()}
      {tab === TAB_DONE && this.renderStep2Content()}
      <div className='tab-bottombuttons'>
        {this.rentderTabButtons(tab)}
      </div>
    </Section>
  }

  renderStep0Content () {
    return <div>
      <UserSettings className='overlay-initial-settings' mode='initial' />
    </div>
  }

  renderStep1Content () {
    return <div className='page-downloads'>
      <h4>Select your platform</h4>
      <div className='download-links'>
        <a href={DOWNLOAD_WIN_LATEST}>
          <span className='download-links__os'><FA name='windows' /></span>
          <div>WINDOWS</div>
        </a>
        <a href={DOWNLOAD_MAC_LATEST}>
          <span className='download-links__os'><FA name='apple' /></span>
          <div>MAC</div>
        </a>
      </div>
    </div>
  }

  renderStep2Content () {
    return <div>
      <div className='tab-content_video'><ReactPlayer url='https://youtu.be/Hljda2EA3bo' /></div>
      <div>Your sessions will be automatically uploaded, securely stored in the cloud and linked to your profile.</div>
    </div>
  }

  rentderTabButtons (tab) {
    if (tab === TAB_USER_STS) {
      return ''
      // buttons are embedded into the UserSettings
    }

    if (tab === TAB_DONE) {
      // last btn
      return <ButtonToolbar>
        <Button bsStyle='primary' onClick={this.skipButtonHandler}>Done</Button>
      </ButtonToolbar>
    }

    return <ButtonToolbar>
      <Button bsStyle='primary' onClick={this.skipButtonHandler}>Next</Button>
    </ButtonToolbar>
  }

  render () {
    const { session } = this.props

    if (session.isAnonymous || session.welcomeProgress < 0 || session.welcomeProgress > TABS_COUNT - 1) {
      return ''
    }

    if (this.isLoading()) {
      return <div className='welocomedialogwithtabs'>
        <div className='welocomedialogwithtabs-tabs'>
          <Section>
            <h1>{pageTitles[session.welcomeProgress]}</h1>
            <div className='welocomedialogwithtabs-loading'>
              <Circle color={'#337ab7'} size={64} />
            </div>
          </Section>
        </div>
      </div>
    }

    return <div className='welocomedialogwithtabs'>
      <div className='welocomedialogwithtabs-tabs'>
        {this.renderTabs(session.welcomeProgress)}
      </div>
      <div className='welocomedialogwithtabs-body'>
        {this.renderTabBody(session.welcomeProgress)}
      </div>
    </div>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session
  }
}

export default connect(mapStateToProps)(WelcomeConfigurationOverlayWithTabs)

import React from 'react'
import { Section } from './'
import errorImg from '../images/404.jpg'

const Error404 = ({title, subtitle}) => {
  return (
    <Section className='error__root'
      title={title || '404 Error'}
      subtitle={subtitle || 'Whoops! Looks like this URL does not exist.'}>
      <div className='error__image'>
        <img src={errorImg} alt='not found' />
      </div>
    </Section>
  )
}

export default Error404

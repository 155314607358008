import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { Document, Page } from 'react-pdf'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import {WindowScroller, List} from 'react-virtualized'
import {getInnerWindowWidth} from '../../utils/utils'

class PdfViewerVirtualized extends Component {
  static propTypes = {
    pdfUrl: PropTypes.string.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      numPages: 1,
      pageWidth: getInnerWindowWidth()
    }

    this.onDocumentLoaded = this.onDocumentLoaded.bind(this)
    this.renderRow = this.renderRow.bind(this)
  }

  onDocumentLoaded ({numPages}) {
    this.setState({numPages})
  }

  renderRow ({ index, key, style }) {
    return <Page key={key} width={this.state.pageWidth} pageNumber={index + 1} />
  }

  render () {
    const {pdfUrl} = this.props

    return <WindowScroller>
      {({ height, isScrolling, onChildScroll, scrollTop }) => (
        <div id='pdf-viewer'>
          <Document file={pdfUrl} onLoadSuccess={this.onDocumentLoaded}>
            <List
              autoHeight
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              rowCount={this.state.numPages}
              rowHeight={this.state.pageWidth / 0.7}
              rowRenderer={this.renderRow}
              scrollTop={scrollTop}
              width={this.state.pageWidth}
            />
          </Document>
        </div>
      )}
    </WindowScroller>
  }
}

export default PdfViewerVirtualized

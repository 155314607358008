import React from 'react'
import PropTypes from 'prop-types'
import FA from 'react-fontawesome'
import { ExternalLink } from './'

const SocialIcons = (props) => {
  const { website, facebook, twitter, instagram, youtube, email } = props.entity

  return (
    <div className={props.className}>
      <ExternalLink to={website}><FA name='globe' /></ExternalLink>
      <ExternalLink to={instagram}><FA name='instagram' /></ExternalLink>
      <ExternalLink to={facebook}><FA name='facebook' /></ExternalLink>
      <ExternalLink to={youtube}><FA name='youtube-play' /></ExternalLink>
      <ExternalLink to={twitter}><FA name='twitter' /></ExternalLink>
      {email && <a href={'mailto:' + email}><FA name='envelope' /></a>}
    </div>
  )
}

SocialIcons.propTypes = {
  entity: PropTypes.object.isRequired,
  className: PropTypes.string
}

SocialIcons.defaultProps = {
  className: 'page__socials'
}

export default SocialIcons

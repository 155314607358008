import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ROUTE_TRACKS } from '../../utils/routingConstants'
import { uiNavigation } from '../../actions/ui'
import { TracksFilter } from '../index'
import { fetchTracksAction } from '../../actions/trackDbActions'
import classnames from 'classnames'
import { find } from 'lodash'
import FA from 'react-fontawesome'
import { pageView, GA_PAGES } from '../../api/gaHelper'

class PageTracksDatabase extends Component {
  constructor () {
    super()
    this.state = {
      sortName: 'name',
      sortOrder: 'asc'
    }
  }

  componentDidMount () {
    let { dispatch, session } = this.props
    dispatch(uiNavigation({ to: ROUTE_TRACKS }))
    pageView(GA_PAGES.TRACKS_DB, session)
  }

  loadTracks () {
    let { dispatch, tracks, session } = this.props
    dispatch(fetchTracksAction(session.token, tracks.list.length, tracks.filter))
  }

  render () {
    const { tracks, history } = this.props

    const gotoTrack = (id) => {
      history.push(ROUTE_TRACKS + '/' + id)
    }

    const sort = (propName) => {
      const { sortName, sortOrder } = this.state
      if (sortName === propName) {
        this.setState({
          ...this.state,
          sortOrder: sortOrder === 'asc'
            ? 'desc'
            : 'asc'
        })
      } else {
        this.setState({
          ...this.state,
          sortName: propName,
          sortOrder: 'asc'
        })
      }
    }

    const getTrackType = (track) => {
      const { lookups } = this.props

      if (!lookups) {
        return '-'
      }

      let type = find(lookups.trackTypes, { 'id': track.id })

      return type
        ? type.description
        : '-'
    }

    //   "id": "00000000-0000-0000-0000-000000000000",     "id": null,
    // "isOfficial": false,     "id": 7,     "trackSurfaceType": null,
    // "name": "FUN RUN",     "description": null,     "startPointLat": 47.840298,
    // "startPointLong": 35.112,     "endPointLat": 47.840298,     "endPointLong":
    // 35.112,     "imageUrl": null,     "isClockwise": null,     "turnCount": null,
    //     "minAltitude": null,     "maxAltitude": null,     "createdOn":
    // "2017-01-26T23:16:25+01:00",     "updatedOn": "2017-01-26T23:16:25+01:00",
    // "deleted": false

    const getClassnamesForTHeader = (propName) => {
      return classnames('table-header-sort', {
        [`${this.state.sortOrder}`]: this.state.sortName === propName
      })
    }

    const renderTracks = (tracks) => {
      return (
        <table className='tracks-table'>
          <thead>
            <tr>
              <th
                onClick={(e) => {
                  e.preventDefault()
                  sort('name')
                }}>
                <span>Name</span>
                <span className={getClassnamesForTHeader('name')} />
              </th>
              <th
                onClick={(e) => {
                  e.preventDefault()
                  sort('id')
                }}>
                <span>Type</span>
                <span className={getClassnamesForTHeader('id')} />
              </th>
              <th
                onClick={(e) => {
                  e.preventDefault()
                  sort('trackSurfaceType')
                }}>
                <span>
                  Surface type</span>
                <span className={getClassnamesForTHeader('trackSurfaceType')} />
              </th>
              <th
                onClick={(e) => {
                  e.preventDefault()
                  sort('turnCount')
                }}>
                <span>Turns</span>
                <span className={getClassnamesForTHeader('turnCount')} />
              </th>
              <th
                onClick={(e) => {
                  e.preventDefault()
                  sort('isClockwise')
                }}>
                <span>Direction</span>
                <span className={getClassnamesForTHeader('isClockwise')} />
              </th>
              <th
                onClick={(e) => {
                  e.preventDefault()
                  sort('name')
                }}
                className='tracks-mobile-cell-header'>
                <span>Name</span>
                <span className={getClassnamesForTHeader('name')} />
              </th>
            </tr>
          </thead>
          <tbody>
            {tracks.map(track => {
              return (
                <tr
                  className={classnames('tracks-table-row', { 'tracks-table-row-official': track.isOfficial })}
                  key={track.id}
                  onClick={e => {
                    e.preventDefault()
                    gotoTrack(track.id)
                  }}>
                  <td className='tracks-table-cell-name'>
                    {track.name}
                    {track.isOfficial && <FA name='check' className='track-official' />}
                  </td>
                  <td>{getTrackType(track)}</td>
                  <td>{track.SurfaceType
                    ? track.SurfaceType
                    : '-?-'}</td>
                  <td>{track.turnCount
                    ? track.turnCount
                    : '-?-'}</td>
                  <td>
                    {track.isClockwise}</td>
                  <td className='tracks-mobile-cell-content'>
                    {track.name}
                    {track.isOfficial && <FA name='check' className='track-official' />}
                    <span className='tracks-table-cell_track-type'>
                      &nbsp;({getTrackType(track)})
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
    }

    return (
      <div className='container-main'>
        <TracksFilter sortName={this.state.sortName} sortOrder={this.state.sortOrder} />
        <h2 className='tracks-mobile-header'>Tracks</h2>
        <div className='container-content-wrap'>
          <div className='tracks-container'>
            {renderTracks(tracks.list)}
            {tracks.isFetching && <div className='tracks-loading'>Loading data</div>}
            {!tracks.isFetching && tracks.hasMore && <a
              className='tracks-load-more'
              onClick={e => {
                e.preventDefault()
                this.loadTracks()
              }}>Load more</a>}
            {tracks.error && <div className='tracks-error'>error loading tracks</div>}

          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return { tracks: state.tracks.all, session: state.session, lookups: state.lookups.item }
}

export default withRouter(connect(mapStateToProps)(PageTracksDatabase))

import * as _ from 'lodash'

export const initialState = {
  isFetching: false,
  list: [],
  filter: null,
  error: null,
  hasMore: false,
  headers: null,
  data: null
}

const remove = (list, item) => {
  return list ? _.filter(list, (element) => { return element.id !== item.id }) : list
}

const add = (list, item, prepend) => {
  return list
    ? prepend ? [item, ...list] : [...list, item]
    : [item]
}

const replace = (list, oldItem, newItem) => {
  const index = _.findIndex(list, {id: oldItem.id})

  let newList = [...list]
  if (index >= 0) {
    newList.splice(index, 1, newItem)
  }
  return newList
}

export default (type) => {
  return (state = initialState, action) => {
    if (action.type !== type) { return state }

    const { result, error, context } = action

    if (context && context.clear) {
      return {
        ...initialState,
        isFetching: !(result || error)
      }
    }

    const filter = context ? context.filter : null
    const append = context ? context.append : false

    if (!(result || error)) {
      return {
        ...state,
        filter: filter,
        isFetching: true
      }
    }

    if (error) {
      return {
        ...state,
        isFetching: false,
        hasMore: false,
        error: error,
        filter: filter,
        list: []
      }
    }

    if (result.add && result.remove) {
      // replace
      return {
        ...state,
        list: replace(state.list, result.remove, result.add),
        isFetching: false
      }
    }

    if (result.add) {
      return {
        ...state,
        list: add(state.list, result.add, result.prepend),
        isFetching: false
      }
    }

    if (result.remove) {
      return {
        ...state,
        list: remove(state.list, result.remove),
        isFetching: false
      }
    }

    if (result) {
      return {
        ...state,
        isFetching: false,
        filter: filter,
        list: (append ? state.list.concat(result.list) : result.list),
        hasMore: result.hasMore,
        headers: result.headers
      }
    }
  }
}

import React from 'react'
import {PropTypes} from 'prop-types'

const propTypes = {
  LatA: PropTypes.number,
  LonA: PropTypes.number,
  MaxA: PropTypes.number
}

const defaultProps = {
  LatA: 0,
  LonA: 0,
  MaxA: 1
}

const r = 49
const d = r * 2
const margins = 2

const TractionCircle = props => (
  <svg
    className='tractioncircle'
    viewBox={'0 0 ' + (d + margins * 2) + ' ' + (d + margins * 2)}>
    <g>
      <circle
        cx={r + margins}
        cy={r + margins}
        r={r}
        stroke='black'
        strokeWidth='2'
        fill='#eaeaea' />
      <circle
        cx={r + margins}
        cy={r + margins}
        r={r / 2}
        stroke='black'
        strokeWidth='2'
        fill='none' />
      <line
        stroke='black'
        strokeWidth='2'
        x1={r + margins}
        y1={margins}
        x2={r + margins}
        y2={d + margins} />
      <line
        stroke='black'
        strokeWidth='2'
        y1={r + margins}
        x1={margins}
        y2={r + margins}
        x2={d + margins} />
      <circle
        cx={(r + margins) * (1 + (props.LonA / props.MaxA))}
        cy={(r + margins) * (1 - (props.LatA / props.MaxA))}
        r={r / 10}
        stroke='#aa3333'
        strokeWidth='1'
        fill='#ff7777' />
    </g>
  </svg>
)

TractionCircle.propTypes = propTypes

TractionCircle.defaultProps = defaultProps

export default TractionCircle

import { getInmemory, putInmemory, AGE_12_HR } from './apiCache'

// Indianapolis
const FALLBACK_LOCATION = {
  lat: 39.795017,
  lon: -86.234566
}

const PRIMARY = 'https://extreme-ip-lookup.com/json'
const SECONDARY = 'https://ipapi.co/json'

const CACHE_KEY = 'location'

export function getLocation () {
  // TODO: add html 5 geolocation APIs

  // primary: IP API
  // BACKUP: IP STACK
  // FALLBACK: Indianapolis coordinates

  const chachedLocation = getInmemory(CACHE_KEY, AGE_12_HR)

  if (chachedLocation) {
    return new Promise((resolve) => { resolve(chachedLocation) })
  }

  return fetch(PRIMARY)
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      const loc = {
        lat: res.lat,
        lon: res.lon
      }

      if (loc.lat && loc.lon) {
        putInmemory(CACHE_KEY, loc)
        return loc
      } else {
        throw new Error()
      }
    })
    .catch((anyErr) => {
      return fetch(SECONDARY)
        .then((res) => {
          return res.json()
        })
        .then((res) => {
          const loc = {
            lat: res.latitude,
            lon: res.longitude
          }

          if (loc.lat && loc.lon) {
            putInmemory(CACHE_KEY, loc)
            return loc
          }
          return FALLBACK_LOCATION
        })
        .catch((ignored) => {
          // both APIs failed
          // return Indianapolis coords
          return new Promise((resolve) => { resolve(FALLBACK_LOCATION) })
        })
    })
}

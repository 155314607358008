import auth0 from 'auth0-js'
import { searchToObject } from '../utils/utils'
import {SUBSCRIPTION_TYPES} from './apiConstants'

const myAuth0 = new auth0.WebAuth({
  domain: 'trackattack.auth0.com',
  clientID: 'g0IlJtLriH4zFPkAHE2ao7AsGsWCiqgc',
  redirectUri: window.location.origin + '/callback',
  audience: 'https://trackattack.auth0.com/userinfo',
  responseType: 'token id_token',
  scope: 'openid'
})

export const ROLES = {
  ADMIN: 'admin',
  TRACKMANAGER: 'trackmanager'
}

export function login (overrideAppState) {
  // we use an 'appState' - that is an additional parameter that will be returned by auth service and is stored in 'session.authResult.appState'
  const params = {...(searchToObject()), ...overrideAppState}
  const {to} = params
  const appState = to ? encodeURIComponent(JSON.stringify({to})) : null

  console.log(appState)

  const authOpts = {...(appState ? {appState} : {})}
  myAuth0.authorize(authOpts)
}

export function handleAuthentication (onSucess, onFail) {
  myAuth0.parseHash((err, authResult) => {
    if (authResult && authResult.accessToken && authResult.idToken) {
      // TODO: save it!
      onSucess(authResult)
    } else {
      console.error(err)
      onFail(err)
    }
  })
}

export function getRoles (session) {
  const list = session &&
    session.authResult &&
    session.authResult.idTokenPayload &&
    session.authResult.idTokenPayload['https://schemas.trackattack.com/roles']

  return list || []
}

export function isAdmin (session) {
  return getRoles(session).includes(ROLES.ADMIN)
}

export function isPremiumUserLegacy (session) {
  return isPremiumUser(session) && !session.profile.subscriptionStartedAt
}

export function isPremiumUser (session) {
  return session && session.profile && session.profile.subscriptionType === SUBSCRIPTION_TYPES.PREMIUM
}

export function isTrackmanager (session) {
  return getRoles(session).includes(ROLES.TRACKMANAGER)
}

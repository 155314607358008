import { loadSasTokenForFile, loadSasTokenForSessionExtra, upsertEntityFile, deleteEntityFile, downloadSessionSourceZipEntityFile } from '../api'
import { uploadFileToBlob } from '../api/azureApi'
import { asyncActions } from './index'
import {downloadUrl} from '../utils/utils'
import {UI_EDIT_SESSION_EXTRA, uiTimeConsumingOperationProgress, uiToast} from './ui'
import {SESSIONS_SELECTED_ENTITYFILES_FETCH} from './resultsActions'
import { FILE_CATEGORY_TYPES } from '../api/apiConstants'

export const ENTITY_FILE_UPLOAD = 'ENTITY_FILE_UPLOAD'
export const ZIPPING_SESSION_SOURCE_FILE = 'ZIPPING_SESSION_SOURCE_FILE'
// export const ENTITY_FILE_GET_SAS_TOKEN = 'ENTITY_FILE_GET_SAS_TOKEN'
// export const ENTITY_FILE_DOWNLOAD_TELEMETRY = 'ENTITY_FILE_DOWNLOAD_TELEMETRY'
// export const ENTITY_FILE_CLEAR = 'ENTITY_FILE_CLEAR'
// export function clearFiles () {
//   return { type: ENTITY_FILE_CLEAR }
// }

export function startDownloadEntityFileDownload (entityFile, token) {
  if (entityFile.typeCategoryId === FILE_CATEGORY_TYPES.TELEMETRY_ORIGINAL) {
    return downloadSessionFileAsZipArchive(entityFile, token)
  }

  return (dispatch) => {
    // dont fire actions for now
    return loadSasTokenForFile(entityFile, token)
      .then((result) => {
        // got tokens!
        downloadUrl(result.blobUrl + result.sasToken)
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function downloadSessionFileAsZipArchive (entityFile, token) {
  return (dispatch) => {
    dispatch({type: ZIPPING_SESSION_SOURCE_FILE, zipping: true})
    downloadSessionSourceZipEntityFile(entityFile, token)
      .then((entityFile) => {
        return loadSasTokenForFile(entityFile, token)
      })
      .then((result) => {
        // got tokens!
        dispatch({type: ZIPPING_SESSION_SOURCE_FILE, zipping: false})
        downloadUrl(result.blobUrl + result.sasToken)
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function deleteSessionExtra (entityFile, token) {
  return (dispatch) => {
    const filesListActions = asyncActions(SESSIONS_SELECTED_ENTITYFILES_FETCH)
    const clearUiAction = { type: UI_EDIT_SESSION_EXTRA, entityFile: null }

    return deleteEntityFile(entityFile, token)
      .then((result) => {
        dispatch(filesListActions.success({remove: entityFile}))
        dispatch(clearUiAction)
        dispatch(uiToast({text: `File ${entityFile.descr} deleted.`}))
      })
      .catch((err) => {
        dispatch(uiToast({text: `Failed to delete ${entityFile.descr}.`, bsStyle: 'danger', err}))
        dispatch(clearUiAction)
      })
  }
}

export function updateSessionExtra (entityFile, token) {
  return (dispatch) => {
    const filesListActions = asyncActions(SESSIONS_SELECTED_ENTITYFILES_FETCH)
    const clearUiAction = { type: UI_EDIT_SESSION_EXTRA, entityFile: null }

    return upsertEntityFile(entityFile, token)
      .then((result) => {
        dispatch(filesListActions.success({remove: entityFile, add: result}))
        dispatch(clearUiAction)
        dispatch(uiToast({text: `File ${entityFile.descr} updated.`, bsStyle: 'info'}))
      })
      .catch((err) => {
        dispatch(uiToast({text: `Failed to update ${entityFile.descr}.`, bsStyle: 'danger', err}))
        dispatch(clearUiAction)
      })
  }
}

export function addSessionExtra (entityFile, token) {
  let resultingEntityFile

  return (dispatch) => {
    const uploadActions = asyncActions(ENTITY_FILE_UPLOAD)
    const filesListActions = asyncActions(SESSIONS_SELECTED_ENTITYFILES_FETCH)
    const clearUiAction = { type: UI_EDIT_SESSION_EXTRA, entityFile: null }
    dispatch(uploadActions.start())

    let tokenResult

    const reportProgress = (loadedBytes) => dispatch(uiTimeConsumingOperationProgress(entityFile.file.name, loadedBytes || 0))
    reportProgress()

    return loadSasTokenForSessionExtra(entityFile, token)
      .then((result) => {
        tokenResult = result
        return uploadFileToBlob(entityFile, result.sasToken, result.blobUrl, reportProgress)
      })
      .then((uploadResult) => {
        // got the entity file with url
        // now save it
        resultingEntityFile = {
          ...entityFile,
          url: tokenResult.blobUrl,
          storageContainerName: tokenResult.container,
          ownerId: tokenResult.container,
          storageFilePath: `Sessions/${entityFile.entityId}/${entityFile.id}`
        }

        return upsertEntityFile(resultingEntityFile, token)
      })
      .then((result) => {
        dispatch(filesListActions.success({add: result}))
        dispatch(uploadActions.success(result))
        dispatch(clearUiAction)
        dispatch(uiToast({text: `File ${entityFile.file.name} uploaded.`, bsStyle: 'info'}))
        dispatch(uiTimeConsumingOperationProgress())
      })
      .catch((err) => {
        dispatch(uiToast({text: `Failed to upload ${entityFile.file.name}.`, bsStyle: 'danger', err}))
        dispatch(clearUiAction)
      })
  }
}

// export function fetchFileTokens (entityFile, token) {
//   return (dispatch) => {
//     const actions = asyncActions(ENTITY_FILE_GET_SAS_TOKEN)

//     dispatch(actions.start({ entityFile }))
//     return loadSasTokenForFile(entityFile, token)
//       .then(
//         (result) => {
//           dispatch(actions.success(result, { entityFile }))
//         }
//       )
//       .catch((error) => {
//         dispatch(actions.error(error))
//         checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
//       })
//   }
// }

// export function fetchTelemetryData (entityFile, token) {
//   // get sas token, then load data

//   return (dispatch) => {
//     const actions = asyncActions(ENTITY_FILE_GET_SAS_TOKEN)
//     const dataActions = asyncActions(ENTITY_FILE_DOWNLOAD_TELEMETRY)

//     dispatch(actions.start({ entityFile }))
//     return loadSasTokenForFile(entityFile, token)
//       .then(
//         (result) => {
//           // got tokens!
//           dispatch(actions.success(result, { entityFile }))
//           dispatch(dataActions.start({ entityFile }))
//           return loadTelemetryFile(entityFile, result, token)
//         }
//       )
//       .then(
//         // dispatch other action type!
//         (result) => {
//           dispatch(dataActions.success(result, { entityFile }))
//         }
//       )
//       .catch((error) => {
//         dispatch(actions.error(error, { entityFile }))
//         checkErrorAndDispatchlogoutIfNeeded(error, dispatch)
//       })
//   }
// }

import { asyncActions } from './'
import { fetchCurrentUser, putProfile, putProfileAvatar, fetchQuotas, putLicenseKey, cancelProfileSubscription, getAvailableProfileSubscriptions, getAvailableProfileSubscriptionsStudents, getSubscriptionInfo } from '../api'
import { pixelTrackLoginOrSignIn, pixelTrackSigninCompleted } from '../api/facebookPixelHelper'
import * as _ from 'lodash'
import { uiToast, uiRedirect } from './ui'
import { generateUserSettingsUpdatedToast, generateDefaultVehicleSetToast } from '../utils/toastCreator'
import { ROUTE_ROOT } from '../utils/routingConstants'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const FETCH_PROFILE = 'FETCH_PROFILE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const FETCH_QUOTA_USAGE = 'FETCH_QUOTA_USAGE'
export const UPDATE_WELCOME_PROGRESS = 'UPDATE_WELCOME_PROGRESS'
export const PUT_LICENSE_KEY = 'PUT_LICENSE_KEY'
export const CANCEL_PROFILE_SUBSCRIPTION = 'CANCEL_PROFILE_SUBSCRIPTION'
export const FETCH_PROFILE_SUBSCRIPTION_DETAILS = 'FETCH_PROFILE_SUBSCRIPTION_DETAILS'
export const FETCH_PROFILE_SUBSCRIPTIONS_LIST = 'FETCH_PROFILE_SUBSCRIPTIONS_LIST'
export const FETCH_PROFILE_SUBSCRIPTIONS_LIST_STUDENTS = 'FETCH_PROFILE_SUBSCRIPTIONS_LIST_STUDENTS'

function parseAppState (appState) {
  try {
    return JSON.parse(decodeURIComponent(appState))
  } catch (error) {
    return {}
  }
}

export function login (newToken, authResult) {
  return (dispatch) => {
    const actions = asyncActions(LOGIN)
    const quotaAction = asyncActions(FETCH_QUOTA_USAGE)

    dispatch(actions.start())
    return fetchCurrentUser(newToken).then(
      result => {
        pixelTrackLoginOrSignIn(result)
        dispatch(actions.success(result, { token: newToken, authResult: {...authResult, appState: parseAppState(authResult.appState)} }))
        return fetchQuotas(newToken)
      })
      .then(result => {
        dispatch(quotaAction.success(result))
      })
      .catch(err => {
        dispatch(actions.error(err))
      })
  }
}

export function fetchProfileAction (token) {
  return (dispatch) => {
    const actions = asyncActions(FETCH_PROFILE)
    const quotaAction = asyncActions(FETCH_QUOTA_USAGE)

    return fetchCurrentUser(token).then(
      result => {
        dispatch(actions.success(result))
        return fetchQuotas(token)
      })
      .then(result => {
        dispatch(quotaAction.success(result))
      })
      .catch(err => {
        checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
      })
  }
}

export function updateLicenseKeyAction (token, licenseKey, throwErr = false) {
  return (dispatch) => {
    const actions = asyncActions(PUT_LICENSE_KEY)
    const fetchProfile = asyncActions(FETCH_PROFILE)
    const fetchQuota = asyncActions(FETCH_QUOTA_USAGE)

    actions.start()
    return putLicenseKey(token, licenseKey)
      .then(result => {
        dispatch(actions.success(result))
        dispatch(fetchProfile.start())
        return fetchCurrentUser(token)
      })
      .then(result => {
        dispatch(fetchProfile.success(result))
        dispatch(fetchQuota.start())
        return fetchQuotas(token)
      })
      .then(result => {
        dispatch(fetchQuota.success(result))
      })
      .catch(err => {
        if (throwErr) {
          throw err
        }
      })
  }
}

export function updateDefaultVehicle (profile, token, newDefaultVehicle) {
  return (dispatch) => {
    const actions = asyncActions(UPDATE_PROFILE)
    dispatch(actions.start())

    return putProfile({...profile, defaultVehicleId: newDefaultVehicle.id}, token)
      .then(result => {
        dispatch(actions.success(result))
        dispatch(uiToast(generateDefaultVehicleSetToast(newDefaultVehicle)))
      })
      .catch(err => {
        dispatch(actions.error(err))
        checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
      })
  }
}

export function updateProfile (profile, token, clearError = false, currentProfile) {
  const newAvatar = profile.newAvatar
  let profileData = _.pickBy(profile, (value, key) => { return key !== 'newAvatar' })

  if (clearError) {
    return { type: UPDATE_PROFILE, result: profileData, group: UPDATE_PROFILE }
  }

  return (dispatch) => {
    const actions = asyncActions(UPDATE_PROFILE)
    dispatch(actions.start())

    const firstStep = (newAvatar ? putProfileAvatar(newAvatar, token) : new Promise((resolve) => { resolve(true) }))

    return firstStep
      .then((result) => {
        if (result && result.rowVersion) {
          // previous call returns updated profile object
          // now we need to update rowVersion for our changed data
          profileData.rowVersion = result.rowVersion
          // and change avatarUrl of our incoming data
          profileData.avatarUrl = result.avatarUrl + '?ignorecache=' + new Date().getTime()
        }

        return putProfile(profileData, token)
      })
      .then(result => {
        if (currentProfile && !currentProfile.handle && result.handle) {
          pixelTrackSigninCompleted(result)
        }
        dispatch(actions.success(result))
        dispatch(uiToast(generateUserSettingsUpdatedToast()))
      })
      .catch(err => {
        dispatch(actions.error(err))
        checkErrorAndDispatchlogoutIfNeeded(err, dispatch)
      })
  }
}

export function logout (error = null, redirect = false) {
  return (dispatch) => {
    dispatch({ type: LOGOUT, error })
    // clear any welcome dialog progress
    dispatch(welcomeProgress(-1))
    redirect && dispatch(uiRedirect(ROUTE_ROOT))
  }
}

export function checkErrorAndDispatchlogoutIfNeeded (error, dispatch) {
  if (error.status === 401) {
    dispatch(logout(error))
  }
}

export function welcomeProgress (welcomeProgress) {
  return {
    type: UPDATE_WELCOME_PROGRESS,
    welcomeProgress
  }
}

export function cancelProfileSubscriptionAction (token) {
  return (dispatch) => {
    const actions = asyncActions(FETCH_PROFILE)
    const cancelActions = asyncActions(CANCEL_PROFILE_SUBSCRIPTION)

    dispatch(actions.start())
    dispatch(cancelActions.start())

    return cancelProfileSubscription(token).then(
      result => {
        dispatch(cancelActions.success(result))
        return fetchCurrentUser(token)
      })
      .then(result => {
        dispatch(actions.success(result))
      })
      .catch(err => {
        console.error('Failed to cancel subscription :', err)
        dispatch(actions.error(err))
        return fetchCurrentUser(token)
      })
  }
}

export function getAvailableSubscriptions (token) {
  return (dispatch) => {
    const actions = asyncActions(FETCH_PROFILE_SUBSCRIPTIONS_LIST)

    dispatch(actions.start())
    return getAvailableProfileSubscriptions(token)
      .then((result) => {
        dispatch(actions.success({list: result, hasMore: false}))
      })
      .catch(err => {
        console.error('Failed to load subscriptions list', err)
      })
  }
}

export function getAvailableSubscriptions4Students (token) {
  return (dispatch) => {
    const actions = asyncActions(FETCH_PROFILE_SUBSCRIPTIONS_LIST_STUDENTS)

    dispatch(actions.start())
    return getAvailableProfileSubscriptionsStudents(token)
      .then((result) => {
        dispatch(actions.success({list: result, hasMore: false}))
      })
      .catch(err => {
        console.error('Failed to load subscriptions list', err)
      })
  }
}

export function getSubscriptionDetails (token) {
  return (dispatch) => {
    const actions = asyncActions(FETCH_PROFILE_SUBSCRIPTION_DETAILS)

    dispatch(actions.start())
    return getSubscriptionInfo(token)
      .then((result) => {
        dispatch(actions.success(result))
      })
      .catch(err => {
        console.error('Failed to aquire subscriptions details:', err)
      })
  }
}

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
// import { Document, Page } from 'react-pdf'
import {Panel, Alert} from 'react-bootstrap'
import { uiRedirect } from '../../actions/ui'
import { fetchProductContentAction } from '../../actions/storeActions'
import { Error404, PdfViewerWithEmbed, WistiaViewer, ExternalLink, YouTubeViewer } from '../../components'
import { ROUTE_VIEWER, ROUTE_STORE } from '../../utils/routingConstants'
import { PRODUCT_FILE_TYPES, PRODUCT_OWNERSHIP_TYPES } from '../../api/apiConstants'
import { GA_PAGES, pageView } from '../../api/gaHelper'
import FA from 'react-fontawesome'

class PageViewer extends Component {
  static createLink (product = {}) {
    if (!product) {
      return ROUTE_VIEWER
    }
    const {name,
      productType,
      idSendOwl,
      id,
      url,
      additionalFile1Descr,
      additionalFile1Url,
      additionalFile2Descr,
      additionalFile2Url,
      additionalFile3Descr,
      additionalFile3Url,
      additionalFile4Descr,
      additionalFile4Url,
      additionalFile5Descr,
      additionalFile5Url} = product

    // TODO: check all params
    return ROUTE_VIEWER + '/' + btoa(JSON.stringify({
      name,
      productType,
      idSendOwl,
      id,
      url,
      additionalFile1Descr,
      additionalFile1Url,
      additionalFile2Descr,
      additionalFile2Url,
      additionalFile3Descr,
      additionalFile3Url,
      additionalFile4Descr,
      additionalFile4Url,
      additionalFile5Descr,
      additionalFile5Url})).replace(/e/g, '!c')
  }

  constructor (props) {
    super(props)
    this.state = {
      target: null
    }
  }

  isPreviewMode () {
    return this.state.target && this.state.target.url
  }

  componentDidMount () {
    const {userSession, params, dispatch} = this.props
    pageView(GA_PAGES.VIEWER, userSession)

    try {
      const target = JSON.parse(atob(params.replace(/!c/g, 'e')))
      this.setState({target})
      dispatch(fetchProductContentAction(userSession.token, target.id))
    } catch (error) {
      console.error(error)
      dispatch(uiRedirect(ROUTE_STORE))
    }
  }

  shouldComponentUpdate (newProps, newState) {
    return (!this.state.target && newState.target) ||
      (this.props.productContent.isFetching !== newProps.productContent.isFetching) ||
      (!this.props.productContent.item && newProps.productContent.item)
  }

  renderViewer (product) {
    if (product.productType === PRODUCT_FILE_TYPES.WISTIA) {
      return <WistiaViewer wistiaID={product.url} allowDownload={product.ownerShipType === PRODUCT_OWNERSHIP_TYPES.CAN_DOWNLOAD} />
    }

    if (product.productType === PRODUCT_FILE_TYPES.YOUTUBE) {
      return <YouTubeViewer youtubeUrl={product.url} />
    }

    if (product.productType === PRODUCT_FILE_TYPES.PDF) {
      return <PdfViewerWithEmbed pdfUrl={product.url} allowDownload={product.ownerShipType === PRODUCT_OWNERSHIP_TYPES.CAN_DOWNLOAD} />
    }
  }

  renderError (product) {
    return <Error404 title={product && product.productType === PRODUCT_FILE_TYPES.WISTIA ? 'Video not found' : 'Content not found'} />
  }

  renderAdditionalFile (url, title) {
    if (!url) return null

    const urlParts = url.split('/')
    const visibleTitle = title || (urlParts[urlParts.length - 1] || '').split('?')[0]

    const dotArr = url.split('?')[0].split('.')
    const ext = dotArr[dotArr.length - 1]

    let iconName = 'file-o'
    switch (ext.toLowerCase()) {
      case 'pdf':
        iconName = 'file-pdf-o'
        break
      case 'doc':
      case 'docx':
        iconName = 'file-word-o'
        break
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        iconName = 'file-image-o'
        break
      case 'xlx':
      case 'xlxx':
        iconName = 'file-exel-o'
        break
      case 'mp3':
      case 'ogg':
        iconName = 'file-sound-o'
        break
      case 'mp4':
      case 'avi':
        iconName = 'file-video-o'
        break
      default:
        iconName = 'file-o'
    }

    return <ExternalLink className='additional-file' to={url}>
      <FA name={iconName} />
      <span className='additional-file__name'>{visibleTitle}</span>
    </ExternalLink>
  }

  renderAdditionalFiles (productContent) {
    if (productContent.productType === PRODUCT_FILE_TYPES.PDF) {
      return null
    }

    return <Panel>
      <Panel.Heading><h3>Additional files</h3></Panel.Heading>
      <Panel.Body>
        {this.isPreviewMode() && <Alert bsStyle='warning'>Additional files cannot be viewed in the preview mode!</Alert>}
        {!productContent.additionalFile1Url &&
          !productContent.additionalFile2Url &&
          !productContent.additionalFile3Url &&
          !productContent.additionalFile4Url &&
          !productContent.additionalFile5Url &&
          <Alert>No additional files specified</Alert>}
        {productContent.additionalFile1Url && this.renderAdditionalFile(productContent.additionalFile1Url, productContent.additionalFile1Descr)}
        {productContent.additionalFile2Url && this.renderAdditionalFile(productContent.additionalFile2Url, productContent.additionalFile2Descr)}
        {productContent.additionalFile3Url && this.renderAdditionalFile(productContent.additionalFile3Url, productContent.additionalFile3Descr)}
        {productContent.additionalFile4Url && this.renderAdditionalFile(productContent.additionalFile4Url, productContent.additionalFile4Descr)}
        {productContent.additionalFile5Url && this.renderAdditionalFile(productContent.additionalFile5Url, productContent.additionalFile5Descr)}
      </Panel.Body>
    </Panel>
  }

  renderPreviewViewer (target) {
    return <Fragment>
      {this.renderViewer(target)}
      {this.renderAdditionalFiles(target)}
    </Fragment>
  }

  renderUserViewer (productContent, target) {
    return <Fragment>
      {productContent.isFetching && <div className='loader'>Loading...</div>}
      {!productContent.error && productContent.item && this.renderViewer(productContent.item)}
      {productContent.error && this.renderError(target, productContent.error)}
      {productContent.item && this.renderAdditionalFiles(productContent.item)}
    </Fragment>
  }

  render () {
    const {target} = this.state
    const {productContent} = this.props

    return <div id='viewer'>
      {this.isPreviewMode() && <Alert bsStyle='warning'>This is product preview!</Alert>}
      {this.isPreviewMode() && productContent.item && productContent.item.productType === PRODUCT_FILE_TYPES.PDF && <Alert bsStyle='danger'>PDF file cannot be loaded in the preview mode...</Alert>}
      {this.isPreviewMode() ? this.renderPreviewViewer(target) : this.renderUserViewer(productContent, target)}
    </div>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    params: ownProps.match.params.params,
    userSession: state.session,
    productContent: state.store.productContent
  }
}

export default connect(mapStateToProps)(PageViewer)

import {
  fetchAllProducts,
  fetchAllPurchases,
  fetchProductContent,
  fetchAllProductsAdmin,
  fetchAllOrders,
  updateAllProductsFromSendOwl,
  updateProduct,
  putProductFile,
  deletePurchase,
  sortProducts } from '../api'
import { PRODUCT_FILE_TYPES } from '../api/apiConstants'
import { getCartItemsCount, addProductToCart } from '../api/sendowlApi'
import { asyncActions } from './index'
import {filter, find} from 'lodash'
import { clearKey } from '../api/apiCache'
import { uiToast } from './ui'

export const FETCH_STOREFRONT = 'FETCH_STOREFRONT'
export const FETCH_PURCHASES = 'FETCH_PURCHASES'

export const FETCH_ADMIN_PRODUCTS = 'FETCH_ADMIN_PRODUCTS'
export const UPDATE_ADMIN_PRODUCTS = 'UPDATE_ADMIN_PRODUCTS'

export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const FETCH_PRODUCT_CONTENT = 'FETCH_PRODUCT_CONTENT'
export const FETCH_DATA_PACK_SESSIONS_LIST = 'FETCH_DATA_PACK_SESSIONS_LIST'
export const SAVE_PRODUCT = 'SAVE_PRODUCT'

export const FETCH_CART_COUNT = 'FETCH_CART_COUNT'
export const FETCH_CART_ITEMS = 'FETCH_CART_ITEMS'
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'

export const FETCH_ORDERS = 'FETCH_ORDERS'

function filterProducts (list, listFilter = {}) {
  const {search = '', purchased = null, productType = PRODUCT_FILE_TYPES.UNDEFINED} = listFilter

  return filter(list, (product) => {
    return product.description &&
      (purchased === null || Boolean(product.purchased) === purchased) &&
      (search.length < 0 || product.description.toLowerCase().includes(search.toLowerCase())) &&
      (productType === PRODUCT_FILE_TYPES.UNDEFINED || product.productType === productType)
  })
}

export function fetchStorefront (token, filter) {
  return dispatch => {
    const actions = asyncActions(FETCH_STOREFRONT)
    dispatch(actions.start())
    return fetchAllProducts(token).then(
      result => {
        // small change to make things easier
        // when user is anonymous, products have memberOwnership:'None'
        const processedResult = result && result.map((item) => { return {...item, purchased: item.purchased || (item.memberOwnership !== 'None' && Boolean(item.memberOwnership))} })

        const products = filter ? filterProducts(processedResult, filter) : processedResult
        // hack! it empties grid before changing the data. otherwise it goes crazy
        dispatch(actions.success({list: []}))
        // convert resulting array to make it compatible with the listReducer
        dispatch(actions.success({list: products, hasMore: false}))
        return {filter, products}
      },
      error => dispatch(actions.error(error))
    )
  }
}

export function fetchAdminProducts (token) {
  return dispatch => {
    const actions = asyncActions(FETCH_ADMIN_PRODUCTS)
    dispatch(actions.start())
    return fetchAllProductsAdmin(token).then(
      result => {
        // convert resulting array to make it compatible with the listReducer
        dispatch(actions.success({list: result, hasMore: false}))
      },
      error => dispatch(actions.error(error))
    )
  }
}

export function fetchProduct (token, productId, product) {
  return (dispatch) => {
    // no special API to load product. Just return the same info as in the list
    const actions = asyncActions(FETCH_PRODUCT)
    if (product && productId === product.idSendOwl) {
      dispatch(actions.success(product))
    } else {
      dispatch(actions.error({message: 'Failed to load product ' + productId}))
    }

    // console.log(product)

    // const sessionsListActions = asyncActions(FETCH_DATA_PACK_SESSIONS_LIST)
    // dispatch(sessionsListActions.start({ clear: true }))

    // if (product && product.productType === PRODUCT_FILE_TYPES.DATA_PACK) {
    //   const sessionsIDs = product.contentDefinition && product.contentDefinition.split && product.contentDefinition.split(';')
    //   console.log(sessionsIDs)
    // }
  }
}

export function unbuyProduct (token, product) {
  return (dispatch) => {
    const allProductsactions = asyncActions(FETCH_STOREFRONT)
    const singleActions = asyncActions(FETCH_PRODUCT)

    return deletePurchase(product, token)
      .then(
        (result) => {
          return fetchAllProducts(token)
        }
      )
      .then(
        (result) => {
          dispatch(allProductsactions.success({list: result, hasMore: false}))
          const refreshedProduct = find(result, {id: product.id})
          dispatch(singleActions.success(refreshedProduct))
        }
      )
  }
}

export function fetchProductContentAction (token, productId) {
  return (dispatch) => {
    const actions = asyncActions(FETCH_PRODUCT_CONTENT)
    dispatch(actions.start({clear: true}))

    return fetchProductContent(token, productId)
      .then(
        result => {
          dispatch(actions.success(result))
        }
      )
      .catch(
        err => {
          dispatch(actions.error(err))
        }
      )
  }
}

export function saveProduct (token, product, files) {
  return (dispatch) => {
    const actionsFetchAll = asyncActions(FETCH_ADMIN_PRODUCTS)
    const actionsFetchOne = asyncActions(FETCH_PRODUCT)
    const actionsSave = asyncActions(SAVE_PRODUCT)

    dispatch(actionsFetchAll.start())
    dispatch(actionsFetchOne.start())
    dispatch(actionsSave.start())

    const promiseGenerator = (actualProduct, fileField, urlField, enableUploadContent) => {
      return (files[fileField]
        ? putProductFile(token, files[fileField], enableUploadContent).then((result) => { return {urlField, url: result} })
        : new Promise((resolve) => {
          resolve({url: actualProduct[urlField], urlField})
        }))
        .then((urlResult) => {
          return {...actualProduct, [urlResult.urlField]: urlResult.url}
        })
    }

    const fileStep = promiseGenerator(product, 'file', 'url', false)

    return fileStep
      .then((result) => promiseGenerator(result, 'additionalFile1', 'additionalFile1Url', true))
      .then((result) => promiseGenerator(result, 'additionalFile2', 'additionalFile2Url', true))
      .then((result) => promiseGenerator(result, 'additionalFile3', 'additionalFile3Url', true))
      .then((result) => promiseGenerator(result, 'additionalFile4', 'additionalFile4Url', true))
      .then((result) => promiseGenerator(result, 'additionalFile5', 'additionalFile5Url', true))
      // one update to rule them all
      .then((result) => updateProduct(token, result))
      .then((result) => {
        dispatch(actionsSave.success(result))
        dispatch(actionsFetchOne.success(result))
        dispatch(actionsFetchAll.success({remove: result}))
        dispatch(actionsFetchAll.success({add: result}))
        clearKey('/storefront/products')
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export function updateProductsFromSendOwlAction (token) {
  return dispatch => {
    const updateActions = asyncActions(UPDATE_ADMIN_PRODUCTS)
    const fetchActions = asyncActions(FETCH_ADMIN_PRODUCTS)

    dispatch(updateActions.start())
    return updateAllProductsFromSendOwl(token).then(
      result => {
        dispatch(updateActions.success(result))
        if ((result.importedProducts.length + result.updatedProducts.length)) {
          dispatch(fetchActions.start({persistStoreUpdate: true}))
          return fetchAllProductsAdmin(token)
        }
      },
      error => dispatch(updateActions.error(error))
    ).then(
      result => {
        // convert resulting array to make it compatible with the listReducer
        result && dispatch(fetchActions.success({list: result, hasMore: false}, {persistStoreUpdate: true}))
        // invalidate cache for the local store
        clearKey('/storefront/products')
      },
      error => dispatch(fetchActions.error(error))
    )
  }
}

export function updateProductsOrder (token, newProductsList) {
  return dispatch => {
    const fetchActions = asyncActions(FETCH_ADMIN_PRODUCTS)

    dispatch(fetchActions.start({clear: true}))

    const data = newProductsList.reduce(
      (accumulator, value, index) => { return {...accumulator, [value.id]: index} },
      {}
    )

    return sortProducts(token, data)
      .then((result) => {
        // TODO: consume result
        return fetchAllProductsAdmin(token)
      })
      .then(
        (result) => {
          // convert resulting array to make it compatible with the listReducer
          dispatch(fetchActions.success({list: result, hasMore: false}))
        },
        (error) => {
          console.error(error)
          dispatch(fetchActions.error(error))
        }
      )
  }
}

export function fetchOrders (token) {
  return dispatch => {
    const actions = asyncActions(FETCH_ORDERS)
    dispatch(actions.start())
    return fetchAllOrders(token).then(
      result => {
        dispatch(actions.success({list: result}))
      },
      error => dispatch(actions.error(error))
    )
  }
}

export function fetchPurchases (token) {
  return dispatch => {
    const actions = asyncActions(FETCH_PURCHASES)
    dispatch(actions.start())
    return fetchAllPurchases(token).then(
      result => {
        dispatch(actions.success(result))
      },
      error => dispatch(actions.error(error))
    )
  }
}

export function cartItemsCount () {
  return dispatch => {
    const actions = asyncActions(FETCH_CART_COUNT)
    const count = getCartItemsCount()
    dispatch(actions.success(count || 0))
  }
}

function reportAddToCartError (dispatch, addResult) {
  let text = ''
  let bsStyle = 'warning'

  switch (addResult.message) {
    case 'single_quantity_item_limit' :
      text = 'This product is already in your cart'
      bsStyle = 'info'
      break
    default: text = 'Failed to add the product to the cart'
  }

  dispatch(uiToast({text, bsStyle}))
}

export function addProductToCartAction (product) {
  return dispatch => {
    // const actionsAdd = asyncActions(ADD_PRODUCT_TO_CART)
    const actionsCount = asyncActions(FETCH_CART_COUNT)

    return addProductToCart(product).then(
      (result) => {
        if (result && result.status === 'error') {
          reportAddToCartError(dispatch, result)
          const count = getCartItemsCount()
          dispatch(actionsCount.success(count || 0))
        } else {
          result.cart_item_count && dispatch(actionsCount.success(result.cart_item_count))
        }
      }
    )
  }
}

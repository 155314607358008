import React, { Fragment, Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { fetchAdminProducts, updateProductsFromSendOwlAction, updateProductsOrder } from '../../actions/storeActions'
import { connect } from 'react-redux'
import { ROUTE_ADMIN_STORE, ROUTE_STORE } from '../../utils/routingConstants'
import { Section, ProductsSortableList } from '../../components'
import { GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'
import FA from 'react-fontawesome'

class PageStoreAdmin extends Component {
  constructor (props) {
    super(props)
    this.refreshProductsFromSendOwl = this.refreshProductsFromSendOwl.bind(this)
    this.rowClickHandler = this.rowClickHandler.bind(this)
    this.listSortedHandler = this.listSortedHandler.bind(this)
    this.saveCurrentProductsOrder = this.saveCurrentProductsOrder.bind(this)

    this.state = {
      updated: false,
      newOrderedList: null
    }
  }

  componentWillMount () {
    const { dispatch, userSession } = this.props
    dispatch(fetchAdminProducts(userSession.token))
  }

  refreshProductsFromSendOwl () {
    const {dispatch, userSession} = this.props
    dispatch(updateProductsFromSendOwlAction(userSession.token)).then(() => {
      this.setState({updated: true})
    })
  }

  saveCurrentProductsOrder () {
    const {dispatch, userSession} = this.props
    const {newOrderedList} = this.state

    dispatch(updateProductsOrder(userSession.token, newOrderedList))
    this.setState({newOrderedList: []})
  }

  rowClickHandler (product) {
    const {history} = this.props
    history.push(ROUTE_ADMIN_STORE + '/' + product.idSendOwl)
  }

  listSortedHandler (newList) {
    this.setState({newOrderedList: newList})
  }

  render () {
    const {storeProductsList, storeUpdateResult, selectedProduct} = this.props
    const { updated, newOrderedList } = this.state

    const buttons = <ButtonToolbar>
      {newOrderedList && newOrderedList.length !== 0 && <Button bsStyle='warning' onClick={this.saveCurrentProductsOrder}>Save products order</Button>}
      <Button bsStyle='primary' onClick={this.refreshProductsFromSendOwl}>Update from SendOwl</Button>
    </ButtonToolbar>

    const headerMessage = updated && <span>Imported: {storeUpdateResult.importedProducts.length}, updated: {storeUpdateResult.updatedProducts.length}</span>

    return (
      <Section
        title={<Fragment><FA name='shopping-cart' /><span>&nbsp;Admin panel</span></Fragment>}
        subtitle='Panel for admins'
        buttons={buttons}
        headerMessage={headerMessage}
        className='storeadmin'>
        <ProductsSortableList
          list={storeProductsList ? storeProductsList.list : []}
          itemClick={this.rowClickHandler}
          isFetching={storeProductsList.isFetching}
          error={selectedProduct.error || storeProductsList.error}
          listReordered={this.listSortedHandler} />
      </Section>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    userSession: state.session,
    storeProductsList: state.store.storeProductsList,
    storeUpdateResult: state.store.storeUpdateResult,
    selectedProduct: state.store.selectedProduct
  }
}

export default withRouter(page(connect(mapStateToProps)(PageStoreAdmin), { pageView: GA_PAGES.ADMIN_STORE, uiNavigationRoute: ROUTE_STORE }))

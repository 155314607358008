import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Section } from '../../components'
import { login } from '../../api/auth0'
import { Row, Col, Alert, Button } from 'react-bootstrap'
import { pageView, GA_PAGES } from '../../api/gaHelper'
import { ROUTE_AUTH_LICENSECHECK } from '../../utils/routingConstants'
import { Circle } from 'better-react-spinkit'
import { updateLicenseKeyAction, logout } from '../../actions/sessionActions'
import { uiToast } from '../../actions/ui'
import { userFullName } from '../../utils/modelConvertionUtils'
import noImage from '../../images/no_image.jpg'

/**
 * CALLBACK page
 * checks current profile state
 * accepts 1 hash parameter 'id_token'
*/
class PageLecenseCheck extends Component {
  constructor (props) {
    super(props)
    this.state = {
      licenseKey: null,
      licenseError: false,
      licensePending: false,
      licenseKeySet: false
    }

    this.logInAsOtherUser = this.logInAsOtherUser.bind(this)
    this.proceedLicenseKey = this.proceedLicenseKey.bind(this)
  }

  componentWillMount () {
    const { session } = this.props
    this.handleUrlParams()

    pageView(GA_PAGES.LICENCECHECK)

    if (session.isAnonymous) {
      // login and come back later
      login({ to: `${ROUTE_AUTH_LICENSECHECK}${window.location.hash}` })
    }
  }

  isKeyValid (key) {
    if (key && key.length && key.length === 6) {
      const pattern = /^[A-z][0-9]{5}/
      return pattern.test(key)
    }

    return false
  }

  handleUrlParams () {
    const { hash } = window.location
    let key = null

    if (hash.startsWith('#key=')) {
      key = hash.replace('#key=', '')
    }

    this.setState({
      licenseKey: this.isKeyValid(key) && key,
      licenseError: false
    })
  }

  logInAsOtherUser () {
    const {dispatch} = this.props

    dispatch(logout(null, false))
    login({ to: `${ROUTE_AUTH_LICENSECHECK}${window.location.hash}` })
  }

  proceedLicenseKey () {
    const { session, dispatch } = this.props
    const { licenseKey, licensePending, licenseError } = this.state
    if (licenseError || licensePending) {
      console.log('Cannot set')
      return
    }

    // do set
    this.setState({
      licenseError: false,
      licensePending: true,
      licenseKeySet: false
    })

    dispatch(updateLicenseKeyAction(session.token, licenseKey, true))
      .then(
        result => {
          this.setState({
            licensePending: false,
            licenseKeySet: true
          })
          dispatch(uiToast(
            {
              text: 'License key updated!',
              bsStyle: 'success'
            }
          ))
        },
        error => {
          this.setState({
            licensePending: false,
            licenseError: true
          })
          console.log(error)
        }
      )
  }

  render () {
    const { session } = this.props
    const { licenseError, licenseKey, licensePending, licenseKeySet } = this.state

    const { profile } = session

    const renderCurrentState = () => {
      if (session.isAnonymous) {
        return ''
      }

      return <Row className='servicepage-profile'>
        <Col sm={3}>
          <img className='page__logo' src={profile.avatarUrl || noImage} alt='user' />
        </Col>
        <Col sm={9}>
          <h4>{userFullName(profile)}</h4>
          <h5>{'@' + profile.handle}</h5>
          <div>License status: <strong>{profile.licenseValid ? 'VALID' : 'NOT SET'}</strong></div>
        </Col>
      </Row>
    }

    const renderInternalMessage = () => {
      // success!
      // console.log(licenseKey, session)

      if (licensePending || session.isAnonymous) {
        return <div className='loading'>
          <Circle color={'#337ab7'} size={64} />
        </div>
      }

      if (!licenseKey) {
        return <Alert bsStyle='danger'>
          <div>License key is not valid</div>
        </Alert>
      }

      if (licenseError) {
        return <Alert bsStyle='danger'>
          <div>Unable to verify your license key</div>
          {licenseKey && <div>Please contact support about your key: <strong>{licenseKey}</strong></div>}
        </Alert>
      }

      if (licenseKeySet) {
        return <Alert bsStyle='success'>
          <div>You have successfully set the key <strong>{licenseKey}</strong> for your account!</div>
        </Alert>
      }

      return <div>
        <Alert bsStyle='info'>
          <div>You are about to set <strong>{licenseKey}</strong> license key for your <strong>current</strong> account (<strong>@{profile.handle}</strong>).</div>
        </Alert>
        <div className='buttons'>
          <Button onClick={this.logInAsOtherUser} bsStyle='warning'>Proceed for another account</Button>
          <Button onClick={this.proceedLicenseKey} bsStyle='success'>Proceed for @{profile.handle}</Button>
        </div>
      </div>
    }

    return (
      <div className='servicepage-container'>
        <Row>
          <Col sm={8} smOffset={2} >
            <Section title='License key verification'>
              {renderCurrentState()}
              {renderInternalMessage()}
            </Section>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return { session: state.session }
}

export default connect(mapStateToProps)(PageLecenseCheck)

import React from 'react'
import { Link } from 'react-router-dom'
import { Section, ExternalLink } from '../../components'
import { page } from '../../hocs/page'
import { GA_PAGES } from '../../api/gaHelper'
import ReactPlayer from 'react-player'
import img01 from '../../images/iracing/img01.jpg'
import img02 from '../../images/ac/img02.jpg'
import img03 from '../../images/ac/img03.jpg'
import img04 from '../../images/ac/img04.jpg'

const LandingAssettoCorsaPage = () => {
  return (
    <Section title='Assetto Corsa with Track Attack' subtitle='What it is and how to use it'>
      <p>Track Attack is the most modern and easy to use motorsports analysis solution in the world and we are firm believers in the power of simulator driving. Driving in a simulator has come such a long way over the past couple of decades where, with the right equipment, driving in a simulator is 90-95% of the real-world experience. Furthermore, there are more simulator drivers, driving more often in practice, wheel to wheel racing and time attack sessions than there are in the real world already.</p>
      <p>Drivers in simulators take their driving and competitiveness just as seriously as those in the real world (many drivers, coaches and teams straddling both worlds) and that is why we decided to make Track Attack work with the most popular simulator platforms.</p>

      <h3>What is it?</h3>
      <p>Track Attack has created a plugin, available only through the Track Attack desktop application, which logs Assetto Corsa telemetry and generates a .csv.gzip telemetry file. This means that the full functionality you’ve come to know (and love!) of Track Attack will now work and be officially supported for Assetto Corsa telemetry data.</p>

      <h3>How does it work?</h3>
      <p>See the demo video below and the step by step instructions, below the video. </p>
      <div className='landing__videocontainer'>
        <ReactPlayer url='https://www.youtube.com/watch?v=wgLxsJvdbYU' />
      </div>
      <p>Here are the steps to getting your Assetto Corsa telemetry data into Track Attack:</p>

      <ol>
        <li>Make sure you have v114 or newer of Track Attack. <Link to='/downloads'>Download here if you don’t have it.</Link></li>
        <li>Make sure you are logged-in to your Track Attack account in the Track Attack desktop software.</li>
        <ol type='a'>
          <li>You need to be signed-in to your TA account so that the desktop client can download the latest track database, which will contain the supported Assetto Corsa track layouts.</li>
        </ol>
        <li>Make sure Assetto Corsa IS NOT running.  Install the AC plugin from the Track Attack desktop software, under Menu --> Simulators --> Install Assetto Corsa Plugin.</li>
        <li>Make sure to select the default folder location "C:\Program Files (x86)\Steam\steamapps\common\assettocorsa".  Note if you have installed Assetto Corsa components in custom locations, this may not work.</li>
        <li>After successful install, you should see a "trackAttackPlugin" folder in this directory location "C:\Program Files (x86)\Steam\steamapps\common\assettocorsa\apps\python\" .</li>
        <li>Launch Assetto Corsa and when you are driving, you should see an app widget which indicates the Plugin is installed and logging.</li>
        <li>After you are done driving, exit the simulator.</li>
        <li>Open Track Attack and click on the big green “Import” button</li>
        <li>Find the .csv.gz file for your session in the “C:\\Users\[your computer user name]\Documents\Track Attack Data” folder.</li>
        <ol type='a'>
          <li>Note – you can import multiple sessions at once.</li>
        </ol>
        <li>Track Attack will read the file and display a preview of the data in the ‘Import Window’. Review the data and make sure all looks good before clicking “Import”. Click “Import” when you’re ready.</li>
        <ol type='a'>
          <li><strong>Make sure the right track and layout are selected</strong>. All Assetto Corsa track layouts start with “AC [track and layout name]”. Some tracks have multiple layouts – make sure the right one is selected.</li>
          <li><strong>Review the lap times</strong> – we generate GPS coordinates and recalculate the lap times to ensure they are comparable with all other data from the same track, including real-world data sessions, so the lap times may differ by ~.1 seconds or less.</li>
          <li><strong>Make sure the right driver is selected</strong> – by default we have the currently logged in user as the driver, but the driver can be changed to anyone you are connected to in Track Attack across teams or people you’ve shared data within the past.</li>
          <li>Add any notes you may want and enter any individual or team you want to share the session with.</li>
        </ol>
        <li>That’s it! After the session(s) have imported, you will automatically be able to view the data however you want. TA automatically selects the fastest three laps of the session(s) you imported.</li>
        <ol type='a'>
          <li>The data session(s) will automatically start synchronizing a few seconds after import.</li>
          <li>If you entered any user handles, email addresses or team handles when importing the session, when the synchronization happens, it will initiate those share requests.</li>
        </ol>
      </ol>

      <h3>Can I compare real-world with Assetto Corsa data?</h3>
      <p>Absolutely and this is one of the most common scenarios that drove this feature request. For example, let’s say you are a Global Mazda MX-5 Cup driver, coach or team and you want to compare real-world data from Laguna Seca with AC data from Laguna Seca. When you import the real-world session, you will select “Laguna Seca” as the track and layout (there is only one). When you import the AC session, you will select “AC Laguna Seca”.</p>
      <p>Open both sessions up and you will notice that there are two sessions open on two different tracks/layouts but when the sessions are viewed in ‘Distance Mode’ with the ‘Time Lost’ graph enabled, TA shows the time delta from any of the laps selected, even across both sessions.</p>

      <div className='landing__imgcontainer'><img src={img01} alt='iRacing' /></div>

      <h3>Is the track map real?</h3>
      <p>Of course it’s real, you see it, right? <span role='img' aria-label=':)'>😊</span> This is where things get a little tricky. Assetto Corsa does not have real-world GPS coordinates associated with every point on each track. Part of the plugin is to use the data we do have about the track, the layout, start/finish coordinates and few other variables to generate GPS coordinates.  This means that the channel data you look at is accurate but the GPS coordinates we generate may be off a bit from their real place in the real world.  The driven line shown will be comprable to other laps and sessions in AC but will likely be off a bit from sessions that are recorded with real GPS coordinates.</p>

      <div className='landing__imgcontainer'><img src={img02} alt='iRacing' /></div>

      <h3>Will split time/segment analysis still work?</h3>
      <p>Yes! We have created standard segment/split definitions for all of the major road-course tracks in AC and even a few unofficial mod tracks. These segments were created based on a methodology used by several professional coaches and racing teams, where tracks are broken up into at least three segments and each segment is created right before a major braking zone, with the exception of start/finish.</p>
      <p>To see the split time/segment analysis, open a session and click on the "Split Time" button in the main menu section at the top of the application. Here is how to read the report:</p>
      <ol>
        <li>Each row is a lap time in the session with the columns being the total lap time and each of the segments for the track/layout.</li>
        <li>Each cell is the time for that specific segment on that specific lap.</li>
        <li>Fastest segment times are highlighted in yellow.</li>
        <li><strong>Theoretical Best:</strong> This is the theoretical fastest lap time if the driver was able to combine the fastest segments across all the laps, into one lap.</li>
        <li><strong>Rolling Best:</strong> This is considered a more accurate display of a driver’s potential ‘pace’ in that session. It ignores the official start/finish location and determines the fastest complete lap time driven, regardless of which segment contains the start/finish.</li>
        <li><strong>Range:</strong> A calculation of the time range for each segment from the slowest to the fastest time. The largest range is a good indication of where to work on being more consistent. #speedsecret</li>
        <li>If you want to look analyze specific laps in your session, you have a couple options:</li>
        <ol type='a'>
          <li>CTRL + Select all the laps you want to analyze. This will update range row with only the laps you have selected.</li>
          <li>Right Click + Hide Lap will hide or disable a lap. This will remove it from laps considered for theoretical fastest lap, best rolling lap and the lap range.</li>
        </ol>
      </ol>

      <div className='landing__imgcontainer'><img src={img03} alt='iRacing' /></div>

      <h3>Does this work for Mac?</h3>
      <p>Yes and No – the Track Attack ability to import and read data from the TA AC plugin will work exactly as described for Windows and Mac. However, AC only runs on Windows devices and thus the plugin will only work on Windows devices to log data.</p>

      <h3>Can I synchronize video with my Assetto Corsa data?</h3>
      <p>Yes – many of our beta testers used screen capture/recording solutions such as the built-in WINDOWS_KEY + G to record the screen, of which then outputs to an .mp4 file. Some users have also recorded their driving sessions with an external camera that records their screens and their own driver inputs.</p>
      <p>The advanced auto-sync video feature has not been validated on either of these methods so results may vary. However, we expect it to generally work and there is still a mechanism for manually aligning the data and video – <ExternalLink to={'https://www.youtube.com/watch?v=DMImztOnVzo&feature=youtu.be'}>see this video for step-by-step instructions</ExternalLink>.</p>

      <h3>Will my session data still sync with the cloud?</h3>
      <p>Yes – AC telemetry .csv.gz sessions are treated like any other supported session within Track Attack. For free members, it will count towards the 50 sessions that are synced to the cloud. The 51st and higher sessions will still be importable and viewable locally on your computer but those will not sync with the cloud, unless you are a premium user.</p>

      <h3>Will my session data still count towards leaderboards?</h3>
      <p>Yes – all sessions that are imported and associated with an official track and layout, will count towards the current track leaderboards. The main difference is that we’ve created new layouts for each of the iRacing supported tracks and therefore, iRacing lap times will only count towards the iRacing specific track layout leaderboards.</p>

      <div className='landing__imgcontainer'><img src={img04} alt='iRacing' /></div>
    </Section>
  )
}

export default page(LandingAssettoCorsaPage, { pageView: GA_PAGES.VALUE_ASSETTOCORSA })

function buildInviteeCountText (specs) {
  let names = specs.map((spec) => spec.recipientSpec)
  if (names.length > 3) {
    names = names.slice(0, 3)
  }

  let text = ''
  switch (specs.length) {
    case 1:
      text = names[0]
      break
    case 2:
      text = `${names[0]} and ${names[1]}`
      break
    case 3:
      text = `${names[0]}, ${names[1]} and ${names[2]}`
      break
    default:
      text = `${names[0]}, ${names[1]}, ${names[2]} and ${specs.length - names.length} others`
      break
  }
  return text
}

export const generateInvitesSentToast = (specs, type) => {
  const text = buildInviteeCountText(specs)
  return {
    text: `You have invited ${text} to your ${type}`
  }
}

export const generateTrackDeletedToast = (track) => {
  return {
    text: `The track @${track.handle} was successfully deleted`
  }
}

export const generateTrackUpdatedToast = (track) => {
  return {
    text: `The track @${track.handle} was successfully updated`
  }
}

export const generateTrackCreatedToast = (track) => {
  return {
    text: `The track @${track.handle} was successfully created`
  }
}

export const generateTeamCreatedToast = (team) => {
  return {
    text: `The team @${team.handle} was created`
  }
}

export const generateTeamUpdatedToast = (team) => {
  return {
    text: `The team @${team.handle} was updated`
  }
}

export const generateRequestToast = (spec) => {
  return {
    text: spec.targetType === 'Team' ? `You have requested to join the team` : `You have requested to view the session`
  }
}

export const generateTeamDeletedToast = (team) => {
  return {
    text: `The team @${team.handle} was deleted forever`,
    bsStyle: 'danger'
  }
}

export const generateYouHaveLeftTheTeamToast = (team) => {
  return {
    text: `You have left the team @${team.handle}`,
    bsStyle: 'warning'
  }
}

export const generateVehicleCreatedToast = (vehicle) => {
  return {
    text: `The vehicle @${vehicle.handle} was created`
  }
}

export const generateVehicleUpdatedToast = (vehicle) => {
  return {
    text: `The vehicle @${vehicle.handle} was updated`
  }
}

export const generateVehicleDeletedToast = (vehicle) => {
  return {
    text: `The vehicle @${vehicle.handle} was deleted forever`,
    bsStyle: 'danger'
  }
}

export const generateUserSettingsUpdatedToast = () => {
  return {
    text: `Your user settings were updated`
  }
}

export const generateDefaultVehicleSetToast = (vehicle) => {
  return {
    text: `The vehicle @${vehicle.handle} was set as default`
  }
}

export const generateSessionSettingsUpdatedToast = () => {
  return {
    text: `The session settings have been updated`
  }
}

export const generateSessionDeletedToast = (session) => {
  return {
    text: `The session was deleted`,
    bsStyle: 'danger'
  }
}

export const generateProgressToast = (text) => {
  return {
    text: `${text}`,
    progress: true
  }
}

// bsStyle - same as for react components

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, InputGroup, FormGroup, FormControl, ControlLabel, Row, Col } from 'react-bootstrap'
import { singleTrackFetch, insertNewTrack, updateTrack, removeTrack, uploadTrackAvatar } from '../../actions/trackDbActions'
import { updateLayout, insertLayout, delLayout } from '../../actions/layoutDbActions'
import { default as TrackLayoutEdit } from '../../components/TrackLayoutEdit'
import { default as TrackLayoutView } from '../../components/TrackLayoutView'
import InfiniteProgressCircle from '../../components/InfiniteProgressCircle'
import { ROUTE_TRACKS } from '../../utils/routingConstants'
import {getAllContries} from '../../api/restContriesApi'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { default as TrackOperations } from '../../components/TrackOperations'
import { uiRedirect } from '../../actions/ui'
import Guid from 'guid'
import FA from 'react-fontawesome'
import { DropzoneWithImagePreview, HandleEdit, UrlInput } from '../../components'
import { UI_INPUT_LENGTH } from '../../constants'
import * as _ from 'lodash'
import AsyncSelect from 'react-select/lib/Async'
import { components } from 'react-select'
import { page } from '../../hocs/page'
import { pageView, GA_PAGES } from '../../api/gaHelper'

const TOOLBAR_OPTS = [
  [{ 'header': 1 }, { 'header': 2 }], // custom button values
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],

  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
  // [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
  // [{ 'direction': 'rtl' }], // text direction

  [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],

  ['link', 'image', 'video'],

  ['clean'] // remove formatting button
]

class PageTrackEdit extends Component {
  static FieldGroup ({ url, inputgroup, label, validationState, ...props }) {
    let input
    if (inputgroup) {
      if (url === true) {
        input = (
          <InputGroup className='field-group-container'>
            <InputGroup.Addon><FA name='globe' /></InputGroup.Addon>
            <FormControl {...props} />
          </InputGroup>
        )
      } else {
        input = (
          <InputGroup className='field-group-container'>
            <InputGroup.Addon>{inputgroup}</InputGroup.Addon>
            <FormControl {...props} />
          </InputGroup>
        )
      }
    } else {
      input = (<FormControl {...props} className='field-group-container info-input' />)
    }

    return (
      <FormGroup controlId={props.name} validationState={validationState ? 'success' : 'error'} >
        <ControlLabel>{label}</ControlLabel>
        {input}
      </FormGroup>
    )
  }

  static LocationGroup ({ label, latValidState, lngValidState, latValue, lngValue, latChange, lngChange }) {
    return (
      <Row className='nomargin'>
        <ControlLabel>{label}</ControlLabel>
        <Row>
          <Col xs={6}>
            <FormGroup controlId={label + '-lat'} validationState={latValidState ? 'success' : 'error'} >
              <Row>
                <Col xs={3} className='rightalign'>
                  <ControlLabel>Lat:</ControlLabel>
                </Col>
                <Col xs={9} className='no-padding'>
                  <FormControl
                    type='number'
                    className='info-input'
                    onChange={latChange}
                    value={latValue} />
                </Col>
              </Row>
            </FormGroup>
          </Col>

          <Col xs={6}>
            <FormGroup controlId={label + '-lng'} validationState={lngValidState ? 'success' : 'error'} >
              <Row>
                <Col xs={3} className='rightalign'>
                  <ControlLabel>Long:</ControlLabel>
                </Col>
                <Col xs={9} className='no-padding'>
                  <FormControl
                    type='number'
                    className='info-input'
                    onChange={lngChange}
                    value={lngValue} />
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
      </Row>
    )
  }

  constructor (props) {
    super(props)

    const { selectedId } = this.props

    let createNew
    let pageTitle

    if (!selectedId) {
      // This is creating new track
      createNew = true
      pageTitle = 'Create a new track'
    } else {
      // This is editing track
      createNew = false
      pageTitle = 'Edit track'
    }

    this.trackChangeHandler = this.handleTrackChange.bind(this)

    this.state = {
      error_message: '',
      pageTitle: pageTitle,
      createNew: createNew,
      startedInsert: false,
      startedDelete: false,
      startedUpdate: false,
      startedInsertLayout: false,
      startedUpdateLayout: false,

      validationState: {
        name: true,
        handle: true,
        website: true,
        facebook: true,
        instagram: true,
        twitter: true,
        lat: true,
        lng: true
      },
    }
  }

  validTypes () {
    let trackTypes = this.props.lookups.item.trackTypes
    if (!trackTypes) { return [] }

    return trackTypes.map((item) => {
      return {
        value: item.typeId,
        label: item.description
      }
    })
  }

  surfaceTypes () {
    let surfaceTypes = this.props.lookups.item.surfaceTypes
    if (!surfaceTypes) { return [] }

    return surfaceTypes.map((item) => {
      return {
        value: item.typeId,
        label: item.description
      }
    })
  }

  handleTrackChange (event) {
    const target = event.target
    const value = target.value
    const name = target.name

    const {trackInfo, validationState} = this.state

    // validate name

    this.setState({
      trackInfo: {...trackInfo, [name]: value},
      validationState: validationState
    })
  }

  onEditHandle = (handle, isValid) => {
    const {trackInfo, validationState} = this.state
    this.setState({
      trackInfo: {...trackInfo, handle },
      validationState: {...validationState, handle: isValid}
    })
  }

  handleUrlChange (name, value, isValid) {
    const {trackInfo, validationState} = this.state

    this.setState({
      trackInfo: {...trackInfo, [name]: value },
      validationState: {...validationState, [name]: isValid}
    })
  }

  handleCountryChange (country) {
    const {trackInfo} = this.state

    this.setState({
      trackInfo: {...trackInfo, countryCode: country.countryCode, countryName: country.label, countryFlag: country.flag }
    })
  }

  handleDescriptionChange (value) {
    const {trackInfo} = this.state
    this.setState({
      trackInfo: {...trackInfo, description: value}
    })
  }

  getCurrentDateTime () {
    let value = new Date().toISOString()
    value = value.substr(0, value.length - 1) + Math.floor(1000 + Math.random() * 9000) + '+00:00'
    return value
  }

  componentWillMount () {
    const { dispatch, selectedId, session } = this.props

    if (!this.state.createNew) {
      dispatch(singleTrackFetch(session.token, selectedId, false, session.profile))
    } else {
      let newLayoutInfo = this.createNewLayoutInfo()
      newLayoutInfo.state = 2

      this.setState({
        trackInfo: {
          isOfficial: true,
          name: '',
          handle: '',
          description: null,
          locationLat: 0,
          locationLong: 0,
          avatarUrl: null,
          minAltitude: null,
          maxAltitude: null,
          facebook: '',
          twitter: '',
          instagram: '',
          website: '',
          createdOn: null,
          updatedOn: null,
          deleted: false,
          rowVersion: '',
          id: this.createGuid(),
          ownerId: session.profile.id,
          type: 'Track',
          countryCode: null,
          thirdPartyId: null
        },
        layouts: [newLayoutInfo]
      })
    }

    pageView(GA_PAGES.ADMIN_TRACK_EDIT + selectedId, session)
  }

  createGuid () {
    return Guid.create().value
  }

  componentWillReceiveProps (nextProps) {
    const { selected, insert, update, _delete, layout_insert, layout_update, looksup, track_avatar } = nextProps

    let isProgress = false

    if (looksup && looksup.isFetching) {
      isProgress = true
    } else {
      if (!this.state.createNew) {
        if (!this.state.trackInfo && selected && !selected.isFetching && selected.item) {
          this.setState({
            trackInfo: selected.item,
            layouts: selected.item.layouts
          })
        }

        if (selected === undefined) {
          isProgress = true
        } else {
          isProgress = selected.isFetching
        }

        if (!isProgress) {
          if (!update) {
            isProgress = false
          } else {
            isProgress = update.isFetching

            if (!isProgress && update.item && !update.error && this.state.startedUpdate) {
              // this.props.history.push(ROUTE_TRACKS)
              // return
            }
          }
        }

        if (!isProgress) {
          if (!_delete) {
            isProgress = false
          } else {
            isProgress = _delete.isFetching

            if (!isProgress && _delete.deleted && this.state.startedDelete) {
              this.props.history.push(ROUTE_TRACKS)
              return
            }
          }
        }

        // Layout management
        if (!isProgress) {
          if (!layout_insert) {
            isProgress = false
          } else {
            isProgress = layout_insert.isFetching

            if (!isProgress && layout_insert.item && !layout_insert.error && this.state.startedInsertLayout) {
              let newLayouts = Object.assign([], this.state.layouts)

              for (var i = 0; i < newLayouts.length; i++) {
                if (newLayouts[i].id === layout_insert.item.id) {
                  newLayouts[i] = layout_insert.item
                }
              }

              this.setState({startedInsertLayout: false, layouts: newLayouts})
            }
          }
        }

        if (!isProgress) {
          if (!layout_update) {
            isProgress = false
          } else {
            isProgress = layout_update.isFetching

            if (!isProgress && layout_update.item && !layout_update.error && this.state.startedUpdateLayout) {
              let newLayouts = Object.assign([], this.state.layouts)

              for (i = 0; i < newLayouts.length; i++) {
                if (newLayouts[i].id === layout_update.item.id) {
                  newLayouts[i] = layout_update.item
                }
              }

              this.setState({startedUpdateLayout: false, layouts: newLayouts})
            }
          }
        }
      } else {
        if (!insert) {
          isProgress = false
        } else {
          isProgress = insert.isFetching

          if (!isProgress && insert.item && !insert.error && this.state.startedInsert) {
            // Start inserting layouts
            this.setState({startedInsert: false})
            isProgress = true
            this.insertLayouts()
          }
        }

        if (!isProgress) {
          if (layout_insert) {
            isProgress = layout_insert.isFetching

            if (!isProgress && layout_insert.item && !layout_insert.error) {
              // Succeeded to insert a new layout
              isProgress = true
              this.insertLayouts()
            }
          }
        }
      }

      if (!isProgress) {
        if (track_avatar) {
          isProgress = track_avatar.isFetching
          if (!isProgress && track_avatar.item && !track_avatar.error && this.state.startedUploadingTrackAvatar) {
            // Succeeded to upload avatar.
            console.log('Avatar item', track_avatar.item.avatarUrl)
            let stateTrackInfo = Object.assign({}, this.state.trackInfo)
            stateTrackInfo.avatarUrl = track_avatar.item.avatarUrl
            stateTrackInfo.rowVersion = track_avatar.item.rowVersion

            this.setState({ startedUploadingTrackAvatar: false })

            this.uploadTrack(stateTrackInfo)
          }
        }
      }
    }

    this.setState({ progressing: isProgress })
  }

  insertLayouts () {
    const { session, dispatch, history } = this.props

    let layouts = this.getCurrentLayouts()
    if (layouts && layouts.length > 0) {
      let layout = Object.assign({}, layouts[0])
      if (!layout.segments || layout.segments.length === 0) {
        layout.segments = null
      } else {
        layout.segments = layout.segments
      }

      layout.createdOn = this.getCurrentDateTime()
      layout.updatedOn = this.getCurrentDateTime()
      layout.trackId = this.state.trackInfo.id

      dispatch(insertLayout(session.token, this.state.trackInfo.id, layout))

      layouts.splice(0, 1)
      return
    }

    history.push(ROUTE_TRACKS)
  }

  getCurrentTrack () {
    if (!this.state.createNew && !this.state.trackInfo) {
      return null
    }

    return this.state.trackInfo
  }

  getCurrentLayouts () {
    if (!this.state.createNew && !this.state.layouts) {
      return null
    }

    return this.state.layouts
  }

  onStartEdit (layout_uuid) {
    let currentLayouts = this.getCurrentLayouts()
    if (!currentLayouts) { return }

    let layouts = Object.assign([], currentLayouts)

    for (var i = 0; i < layouts.length; i++) {
      let old_layoutInfo = layouts[i]
      if (old_layoutInfo.id === layout_uuid) {
        layouts[i].state = 2
        break
      }
    }

    this.setState({layouts: layouts})
  }

  onAddedLayout (layoutInfo) {
    let currentLayouts = this.getCurrentLayouts()
    if (!currentLayouts) { return }

    layoutInfo.state = 1

    let layouts = Object.assign([], currentLayouts)

    layouts.push(layoutInfo)
    this.setState({layouts: layouts})
  }

  onClickedAddNewLayout () {
    let currentLayouts = this.getCurrentLayouts()
    if (!currentLayouts) { return }

    var newLayoutInfo = this.createNewLayoutInfo()
    newLayoutInfo.state = 2

    let layouts = Object.assign([], currentLayouts)

    layouts.push(newLayoutInfo)
    this.setState({layouts: layouts})
  }

  createNewLayoutInfo () {
    const { session } = this.props

    return {
      isOfficial: true,
      trackId: '',
      typeTrackId: 1,
      surfaceType: 0,
      name: '',
      description: null,
      startPointLat: 0,
      startPointLong: 0,
      endPointLat: 0,
      endPointLong: 0,
      isClockwise: true,
      turnCount: 0,
      distance: 0,
      minAltitude: null,
      maxAltitude: null,
      segments: [],
      createdOn: null,
      updatedOn: null,
      deleted: false,
      rowVersion: null,
      receiverType: 'GPS',
      id: this.createGuid(),
      ownerId: session.profile.id,
      type: 'Layout',
      real_virtual: 1
    }
  }

  onClickErrorMessage () {
    this.setState({ error_message: '' })
  }

  onClickedSaveChanges () {
    // Validate input
    let alertMsg

    const {validationState} = this.state

    if (!validationState.handle) {
      this.showAlert('Invalid handle')
      return
    }

    alertMsg = this.validateName(this.state.trackInfo.name)
    if (alertMsg !== '') {
      this.showAlert(alertMsg)
      return
    }

    if (!validationState.website) {
      this.showAlert('Invalid website')
      return
    }

    if (!validationState.facebook) {
      this.showAlert('Invalid facebook name')
      return
    }

    if (!validationState.instagram) {
      this.showAlert('Invalid instagram name')
      return
    }

    if (!validationState.twitter) {
      this.showAlert('Invalid twitter name')
      return
    }

    // Validation finished

    // Integrate with API to post a new track
    const { session, dispatch } = this.props

    if (this.state.avatarFile) {
      dispatch(uploadTrackAvatar(session.token, this.state.trackInfo.id, this.state.avatarFile))
      this.setState({ startedUploadingTrackAvatar: true })
      return
    }

    this.uploadTrack(Object.assign({}, this.state.trackInfo))
  }

  uploadTrack (stateTrackInfo) {
    const { session, dispatch, selected } = this.props

    stateTrackInfo.rowVersion = selected.rowVersion

    if (this.state.createNew) {
      // Create a new track
      this.setState({ startedInsert: true })

      stateTrackInfo.createdOn = this.getCurrentDateTime()
      stateTrackInfo.updatedOn = this.getCurrentDateTime()
      stateTrackInfo.layouts = undefined

      dispatch(insertNewTrack(session.token, stateTrackInfo))
    } else {
      // Save edited track
      this.setState({ startedUpdate: true })
      stateTrackInfo.updatedOn = this.getCurrentDateTime()
      stateTrackInfo.layouts = undefined
      dispatch(updateTrack(session.token, stateTrackInfo.id, stateTrackInfo))
    }
  }

  onClickedDelete () {
    const { session, dispatch } = this.props
    this.setState({ startedDelete: true })
    dispatch(removeTrack(session.token, this.getCurrentTrack()))
  }

  onClickedDiscardChanges () {
    const { dispatch } = this.props
    dispatch(uiRedirect(ROUTE_TRACKS))
  }

  renderNewEditLayout (layoutInfo) {
    return (
      <TrackLayoutEdit
        layoutInfo={layoutInfo}
        onSaveLayout={this.layout_onClickedSave.bind(this)}
        onDeleteLayout={this.layout_onClickedDelete.bind(this)}
        onAddSegment={this.layout_onClickedAddSegment.bind(this)}
        onDeleteSegment={this.layout_onDeleteSegment.bind(this)}
        onEditName={this.layout_onEditName.bind(this)}
        onEditVideo={this.layout_onEditVideo.bind(this)}
        onEditOrientation={this.layout_onEditOrientation.bind(this)}
        onEditType={this.layout_onEditType.bind(this)}
        onEditSurfaceType={this.layout_onEditSurfaceType.bind(this)}
        onEditSimulatorPlatform={this.layout_onEditSimulatorPlatform.bind(this)}
        onEditTurns={this.layout_onEditTurns.bind(this)}
        onEditDistance={this.layout_onEditDistance.bind(this)}
        onClickedMapImage={this.layout_onClickedMapImage.bind(this)}
        onEditStartLineLat={this.layout_onChangeStartLineLat.bind(this)}
        onEditStartLineLong={this.layout_onChangeStartLineLng.bind(this)}
        onEditFinishLineLat={this.layout_onChangeFinishLineLat.bind(this)}
        onEditFinishLineLong={this.layout_onChangeFinishLineLng.bind(this)}
        onEditSegmentLat={this.layout_onChangeSegmentLat.bind(this)}
        onEditSegmentLong={this.layout_onChangeSegmentLng.bind(this)}
        onEdit3rdpartyID={this.layout_onEditThirdPartyId.bind(this)}
        onEditStartPointHeading={this.layout_onEditStartPointHeading.bind(this)}
        onEditEndPointHeading={this.layout_onEditEndPointHeading.bind(this)}

        validTypes={this.validTypes()}
        surfaceTypes={this.surfaceTypes()}

        onValidateName={this.layout_validateName.bind(this)}
        onValidateType={this.layout_validateType.bind(this)}
      />
    )
  }

  render () {
    if (!this.props.lookups.item) { return null }

    let currentTrack = this.getCurrentTrack()
    let currentlayouts = this.getCurrentLayouts()

    if (this.state.progressing) {
      return (<div><InfiniteProgressCircle show /></div>)
    } else if (!this.state.createNew && (!currentTrack || !currentlayouts)) {
      return (<div />)
    } else {
      let layoutGroup = ''
      let newLayoutGroup = ''

      if (currentlayouts) {
        layoutGroup = currentlayouts.map((item, index) => <div className='track-layout-info-container' key={index}>
          {this.renderLayout(item)}
          <div className='separator' />
        </div>)

        if (currentlayouts.length === 0) {
          var newLayoutInfo = this.createNewLayoutInfo()
          newLayoutInfo.state = 2
          newLayoutGroup = (<div className='track-layout-info-container'>{this.renderNewEditLayout(newLayoutInfo)}</div>)
        } else {
          newLayoutGroup = (<div className='hcenter button-container'>
            <Button className='layout-title' onClick={this.onClickedAddNewLayout.bind(this)}>Add new layout</Button>
          </div>)
        }
      }

      return (<div className='track-edit-page-container'>
        {/* Page title */}
        <h1>{`${this.state.pageTitle} | ${this.state.trackInfo.name || 'New track'}`}</h1>

        {/* Separator */}
        <div className='separator' /> {this.renderTrackMainInfo()}

        {/* Separator */}
        <div className='separator' />

        <h2>Layouts</h2>
        <div className='separator' />

        {layoutGroup}

        {newLayoutGroup}
      </div>)
    }
  }

  renderLayout (layoutInfo) {
    if (layoutInfo.state === 2) {
      return this.renderNewEditLayout(layoutInfo)
    } else {
      return (
        <TrackLayoutView
          layoutInfo={layoutInfo}
          onStartEdit={this.onStartEdit.bind(this)}
          surfaceTypes={this.surfaceTypes()}
          validTypes={this.validTypes()} />
      )
    }
  }

  onEditLatitude = (e) => {
    let trackInfo = Object.assign({}, this.state.trackInfo)
    trackInfo.locationLat = e.target.value

    let validationState = Object.assign({}, this.state.validationState)

    this.setState({
      trackInfo: trackInfo,
      validationState: validationState
    })
  }

  onEditLongitude = (e) => {
    let trackInfo = Object.assign({}, this.state.trackInfo)
    trackInfo.locationLong = e.target.value

    let validationState = Object.assign({}, this.state.validationState)

    this.setState({
      trackInfo: trackInfo,
      validationState: validationState
    })
  }

  validateName (name) {
    if (!name || name === '') { return 'Please input name' }

    var format = /[!@#$%^*()+=[\]{};':"\\|,.<>/?]/
    if (format.test(name)) { return 'Name should not contain special characters' }

    return ''
  }

  customCountryValue (props) {
    const {data, options} = props
    const selectedCountry = options ? _.find(options, {value: data.value}) : null

    return <components.SingleValue {...props}>
      <div className='dropdown-item'>
        { selectedCountry && <img src={selectedCountry.flag} alt='flag' /> }
        <span>{selectedCountry ? selectedCountry.label : 'Loading...'}</span>
      </div>
    </components.SingleValue>
  }

  customCountryOption (props) {
    return <components.Option {...props}>
      <div className='dropdown-item' key={props.data.alpha3Code}>
        <img src={props.data.flag} alt='flag' />
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  }

  renderTrackMainInfo () {
    let buttonsArray
    if (this.state.createNew) { buttonsArray = ['save', 'discard'] } else { buttonsArray = ['save', 'discard', 'delete'] }

    const track = this.state.trackInfo

    const getCountryOptions = (input) => {
      return getAllContries().then((values) => {
        const opts = _.filter(values, (v) => { return !input || v.name.toLowerCase().includes(input.toLowerCase()) })
          .map((value) => {
            return { value: value.alpha2Code, label: value.name, flag: value.flag, countryCode: value.alpha2Code }
          })

        return opts
      })
    }

    return (
      <div>
        <Row className='hcenter'>
          <TrackOperations
            buttons={buttonsArray}
            onSave={this.onClickedSaveChanges.bind(this)}
            onDiscard={this.onClickedDiscardChanges.bind(this)}
            onDelete={this.onClickedDelete.bind(this)} />
        </Row>

        <Row className='track-info-container'>
          <Col sm={6}>
            <PageTrackEdit.FieldGroup
              name='name'
              type='text'
              label='Name'
              maxLength={UI_INPUT_LENGTH.NAME}
              value={track.name || ''}
              placeholder='Name of the track'
              onChange={this.trackChangeHandler}
              className='info-input nomargin'
              validationState={this.state.validationState.name}
            />

            <HandleEdit
              layoutType={'row'}
              onHandleChanged={this.onEditHandle.bind(this)}
              apiToken={this.props.session.token}
              oldHandle={track.handle} />

            <FormGroup controlId='CountrySelect' key='CountrySelect' >
              <ControlLabel>Country</ControlLabel>
              <AsyncSelect
                className='react-select-container'
                classNamePrefix='react-select'
                onChange={this.handleCountryChange.bind(this)}
                loadOptions={getCountryOptions}
                defaultOptions
                components={{ Option: this.customCountryOption, SingleValue: this.customCountryValue }}
                value={{value: track.countryCode || null}}
              />
            </FormGroup>

            <UrlInput
              type='url'
              value={track.website || ''}
              onChanged={this.handleUrlChange.bind(this, 'website')}
              layoutType={'row'}
            />

            <UrlInput
              type='facebook'
              value={track.facebook || ''}
              onChanged={this.handleUrlChange.bind(this, 'facebook')}
              layoutType={'row'}
            />

            <UrlInput
              type='instagram'
              value={track.instagram || ''}
              onChanged={this.handleUrlChange.bind(this, 'instagram')}
              layoutType={'row'}
            />

            <UrlInput
              type='twitter'
              value={track.twitter || ''}
              onChanged={this.handleUrlChange.bind(this, 'twitter')}
              layoutType={'row'}
            />

            <PageTrackEdit.LocationGroup
              name='track-location'
              label='Location'
              className='info-input nomargin'
              latValidState={this.state.validationState.lat}
              lngValidState={this.state.validationState.lng}
              latChange={this.onEditLatitude.bind(this)}
              lngChange={this.onEditLongitude.bind(this)}
              latValue={track.locationLat || 0}
              lngValue={track.locationLong || 0}
            />
          </Col>

          <Col sm={6}>
            <ControlLabel>Profile Picture</ControlLabel>
            <DropzoneWithImagePreview
              onDrop={this.onDrop.bind(this)}
              previewUrl={(this.state.avatarFile && this.state.avatarFile.preview) || track.avatarUrl}
            />

            <ControlLabel className='top-space'>Description</ControlLabel>
            {/* onChange={this.descriptionChangeHandler} */}
            <ReactQuill 
              modules={{toolbar: TOOLBAR_OPTS}} 
              onChange={this.handleDescriptionChange.bind(this)} 
              value={track.description || ''} />
          </Col>
        </Row>
      </div>
    )
  }

  onDrop (acceptedFiles, rejectedFiles) {
    if (acceptedFiles && acceptedFiles.length === 1) {
      this.setState({
        avatarFile: acceptedFiles[0]
      })
    }
  }

  showAlert (msg) {
    console.error('ERROR', msg)
  }

  layout_onClickedDelete (layoutInfo) {
    const {layouts} = this.state
    const {dispatch, session} = this.props

    this.setState({layouts: _.filter(layouts, (l) => l.id !== layoutInfo.id)})
    dispatch(delLayout(session.token, layoutInfo.id))
  }

  layout_onClickedSave (layoutInfo) {
    let alertMsg
    alertMsg = this.layout_validateName(layoutInfo.name)
    if (alertMsg !== '') {
      this.showAlert(alertMsg)
      return
    }

    alertMsg = this.layout_validateType(layoutInfo.typeTrackId)
    if (alertMsg !== '') {
      this.showAlert(alertMsg)
      return
    }

    alertMsg = this.layout_validateTurnsCount(layoutInfo.turnCount)
    if (alertMsg !== '') {
      this.showAlert(alertMsg)
      return
    }

    alertMsg = this.layout_validateDistance(layoutInfo)
    if (alertMsg !== '') {
      this.showAlert(alertMsg)
      return
    }

    if (!this.state.createNew) {
      const {session, dispatch} = this.props

      if (!layoutInfo.rowVersion) {
        // Creating a new layout
        this.setState({startedInsertLayout: true})
        layoutInfo.createdOn = this.getCurrentDateTime()
        layoutInfo.updatedOn = this.getCurrentDateTime()
        layoutInfo.trackId = this.state.trackInfo.id

        dispatch(insertLayout(session.token, this.state.trackInfo.id, {...layoutInfo, segments: layoutInfo.segments || []}))
      } else {
        this.setState({startedUpdateLayout: true})
        layoutInfo.updatedOn = this.getCurrentDateTime()
        layoutInfo.trackId = this.state.trackInfo.id

        dispatch(updateLayout(session.token, layoutInfo.id, {...layoutInfo, segments: layoutInfo.segments || []}))
      }
    } else {
      let newLayouts = this.getCurrentLayouts()
      if (!newLayouts) {
        newLayouts = []
      } else {
        newLayouts = Object.assign([], newLayouts)
      }

      layoutInfo.state = 1

      for (var i = 0; i < newLayouts.length; i++) {
        let layoutItem = newLayouts[i]
        if (layoutItem.id === layoutInfo.id) {
          newLayouts[i] = layoutInfo
        }
      }

      this.setState({layouts: newLayouts})
    }
  }

  layout_validateName (name) {
    if (!name || name === '') { return 'Please input name' }

    var format = /[!@#$%^*()+=[\]{};':"\\|,.<>/?]/
    if (format.test(name)) { return 'Name should not contain special characters' }

    return ''
  }
  layout_validateType (type) {
    return ''
  }
  layout_validateTurnsCount (turnCount) {
    return ''
  }
  layout_validateDistance (layout) {
    return ''
  }

  layout_onEditName = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.name = e.target.value
    this.setState({layouts: newLayouts})
  }

  layout_onEditVideo = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.video = e.target.value
    this.setState({layouts: newLayouts})
  }

  layout_onEditOrientation = (orientation, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.isClockwise = orientation.value === 1
    this.setState({layouts: newLayouts})
  }

  layout_onEditType = (typeid, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.typeTrackId = typeid.value
    this.setState({layouts: newLayouts})
  }

  layout_onEditSurfaceType = (type, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.surfaceType = type.value
    this.setState({layouts: newLayouts})
  }

  layout_onEditSimulatorPlatform = (simPlatform, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.simulatorPlatform = simPlatform.value

    this.setState({layouts: newLayouts})
  }

  layout_onEditTurns = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.turnCount = e.target.value
    this.setState({layouts: newLayouts})
  }

  layout_onEditDistance = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.distance = parseFloat(e.target.value)
    this.setState({layouts: newLayouts})
  }

  layout_onEditThirdPartyId = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.thirdPartyId = e.target.value || null
    this.setState({layouts: newLayouts})
  }

  layout_onEditStartPointHeading = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.startPointAngle = e.target.value || null
    this.setState({layouts: newLayouts})
  }

  layout_onEditEndPointHeading = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.endPointAngle = e.target.value || null
    this.setState({layouts: newLayouts})
  }

  layout_onClickedMapImage (e, layout_uuid) {
    console.log('Image upload--------------------')
  }

  layout_onChangeStartLineLat = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.startPointLat = e.target.value
    this.setState({layouts: newLayouts})
  }

  layout_onChangeStartLineLng = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.startPointLong = e.target.value
    this.setState({layouts: newLayouts})
  }

  layout_onChangeFinishLineLat = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.endPointLat = e.target.value
    this.setState({layouts: newLayouts})
  }

  layout_onChangeFinishLineLng = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.endPointLong = e.target.value
    this.setState({layouts: newLayouts})
  }

  layout_onChangeSegmentLat = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.segments[e.target.dataset.index].lat = e.target.value
    this.setState({layouts: newLayouts})
  }

  layout_onChangeSegmentLng = (e, layout_uuid) => {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.segments[e.target.dataset.index].lon = e.target.value
    this.setState({layouts: newLayouts})
  }

  layout_onDeleteSegment (index, layout_uuid) {
    if (!this.state.layouts) { return }
    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    layout.segments.splice(index, 1)
    this.setState({layouts: newLayouts})
  }

  layout_onClickedAddSegment (layout_uuid) {
    if (!this.state.layouts) { return }

    let newLayouts = Object.assign([], this.state.layouts)
    let layout = newLayouts.find((item) => item.id === layout_uuid)
    if (!layout) { return }

    if (!layout.segments) { layout.segments = [] }

    layout.segments.push({lat: 0, lon: 0})
    this.setState({layouts: newLayouts})
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session,

    insert: state.tracks.insert,
    update: state.tracks.update,
    _delete: state.tracks._delete,
    layouts: state.tracks.layouts,
    selected: state.tracks.selected,
    track_avatar: state.tracks.avatar,

    layout_insert: state.layouts.insert,
    layout_update: state.layouts.update,

    selectedId: ownProps.match.params.uuid,

    lookups: state.lookups
  }
}

export default page(connect(mapStateToProps)(PageTrackEdit))

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ROUTE_TRACKS } from '../utils/routingConstants'
import PropTypes from 'prop-types'
import { Section, BingMapWidget } from '../components'
import { Table, Col } from 'react-bootstrap'
import { find, uniq } from 'lodash'
import Truncate from 'react-truncate-html'

export default class TrackDescription extends Component {
  static propTypes = {
    track: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    lookups: PropTypes.object.isRequired,
    truncate: PropTypes.bool,
    truncateToLines: PropTypes.number
  }

  static defaultProps = {
    truncate: true,
    truncateToLines: 11
  }

  constructor (props) {
    super(props)
    this.state = {
      expanded: false
    }

    this.toggleExpand = this.toggleExpand.bind(this)
  }

  toggleExpand (event) {
    event.stopPropagation()

    this.setState(
      { expanded: !this.state.expanded }
    )
  }

  render () {
    const { track, title, lookups, truncate, truncateToLines } = this.props
    const { expanded } = this.state

    const isExpanded = expanded || !truncate

    const titleLink = <Link to={`${ROUTE_TRACKS}/${track.handle}`}>{title}</Link>
    return (<Section title={titleLink}>
      <div className='description-logo pull-right'>
        <BingMapWidget track={track} lookups={lookups} />
      </div>
      <div className='description-text'>
        {track.description && !isExpanded && <Truncate
          lines={truncateToLines}
          breakWord
          responsive
          ellipsis='...'
          dangerouslySetInnerHTML={{ __html: track.description }}
        />}
        {track.description && isExpanded && <div
          dangerouslySetInnerHTML={{ __html: track.description }}
        />}
        {track.description && truncate && <div className='more__link' onClick={this.toggleExpand} >{isExpanded ? 'Show less' : 'Show more...'}</div>}
        {/* <div className="clearfix" /> */}
        <Col md={6}>
          {this.renderTrackFacts(track)}
        </Col>
      </div>
    </Section>)
  }

  getTrackTypes (track) {
    const { trackTypes } = this.props.lookups
    return uniq(track.layouts.map(layout => {
      const type = find(trackTypes, { typeId: layout.typeTrackId })
      return type ? type.description : 'Unknown'
    })).filter(a => a)
  }

  getSurfaceTypes (track) {
    const { surfaceTypes } = this.props.lookups
    return uniq(track.layouts.map(layout => {
      const surface = find(surfaceTypes, { typeId: layout.surfaceType })
      return surface ? surface.description : 'Unknown'
    })).filter(a => a)
  }

  renderTrackFacts (track) {
    const typeString = this.getTrackTypes(track).join(', ')
    const surfaceString = this.getSurfaceTypes(track).join(', ')
    return (<Table responsive>
      <tbody className='description-table'>
        <tr>
          <td>Layouts:</td>
          <td>{track.layouts ? track.layouts.length : 'Unknown'}</td>
        </tr>
        <tr>
          <td>Track types:</td>
          <td>{typeString}</td>
        </tr>
        <tr>
          <td>Surface types:</td>
          <td>{surfaceString}</td>
        </tr>
      </tbody>
    </Table>)
  }
}

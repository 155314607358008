import 'whatwg-fetch'
import { toPairs } from 'lodash'
import protobuf from 'protobufjs'
import { INVITE_STATUS, INVITE_TYPE } from './apiConstants'
import moment from 'moment'
import { getInmemory, putInmemory, AGE_30_DAY, getData, putData } from './apiCache'
import { API_ENDPOINT, IS_DEVELOP, IS_PROD } from '../constants'

const serverAddress = API_ENDPOINT
export const apiHome = `${serverAddress}/api/`

const NULL_GUID = '99999999-9999-9999-9999-999999999999'

export const BASIC_PAGE_SIZE = IS_DEVELOP ? 10 : 25
export const MAX_PAGE_SIZE = IS_DEVELOP ? 10 : 50
const PROFILE = 'profile'
const PROFILE_AVATAR = 'profile/avatar'
const SESSIONS = 'sessions'
const ORIGINAL_TELEMETRY = 'originaltelemetry'
const ENTITYFILES_LIST = 'entityFiles'
const STORAGE_SECURITY = 'storagesecurity'
const TRACKS = 'tracks'
const TEAMS = 'teams'
const USER_TEAMS = 'user/teams'
const USER = 'user'
const INVITATIONS = 'invitations'
const USER_SESSIONS = 'user/sessions'
const USERS = 'users'
const VEHICLES = 'vehicles'
const USER_VEHICLES = 'user/vehicles'
const LOOKUPS_ALL = 'lookups/all'
const HANDLES = 'handles'
const LAYOUTS = 'layouts'
const KPI_CURRENT = 'kpis/current'
const KPI_HISTORICAL = 'kpis/historical'
const KPI_PARSERS = 'kpis/sessionsbyparser'
const KPI_SUBSCRIPTIONS = 'kpis/subscriptions'
const KPI_HISTORICAL_SUBSCRIPTIONS = 'kpis/historysubscriptions'
const USER_QUOTAS = 'user/storagestats'
const USER_LICENSE_KEY = 'profile/license/'
const PRODUCT_FILE = 'adminstorefront/uploadcontent'

const SUBSCRIPTION = 'subscription'
const SUBSCRIPTIONS_LIST = 'subscription/list'
const SUBSCRIPTIONS_LIST_STUDENT = 'subscription/studentlist'
const SUBSCRIPTION_MORE_INFO = 'subscription/moreinfo'
const STORE_PRODUCTS = 'storefront/products'
const STORE_STOREFRONT = 'storefront/'
const STORE_PURCHASES = 'storefront/purchases'
const STORE_ORDERS = 'storefront/orders'

const LEADERBOARDS_USERLAPS = 'leaderboards/userlaps'
const LEADERBOARDS_USERSPEED = 'leaderboards/userspeed'
const LEADERBOARDS_USERHOURS = 'leaderboards/userhours'
const LEADERBOARDS_USERSESSIONS = 'leaderboards/usersessions'
const LEADERBOARDS_TRACKS = 'leaderboards/usertracks'

const STORE_ADMIN_PRODUCTS = 'adminstorefront/products'
const STORE_ADMIN_PRODUCTS_UPDATE = 'adminstorefront/importproducts'
const STORE_ADMIN_PURCHASE = 'adminstorefront/purchase'
const STORE_ADMIN_SORTPRODUCTS = 'adminstorefront/sortproducts'

const USER_NOTIFICATIONS = 'user/notifications'
const NOTIFICATIONS = '/notifications'

// PUT
// /api/adminstorefront/products/{id}

// ============================================================ MISC ============================================================
export function url (path, query) {
  path = apiHome + path
  // path = '/api/' + path
  if (!query) {
    return path
  }

  const queryString = toPairs(query)
    // take only having values ones (0 is allowed)
    .filter((p) => p[1] || p[1] === 0)
    // sort() is essential for caching
    .sort()
    // compose key=value&key2=value with encoded keys and values
    .map((p) => p.map(encodeURIComponent).join('=')).join('&')
  return `${path}?${queryString}`
}

const fetchPut = (url, token, payload) => fetch(url, {
  headers: {
    'Authorization': 'Bearer ' + token,
    'Content-Type': 'application/json; charset=utf-8'
  },
  method: 'PUT',
  body: JSON.stringify(payload)
})
  .then((response) => {
    // reject if code is out of range 200-299
    if (!response || !response.ok) {
      let err = new Error(response ? response.statusText : 'Error')
      if (response) {
        err.status = response.status
      }
      throw err
    }

    if (response.status === 204) {
      // handle NO DATA
      let err = new Error(response ? response.statusText : 'No data')
      err.status = 204
      throw err
    }

    return response.json()
  })
  .then((response) => {
    return response
  })

const fetchPost = (url, token, payload) => fetch(url, {
  headers: {
    'Authorization': 'Bearer ' + token,
    'Content-Type': 'application/json; charset=utf-8'
  },
  method: 'POST',
  body: JSON.stringify(payload)
})
  .then((response) => {
    // reject if code is out of range 200-299
    if (!response || !response.ok) {
      let err = new Error(response ? response.statusText : 'Error')
      if (response) {
        err.status = response.status
      }
      throw err
    }

    if (response.status === 204) {
      // handle NO DATA
      let err = new Error(response ? response.statusText : 'No data')
      err.status = 204
      throw err
    }

    return response.json()
  })
  .then((response) => {
    return response
  })

const sendPatch = (url, token, payload) => fetch(url, {
  headers: {
    'Authorization': 'Bearer ' + token,
    'Content-Type': 'application/json; charset=utf-8'
  },
  method: 'PATCH',
  body: JSON.stringify(payload)
})
  .then((response) => {
    // reject if code is out of range 200-299
    if (!response || !response.ok) {
      let err = new Error(response ? response.statusText : 'Error')
      if (response) {
        err.status = response.status
      }
      throw err
    }

    if (response.status === 204) {
      // handle NO DATA
      let err = new Error(response ? response.statusText : 'No data')
      err.status = 204
      throw err
    }

    return response.json()
  })
  .then((response) => {
    return response
  })

const sendDelete = (url, token) => fetch(url, {
  headers: {
    'Authorization': 'Bearer ' + token,
    'Content-Type': 'application/json; charset=utf-8'
  },
  method: 'DELETE'
})
  .then((response) => {
    // reject if code is out of range 200-299
    if (!response || !response.ok) {
      let err = new Error(response ? response.statusText : 'Error')
      if (response) {
        err.status = response.status
      }
      throw err
    } else if (response.status === 204) {
      return {status: response.status, statusText: response.statusText}
    }

    return response.text()
  })
  .then((text) => {
    return text.length ? JSON.parse(text) : {}
  })
  .then((response) => {
    return response
  })

const fetchGet = (url, token, scope) => fetch(url, {
  headers: {
    'Authorization': 'Bearer ' + token
  }
})
  .then((response) => {
    // reject if code is out of range 200-299
    if (!response || !response.ok) {
      let err = new Error(response ? response.statusText : 'Error')
      if (response) {
        err.status = response.status
      }
      throw err
    }

    if (response.status === 204) {
      // handle NO DATA
      let err = new Error(response ? response.statusText : 'No data')
      err.status = 204
      throw err
    }

    if (scope && response.headers.has('x-pagination')) {
      scope.pagination = JSON.parse(response.headers.get('x-pagination'))
    }

    return response.json()
  })
  .then((response) => {
    return response
  })

export function fetchList (urlBase, token, skip = 0, filter = {}) {
  const scope = {}
  const filterexpr = filter
  const requestUrl = url(urlBase, {
    pageSize: BASIC_PAGE_SIZE,
    page: 1 + Math.floor(skip / (filter.pageSize || BASIC_PAGE_SIZE)),
    ...filterexpr
  })

  return fetchGet(requestUrl, token, scope)
    .then((response) => {
      return {
        list: response,
        hasMore: response.length === BASIC_PAGE_SIZE,
        headers: scope
      }
    })
}

export function fetchListAll (urlBase, token, filter = {}) {
  const scope = {}

  const urlMaker = (page, pageSize) => {
    return url(urlBase, {
      ...filter,
      pageSize,
      page
    })
  }

  const requestUrl = urlMaker(1, MAX_PAGE_SIZE)

  let resultingList = {}

  return fetchGet(requestUrl, token, scope)
    .then((response) => {
      resultingList = {
        list: response,
        hasMore: response.length < scope.pagination.totalCount,
        headers: scope
      }

      if (!resultingList.hasMore) {
        return resultingList
      }

      // server has a page size limit
      // we have to respect it
      const pagesToLoad = Array.from(new Array(resultingList.headers.pagination.totalPages - 1), (val, index) => index + 2)
      // all pages, starting from 2

      const firstPromice = new Promise((resolve, reject) => { resolve(resultingList.list) })

      const promices = [firstPromice, ...pagesToLoad.map((page) => fetchGet(urlMaker(page, MAX_PAGE_SIZE), token))]

      return Promise.all(promices)
    })
    .then((response) => {
      if (response.list) {
        // first call worked - just pass through
        return response
      }

      const totalResult = response.reduce((r, v) => [...r, ...v])
      return {
        list: totalResult,
        hasMore: false,
        headers: {pagination: {currentPage: 1, pageSize: totalResult.length, totalCount: totalResult.length, totalPages: 1}}
      }
    })
}

const putAvatar = (avatarFile, url, token) => {
  const img = new Image()
  const fr = new FileReader()

  return new Promise((resolve, reject) => {
    // 0 read file
    fr.onload = resolve
    fr.readAsDataURL(avatarFile)
  })
    .then(
    // 1 load image
      (result) => {
        return new Promise((resolve, reject) => {
          img.onload = resolve
          img.src = fr.result
        })
      }
    )
    .then(
    // 2 read height and width and send avatar
      (result) => {
        const { width, height } = img
        const ratio = Math.max(width, height) / 200

        let fd = new FormData()
        fd.append('File', avatarFile)

        fd.append('width', Math.round(width / ratio))
        fd.append('height', Math.round(height / ratio))

        return fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token
          },
          body: fd
        })
      }
    )
    .then((response) => {
      // reject if code is out of range 200-299
      if (!response || !response.ok) {
        let err = new Error(response ? response.statusText : 'Error')
        if (response) {
          err.status = response.status
        }
        throw err
      }
      return response.json()
    })
}
// ============================================================ STORE ============================================================

export function fetchAllProducts (token) {
  // for now do not cache
  // let cached = getData(STORE_PRODUCTS, AGE_1_SEC)

  // if (cached) {
  //   return new Promise((resolve, reject) => resolve(cached))
  // }

  // return fetchGet(url(STORE_PRODUCTS), token)
  //   .then((response) => {
  //     putData(STORE_PRODUCTS, response)
  //     return response
  //   })
  return fetchGet(url(STORE_PRODUCTS), token)
}

export function sortProducts (token, data) {
  return fetchPost(url(STORE_ADMIN_SORTPRODUCTS), token, data)
}

export function fetchAllProductsAdmin (token) {
  return fetchGet(url(STORE_ADMIN_PRODUCTS), token)
}

export function updateAllProductsFromSendOwl (token) {
  return fetchGet(url(STORE_ADMIN_PRODUCTS_UPDATE), token)
}

export function fetchAllOrders (token) {
  return fetchGet(url(STORE_ORDERS), token)
}

export function fetchAllPurchases (token) {
  return fetchGet(url(STORE_PURCHASES), token)
}

export function fetchProductContent (token, id) {
  return fetchGet(url(`${STORE_STOREFRONT}/${id}/content`), token)
}

export function updateProduct (token, product) {
  return fetchPut(url(`${STORE_ADMIN_PRODUCTS}/${product.id}`), token, product)
}

export function putProductFile (token, file, enableContentDisposition = false) {
  let data = new FormData()
  data.append('file', file)

  // enableContentDisposition=true
  return fetch(url(PRODUCT_FILE, {...(enableContentDisposition ? {enableContentDisposition} : {})}), {
    headers: {
      'Authorization': 'Bearer ' + token
    },
    method: 'POST',
    body: data
  })
    .then((response) => {
      // reject if code is out of range 200-299
      if (!response || !response.ok) {
        let err = new Error(response ? response.statusText : 'Error')
        if (response) {
          err.status = response.status
        }
        throw err
      }
      if (response.status === 204) {
        // handle NO DATA
        let err = new Error(response ? response.statusText : 'No data')
        err.status = 204
        throw err
      }
      return response.text()
    })
}

export function deletePurchase (item, token) {
  return !IS_PROD && sendDelete(url(STORE_ADMIN_PURCHASE + '/' + item.purchaseId), token)
}

// ============================================================ KPI ============================================================

export function fetchCurrentKPI (token) {
  return fetchGet(url(KPI_CURRENT), token)
}

export function fetchSubscriptionsKPI (token) {
  return fetchGet(url(KPI_SUBSCRIPTIONS), token)
}

export function fetchCurrentParsersKPI (token) {
  return fetchGet(url(KPI_PARSERS, {dates: moment().format('YYYY-MM-DD')}), token)
}

export function fetchHistoricalKPI (token, dates) {
  // https://api.trackattack.io/api/KPIs/historical?dates=2018-02-14T12:00:00&dates=2018-02-15T00:00:00&dates=2018-02-15T23:00:00
  const args = dates
    .map((date) => { return `dates=${moment(date).format('YYYY-MM-DD')}` })
    .join('&')

  return fetchGet(url(KPI_HISTORICAL) + '?' + args, token)
}

export function fetchHistoricalSubscriptionsKPI (token, dates) {
  const args = dates
    .map((date) => { return `dates=${moment(date).format('YYYY-MM-DD')}` })
    .join('&')

  return fetchGet(url(KPI_HISTORICAL_SUBSCRIPTIONS) + '?' + args, token)
}

export function fetchHistoricaParserslKPI (token, dates) {
  const args = dates
    .map((date) => { return `dates=${moment(date).format('YYYY-MM-DD')}` })
    .join('&')

  return fetchGet(url(KPI_PARSERS) + '?' + args, token)
}

// ============================================================ RESULTS ============================================================

export function fetchUserSessions (token, skip, filter) {
  // asume the page size is constant!
  let $scope = {}
  return fetchGet(url(USER_SESSIONS, { pageSize: BASIC_PAGE_SIZE, page: 1 + Math.floor(skip / BASIC_PAGE_SIZE), ...filter }), token, $scope)
    .then((response) => {
      return {
        list: response,
        hasMore: response.length === BASIC_PAGE_SIZE,
        headers: $scope
      }
    })
}

/**
 * @param {*} token
 * @param {*} skip
 * @param {*} filter driverId,trackId,teamId,vehicleId
 */
export function fetchPublicSessions (token, skip, filter) {
  // asume the page size is constant!
  let $scope = {}
  const newFilter = {
    driverId: filter.driverId,
    trackId: filter.trackId,
    teamId: filter.teamId,
    vehicleId: filter.vehicleId
  }
  return fetchGet(url(SESSIONS, { pageSize: BASIC_PAGE_SIZE, page: 1 + Math.floor(skip / BASIC_PAGE_SIZE), ...newFilter }), token, $scope)
    .then((response) => {
      return {
        list: response,
        hasMore: response.length === BASIC_PAGE_SIZE,
        headers: $scope
      }
    })
}

export function fetchSelectedSession (token, id) {
  return fetchGet(url(SESSIONS + '/' + id), token)
}

export function fetchSessionShares (token, id) {
  return fetchList(SESSIONS + '/' + id + '/shares', token)
}

export function fetchSessionInvites (token, handle) {
  return fetchGet(url(INVITATIONS,
    {
      targetId: handle,
      targetType: 'session',
      status: INVITE_STATUS.PENDING_REGISTRATION + INVITE_STATUS.PENDING
    }), token)
}

export function deleteSessionShares (token, id, shareId) {
  return sendDelete(url(`${SESSIONS}/${id}/shares/${shareId}`), token)
}

export function deleteSession (token, id) {
  return sendDelete(url(SESSIONS + '/' + id), token)
}

export function updateSession (token, id, session) {
  // use null guid to remove driver and vehicle correctly

  return fetchPut(url(SESSIONS + '/' + id), token, {
    ...session,
    driverId: session.driverId || NULL_GUID,
    vehicleId: session.vehicleId || NULL_GUID
  })
}

// ============================================================ DATA FILES ============================================================

export function upsertEntityFile (entityFile, token) {
  const requestUrl = entityFile.rowVersion ? url(`${ENTITYFILES_LIST}/${entityFile.id}`) : url(ENTITYFILES_LIST)

  return entityFile.rowVersion
    ? fetchPut(requestUrl, token, entityFile) // update
    : fetchPost(requestUrl, token, entityFile)
}

export function deleteEntityFile (entityFile, token) {
  return sendDelete(url(`${ENTITYFILES_LIST}/${entityFile.id}`), token)
}

export function loadEntityFilesForSession (id, token) {
  return fetchListAll(ENTITYFILES_LIST, token, { entityId: id })
  // return fetchGet(url(ENTITYFILES_LIST, { entityId: id }), token)
}

export function downloadSessionSourceZipEntityFile (entityFile, token) {
  return fetchGet(url(`${SESSIONS}/${entityFile.entityId}/${ORIGINAL_TELEMETRY}`), token)
}

export function loadSasTokenForFile (entityFile, token) {
  let cached = getData(entityFile.id, AGE_30_DAY)

  if (cached) {
    return new Promise((resolve, reject) => resolve(cached))
  }

  return fetchGet(url(STORAGE_SECURITY + '/' + entityFile.id), token)
    .then((response) => {
      putData(entityFile.id, response)
      return response
    })
}

export function loadSasTokenForSessionExtra (entityFile, token) {
  return fetchPost(url(STORAGE_SECURITY), token, { FileName: `Sessions/${entityFile.entityId}/${entityFile.id}` })
    .then((response) => {
      return response
    })
}

export function loadTelemetryFile (entityFile, sasToken, token) {
  let url = sasToken.blobUrl + sasToken.sasToken
  let proto = {}

  return fetchTelemetryProto()
    .then((result) => {
      proto = result
      return fetch(url)
    })
    .then((response) => {
      return response.arrayBuffer()
    })
    .then((arrayBuffer) => {
      let data = new DataView(arrayBuffer)
      let tempArray = new Uint8Array(
        data.byteLength / Uint8Array.BYTES_PER_ELEMENT)
      let len = tempArray.length

      // Incoming data is raw uint8
      // with little-endian byte ordering.
      for (var jj = 0; jj < len; ++jj) {
        tempArray[jj] =
          data.getUint8(jj * Uint8Array.BYTES_PER_ELEMENT, true)
      }

      let decoded = proto.decode(tempArray)
      return proto.toObject(decoded)
    })
}

function fetchTelemetryProto () {
  return new Promise(function (resolve, reject) {
    let cached = getInmemory('telemetry_proto')
    const PROTOURL = '/data.proto'

    if (cached) {
      resolve(cached)
    }

    protobuf.load(PROTOURL, function (err, root) {
      if (err) {
        return reject(err)
      } else {
        // Obtain a message type
        let channelData = root.lookupType('ChannelData')
        putInmemory('telemetry_proto', channelData)
        resolve(channelData)
      }
    })
  })
}

// ============================================================ TRACKS ============================================================

export function searchTracks (token, name) {
  return fetchTracks(token, 0, { name: name })
}

export function fetchTracks (token, skip = 0, tracksFilter = {}) {
  let $scope = {}

  let filterexpr = tracksFilter

  let tracksUrl = url(TRACKS, {
    pageSize: BASIC_PAGE_SIZE,
    page: 1 + Math.floor(skip / BASIC_PAGE_SIZE),
    ...filterexpr
  })

  return fetchGet(tracksUrl, token, $scope)
    .then((response) => {
      return {
        list: response,
        hasMore: response.length === BASIC_PAGE_SIZE,
        headers: $scope
      }
    })
}

export function fetchRecentTracks (token) {
  // TODO: FAKE!!!
  let tracksUrl = url(TRACKS, {
    pageSize: 3,
    page: 3
  })

  return fetchGet(tracksUrl, token)
    .then((response) => {
      return {
        list: response
      }
    })
}

export function fetchNearByTracks (token, lat, lon, skip = 0, filter = {}) {
  filter = { ...filter, sort: 'location', latitude: lat, longitude: lon }
  return fetchTracks(token, skip, filter)
}

export function fetchPopularTracks (token, lat, lon) {
  // TODO: FAKE!!!
  let tracksUrl = url(TRACKS, {
    pageSize: 9,
    page: 2
  })

  // console.log("NEARBY:", lat, lon)

  return fetchGet(tracksUrl, token)
    .then((response) => {
      return {
        list: response
      }
    })
}

export function fetchTracksCount (token) {
  let $scope = {}

  let tracksUrl = url(TRACKS, {
    pageSize: 1,
    page: 1
  })

  return fetchGet(tracksUrl, token, $scope)
    .then((response) => {
      return $scope.pagination.totalCount
    })
}

export function fetchFeaturedTrack (token) {
  // TODO: REWRITE!!!!
  const availableFeaturedTracks = ['WillowSprings', 'RoadAtlanta', 'IMS', 'TheRidgeMP']

  const featuredHandle = availableFeaturedTracks[Math.floor(availableFeaturedTracks.length * Math.random())]

  return fetchTrack(token, featuredHandle)
}

export function fetchTrack (token, id) {
  return fetchGet(url(TRACKS + '/' + id), token)
}

export function postTrack (token, track) {
  return fetchPost(url(TRACKS), token, track)
}

export function putTrack (token, id, track) {
  return fetchPut(url(TRACKS + '/' + id), token, track)
}

export function deleteTrack (token, id) {
  return sendDelete(url(TRACKS + '/' + id), token)
}

export function postTrackAvatar (token, id, avatarFile) {
  return putAvatar(avatarFile, url(TRACKS + '/' + id + '/avatar'), token)
}

export function getTrackLeaderboardFastlaps (token, filter) {
  const {startDate, endDate} = filter

  return fetchList(`/layouts/${filter.layoutId}/stats/fastestlap`, token, filter.skip, {startDate, endDate})
}

// =========================================================== USERS ===========================================================

export function fetchUserStats (token, handle) {
  return fetchGet(url(`${USERS}/${handle}/stats`), token)
}

export function fetchCurrentUser (token) {
  return fetchGet(url(PROFILE), token)
}

export function fetchQuotas (token) {
  return fetchGet(url(USER_QUOTAS), token)
}

export function putLicenseKey (token, licenseKey) {
  return fetchPut(url(USER_LICENSE_KEY + licenseKey), token)
}

export function getLicenseKey (token) {
  return fetchGet(url(USER_LICENSE_KEY), token)
}

export function fetchUser (token, handle) {
  return fetchGet(url(USERS + '/' + handle), token)
}

export function fetchUserFollowers (token, handle, skip = 0, filter = {}) {
  return fetchList(USERS + '/' + handle + '/followers', token, skip, filter)
}

export function fetchUserFollowing (token, handle, skip = 0, filter = {}) {
  return fetchList(USERS + '/' + handle + '/following', token, skip, filter)
}

export function fetchUsers (token, skip = 0, filter = {}) {
  return fetchList(USERS, token, skip, filter)
}

export function searchUsers (token, pattern) {
  return fetchList(USERS, token, 0, { filter: pattern })
}

// =========================================================== INVITES ===========================================================

/**
 *
 * @param {string} token
 * @param {object} inviteSpec {recipientSpec, targetId, targeType}
 */
export function postInvite (token, inviteSpec) {
  return fetchPost(url(INVITATIONS), token, inviteSpec)
}

/**
 * fetch invitations of the current user to somewhere
 * @param {string} token
 */
export function fetchInvites (token, filter = {}) {
  // Bitmask invitation status ( 1=pendingRegistration, 2=pending, 4=accepted, 8=Rejected, 16=cancelled )
  const actualFilter = {
    ...filter,
    invitationType: INVITE_TYPE.INVITE_TO + INVITE_TYPE.REQUEST_TO,
    status: INVITE_STATUS.PENDING_REGISTRATION + INVITE_STATUS.PENDING
  }
  return fetchListAll(`${USER}/${INVITATIONS}`, token, actualFilter)

  // return fetchGet(url(USER + '/invitations?invitationType=3&status=3'), token)
}

export function fetchInvitesSummary (token) {
  return fetchGet(url(`${USER}/${INVITATIONS}/summary`), token)
}

export function changeInviteStatus (token, invite, status) {
  const values = [{ op: 'replace', path: 'InvitationStatus', value: status }]
  return sendPatch(url(`${INVITATIONS}/${invite.id}`), token, values)
}

///  =========================================================== TEAMS ===========================================================

export function searchTeams (token, filter) {
  return fetchTeams(token, 0, { filter: filter })
}

export function fetchTeams (token, skip = 0, filter = {}) {
  return fetchList(TEAMS, token, skip, filter)
}

export function fetchUserTeams (token, skip = 0, filter = {}) {
  return fetchList(USER_TEAMS, token, skip, filter)
}

export function fetchTeamMembers (token, handle, skip = 0, filter = {}) {
  return fetchList(TEAMS + '/' + handle + '/members', token, skip, filter)
}

export function fetchTeamSessions (token, handle, skip = 0, filter = {}) {
  return fetchList(TEAMS + '/' + handle + '/sessions', token, skip, filter)
}

export function fetchTeamsCount (token) {
  let $scope = {}

  let requestUrl = url(TEAMS, {
    pageSize: 1,
    page: 1
  })

  return fetchGet(requestUrl, token, $scope)
    .then((response) => {
      return $scope.pagination.totalCount
    })
}

export function fetchTeam (token, handle) {
  return fetchGet(url(TEAMS + '/' + handle), token)
}

export function fetchTeamSummary (token, handle) {
  return fetchGet(url(TEAMS + '/' + handle + '/summary'), token)
}

export function postTeam (token, teamSpec) {
  return fetchPost(url(TEAMS), token, teamSpec)
}

export function putTeam (token, teamSpec) {
  // Purposely use the id here so we don't modify a random team when we change handle
  return fetchPut(url(TEAMS + '/' + teamSpec.id), token, teamSpec)
}

export function deleteTeam (token, handle) {
  return sendDelete(url(TEAMS + '/' + handle), token)
}

export function deleteTeamMember (token, teamHandle, memberHandle) {
  return sendDelete(url(TEAMS + '/' + teamHandle + '/members/' + memberHandle), token)
}

export function fetchTeamInvites (token, handle) {
  return fetchGet(url(INVITATIONS,
    {
      targetId: handle,
      targetType: 'team',
      status: INVITE_STATUS.PENDING_REGISTRATION + INVITE_STATUS.PENDING
    }), token)
}

export function putTeamAvatar (teamAvatarFile, team, token) {
  // Purposely use the id here so we don't modify a random team when we change handle
  return putAvatar(teamAvatarFile, url(`${TEAMS}/${team.id}/avatar`), token)
}

// =========================================================== vehicles ===========================================================

export function fetchMyVehicles (token, skip = 0, filter = {}) {
  return fetchList(USER_VEHICLES, token, skip, filter)
}

export function fetchVehicles (token, skip = 0, filter = {}) {
  return fetchList(VEHICLES, token, skip, filter)
}

export function fetchTeamVehicles (token, handle, skip = 0, filter = {}) {
  return fetchList(`${TEAMS}/${handle}/${VEHICLES}`, token, skip, filter)
}

export function fetchVehicle (token, id) {
  return fetchGet(url(VEHICLES + '/' + id), token)
}

export function fetchVehicleStats (token, id) {
  return fetchGet(url(VEHICLES + '/' + id + '/stats'), token)
}

export function putVehicle (vehicle, token) {
  return fetchPut(url(VEHICLES + '/' + vehicle.id), token, vehicle)
}

export function postVehicle (vehicle, token) {
  return fetchPost(url(VEHICLES), token, vehicle)
}

export function fetchAllLookups (token) {
  return fetchGet(url(LOOKUPS_ALL), token)
}

export function deleteVehicle (token, handle) {
  return sendDelete(url(VEHICLES + '/' + handle), token)
}

export function putVehicleAvatar (avatarFile, vehicle, token) {
  // Purposely use the id here so we don't modify a random thing when we change handle
  return putAvatar(avatarFile, url(`${VEHICLES}/${vehicle.id}/avatar`), token)
}

export function fetchVehicleSessions (token, handle, skip = 0, filter = {}) {
  return fetchList(VEHICLES + '/' + handle + '/sessions', token, skip, filter)
}

// =========================================================== HANDLES ===========================================================

export function searchHandles (token, filter, filterType = null) {
  const cacheKey = 'handles_' + filter + (filterType || '0')
  const cached = getInmemory(cacheKey)

  if (cached) {
    return new Promise((resolve) => { resolve(cached) })
  }

  return fetchGet(url(HANDLES, { filter, filterType }), token)
    .then((result) => {
      putInmemory(cacheKey, result)
      return result
    })
}

export function fetchHandle (token, handle) {
  return fetchGet(url(HANDLES + '/' + handle), token)
}

export function putProfile (profile, token) {
  return fetchPut(url(PROFILE + '/' + profile.id), token, profile)
    .then((response) => {
      return response
    })
}

export function putProfileAvatar (profileAvatarFile, token) {
  return putAvatar(profileAvatarFile, url(PROFILE_AVATAR), token)
}

export function deleteLayout (token, uuid) {
  return sendDelete(url(LAYOUTS + '/' + uuid), token)
}

export function putLayout (token, uuid, layout) {
  return fetchPut(url(LAYOUTS + '/' + uuid), token, layout)
}

export function postLayout (token, trackUUID, layout) {
  return fetchPost(url(TRACKS + '/' + trackUUID + '/layouts'), token, layout)
}

// ===================================================== SUBSCRIPTIONS ==================================================

export function cancelProfileSubscription (token) {
  return sendDelete(url(SUBSCRIPTION), token)
}

export function getAvailableProfileSubscriptions (token) {
  return fetchGet(url(SUBSCRIPTIONS_LIST), token)
}

export function getAvailableProfileSubscriptionsStudents (token) {
  return fetchGet(url(SUBSCRIPTIONS_LIST_STUDENT), token)
}

export function getSubscriptionInfo (token) {
  return fetchGet(url(SUBSCRIPTION_MORE_INFO), token)
}

// ===================================================== LEADERBOARDS ==================================================

export function getLeaderboardsSpeed (token, skip = 0, filter = {}) {
  return fetchList(LEADERBOARDS_USERSPEED, token, skip, filter)
}

export function getLeaderboardsLaps (token, skip = 0, filter = {}) {
  return fetchList(LEADERBOARDS_USERLAPS, token, skip, filter)
}

export function getLeaderboardsHours (token, skip = 0, filter = {}) {
  return fetchList(LEADERBOARDS_USERHOURS, token, skip, filter)
}

export function getLeaderboardsSessions (token, skip = 0, filter = {}) {
  return fetchList(LEADERBOARDS_USERSESSIONS, token, skip, filter)
}

export function getLeaderboardsTracks (token, skip = 0, filter = {}) {
  return fetchList(LEADERBOARDS_TRACKS, token, skip, filter)
}

// ====================================================== NOTIFICATIONS =======================================================

export function unreadNotifications (token) {
  // always grab some fixed number
  // filter unread default, but putting it explicitly here
  return fetchList(USER_NOTIFICATIONS, token, 0, {filter: 'unread', sort: '-CreatedOn'})
}

export function allNotifications (token, skip = 0) {
  return fetchList(USER_NOTIFICATIONS, token, skip, {filter: 'all', sort: '-CreatedOn'})
}

export function markNotificationAsRead (token, notification) {
  return fetchPut(url(`${USER_NOTIFICATIONS}/${notification.id}`), token, {isDelivered: true})
}

export function createNotification (token, notification) {
  return fetchPost(url(NOTIFICATIONS), token, notification)
}

export function deleteNotification (token, notification) {
  return sendDelete(url(`${NOTIFICATIONS}/${notification.id}`), token)
}

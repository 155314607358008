import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import {Button} from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'

const DT_FORMAT = 'DD MMM YYYY'

class DateIntervalSelector extends Component {
  static propTypes = {
    // selectedDateFilter: PropTypes.oneOf(Object.keys(DATE_FILTER_OPTIONS)),
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    onDatesChanged: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      beforeFilterVisible: false,
      afterFilterVisible: false
    }

    this.beforeDateFilterChanged = this.beforeDateFilterChanged.bind(this)
    this.afterDateFilterChanged = this.afterDateFilterChanged.bind(this)
    this.closeAllDatePickers = this.closeAllDatePickers.bind(this)
    this.showAfterPicker = this.showAfterPicker.bind(this)
    this.showBeforePicker = this.showBeforePicker.bind(this)
    this.clearAllFilters = this.clearAllFilters.bind(this)
  }

  beforeDateFilterChanged (date, e) {
    const {endDate, onDatesChanged} = this.props

    this.setState({beforeFilterVisible: false})
    onDatesChanged({endDate, startDate: date})
  }

  afterDateFilterChanged (date, e) {
    const {startDate, onDatesChanged} = this.props

    this.setState({afterFilterVisible: false})
    onDatesChanged({endDate: date, startDate})
  }

  clearAllFilters (date, e) {
    const {onDatesChanged} = this.props

    this.closeAllDatePickers()
    onDatesChanged({endDate: null, startDate: null})
  }

  showBeforePicker () {
    this.setState({afterFilterVisible: false, beforeFilterVisible: true})
  }

  showAfterPicker () {
    this.setState({afterFilterVisible: true, beforeFilterVisible: false})
  }

  closeAllDatePickers () {
    this.setState({afterFilterVisible: false, beforeFilterVisible: false})
  }

  renderDatePicker (isFrom) {
    const {endDate, startDate} = this.props
    const {beforeFilterVisible, afterFilterVisible} = this.state

    if ((!beforeFilterVisible && isFrom) || (!afterFilterVisible && !isFrom)) {
      return
    }

    return <div className='datesfilter__customselector'>
      <div className='datesfilter__customselector__picker'>
        <DatePicker
          inline
          selected={isFrom ? startDate : endDate}
          maxDate={isFrom ? startDate : new Date()}
          selectsStart={isFrom}
          selectsEnd={!isFrom}
          onChange={isFrom ? this.beforeDateFilterChanged : this.afterDateFilterChanged}
          minDate={isFrom ? null : startDate}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div className='datesfilter__customselector__buttons'>
        <Button onClick={this.closeAllDatePickers} size='sm' block bsStyle='info'>Hide</Button>
      </div>
    </div>
  }

  render () {
    const {startDate, endDate} = this.props
    const {beforeFilterVisible, afterFilterVisible} = this.state

    return <div className='datesfilter'>
      <div className={classnames('datesfilter__item', {'datesfilter__item--selected': beforeFilterVisible})}>
        <span onClick={this.showBeforePicker}>
          {`From: ${startDate ? moment(startDate).format(DT_FORMAT) : '[Not set]'}`}
        </span>
      </div>
      <div className={classnames('datesfilter__item', {'datesfilter__item--selected': afterFilterVisible})}>
        <span onClick={this.showAfterPicker}>
          {`To: ${endDate ? moment(endDate).format(DT_FORMAT) : '[Not set]'}`}
        </span>
      </div>
      <div className='datesfilter__item'>
        <span onClick={this.clearAllFilters}>
          Clear Filters
        </span>
      </div>
      {this.renderDatePicker(true)}
      {this.renderDatePicker(false)}
    </div>
  }
}

export default DateIntervalSelector

import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl, HelpBlock, ControlLabel } from 'react-bootstrap'
import {UI_INPUT_LENGTH} from '../constants'

export default class RequestForm extends React.Component {
  static propTypes = {
    changeHandler: PropTypes.func.isRequired,
    explanation: PropTypes.string,
    request: PropTypes.object
  }

  static defaultProps = {
  }

  static FieldGroup ({ id, label, help, ...props }) {
    return (
      <FormGroup controlId={id}>
        <ControlLabel>{label}</ControlLabel>
        <FormControl {...props} />
        {help && <HelpBlock>{help}</HelpBlock>}
      </FormGroup>
    )
  }

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.props.changeHandler({ [name]: value })
  }

  render () {
    const { explanation, request } = this.props

    return (
      <Form onSubmit={(e) => { e.preventDefault() }}>
        {explanation && <FormControl.Static>{explanation}</FormControl.Static>}
        <RequestForm.FieldGroup
          id='message_inp'
          name='message'
          type='text'
          label='Message'
          value={request.message || ''}
          onChange={this.handleChange}
          maxLength={UI_INPUT_LENGTH.MESSAGE}
          placeholder='Message to go along with this request'
        />
      </Form>
    )
  }
}

import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import { RowEntityList, TwoLineEntry } from '../'
import { Button } from 'react-bootstrap'
import noImage from '../../images/no_image.jpg'
import { PageViewer } from '../../containers'
import { PRODUCT_FILE_TYPES } from '../../api/apiConstants'

class ProductsList extends React.Component {
  static propTypes = {
    list: PropTypes.object.isRequired,
    mixinList: PropTypes.arrayOf(PropTypes.object),
    fetchingRenderer: PropTypes.func,
    onClick: PropTypes.func,
    noRowsRenderer: PropTypes.func,
    loadMoreRows: PropTypes.func,
    isGrid: PropTypes.bool
  }

  static defaultProps = {
    noRowsRenderer: () => <div className={'grid-noRows'}>No products here</div>,
    loadMoreRows: () => {},
    mixinList: [],
    isGrid: true
  }

  constructor (props) {
    super(props)
    this.renderRow = this.renderRow.bind(this)
  }

  resultingList () {
    const { list, mixinList } = this.props

    const generatePrice = (item) => {
      if (item.price) {
        return item.price
      }

      if (item.recurring_price) {
        // special cases for subscriptions
        if (item.subscriptionType === 0) {
          return `${item.recurring_price} per month`
        }

        if (item.subscriptionType === 1) {
          return `${item.recurring_price} per year`
        }

        const prefix = `${item.recurring_price} per ${item.frequency_value}`

        switch (item.frequency_interval) {
          case 1: return `${prefix} day${item.frequency_value > 1 ? 's' : ''}`
          case 2: return `${prefix} week${item.frequency_value > 1 ? 's' : ''}`
          case 3: return `${prefix} month${item.frequency_value > 1 ? 's' : ''}`
          case 4: return `${prefix} year${item.frequency_value > 1 ? 's' : ''}`
          default: return prefix + ' interval'
        }
      }

      return ''
    }

    const mixinProducts = mixinList.length
      ? mixinList.map((item) => {
        return {
          ...item,
          productType: item.productType || PRODUCT_FILE_TYPES.SUBSCRIPTIONS,
          price: generatePrice(item),
          imageUrl: item.imageUrl || item.product_image_url
        }
      })
      : []

    return {...list, list: [...list.list, ...mixinProducts]}
  }

  render () {
    const { loadMoreRows, noRowsRenderer, fetchingRenderer, isGrid } = this.props

    return <RowEntityList
      list={this.resultingList()}
      loadMoreRows={loadMoreRows}
      rowRenderer={this.renderRow}
      noRowsRenderer={noRowsRenderer}
      fetchingRenderer={fetchingRenderer}
      isGrid={isGrid}
    />
  }

  stopPropagation (e) {
    e.stopPropagation()
  }

  renderItemPrice (item) {
    if (item.purchased) {
      return <div>
        {item.memberOwnership && <div className='subscription-mark'>Included in Membership</div>}
        {item.productType === PRODUCT_FILE_TYPES.WISTIA && <Button target='_blank' onClick={this.stopPropagation} href={PageViewer.createLink(item)}>
          <span>Watch</span>
        </Button>}
        {item.productType === PRODUCT_FILE_TYPES.YOUTUBE && <Button target='_blank' onClick={this.stopPropagation} href={PageViewer.createLink(item)}>
          <span>Watch</span>
        </Button>}
        {item.productType === PRODUCT_FILE_TYPES.PDF && <Button target='_blank' onClick={this.stopPropagation} href={PageViewer.createLink(item)}>
          <span>Read</span>
        </Button>}
        {/* TBD: What to show here for the other product types */}
      </div>
    }

    // special case
    if (item.price === 0) {
      return <div className='discount-price'>FREE!</div>
    }

    return <Fragment>
      {!item.discount && <div className='price'>{`$${item.price}`}</div>}
      {item.discount && <div className='raw-price tooltipped' data-tip-text={item.discount.name}>{`$${item.price}`}</div>}
      {item.discount && <div className='discount-price tooltipped' data-tip-text={item.discount.name}>{`$${(item.price * (100 - item.discount.percentageDiscount) / 100).toFixed(2)}`}</div>}
    </Fragment>
  }

  renderRow ({ index, key, style }) {
    const { isGrid, onClick } = this.props

    const item = this.resultingList().list[index]
    const clickHandler = () => {
      onClick && onClick(item)
    }

    if (isGrid) {
      return <div key={key} style={style} className='gridItem' onClick={clickHandler}>
        <div className='gridItem-cover'>
          <img src={item.imageUrl || noImage} alt={item.name} />
        </div>
        <div className='gridItem-text'>
          <div className='name'>{item.name}</div>
          {this.renderItemPrice(item)}
        </div>
      </div>
    } else {
      return <div key={key} style={style} onClick={clickHandler}>
        <TwoLineEntry
          className={item.isPublished ? null : 'disabled'}
          image={item.imageUrl || noImage}
          headline={<div><strong>[{item.idSendOwl}]</strong>&nbsp;{item.name}</div>}
          message={<div>
            <span className='list-info-value'>{`$${item.price}`}</span>
            <span className='list-info-value'>{item.isPublished ? 'PUBLISHED' : 'NOT PUBLISHED'}</span>
            <span className='list-info-value'>{item.isNew ? 'NEW' : ''}</span>
          </div>}
          // buttons={this.renderTeamButtons(item)}
          // onClick={clickHandler}
        />
      </div>
    }
  }
}

export default ProductsList

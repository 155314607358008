import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { getRoles } from '../api/auth0'

class ProtectedRoute extends Component {
  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
    path: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    fallbackPath: PropTypes.string
  }

  static defaultProps = {
    roles: [],
    fallbackPath: '/'
  }

  render () {
    const {session, roles, component, path, fallbackPath} = this.props

    const sessionRoles = getRoles(session)

    // at least one of the mentioned roles
    const allowed = !session.isAnonymous && (roles.length === 0 || roles.map(r => sessionRoles.includes(r)).includes(true))

    return allowed ? <Route path={path} component={component} /> : <Redirect to={fallbackPath} />
  }
}

function mapStateToProps (state, ownProps) {
  return { session: state.session }
}

export default connect(mapStateToProps)(ProtectedRoute)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { uiNavigation } from '../../actions/ui'
import { login } from '../../api/auth0'
import { ROUTE_ROOT } from '../../utils/routingConstants'
import { getRedirectToFromUrl } from '../../utils/routingUtils'
import { searchToObject, isMobile } from '../../utils/utils'
import ReactPlayer from 'react-player'
import { Col, Grid, Row } from 'react-bootstrap'
import { pageView, GA_PAGES } from '../../api/gaHelper'
import classnames from 'classnames'

// Images
import imgVideoData from '../../images/landing/landing_video_data.jpg'
import imgVideoData2 from '../../images/landing/landing_video_data_2.jpg'

import imgTeamsSharing from '../../images/landing/landing_teams_sharing.jpg'
import imgTeamsSharing2 from '../../images/landing/landing_teams_sharing_2.jpg'

import imgCloudStorage from '../../images/landing/landing_cloud_storage.jpg'

import imgCommunity from '../../images/landing/landing_community.jpg'

import imgAnyDataSystem from '../../images/landing/landing_any_data_system.jpg'
import imgAnyDataSystem2 from '../../images/landing/landing_any_data_system_2.jpg'

class Landing extends Component {
  constructor (props) {
    super(props)

    this.state = {
      height: '600px'
    }

    this.updDimensionsHandler = this.updateDimensions.bind(this)
  }

  static LandingSection ({ img1, img2, title, className, children, textFirst = false }) {
    return (
      <Row className={classnames('landing-section', className)}>
        <h2 className='landing-section__title'>{title}</h2>

        {textFirst && <Col sm={6} xs={12}>
          <div className='landing-section__text'>
            {children}
          </div>
        </Col>}

        <Col sm={6} xs={12}>
          {img1 && img2 && <div>
            <img className='landing-section__img1' src={img1} alt='' />
          </div>}

          {img2 && img1 && <div>
            <img className='landing-section__img2' src={img2} alt='' />
          </div>}

          {img1 && !img2 && <div>
            <img className='landing-section__img-single' src={img1} alt='' />
          </div>}
        </Col>

        {!textFirst && <Col sm={6} xs={12}>
          <div className='landing-section__text'>
            {children}
          </div>
        </Col>}
      </Row>
    )
  }

  updateDimensions () {
    const topBanner = document.getElementById('landing-top-banner-container')

    topBanner && this.setState({
      height: topBanner.clientHeight
    })
  }

  componentWillMount () {
    const { dispatch, session } = this.props

    pageView(GA_PAGES.LANDING, this.props.session)

    dispatch(uiNavigation({ to: ROUTE_ROOT }))
    if (session.isAnonymous && searchToObject().to) {
      login()
    }
  }

  componentDidMount () {
    this.updateDimensions()
    window.addEventListener('resize', this.updDimensionsHandler)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updDimensionsHandler)
  }

  renderProtectedLanding () {
    const { session } = this.props

    return (<Redirect to={getRedirectToFromUrl(session)} />)
  }

  renderError () {
    return (
      <div className='landing-root container-main'>
        <div className='landing-error'>
          Error: the token looks broken or is expired. Try logging in once more.
        </div>
        {window.location.search && <Redirect to='/' />}
      </div>
    )
  }

  render () {
    const { session } = this.props

    return (
      <div className='landing-root container-main'>
        {!session.isAnonymous && this.renderProtectedLanding()}

        {/* render landing here */}
        {session.isFetching && <div className='landing-fetching'>Logging you in, please wait...</div>}

        {session.error && this.renderError()}

        <Grid className='container-content-wrap'>

          {/* High level value prop + Signup module */}
          {!isMobile() && <div id='top-banner' style={{ height: this.state.height }}>
            <div className='landing-top-banner-image-container' style={{
              height: this.state.height
            }}>
              {/* Banner video playback - muted to enable autoplay */}
              <ReactPlayer
                url='https://youtu.be/4S5vVJzCZ9Y'
                playing
                loop
                muted
                width='100%'
                height='100%' />
            </div>

            <div className='landing-top-banner-dim-foreground' />

            <div className='landing-top-banner-container' id='landing-top-banner-container'>
              <Col sm={8} md={8} lg={5} smOffset={2}>
                <div className='landing-high-level-container'>
                  <p className='landing-high-level-value-title'>Drive faster, faster</p>
                  <p className='landing-high-level-value-content' style={{ marginTop: '20px' }}>
                    Track Attack is an easy to use analytics experience, cloud storage service, built to help drivers, teams and coaches connect and get faster.
                  </p>
                </div>
              </Col>
              <Col sm={5} lg={2} />
            </div>
          </div>}

          <Row className={classnames('landing-section', 'mobile-only', 'mobile-banner')}>
            <h1 className='landing-section__title'>Drive faster, faster</h1>
            <Col xs={12}>
              <div className='landing-section__text'>
                Track Attack is an easy to use analytics experience, cloud storage service, built to help drivers, teams and coaches connect and get faster.
              </div>
            </Col>
          </Row>

          <Landing.LandingSection img1={imgVideoData} img2={imgVideoData2} title='Data analysis made easy with video'>
            Graphs and lines from data analysis software are incredibly powerful but can be difficult to understand.<br /><br />
            With Track Attack, easily pair your video and data once, use the video to help you remember why, how and when something happened in the data.<br /><br />
            Create awesome videos with custom data overlays that tell a more complete story of what it means to drive and compete.
          </Landing.LandingSection>

          <Landing.LandingSection textFirst img1={imgTeamsSharing} img2={imgTeamsSharing2} title='One-click share and compare data'>
            Many drivers run in groups or even formal teams.<br /><br />
            With the Track Attack, create teams and automatically have your sessions shared within those teams.<br /><br />
            And you can even share with individuals.<br /><br />
            No more emailing data files and 'GPS inserts' - just hit 'share' and have instant access to others data and video.
          </Landing.LandingSection>

          <Landing.LandingSection img1={imgCloudStorage} title='Cloud storage and anywhere access'>
            Track Attack isn't just software, it's a service that stores all of your data and video to your profile, on the cloud.<br /><br />
            Have ease of mind knowing that if your trusty laptop is dropped, ran over in the paddock or forgotten, you will always have access to your data, on a PC, Mac, web browser and your mobile devices.
          </Landing.LandingSection>

          <Landing.LandingSection textFirst img1={imgCommunity} title='Welcome home, motorsports people'>
            While we drive with friends and competitors, when it comes to data, analysis and driver development, it's still a very isolated experience.<br /><br />
            With sharing, teams, driver profiles, car profiles and track profile pages, Track Attack is the best place to find and connect with other drivers, coaches and teams on real driving data.<br /><br />
            Ever wish that there was a part of Facebook that was only about real driving, videos and lap times?  Wish no more!  This is the new Track Attack!
          </Landing.LandingSection>

          <Landing.LandingSection img1={imgAnyDataSystem} img2={imgAnyDataSystem2} title='Works with all major data systems'>
            Comparing data with other drivers, faster or even with similar pace, is one of the best ways to figure out how to drop lap times.<br /><br />
            The problem is that different drivers use different data systems and the sessions files are not compatible, making comparing data nearly impossible.<br /><br />
            Track Attack takes in session files from all major data systems and makes them automatically comparable.  No GPS lap-insert ever required!
          </Landing.LandingSection>

        </Grid>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return { session: state.session }
}

export default withRouter(connect(mapStateToProps)(Landing))

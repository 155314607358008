
export const initialState = { isFetching: false, item: null, error: null, headers: null, deleted: false }

export default (type, itemName = 'item') => {
  return (state = initialState, action) => {
    if (action.type !== type) { return state }

    const { result, error, context } = action
    if (context && context.clear) {
      return {
        ...initialState,
        isFetching: !(result || error)
      }
    }

    if (result && result.deleted) {
      return {
        ...state,
        isFetching: false,
        error: false,
        deleted: true
      }
    }

    if (result && context && context.updated) {
      // if the update mode is just rowVersion then keep the current object but update its rowVersion
      // this is for cases where the response from the PUT/PATCH is not conformant to that of the GET
      if (context.updated === 'rowVersion') {
        const newValue = state[itemName]
        newValue.rowVersion = result.rowVersion
        return { ...state, isFetching: false, error: false, [itemName]: newValue }
      }
      return { ...state, isFetching: false, error: false, [itemName]: result }
    }

    if (!(result || error)) {
      return { ...state, isFetching: true, deleted: false }
    }

    if (error) {
      return { ...state, isFetching: false, error: error, item: null, deleted: false }
    }

    if (result) {
      return { ...state, isFetching: false, deleted: false, [itemName]: result, error: false, headers: result.headers }
    }
  }
}

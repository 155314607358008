import 'core-js/fn/string/starts-with'
import 'core-js/fn/symbol'
import 'core-js/fn/symbol/iterator'
import 'core-js/fn/map'
import 'core-js/fn/set'
import 'core-js/fn/array/includes'

import React from 'react'
import ReactDOM from 'react-dom'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'

import {RehydratationDelayedProvider} from './containers'
import {FACEBOOK_PIXEL_ID, GOOGLE_ANALYTICS_PROPERTY_ID} from './constants'

import './styles/index.css'

FACEBOOK_PIXEL_ID && ReactPixel.init(FACEBOOK_PIXEL_ID)
FACEBOOK_PIXEL_ID && ReactPixel.pageView()

GOOGLE_ANALYTICS_PROPERTY_ID && ReactGA.initialize(GOOGLE_ANALYTICS_PROPERTY_ID) //, {debug: true})

ReactDOM.render(
  <RehydratationDelayedProvider />, document.getElementById('root'))

import React, { Component } from 'react'
import {Button} from 'react-bootstrap'

export default class TrackOperations extends Component {
  renderButton (title, key, backColor, callback) {
    if (!this.props.buttons || this.props.buttons.indexOf(key) === -1) {

    } else {
      return (<Button className='button-container' style={{ backgroundColor: backColor }} onClick={() => callback()}>{title}</Button>)
    }
  }

  render () {
    return (
      <div className='track-operations-container'>
        {this.renderButton('Save changes', 'save', '#2ECC71', this.props.onSave)}
        {this.renderButton('Discard changes', 'discard', '#9B9B9B', this.props.onDiscard)}
        {this.renderButton('Delete Track', 'delete', '#E74C3C', this.props.onDelete)}
      </div>
    )
  }
}

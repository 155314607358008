import React, { Component } from 'react'
import { Circle } from 'better-react-spinkit'

export default class InfiniteProgressCircle extends Component {
  render () {
    return (<div className='infinite-progress-circle-container' style={{ display: this.props.show ? 'block' : 'none' }}>
      <div className='infinite-progress-circle-background-dim' />

      <div className='infinite-progress-circle'>
        <Circle color='white' />
      </div>
    </div>)
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ROUTE_STORE_CART, ROUTE_STORE } from '../../utils/routingConstants'
import { Section } from '../../components'
import { GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'
import { SENDOWL_JS_MERCHANT_ID } from '../../constants'

class PageCart extends Component {
  componentDidMount () {
    window.sendOwl.cartWidget({
      parent: document.getElementById('cart'),
      merchantID: SENDOWL_JS_MERCHANT_ID
    })
  }

  render () {
    return (
      <Section>
        <div className='cart__empty'>
          Your cart is empty. <Link to={ROUTE_STORE}>Shop now!</Link>
        </div>
        <div id='cart' />
      </Section>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return { userSession: state.session }
}

export default page(connect(mapStateToProps)(PageCart), { pageView: GA_PAGES.STORE_CART, uiNavigationRoute: ROUTE_STORE_CART })

import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { ROUTE_LEADERBOARDS } from '../../utils/routingConstants'
import { Section, Leaderboard } from '../../components'
import { GA_PAGES } from '../../api/gaHelper'
import { page } from '../../hocs/page'

class PageLeaderboards extends Component {
  render () {
    return (
      <Row className='page-leaderboards'>
        <Col md={6} sm={12}>
          <Section title='Most tracks driven'>
            <Leaderboard type='tracks' />
          </Section>
        </Col>
        <Col md={6} sm={12}>
          <Section title='Most seat hours'>
            <Leaderboard type='hours' />
          </Section>
        </Col>
        <Col md={6} sm={12}>
          <Section title='Most laps completed'>
            <Leaderboard type='laps' />
          </Section>
        </Col>
        <Col md={6} sm={12}>
          <Section title='Most sessions completed'>
            <Leaderboard type='sessions' />
          </Section>
        </Col>
        <Col md={12} sm={12}>
          <Section title='Top speed'>
            <Leaderboard type='speed' />
          </Section>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    userSession: state.session
  }
}

export default page(connect(mapStateToProps)(PageLeaderboards), { pageView: GA_PAGES.LEADERBOARDS, uiNavigationRoute: ROUTE_LEADERBOARDS })

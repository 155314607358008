import * as ROUTES from './routingConstants'
import { searchToObject } from './utils'

export function getRedirectToFromUrl (session, urlParams = searchToObject()) {
  if (urlParams.to === 'invites') {
    // -- special case for 'invites' --
    return session.isAnonymous ? null : `${ROUTES.ROUTE_USERS}/${session.profile.handle}?tab=invites`
  }

  return urlParams.to || (session.isAnonymous ? ROUTES.ROUTE_ROOT : ROUTES.ROUTE_SESSIONS)
}

export function getUserPath (session) {
  return session.isAnonymous ? ROUTES.ROUTE_ROOT : `${ROUTES.ROUTE_USERS}/${session.profile.handle}`
}

export function getLinkToEntity (entity, type = entity.type) {
  if (!entity || !type || (!entity.handle && !entity.id && !entity.product_id)) {
    return ROUTES.ROUTE_ROOT
  }

  switch (type.toLowerCase()) {
    case 'team':
      return `${ROUTES.ROUTE_TEAMS}/${entity.handle || entity.id}`
    case 'user':
      return `${ROUTES.ROUTE_USERS}/${entity.handle || entity.id}`
    case 'vehicle':
      return `${ROUTES.ROUTE_VEHICLES}/${entity.handle || entity.id}`
    case 'track':
      return `${ROUTES.ROUTE_TRACKS}/${entity.handle || entity.id}`
    case 'session':
      return `${ROUTES.ROUTE_SESSIONS}/${entity.id}`
    case 'product':
      return `${ROUTES.ROUTE_STORE}/${entity.product_id}`
    default:
      return ROUTES.ROUTE_ROOT
  }
}

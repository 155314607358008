import moment from 'moment'
import * as _ from 'lodash'

const DIRECTIONS = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']

// date and duration

export function formatDate (date) {
  return moment(date).format('M/D/YYYY HH:MM')
}

export function getDuration (duration, includeMillis = true) {
  if (duration <= 0 || isNaN(duration)) {
    return 'No time set'
  }

  return moment.utc(moment.duration(duration).asMilliseconds()).format(includeMillis ? 'm:ss.SSS' : 'm:ss')
}

// user related

export function userDisplayName (profile, maxlen = -1) {
  let uname = userDisplayNameUntruncated(profile)
  if (maxlen > 0 && uname.length > maxlen) {
    return uname.slice(0, maxlen - 2) + '...'
  }
  return uname
}

export function userFullName (profile, maxlen = -1) {
  const result = profile.firstName && profile.lastName ? `${profile.firstName} ${profile.lastName}` : profile.firstName || profile.lastName
  if (maxlen > 0 && result.length > maxlen) {
    return result.slice(0, maxlen - 2) + '...'
  }
  return result
}

function userDisplayNameUntruncated (profile) {
  if (!profile) { return '' }

  if (profile.handle) {
    return '@' + profile.handle
  }
  if (profile.firstName || profile.lastName) {
    return userFullName(profile)
  }
  if (profile.email) {
    return profile.email
  }

  return '-?-'
}

export function userInitials (profile, maxletters = 2) {
  return userDisplayName(profile)
    .split(' ')
    .slice(0, maxletters)
    .map((element) => element[0] === '@' ? element[1] : element[0])
    .join('')
    .toUpperCase()
}

// misc units conversion

export function getMeasuringUnitsFromProfile (profile) {
  if (profile) {
    return profile.unitMeasure ? profile.unitMeasure : 'i'
  } else {
    return 'i'
  }
}

// wether helper

export function fixWiIconNames (iconName) {
  if (iconName.includes('-night')) {
    return 'wi-night-' + iconName.replace('-night', '')
  }

  if (iconName.includes('-day')) {
    return 'wi-day-' + iconName.replace('-day', '').replace('clear', 'sunny').replace('partly-', '')
  }

  return 'wi-' + iconName
}

export function bearingToDirection (bearing) {
  let degPerDirection = 360 / DIRECTIONS.length
  bearing += degPerDirection / 2
  if (bearing > 360) { bearing -= 360 }
  return DIRECTIONS[Math.floor(bearing / degPerDirection)]
}

// speed

export function getSpeed (speed, units, places = 1, includeUnits = true) {
  const kph = speed * 3600 / 1000
  const value = (units === 'm' ? kph : kph / 1.61).toFixed(places)
  if (includeUnits) { return value + ' ' + (units === 'i' ? 'mph' : 'km/h') }
  return value
}

export function getSpeedUnitsName (units) {
  return units === 'i' ? 'mph' : 'km/h'
}

// distance

export function getDistance (distance, units, places = 1, includeUnits = true) {
  const km = distance / 1000
  const value = (units === 'm' ? km : km / 1.61).toFixed(places)
  if (includeUnits) { return value + ' ' + (units === 'i' ? 'mile' : 'km') }
  return value
}

// session
export function getFastestLapTime (session) {
  if (!session.laps || !session.laps.length) {
    return session.fastestLap || 0
  }

  const fastestLap = _.reduce(session.laps, (result, value) => {
    if (value.hidden || value.lapType !== 0) {
      return result
    }
    return result < value.duration ? result : value.duration
  }, Number.MAX_SAFE_INTEGER)

  return fastestLap === Number.MAX_SAFE_INTEGER ? 0 : fastestLap
}

export function getSlowestLapTime (session) {
  const slowestLap = _.reduce(session.laps, (result, value) => {
    if (value.hidden || value.lapType !== 0) {
      return result
    }
    return result > value.duration ? result : value.duration
  }, 0)

  return slowestLap
}

// TODO: check and refactor the code below

/** DEPRECATED */
export const ensureLeadingZeroes = (num, length) => {
  let numStr = num.toString()

  if (numStr.length >= length) { return numStr }

  return `${'0'.repeat(length - numStr.length)}${numStr}`
}

/** DEPRECATED */
export function beautifulTimePeriodFromMs (millis) {
  if (!millis) {
    // invalid date
    return '-'
  }

  //        `${date.getHours()}.${ensureLeadingZeroes(date.getMinutes(), 2)}.${ensureLeadingZeroes(date.getSeconds(), 2)}:${ensureLeadingZeroes(date.getMilliseconds(),3)}`
  let processing = Math.floor(millis)

  let hrs = Math.floor(processing / 3600000)
  processing -= hrs * 3600000
  let mins = Math.floor(processing / 60000)
  processing -= mins * 60000
  let sec = Math.floor(processing / 1000)
  processing -= sec * 1000

  return `${hrs > 0 ? hrs + '.' : ''}${ensureLeadingZeroes(mins, 2)}.${ensureLeadingZeroes(sec, 2)}:${ensureLeadingZeroes(processing, 3)}`
}

/**
 * DEPRECATED
 * Returns fastest lap of the session
 * @param {object} session
 */
export function sessionFastestLap (session) {
  if (session.fastestLap) { return session.fastestLap }
  if (!session.laps) { return 0 }

  let fastest = session
    .laps
    .reduce((fastest, curLap) => {
      // skip in/out laps
      if (curLap.lapType !== 0) { return fastest }

      return curLap.duration < fastest
        ? curLap.duration
        : fastest
    }, Number.MAX_VALUE)

  return fastest === Number.MAX_VALUE
    ? 0
    : fastest
}

/**
 * DEPRECATED
 * @param {*} session
 */
export function createSessionStats (session) {
  if (!session) {
    return [{
      name: 'session',
      value: 'undefined'
    }]
  }

  let vals = [
    { name: 'Track', value: session.trackName ? session.trackName : 'FUN RUN' },
    { name: 'Date', value: dateStrToDate(session.start) },
    { name: 'Laps', value: session.laps ? session.laps.length : 0 }
  ]

  if (session.duration) {
    vals.push({ name: 'Duration', value: beautifulTimePeriodFromMs(session.duration) })
  }

  if (session.topSpeed) {
    vals.push({ name: 'Top speed', value: session.topSpeed })
  }

  if (session.distance) {
    vals.push({ name: 'Distance', value: session.distance })
  }

  let fastest = sessionFastestLap(session)

  if (fastest > 0) {
    vals.push({ name: 'Fastest lap', value: beautifulTimePeriodFromMs(fastest) })
  }

  return vals
}

/**
 * DEPRECATED
 * @param {*} dateStr
 */
function dateStrToDate (dateStr) {
  if (!dateStr) {
    // invalid date
    return '-'
  }

  let date = new Date(dateStr)

  if (isNaN(date.getTime())) {
    // invalid date
    return '-'
  }

  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
}

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { ROUTE_TRACKS } from '../../utils/routingConstants'
import { TwoLineEntry, RowEntityList, SimBadge } from '../'
import { Badge } from 'react-bootstrap'
import noImage from '../../images/no_image.jpg'
import { uniqBy, sortBy } from 'lodash'

class TrackList extends React.Component {
  static propTypes = {
    list: PropTypes.object.isRequired,
    renderButtons: PropTypes.func,
    noRowsRenderer: PropTypes.func,
    fetchingRenderer: PropTypes.func,
    loadMoreRows: PropTypes.func,
    itemSelector: PropTypes.func,
    filterBar: PropTypes.element
  }

  static defaultProps = {
    itemSelector: (item) => item,
    renderButtons: () => { },
    noRowsRenderer: () => (<div className={'list-noRows'}>No tracks found with that filter.</div>),
    fetchingRenderer: () => <div className={'list-noRows'}>Loading...</div>
  }

  constructor (props) {
    super(props)
    this.renderRow = this.renderRow.bind(this)
  }

  render () {
    const { loadMoreRows, noRowsRenderer, list, fetchingRenderer, filterBar } = this.props
    return (
      <div>
        {filterBar}
        <RowEntityList
          list={list}
          loadMoreRows={loadMoreRows}
          rowRenderer={this.renderRow}
          noRowsRenderer={noRowsRenderer}
          fetchingRenderer={fetchingRenderer}
        />
      </div>)
  }

  renderRow ({ index, key, style }) {
    const { renderButtons, list, itemSelector, history } = this.props
    const item = itemSelector(list.list[index])
    const clickHandler = () => {
      history.push(ROUTE_TRACKS + '/' + (item.handle || item.id))
    }

    return (
      <div key={key} style={style}>
        <TwoLineEntry
          image={item.avatarUrl || noImage}
          headline={this.renderHeadline(item)}
          message={this.renderMessage(item)}
          buttons={renderButtons(item)}
          onClick={clickHandler} />
      </div>)
  }

  renderHeadline (item) {
    const handleLine = item.handle ? '@' + item.handle + ' - ' : ''

    return <div>
      <span>{handleLine}{item.name}</span>
      {item.isOfficial && <Badge className='official'>official</Badge>}
    </div>
  }

  renderMessage (item) {
    const uniqLayouts = item.layouts ? sortBy(uniqBy(item.layouts, 'simulatorPlatform'), 'simulatorPlatform') : []

    return <div>
      <span>Layouts <Badge>{this.getLayoutCount(item)}</Badge> &nbsp; </span>
      <div className='tracklist-layout-badges'>
        {uniqLayouts.map((layout) => <SimBadge key={layout.id} target={layout} />)}
      </div>
    </div>
  }

  getLayoutCount (track) {
    return track.layouts ? track.layouts.length : 0
  }
}

export default withRouter(TrackList)

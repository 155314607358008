import React from 'react'
import classnames from 'classnames'
import { Badge } from 'react-bootstrap'

import { SIMULATOR_PLATFORM, FILE_SOURCE_TYPES } from '../api/apiConstants'
import imgIRacing from '../images/sims/iracing.png'
import imgRFactor from '../images/sims/rfactor.png'
import imgRFactor2 from '../images/sims/rfactor2.png'
import imgAC from '../images/sims/asetto_corsa.png'
import imgACCompetezione from '../images/sims/asetto_corsa_competezione.png'
import imgProjectCars from '../images/sims/project_cars.png'
import imgProjectCars2 from '../images/sims/project_cars2.png'
import imgForzaMS from '../images/sims/forza_ms.png'

const getImageForBadge = (type) => {
  switch (type) {
    case SIMULATOR_PLATFORM.AssettoCorsa: return imgAC
    case SIMULATOR_PLATFORM.AssettoCorsaCompetizione: return imgACCompetezione
    case SIMULATOR_PLATFORM.iRacing: return imgIRacing
    case SIMULATOR_PLATFORM.RFactor: return imgRFactor
    case SIMULATOR_PLATFORM.RFactor2: return imgRFactor2
    case SIMULATOR_PLATFORM.ForzaMotorsports: return imgForzaMS
    case SIMULATOR_PLATFORM.ProjectCars: return imgProjectCars
    case SIMULATOR_PLATFORM.ProjectCars2: return imgProjectCars2
    default: return null
  }
}

const getTextForBadge = (type) => {
  switch (type) {
    case SIMULATOR_PLATFORM.AssettoCorsa: return 'Assetto Corsa'
    case SIMULATOR_PLATFORM.AssettoCorsaCompetizione: return 'Assetto Corsa Competezione'
    case SIMULATOR_PLATFORM.iRacing: return 'iRacing'
    case SIMULATOR_PLATFORM.RFactor: return 'R Factor'
    case SIMULATOR_PLATFORM.RFactor2: return 'R Factor 2'
    case SIMULATOR_PLATFORM.ForzaMotorsports: return 'Forza Motorsport'
    case SIMULATOR_PLATFORM.ProjectCars: return 'Project Cars'
    case SIMULATOR_PLATFORM.ProjectCars2: return 'Project Cars 2'
    case SIMULATOR_PLATFORM.All: return 'All'
    default: return null
  }
}

const sourceToSimulator = (source) => {
  switch (source) {
    case FILE_SOURCE_TYPES.IRACING_MOTEQ:
    case FILE_SOURCE_TYPES.IRACING_IBT:
      return SIMULATOR_PLATFORM.iRacing
    case FILE_SOURCE_TYPES.ASSETTO_CORSA_MOTEC:
    case FILE_SOURCE_TYPES.ASSETTO_CORSA_CSV:
      return SIMULATOR_PLATFORM.AssettoCorsa
    case FILE_SOURCE_TYPES.RFACTOR_MOTEC:
      return SIMULATOR_PLATFORM.RFactor
    default:
      return SIMULATOR_PLATFORM.realWorld
  }
}

const SimBadge = (props = {target: {simulatorPlatform: 0}}) => {
  const {target} = props

  const simulatorPlatform = target.source ? sourceToSimulator(target.source) : target.simulatorPlatform

  const imgSrc = simulatorPlatform ? getImageForBadge(simulatorPlatform) : getImageForBadge(props.target)
  const tipText = simulatorPlatform ? getTextForBadge(simulatorPlatform) : getTextForBadge(props.target)

  return <div className={classnames('simbadge', {'tooltipped': imgSrc}, props.className)} data-tip-text={tipText} >
    {imgSrc && <img alt='sim badge' src={imgSrc} />}
    {!imgSrc && !tipText && <Badge className={'real'}>{tipText || 'real-world'}</Badge>}
    {!imgSrc && tipText && <span className='badge-like'>{tipText}</span>}
  </div>
}

export default SimBadge

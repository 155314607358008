import React from 'react'
import { FormGroup, ControlLabel, Col, FormControl, HelpBlock, Row } from 'react-bootstrap'

const FieldGroup = ({ id, label, help, validationState, children, ...props }) => {
  return (
    <FormGroup className='field-group' controlId={id || props.name} validationState={validationState}>
      <Row>
        <Col componentClass={ControlLabel} sm={3}>{label}</Col>
        <Col sm={8}>
          {children}
          {!children && <FormControl {...props} />}
        </Col>
        {help && <Col sm={8} xsOffset={3}> <HelpBlock>{help}</HelpBlock> </Col>}
      </Row>
    </FormGroup>
  )
}

export default FieldGroup

export const UI_NAVIGATION = 'UI_NAVIGATION'
export const UI_SIDEBAR_TOGGLE = 'UI_SIDEBAR'
export const UI_ANALYTICS_SELECT_ENTITY = 'UI_ANALYTICS_SELECT_ENTITY'
export const UI_CONFIRM = 'UI_CONFIRM'
export const UI_PROFILE_SETTINGS = 'UI_PROFILE_SETTINGS'
export const UI_SESSION_SETTINGS = 'UI_SESSION_SETTINGS'
export const UI_INVITE = 'UI_INVITE'
export const UI_REQUEST = 'UI_REQUEST'
export const UI_VEHICLE_EDIT = 'UI_VEHICLE_EDIT'
export const UI_TOAST = 'UI_TOAST'
export const UI_REDIRECT = 'UI_REDIRECT'
export const UI_SESSION_RELATIONSHIPS = 'UI_SESSION_RELATIONSHIPS'
export const UI_TEAMS_RELATIONSHIPS = 'UI_TEAMS_RELATIONSHIPS'
export const UI_VEHICLES_RELATIONSHIPS = 'UI_VEHICLES_RELATIONSHIPS'
export const UI_TRACK_TYPE = 'UI_TRACK_TYPE'
export const UI_SIM_PLATFORM = 'UI_SIM_PLATFORM'
export const UI_USERS_FILTER = 'UI_USERS_FILTER'
export const UI_TRACKS_FILTER = 'UI_TRACKS_FILTER'
export const UI_TEAMS_FILTER = 'UI_TEAMS_FILTER'
export const UI_VEHICLES_FILTER = 'UI_VEHICLES_FILTER'
export const UI_SESSION_SELECTED_TRACK = 'UI_SESSION_SELECTED_TRACK'
export const UI_ADMIN_SELECTED_PRODUCT = 'UI_ADMIN_SELECTED_PRODUCT'

export const UI_STORE_FILTER = 'UI_STORE_FILTER'

export const UI_START_ADD_SESSION_EXTRA = 'UI_START_ADD_SESSION_EXTRA'
export const UI_EDIT_SESSION_EXTRA = 'UI_EDIT_SESSION_EXTRA'

export const UI_TCO_PROGRESS = 'UI_TCO_PROGRESS'

export function uiAnalyticsSelectEntity (selected) {
  return {
    type: UI_ANALYTICS_SELECT_ENTITY,
    selected: selected
  }
}

export function uiToggleSidebar () {
  return {
    type: UI_SIDEBAR_TOGGLE
  }
}

export function uiNavigation (navItem) {
  return {
    type: UI_NAVIGATION,
    to: navItem
  }
}

export function uiConfirm (confirm) {
  return {
    type: UI_CONFIRM,
    confirm
  }
}

export function uiProfileSettings (settings, uiState) {
  return {
    type: UI_PROFILE_SETTINGS,
    settings,
    uiState
  }
}

export function uiVehicleEdit (settings, uiState) {
  return {
    type: UI_VEHICLE_EDIT,
    settings,
    uiState
  }
}

export function uiSessionSettings (settings, uiState) {
  return {
    type: UI_SESSION_SETTINGS,
    settings,
    uiState
  }
}

export function uiInvite (changes) {
  return {
    type: UI_INVITE,
    ...changes
  }
}

export function uiRequest (changes, other) {
  return {
    type: UI_REQUEST,
    ...changes
  }
}

export function uiToast (toast) {
  return {
    type: UI_TOAST,
    toast
  }
}

export function uiRedirect (to) {
  return {
    type: UI_REDIRECT,
    to
  }
}

export function uiSessionRelationship (relationships) {
  return { type: UI_SESSION_RELATIONSHIPS, relationships }
}

export function uiTeamsRelationship (relationships) {
  return { type: UI_TEAMS_RELATIONSHIPS, relationships }
}

export function uiVehiclesRelationship (relationships) {
  return { type: UI_VEHICLES_RELATIONSHIPS, relationships }
}

export function uiTrackType (trackType) {
  return { type: UI_TRACK_TYPE, trackType }
}

export function uiSimPlatform (simulatorPlatform) {
  return { type: UI_SIM_PLATFORM, simulatorPlatform }
}

export function uiFilterTeams (filter) {
  return { type: UI_TEAMS_FILTER, filter }
}

export function uiFilterUsers (filter) {
  return { type: UI_USERS_FILTER, filter }
}

export function uiFilterTracks (filter) {
  return { type: UI_TRACKS_FILTER, filter }
}

export function uiFilterVehicles (filter) {
  return { type: UI_VEHICLES_FILTER, filter }
}

export function uiAdminSelectProduct (product) {
  return { type: UI_ADMIN_SELECTED_PRODUCT, product }
}

export function uiFilterStore (filter = null) {
  return { type: UI_STORE_FILTER, filter }
}

export function uiAddSessionExtra (session, file) {
  return { type: UI_START_ADD_SESSION_EXTRA, session, file }
}

export function uiEditSessionExtra (entityFile) {
  return { type: UI_EDIT_SESSION_EXTRA, entityFile }
}

export function uiTimeConsumingOperationProgress (name = null, progress = 0) {
  return { type: UI_TCO_PROGRESS, name, progress }
}

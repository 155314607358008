/**
 * Wrapper for sendowl.js methods
 */

export function getCartItemsCount () {
  return window && window.sendOwl && window.sendOwl.cartItemCount()
}

export function addProductToCart (product) {
  const r = RegExp('/([a-zA-Z0-9]*)/purchase')
  const match = r.exec(product.buyNowUrl)

  console.log(product, match)

  return new Promise((resolve, reject) => {
    window && window.sendOwl.addProductToCart(product.idSendOwl, match[1], (args) => { resolve(args) })
  })
}

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { TwoLineEntry, RowEntityList, SimBadge } from '../'
import { Badge } from 'react-bootstrap'
import moment from 'moment'
import noImage from '../../images/no_image.jpg'
import { getDistance } from '../../utils/modelConvertionUtils'
import * as _ from 'lodash'

class LayoutList extends React.Component {
  static propTypes = {
    list: PropTypes.object.isRequired,
    lineWidth: PropTypes.number,
    renderButtons: PropTypes.func,
    noRowsRenderer: PropTypes.func,
    fetchingRenderer: PropTypes.func,
    loadMoreRows: PropTypes.func,
    itemSelector: PropTypes.func,
    units: PropTypes.string,
    onLayoutClicked: PropTypes.func
  }

  static defaultProps = {
    itemSelector: (item) => item,
    renderButtons: () => { },
    lineWidth: 99999,
    loadMoreRows: () => {}
  }

  constructor (props) {
    super(props)
    this.renderRow = this.renderRow.bind(this)
  }

  render () {
    const { loadMoreRows, noRowsRenderer, list, fetchingRenderer } = this.props
    return (<RowEntityList
      list={list}
      loadMoreRows={loadMoreRows}
      rowRenderer={this.renderRow}
      noRowsRenderer={noRowsRenderer}
      fetchingRenderer={fetchingRenderer}
    />)
  }

  renderRow ({ index, key, style }) {
    const { renderButtons, list, itemSelector, lineWidth, onLayoutClicked } = this.props
    const item = itemSelector(list.list[index])
    const clickHandler = () => {
      onLayoutClicked && onLayoutClicked(item)
    }
    return (
      <div key={key} style={style}>
        <TwoLineEntry
          image={item.avatarUrl || noImage}
          headline={this.renderHeadline(item, lineWidth)}
          message={this.renderMessage(item, lineWidth)}
          buttons={renderButtons(item)}
          onClick={clickHandler}
          lineWidth />
      </div>)
  }

  renderHeadline (item, lineWidth) {
    return <div>
      <span>{item.name}</span>
      {item.isOfficial && <Badge className='official'>official</Badge>}
      <SimBadge target={item} />
    </div>
  }

  renderMessage (item, lineWidth) {
    const surface = this.getSurfaceType(item)
    const trackType = this.getTrackType(item)
    let type = ''
    if (!surface && !trackType) { type = '' } else if (!trackType) { type = `${surface}` } else { type = `${surface} ${trackType}` }

    const { units } = this.props
    const length = `${getDistance(item.distance || 0, units)}`
    const turnCount = `${item.turnCount || 9} turns`
    const p2p = this.getTrackConfig(item)
    const direction = p2p ? '' : item.isClockwise ? 'clockwise' : 'counter-clockwise'
    if (p2p) { return (<span>{length} {type}</span>) }
    return (<span>{length} {direction} {type} course with {turnCount}</span>)
  }

  getTrackType (layout) {
    const { trackTypes } = this.props.lookups
    const type = _.find(trackTypes, { typeId: layout.typeTrackId })
    return type ? type.description : null
  }

  getTrackConfig (layout) {
    const { trackTypes } = this.props.lookups
    const type = _.find(trackTypes, { typeId: layout.typeTrackId })
    return type ? type.isPointToPoint : false
  }

  getSurfaceType (layout) {
    const { surfaceTypes } = this.props.lookups
    const surface = _.find(surfaceTypes, { typeId: layout.surfaceType })
    return surface ? surface.description : null
  }

  getLatestDate (track) {
    return moment(track.updatedOn).format('M/D/YYYY')
  }
  getSessionCount (track) {
    return 3074
  }
}

export default withRouter(LayoutList)

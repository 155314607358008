import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { TeamSettings } from '../containers'
import { Button } from 'react-bootstrap'
import { Overlay } from '../components'
import { uiTeamEdit } from '../actions/teamUIActions'
import { createTeamAction } from '../actions/teamDbActions'

class TeamEditOverlay extends React.Component {
  static propTypes = {
    openButton: PropTypes.element
  }

  static defaultProps = {
  }

  constructor (props) {
    super(props)
    this.state = { show: false }

    this.okOperation = this.okOperation.bind(this)
    this.cancelOperation = this.cancelOperation.bind(this)
    this.openOverlay = this.openOverlay.bind(this)
  }

  generateOpenButton () {
    const { openButton } = this.props
    if (openButton) {
      return <span onClick={this.openOverlay}>{openButton}</span>
    }

    return <Button onClick={this.openOverlay}>Create team</Button>
  }

  openOverlay (event) {
    event.stopPropagation()
    this.props.dispatch(uiTeamEdit({ name: '', description: '', icon: '', handle: '' }, { valid: false }))
    this.setState({ show: true })
  }

  render () {
    const { session, profileUIState } = this.props

    if (session.isAnonymous) {
      return null
    }

    return <div>
      {this.generateOpenButton()}
      {this.state.show && <Overlay
        show
        title='Create new team'
        buttons={Overlay.createButtons([{ Save: this.okOperation, disabled: !(profileUIState && profileUIState.valid), bsStyle: 'primary' }, { Cancel: this.cancelOperation }])}
        closeAction={this.cancelOperation}>
        <TeamSettings mode='create' />
      </Overlay>}
    </div>
  }

  cancelOperation () {
    this.setState({ show: false })
  }

  okOperation () {
    const { dispatch, session, settings, profileUIState } = this.props
    const spec = { ...settings, newAvatar: profileUIState.avatarPreview || null }
    dispatch(createTeamAction(session.token, spec))
    this.setState({ show: false })
  }
}

function mapStateToProps (state) {
  return {
    session: state.session,
    settings: state.ui.teams.settings,
    profileUIState: state.ui.teams.uiState
  }
}

export default connect(mapStateToProps)(TeamEditOverlay)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Navbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { LeaderboardList, DateIntervalSelector } from '.'
import { fetchFastLapLeaderboards } from '../actions/trackDbActions'
import { fetchHoursLeaderboard, fetchLapsLeaderboard, fetchSessionsLeaderboard, fetchSpeedLeaderboard, fetchTracksLeaderboard, changeLederboardFilter } from '../actions/leaderboardActions'
import { find } from 'lodash'
import { searchToObject } from '../utils/utils'
import { getMeasuringUnitsFromProfile } from '../utils/modelConvertionUtils'
import FA from 'react-fontawesome'

const TYPE_FASTLAP = 'fastlap'
// const TYPE_SPEED = 'speed'
// const TYPE_TRACKS = 'tracks'
// const TYPE_HOURS = 'hours'
// const TYPE_LAPS = 'laps'
// const TYPE_SESSIONS = 'sessions'

class Leaderboard extends Component {
  static propTypes = {
    type: PropTypes.oneOf([TYPE_FASTLAP, 'speed', 'tracks', 'hours', 'laps', 'sessions']).isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      layout: null
    }

    this.onLoadMoreRows = this.onLoadMoreRows.bind(this)
    this.onLayoutChanged = this.onLayoutChanged.bind(this)
    this.onDateFilterChanged = this.onDateFilterChanged.bind(this)
    this.onNavbarFilterChanged = this.onNavbarFilterChanged.bind(this)
  }

  componentDidMount () {
    const {type, session, dispatch, leaderboarFilters} = this.props

    if (type === TYPE_FASTLAP) {
      const track = this.props.track.item
      const fastestLaps = this.props.fastestLaps

      if (track && !fastestLaps.item && !fastestLaps.isFetching) {
        this.handleTrackSupplied(track)
      }
    } else {
      switch (type) {
        case 'hours': dispatch(fetchHoursLeaderboard(session.token, leaderboarFilters[type]))
          break
        case 'laps': dispatch(fetchLapsLeaderboard(session.token, leaderboarFilters[type]))
          break
        case 'sessions': dispatch(fetchSessionsLeaderboard(session.token, leaderboarFilters[type]))
          break
        case 'speed': dispatch(fetchSpeedLeaderboard(session.token, leaderboarFilters[type]))
          break
        case 'tracks': dispatch(fetchTracksLeaderboard(session.token, leaderboarFilters[type]))
          break
        default: console.warn('Unexpected dleaderboard type: ' + type)
      }
    }
  }

  componentWillReceiveProps (nextProps) {
    const {type, session, dispatch} = this.props

    if (type === TYPE_FASTLAP) {
      const track = nextProps.track.item
      const fastestLaps = nextProps.fastestLaps

      const oldFastestLaps = this.props.fastestLaps

      const getLayoutById = (id) => {
        if (!track) {
          return null
        }

        return find(track.layouts, {id})
      }

      if ((track && !fastestLaps.item && !fastestLaps.isFetching) || // new
          (track && fastestLaps.item && Object.keys(fastestLaps.item).length > 0 && !getLayoutById(Object.keys(fastestLaps.item)[0]))) {
        this.handleTrackSupplied(track)
      }

      if (oldFastestLaps.isFetching && !fastestLaps.isFetching && !this.state.layout) {
        this.preselectLayout(fastestLaps)
      }
    } else {
      const oldFilter = this.props.leaderboarFilters[type]
      const filter = nextProps.leaderboarFilters[type]

      if (oldFilter && oldFilter !== filter) {
        switch (type) {
          case 'hours': dispatch(fetchHoursLeaderboard(session.token, filter))
            break
          case 'laps': dispatch(fetchLapsLeaderboard(session.token, filter))
            break
          case 'sessions': dispatch(fetchSessionsLeaderboard(session.token, filter))
            break
          case 'speed': dispatch(fetchSpeedLeaderboard(session.token, filter))
            break
          case 'tracks': dispatch(fetchTracksLeaderboard(session.token, filter))
            break
          default: console.warn('Unexpected dleaderboard type: ' + type)
        }
      }
    }
  }

  onDateFilterChanged (args) {
    const {dispatch, leaderboarFilters, type} = this.props
    dispatch(changeLederboardFilter(type, {...leaderboarFilters[type], ...args}))
    this.onLoadMoreRows(args)
  }

  preselectLayout (fastestLaps) {
    const { track } = this.props
    if (!track.item) {
      return
    }

    let fallbackLayout = track.item.layouts.length > 0 ? track.item.layouts[0] : null

    if (!fallbackLayout || !fastestLaps.item) {
      this.setState({layout: fallbackLayout})
      return
    }

    if (searchToObject().leaderboard) {
      const targetLayoutHandle = searchToObject().leaderboard
      // check if we have such layout & set it if found
      const searchLayout = find(track.item.layouts, {id: targetLayoutHandle})
      if (searchLayout) {
        this.setState({layout: searchLayout})
        return
      }
    }

    // select longest leaderboard
    const getLayoutById = (id) => {
      return find(track.item.layouts, {id})
    }

    const reduced = fastestLaps.item ? Object.keys(fastestLaps.item).reduce(
      (reduced, current) => {
        const layout = getLayoutById(current)
        if (!current) {
          return reduced
        }

        const count = fastestLaps.item[current].list.length

        if (!reduced.layout || reduced.count < count) {
          return { layout, count }
        } else {
          return reduced
        }
      },
      {
        layout: null,
        count: 0
      }
    ) : {}

    this.setState({layout: reduced.layout || fallbackLayout})
  }

  handleTrackSupplied (track) {
    const { dispatch, session } = this.props
    this.setState({layout: null})
    dispatch(fetchFastLapLeaderboards(session.token, track))
  }

  onLayoutChanged (layout) {
    this.setState({ layout: layout })
    this.onLoadMoreRows({layout})
  }

  onNavbarFilterChanged (newFilter) {
    const {type, dispatch, leaderboarFilters} = this.props
    dispatch(changeLederboardFilter(type, {...leaderboarFilters[type], filter: newFilter}))
  }

  renderNavbar () {
    const { track, type, leaderboarFilters } = this.props

    if (type !== TYPE_FASTLAP) {
      return <Navbar className='search-bar' fluid>
        <Navbar.Form>
          <ToggleButtonGroup
            name='layout'
            type='radio'
            bsSize='small'
            onChange={this.onNavbarFilterChanged}
            value={leaderboarFilters[type].filter || 'all'}
          >
            <ToggleButton value={'all'}><span>All</span></ToggleButton>
            <ToggleButton value={'RealWorld'}><FA name='car' /><span>Real World</span></ToggleButton>
            <ToggleButton value={'Virtual'}><FA name='gamepad' /><span>Virtual</span></ToggleButton>
          </ToggleButtonGroup>
        </Navbar.Form>
      </Navbar>
    }

    const { layout } = this.state

    if (!track.item || !track.item.layouts || !track.item.layouts.length) {
      return <div>No layouts found for the track</div>
    }

    return <Navbar className='search-bar' fluid>
      <Navbar.Form>
        <ToggleButtonGroup
          name='layout'
          type='radio'
          value={layout}
          onChange={this.onLayoutChanged}
          bsSize='small'
        >
          {track.item.layouts.map((l) => {
            return <ToggleButton key={l.id} value={l}>{l.name}</ToggleButton>
          })}
        </ToggleButtonGroup>
      </Navbar.Form>
    </Navbar>
  }

  onLoadMoreRows ({startIndex = 0, stopIndex, layout, startDate, endDate}) {
    const nextLayout = layout || this.state.layout
    const { dispatch, track, session, type } = this.props

    switch (type) {
      case TYPE_FASTLAP: nextLayout && dispatch(fetchFastLapLeaderboards(session.token, track, { layoutId: nextLayout.id, skip: startIndex, startDate, endDate }))
        break
      default: console.err('Unexpected type: ' + type)
    }
  }

  extarctList () {
    const { fastestLaps, track, type } = this.props

    const { layout } = this.state

    if (type === TYPE_FASTLAP) {
      if (!track.item || !track.item.layouts || !track.item.layouts.length) {
        return null
      }

      if (!layout) {
        return null // <div>Layout is not selected</div>
      }

      return fastestLaps.item ? fastestLaps.item[layout.id] : {list: [], isFetching: fastestLaps.isFetching}
    }

    switch (type) {
      case 'tracks': return this.props.leaderboardTracks
      case 'speed': return this.props.leaderboardSpeed
      case 'hours': return this.props.leaderboardHours
      case 'laps': return this.props.leaderboardLaps
      case 'sessions': return this.props.leaderboardSessions
      default: return null
    }
  }

  renderList () {
    const { track, session, type } = this.props
    const { layout } = this.state

    if (type === TYPE_FASTLAP) {
      // special case
      // not loaded?
      if (!track.item || !track.item.layouts || !track.item.layouts.length) {
        return null
      }

      if (!layout) {
        return <div>Layout is not selected</div>
      }
    }

    const list = this.extarctList()

    return list && <LeaderboardList
      list={list}
      units={getMeasuringUnitsFromProfile(session.profile)}
      type={type}
      loadMoreRows={this.onLoadMoreRows}
    />
  }

  render () {
    const {type, leaderboarFilters} = this.props

    return (
      <div className='leaderboard__container'>
        {this.renderNavbar()}
        {type === TYPE_FASTLAP && <DateIntervalSelector onDatesChanged={this.onDateFilterChanged} {...leaderboarFilters[type]} />}
        {this.renderList()}
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    session: state.session,
    fastestLaps: state.tracks.selectedFastestLaps,
    track: state.tracks.selected,

    leaderboardHours: state.leaderboards.leaderboardHours,
    leaderboardLaps: state.leaderboards.leaderboardLaps,
    leaderboardSessions: state.leaderboards.leaderboardSessions,
    leaderboardSpeed: state.leaderboards.leaderboardSpeed,
    leaderboardTracks: state.leaderboards.leaderboardTracks,

    leaderboarFilters: state.leaderboards.leaderboarFilters
  }
}

export default connect(mapStateToProps)(Leaderboard)

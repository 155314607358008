import React from 'react'
import { Link } from 'react-router-dom'
import { Section } from '../../components'
import { ROUTE_PRIVACY } from '../../utils/routingConstants'
import { page } from '../../hocs/page'
import { GA_PAGES } from '../../api/gaHelper'

const PageTermsOfService = () => {
  return (
    <Section title='Terms Of Use' className='page-legal'>
      <div className='page-legal-updated'>Last Updated: January 23, 2018</div>
      <h3>Track Attack Terms of Use</h3>

      <div className='page-legal-paragraph'>
        <h4>1. Your Acceptance of These Terms</h4>
        <p>These Terms of Use (“Terms”) govern your use of our website, Software or services (“Services”). We reserve the right to update the Terms at any time without notice to you. The most current version of the Terms replaces the prior version in its entirety. Subject to your compliance with these terms and the law, you may access and use the Services.</p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>2. Privacy and Use of Personal Information</h4>
        <p>See our <Link to={ROUTE_PRIVACY}>Privacy Policy</Link>, which governs any personal information you provide to us. By using the Services or Software you agree to the terms of the Privacy Policy.</p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>3. Use Limitations and Intellectual Property</h4>
        <p>Unless otherwise specified, the Services are for your personal and non-commercial use. You may not modify, copy, distribute, transmit, host, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, content, software, products or services obtained from the Services. We (and our partners, where indicated) remain the sole owner of all right, title, and interest in the Services and Software and in all content and data collected or created by the Software. You agree that Track Attack shall in all circumstances be deemed the sole creator of all data collected or created by the Software, in whatever form. We reserve all rights not granted under these terms.</p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>4. Notice Specific to Software and Services</h4>
        <p>Any software that is made available to download from the Services (“Software”) is the copyrighted work of Track Attack and/or its partners or suppliers. Use of the Software is governed by the terms of the end user license agreement, if any, which accompanies or is included with the Software (“License Agreement”). An end user will be unable to install any Software that is accompanied by or includes a License Agreement, unless he or she first agrees to the License Agreement terms. Third party scripts or code, linked to or referenced from this website, are licensed to you by the third parties that own such code, not by Track Attack.</p>
        <p>The Software is made available for download solely for use by end users according to the License Agreement. Any reproduction or redistribution of the Software not in accordance with the License Agreement is expressly prohibited by law, and may result in severe civil and criminal penalties. Violators will be prosecuted to the maximum extent possible. We may modify, update, or discontinue the Services or Software (including any of their portions or features) at any time without liability to you or anyone else.</p>
        <p>WITHOUT LIMITING THE FOREGOING, COPYING OR REPRODUCTION OF THE SOFTWARE TO ANY OTHER SERVER OR LOCATION FOR FURTHER REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PROHIBITED. THE SOFTWARE IS WARRANTED, IF AT ALL, ONLY ACCORDING TO THE TERMS OF THE LICENSE AGREEMENT. EXCEPT AS WARRANTED IN THE LICENSE AGREEMENT, TRACK ATTACK HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE SOFTWARE, INCLUDING ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>
        <p>FOR YOUR CONVENIENCE, TRACK ATTACK MAY MAKE AVAILABLE AS PART OF THE SERVICES OR IN ITS SOFTWARE PRODUCTS, TOOLS AND UTILITIES FOR USE AND/OR DOWNLOAD. TRACK ATTACK DOES NOT MAKE ANY ASSURANCES WITH REGARD TO THE ACCURACY OF THE RESULTS OR OUTPUT THAT DERIVES FROM SUCH USE OF ANY SUCH TOOLS AND UTILITIES. PLEASE RESPECT THE INTELLECTUAL PROPERTY RIGHTS OF OTHERS WHEN USING THE TOOLS AND UTILITIES MADE AVAILABLE THROUGH THE SERVICES OR IN TRACK ATTACK SOFTWARE PRODUCTS.</p>
        <p>IN NO EVENT SHALL TRACK ATTACK AND/OR ITS RESPECTIVE SUPPLIERS BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER OR NOT FORESEEABLE, WHETHER IN AN ACTION OF CONTRACT, WARRANTY, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OF, ACCESS TO OR PERFORMANCE OF SOFTWARE, PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR INFORMATION AVAILABLE FROM THE SERVICES.</p>
        <p>Our total liability in any matter arising out of or related to these terms is limited to US $100 or the aggregate amount that you paid for access to the Service and Software during the three- month period preceding the event giving rise to the liability, whichever is larger. This limitation will apply even if we have been advised of the possibility of the liability exceeding the amount and notwithstanding any failure of essential purpose of any limited remedy. The limitations and exclusions in this Section 4 apply to the maximum extent permitted by law.</p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>5. No Unlawful or Prohibited Use</h4>
        <p>As a condition of your use of the Services, you will not use the Services for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You may not use the Services in any manner that could interfere with any other party’s use and enjoyment of any Services. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available through the Services. The Software, Services, content, and your use of the Software, Services, and content, are subject to U.S. and international laws, restrictions, and regulations that may govern the import, export, and use of the Software, Services, and content. You agree to comply with all the laws, restrictions, and regulations.</p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>6. Indemnification</h4>
        <p>You will indemnify us and our subsidiaries, affiliates, officers, agents, employees, partners, and licensors from any claim, demand, loss, or damages, including reasonable attorneys’ fees, arising out of or related to your content, your use of the Services or Software, or your violation of these terms</p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>7. Applicable Law and Dispute Resolution</h4>
        <p>You agree that all matters relating to your access to or use of our websites, the Services or the Software, including all disputes, will be governed by the laws of the United States and by the laws of the State of Washington without regard to its conflicts of laws provisions. You agree to the personal jurisdiction by and venue in the state and federal courts in King County, Washington, and waive any objection to such jurisdiction or venue. The preceding provision regarding venue does not apply if you are a consumer based in the European Union. If you are a consumer based in the European Union, you may make a claim in the courts of the country where you reside. Any claim under these Terms of Use must be brought within one (1) year after the cause of action arises, or such claim or cause of action is barred. Claims made under the separate terms and conditions of purchase for goods and services are not subject to this limitation.</p>
        <p>No recovery may be sought or received for damages other than out-of-pocket expenses, except that the prevailing party will be entitled to costs and attorneys’ fees. In the event of any controversy or dispute between Track Attack and you arising out of or in connection with your use of our websites, the Services or the Software, the parties shall attempt, promptly and in good faith, to resolve any such dispute. If we are unable to resolve any such dispute within a reasonable time (not to exceed thirty (30) days), then either party may submit such controversy or dispute to mediation. If the dispute cannot be resolved through mediation, then the parties must resolve any claims through final and binding arbitration, except that you may assert claims in small claims court if your claims qualify. Notwithstanding the foregoing, in the event of your or others’ unauthorized access to or use of the Services or content in violation of these terms you agree that we are entitled to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction</p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>8. Modification</h4>
        <p>We may modify these terms or any additional terms that apply to a Service or Software to, for example, reflect changes to the law or changes to our Services or Software. You should look at the terms regularly. We will post notice of modifications to these terms on this page. We will post notice of modified additional terms in the applicable Service or Software. By continuing to use or access the Services or Software after the revisions come into effect, you agree to be bound by the revised terms.</p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>9. Notice to You</h4>
        <p>We may notify you by email, postal mail, postings within the Services, or other legally acceptable means.</p>
      </div>

      <div className='page-legal-paragraph'>
        <h4>10. Miscellaneous</h4>
        <p>The English version of these terms will be the version used when interpreting or construing these terms. These terms, together with applicable License Agreements, constitute the entire agreement between you and us regarding your use of the Services and Software and supersede any prior agreements between you and us relating to the Services. You may not assign or otherwise transfer these terms or your rights and obligations under these terms, in whole or in part, without our written consent and any such attempt will be void. We may transfer our rights under these terms to a third party. If a particular term of these Terms or our Privacy Policy is not enforceable, the unenforceability of that term will not affect any other terms. Our failure to enforce or exercise any of these terms is not a waiver of that term.</p>
      </div>

    </Section>
  )
}

export default page(PageTermsOfService, { pageView: GA_PAGES.TERMS })

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import placeholder from '../images/no_image.jpg'
import { Row, Col, ControlLabel, Button, Thumbnail, Badge } from 'react-bootstrap'
import { SimBadge } from './'

const propTypes = {
  layoutInfo: PropTypes.object.isRequired,
  onStartEdit: PropTypes.func.isRequired,
  validTypes: PropTypes.array.isRequired,
  surfaceTypes: PropTypes.array.isRequired
}

class TrackLayoutView extends Component {
  renderTitleAndValue (title, value, paddingLeft) {
    return (
      <Row className='title-value-row' style={{ paddingLeft: paddingLeft }}>
        <Col xs={3}>
          <ControlLabel className='info-title title-label'>{title}:</ControlLabel>
        </Col>
        <Col xs={9}>
          <ControlLabel className='info-title value-label'>{value}</ControlLabel>
        </Col>
      </Row>
    )
  }

  renderTitleAndPair (mainTitle, lat, lng, paddingLeft) {
    return (
      <Row className='title-pair-row' style={{ paddingLeft: paddingLeft }}>
        <Col xs={12}>
          <Row><ControlLabel className='info-title title-label'>{mainTitle}:</ControlLabel></Row>
          <Row className='pair-value-row'>
            <Col xs={6}><ControlLabel className='info-title value-label'>Lat: {lat || ''}</ControlLabel></Col>
            <Col xs={6}><ControlLabel className='info-title value-label'>Long: {lng || ''}</ControlLabel></Col>
          </Row>
        </Col>
      </Row>
    )
  }

  render () {
    const { layoutInfo } = this.props

    let segmentsArray
    try {
      if (layoutInfo.segments instanceof Array) { segmentsArray = layoutInfo.segments } else { segmentsArray = JSON.parse(layoutInfo.segments) }
    } catch (ex) {
      segmentsArray = []
    }

    if (!segmentsArray) {
      segmentsArray = []
    }

    return (
      <div className='layout-view-container'>
        <Row>
          <Col xs={6}>
            <h3>{layoutInfo.name}</h3>
            <div>
              {layoutInfo.isOfficial && <Badge className='official'>official</Badge>}
              <SimBadge target={layoutInfo} />
            </div>

            {this.renderTitleAndValue('# of Turns', layoutInfo.turnCount, 0)}

            {this.renderTitleAndValue('Distance (meters)', layoutInfo.distance || '0', 0)}

            {this.renderTitleAndValue('Orientation', layoutInfo.isClockwise === 1 ? 'Clockwise' : 'Counter-Clockwise', 0)}

            {this.renderTitleAndValue('SurfaceType', this.surfaceTypeDescription(this.props.layoutInfo.surfaceType), 0)}

            {this.renderTitleAndValue('Type', this.typeDescription(this.props.layoutInfo.typeTrackId), 0)}

            {this.renderTitleAndValue('3rd party ID', this.props.layoutInfo.thirdPartyId || '')}

            {this.renderTitleAndValue('Start point heading', this.props.layoutInfo.startPointAngle || '0')}

            {this.renderTitleAndValue('End point heading', this.props.layoutInfo.endPointAngle || '0')}

            {this.renderTitleAndPair('Start Line', this.props.layoutInfo.startPointLat, this.props.layoutInfo.startPointLong, 0)}

            {this.renderTitleAndPair('Finish Line', this.props.layoutInfo.endPointLat, this.props.layoutInfo.endPointLong, 0)}

            {
              segmentsArray.map((item, index) =>
                <div key={index}>
                  {this.renderTitleAndPair('Segment ' + (index + 1), item.lat, item.lon, 30)}
                </div>)
            }
          </Col>

          <Col xs={6}>
            <ControlLabel className='info-title'>Layout Map Image</ControlLabel>
            <Thumbnail src={placeholder} alt='' className='image-thumbnail' />
          </Col>
        </Row>

        <Row className='hcenter'>
          <Button onClick={() => this.props.onStartEdit(this.props.layoutInfo.id)}>Edit Layout</Button>
        </Row>
      </div>
    )
  }

  typeDescription (typeId) {
    if (!this.props.validTypes) {
      return ''
    }

    let findResult = this.props.validTypes.find((item) => {
      return item.value === typeId
    })

    if (findResult) { return findResult.label }

    return ''
  }

  surfaceTypeDescription (surfaceTypeId) {
    if (!this.props.surfaceTypes) {
      return ''
    }

    let findResult = this.props.surfaceTypes.find((item) => {
      return item.value === surfaceTypeId
    })

    if (findResult) {
      return findResult.label
    }

    return ''
  }
}

TrackLayoutView.propTypes = propTypes

export default TrackLayoutView
